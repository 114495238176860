
import React, { Component } from "react";
import swal from 'sweetalert';
import Cookies from 'universal-cookie';

import ApiList from "../../../config/api.js";
import CommonApi from "../../../util/common_api_handler.js"
import HeaderJson from '../../../util/headers.js';


import '../../../stylesheet/user/user_detail/subplan.css';


const cookies = new Cookies();
export default class Subscription extends Component {
    state = {
        currentPlanData: '',
        nextPlanData: '',
        userPersonalData: '',
        planOuterId: '',
        planInnerId: '',
        expireData: '',
        userId: cookies.get('userId')
    }

    componentDidMount = async () => {
        const currentPlanFunction = this.currentPlan();
        const nextPlanFunction = this.nextPlan()
        await Promise.all([currentPlanFunction, nextPlanFunction]);

    }

    currentPlan = () => {
        const self = this;

        const apiUrl = ApiList.current.url + ApiList.endpoints.user.userProfile;

        const apiHeaders = HeaderJson.header();

        CommonApi.getCall(apiUrl, apiHeaders)
            .then((data) => {
                if (data.statusCode === 200) {
                    let userData = data != null ? (data.data) : null;
                    let keyData = [];
                    userData.SubscriptionStatusData.map((key) => {
                       return keyData.push(key.payment_gateway_response.TXNDATE)
                    })
                    self.setState({
                        userPersonalData: data.data,
                        expireData: data.data.expiresInDays,
                        currentPlanData: userData != null ? userData.SubscriptionData : ""
                    });
                }
            })
    }

    nextPlan = () => {
        const self = this;

        const apiUrl = ApiList.current.url + ApiList.endpoints.nextSubscription.nextSubscription;

        const apiHeaders = HeaderJson.header();

        CommonApi.getCall(apiUrl, apiHeaders)
            .then((data) => {
                if (data.statusCode === 200) {
                    let userData = data != null ? (data.data) : null;
                    self.setState({
                        nextPlanData: userData != null ? userData : null,
                        planOuterId: userData != null ? userData._id : null,
                        planInnerId: userData != null ? userData._id : null
                    });
                }
            })
    }

    redirectPlan = () => {
        const self = this;
        window.location.href = self.state.planInnerId != null ? "/payment?outer=" + self.state.planOuterId + "&inner=" + self.state.planInnerId : "/payment?outer=" + self.state.planOuterId
    }

    redirectPayment = (plan_code) => {
        var terms = document.getElementById('defaultCheck1');
        if (!terms.checked) {
            swal({ text: "Please signify your agreement with our terms.", timer: 1500 })
            return false;
        } else {

            const subPlatformCode = plan_code.toUpperCase();

            if (this.state.userPersonalData.is_email_verified === true || this.state.userPersonalData.is_otp_verified === true) {

                const apiUrl = ApiList.current.url + ApiList.endpoints.user.createOrderIdSvod;

                const apiHeaders = HeaderJson.header();

                const bodyData = {
                    "sub_platform_code": subPlatformCode,
                    "voucher": "",
                    "actual_amount":  cookies.get('country') === 'IN' ? this.state.nextPlanData.region[0].price : this.state.nextPlanData.region[0].price_usd,
                    "final_amount":  cookies.get('country') === 'IN' ? this.state.nextPlanData.region[0].discount : this.state.nextPlanData.region[0].discount_usd,
                    "payment_gateway": "",
                }

                CommonApi.postCall(apiUrl, apiHeaders, bodyData)
                    .then((data) => {
                        if (data.statusCode === 200) {
                            window.location.href = ApiList.current.paymentGateway + `/subscription-plan/${data.data.order_id}/${HeaderJson.header().user_id}/abcd/${cookies.get('country')}`
                        }
                    })
            }
            else {
                swal({ text: "Please Verify Your Email And Mobile Number", timer: 1500 })
            }
        }

    }
    render() {
        const { currentPlanData, expireData, nextPlanData } = this.state;
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12 primary-container-padding">
                        <div className="row">
                            {currentPlanData.length > 0 ?
                                <div className="col-md-6 col-sm-12 col-12 py-4">
                                    <div className="card sub-card-current">
                                        <div className="card-body subplan-body">
                                            <h6 className="text-center current-plan py-3">Active Plan</h6>
                                            {
                                                cookies.get('country') === 'IN' ?
                                                    <>
                                                        <span className="discount">&#8377; {currentPlanData.length > 0 ? currentPlanData[0].region[0].price : ''}/ {currentPlanData.length > 0 ? currentPlanData[0].duration : ''}Month</span>
                                                        <div className="price-to-pay">
                                                            <span className="amount"><sup>&#8377;</sup>{currentPlanData.length > 0 ? currentPlanData[0].region[0].discount : ''}/<span>{currentPlanData.length > 0 ? currentPlanData[0].duration : ''}Month</span> </span>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <span className="discount">&#x24; {currentPlanData.length > 0 ? currentPlanData[0].region[0].price_usd : ''}/ {currentPlanData.length > 0 ? currentPlanData[0].duration : ''}Month</span>
                                                        <div className="price-to-pay">
                                                            <span className="amount"><sup>&#x24;</sup>{currentPlanData.length > 0 ? currentPlanData[0].region[0].discount_usd : ''}/<span>{currentPlanData.length > 0 ? currentPlanData[0].duration : ''}Month</span> </span>
                                                        </div>
                                                    </>
                                            }

                                            <h4 className="text-center current-plan-name pt-2">{currentPlanData.length > 0 ? currentPlanData[0].sub_platform_code : null}</h4>
                                            <div className="text-center pt-2">
                                                <span className="plan-content mx-2"> {currentPlanData.length > 0 ? currentPlanData[0].video_quality : null} </span>
                                                <span className="dot-className" >
                                                    <i className="fa fa-circle" aria-hidden="true" style={{ fontSize: '5px' }}></i>
                                                </span>
                                                <span className="plan-content mx-2"> {currentPlanData.length > 0 ? currentPlanData[0].region[0].user_access : null} </span>
                                                <span className="dot-className" >
                                                    <i className="fa fa-circle" aria-hidden="true" style={{ fontSize: '5px' }}></i>
                                                </span>
                                                <span className="plan-content mx-2 pb-3"> {currentPlanData.length > 0 ? currentPlanData[0].ads_display : null} </span>
                                            </div>
                                            <div className="text-center pt-2">
                                                <span className="plan-content">Downloads upto {currentPlanData.length > 0 ? (currentPlanData[0].region[0].downloads / 1024).toFixed(2) : null} GB per month </span>
                                            </div>
                                            {
                                                cookies.get("plan-order") > 1
                                                    ?
                                                    <div className="text-center pb-2 pt-1">
                                                        <span style={{ color: 'red' }}> {expireData > 0 ? `Expires In ${expireData} Days` : `Expired`} </span>
                                                    </div>
                                                    :
                                                    <div className="text-center pb-2 pt-1">
                                                        {/* <span style={{color:'red'}}>Expires In {expireData} Days</span> */}
                                                    </div>
                                            }

                                            <button type="button" className="btn gradient1-bg select-button text-white gemplex-regular  br18" onClick={this.redirectPlan} >UPGRADE PLAN</button>
                                        </div>
                                    </div>
                                </div>
                                :
                                ''
                            }
                            {nextPlanData ?
                                <div className="col-md-6 col-sm-12 col-12 py-4">
                                    <div className="card sub-card-next">
                                        <div className="card-body subplan-body">
                                            <h6 className="text-center recommended-plan py-3">Recommended Plan</h6>
                                            {
                                                cookies.get('country') === 'IN' ?
                                                    <>
                                                        <span className="discount">&#8377; {nextPlanData ? nextPlanData.region[0].price : ''}/ {nextPlanData ? nextPlanData.duration : ''}Month</span>
                                                        <div className="price-to-pay">
                                                            <span className="amount"><sup>&#8377;</sup>{nextPlanData ? nextPlanData.region[0].discount : ''}/<span>{currentPlanData ? nextPlanData.duration : ''}Month</span> </span>
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <span className="discount">&#x24; {nextPlanData ? nextPlanData.region[0].price_usd : ''}/ {nextPlanData ? nextPlanData.duration : ''}Month</span>
                                                        <div className="price-to-pay">
                                                            <span className="amount"><sup>&#x24;</sup>{nextPlanData ? nextPlanData.region[0].discount_usd : ''}/<span>{currentPlanData ? nextPlanData.duration : ''}Month</span> </span>
                                                        </div>
                                                    </>
                                            }

                                            <h4 className="text-center current-plan-name pt-2">{nextPlanData ? nextPlanData.sub_platform_code : null}</h4>
                                            <div className="text-center pt-2">
                                                <span className="plan-content mx-2"> {nextPlanData ? nextPlanData.video_quality : null} </span>
                                                <span className="dot-className">
                                                    <i className="fa fa-circle" aria-hidden="true" style={{ fontSize: '5px' }}></i>
                                                </span>
                                                <span className="plan-content mx-2"> {nextPlanData ? nextPlanData.region[0].user_access : null} </span>
                                                <span className="dot-className" >
                                                    <i className="fa fa-circle" aria-hidden="true" style={{ fontSize: '5px' }}></i>
                                                </span>
                                                <span className="plan-content mx-2 pb-3"> {nextPlanData ? nextPlanData.ads_display : null} </span>
                                            </div>
                                            <div className="text-center pt-2">
                                                <span className="plan-content">Downloads upto {nextPlanData ? (nextPlanData.region[0].downloads / 1024).toFixed(2) : null} GB per month </span>
                                            </div>
                                            <div className="text-center pb-3">
                                                {/* <span className="plan-content">1 User Device</span> */}
                                            </div>
                                            <div className="form-check py-2">
                                                <input className="form-check-input" type="checkbox" value="" id="defaultCheck1" style={{ top: '5px' }} />
                                                <label className="form-check-label" htmlFor="defaultCheck1">
                                                    <small style={{ fontSize: '10px' }}>I agree to above <a href="/terms" target="_blanck" style={{ fontSize: '10px' }} >Terms & Conditions</a> and <a href="/privacy" target="_blanck" style={{ fontSize: '10px' }} >Privacy Policy</a>.</small>
                                                </label>
                                            </div>
                                            <button type="button" className="btn gradient1-bg select-button text-white gemplex-regular  br18" onClick={() => this.redirectPayment(nextPlanData.sub_platform_code)} >BUY PLAN</button>
                                        </div>
                                    </div>
                                </div>
                                :
                                ""
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}