import React, { Component } from "react";
import Skeleton from 'react-loading-skeleton';
import SliderComp from './HypeSlider.jsx';

class RComp extends Component {
  state = {
    slider: [],
    genre_slider: [],
    slide_data: '',
  };

  componentDidMount() {
    //scrollTo Function use because every time page start form top.
    window.scrollTo(0, 0);
    this.fetch_data(this.props.api_data);
  }

  fetch_data = () => {
    if (this.props.api_data != null) {
      let component_arry_var = [];
      this.props.api_data.map((single) => {
      return component_arry_var.push(<SliderComp slider_data={single} key={JSON.stringify(single)} />);
      });
      return component_arry_var;
    }
  }

  renderSkeletonTiles = () => {
    var aa = [];
    for (let i = 0; i < 5; i++) {
      aa.push(
        <div key={i}>
          {
            this.props.api_data > 0 ?
              <div>
                <div className="mr-2" style={{ "height": "150px", "width": "170px" }}>
                  <Skeleton width={"100%"} height={"100%"} />
                </div>
                <div className="mt-1" style={{ "width": "120px" }}>
                  <label className={"m-0"} style={{ "width": "80px" }}>
                    <Skeleton width={"100%"} height={"100%"} />
                  </label>
                  <label style={{ "width": "120px" }}>
                    <Skeleton width={"100%"} height={"100%"} />
                  </label>
                </div>
              </div>
              : ''
          }
        </div>
      )
    }
    return aa;
  }

  render() { 
    return (
      <div className="container-fluid mt-2" style={{ "minHeight": "213px" }}>
        <div className="container-fluid" style={{ "minHeight": "213px" }}>
          {this.props.api_data.length > 0 ?
            this.fetch_data()
            :
            <div className="row p-0 m-0">
              {this.renderSkeletonTiles()}
            </div>
          }
        </div>
      </div>
    );
  }
}

export default RComp;
