import React, { Component } from 'react';
import Navigation from '../partials/NavigationBar.jsx'
import Table from './table'
import Filter from "../partials/filter"
import Footer from '../partials/footer';




export default class LiveGuide extends Component {
    constructor() {
        super();
        this.state = {
        }
    }

    componentDidMount() {

    }


    // function=()=>{

    // }

    render() {
        return (
            <div>
                <Navigation/>
                <Filter/>
                <Table/>
                <Footer/>
            </div>

        )
    }

}