import React, { Component } from 'react';
import $ from 'jquery';
import axios from 'axios';
import Header from "../partials/header";
import NavBar from "../partials/NavigationBar.jsx";
import helpers from '../../util/headers.js';
import Footer from '../partials/footer';
import ApiList from "../../config/api.js"
import '../../stylesheet/legal/policy.css'
import "../../stylesheet/information_page/font_color.css"

export default class Name extends Component {
    constructor() {
        super();
        this.state = {
            headers: helpers.header(),
        }
    }

    componentDidMount() {
        this.fetchData();
    }
    fetchData(){
            let dataString = {
               "slug":"/privacy-policy" 
            }
            axios.post(ApiList.current.url + ApiList.endpoints.footer.pageDetails,dataString,{
                headers: this.state.headers
            })
            .then(res=>res.data)
            .then(data=>{
                if(data.statusCode === 200){
                    $('.policy-data').html(atob(data.data.html_content))
                }
            })
    }
    render() {
        return (
            <div>
                <Header />
                <NavBar />
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 mt-5">
                                <div className="policy-data footer-page">
                                </div>
                            </div>
                        </div>
                    </div>
                <Footer />
            </div>

        )
    }

}