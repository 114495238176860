import React, { Component } from "react";
import Cookies from 'universal-cookie';
import $ from "jquery";
import "../../../stylesheet/music_detail/subscription.css";
const cookies = new Cookies();


var userType


export default class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    componentDidMount = () => {
        userType =  cookies.get('userType') 
    };
    choosePlan = () => {
        if (userType !== 'GUEST') {
          window.location.href = "/payment";
        } else {
          $(".openSignIn").click();
        }
      };
    render() {
        return (
            <div style={{ display: 'inline-block' }}>
                <span >
                    <span className="d-inline-block open-subscription-modal" data-toggle="modal" data-target="#subcription_modal_box" style={{ margin: "0 0 0 10px", cursor: "pointer" }}>
                        <span className="border-align ">subscription</span>
                    </span>
                </span>
                <div className="modal fade" id="subcription_modal_box" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered " role="document">
                        <div className="modal-content" style={{color:'black'}}>
                            <div className="modal-body sub-modal-body">
                                <div
                                    className="row justify-content-center align-items-center music-vod-false primary-container-padding position-relative"
                                    // style={styleRowBgImage}
                                >
                                    <div className="position-absolute vod-layer" />
                                    <div className="col-xl-12 col-md-12 col-sm-8 col-12 text-center primary-container-padding t-vod-vod-section">
                                        <div>
                                            <div className=" text-1 mb-3">
                                                Get premium membership now
                                        </div>
                                            <div className=" cursor text-3 mt-2">
                                                <div onClick={this.choosePlan}>Choose your Plan</div>
                                            </div>
                                            <div className="hr-line" />
                                            <div className="text-2">
                                                Existing member? Please logout and sign in again to
                                                refresh
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}
