import "core-js";
import "core-js/es6/map";
import "core-js/es6/set";
import "raf/polyfill";
import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'jquery/src/jquery';

import HomePage from "./components/home_page/Home";
import DetailPage from "./components/detail_page/MovieDetail.jsx";
import OriginalPage from "./components/original_page/Originals.jsx";
import MoviesPage from "./components/movie_page/MoviePage.jsx";
import MusicPage from "./components/music_detail_page/MusicPage.jsx";
import HypePage from "./components/hype/HypePage.jsx";

import CastDetails from "./components/cast/CastHome.jsx"; 
import CastListing from "./components/cast/CastList.jsx";
import VideosPage from "./components/videos_page/VideoPage.jsx";


import Payment from "./components/payment/index"


import HypeDetail from "./components/hype/hype_detail/hype_detail.jsx" 
import HypeTagList from "./components/hype/HypeTag.jsx" 

import WatchlistFavourite from "./components/watchlist_favourite/UserList.jsx";

import Disclaimer from "./components/legal_page/disclaimer";
import AboutUs from "./components/information_page/about_us";
import ContactUs from "./components/information_page/contact_us";
import FAQ from "./components/information_page/faqs";
import FeedBck from "./components/information_page/feedback";
import Support from "./components/information_page/support";
import Career from "./components/information_page/career";
import PrivacyPage from "./components/legal_page/privacy_police";
import TermsPage from "./components/legal_page/terms_condition";
import ContentSeries from "./components/content/series";
import ContentVideo from "./components/content/VideoSeeAll.jsx";
import ContentGenre from "./components/content/GenreSeeAll.jsx";
import ContentLanugage from "./components/content/LanguageSeeAll.jsx";
import ContentHype from "./components/content/hype";
import PageMangerList from "./components/information_page/index";

import CartHome from "./components/cart/CartPage.jsx"

import Page from "./components/email_verification/page/index";
import Tags from "./components/hype/common_component/index.jsx";
// For roku payment
import TvsStepOne from "./components/tv/step_one";
import TvsStepTwo from "./components/tv/step_two";
import TvsStepThree from "./components/tv/step_three";
import TvsStepFour from "./components/tv/step_four";

// User section
import UserDetail from "./components/user/user_detail/ProfilePage.jsx";
import MyOrder from "./components/user/my_order/Purchase.jsx";
import MyMovies from "./components/user/my_movies/MyMovies.jsx";

// live guide
import LiveGuide from "./components/live_guide/index";
// import Support from './components/information_page/support';
//coupon list
import Coupon from './components/coupon/coupon-list';
import CouponTvod from './components/coupon/coupon';

ReactDOM.render(
  <Router>
    <Switch>
      <Route exact path="/email" component={Page} />
      <Route exact path="/" component={HomePage} />
      <Route exact path="/login" component={HomePage} />
      <Route exact path="/detail/:title/:content_id" component={DetailPage} />]
      {/* /detail/:catalogue/:subcat/:name/:content_id */}
      <Route exact path="/originals" component={OriginalPage} />
      <Route exact path="/cast/:cast_id" component={CastDetails} />
      <Route exact path="/music" component={MusicPage} />
      <Route exact path="/movies" component={MoviesPage} />
      <Route exact path="/videos" component={VideosPage} />
      <Route exact path="/watchlist_favourite" component={WatchlistFavourite} />
      <Route exact path="/content/series/:content_id" component={ContentSeries} />
      <Route exact path="/content/video/:type/:catalogue/:content/:cell/:ratio" component={ContentVideo} />?<Route exact path="/content/video/:type/:catalogue/:content/:cell/:ratio" component={ContentVideo} />:<Route exact path="/content/video/:type/:content/:cell/:ratio" component={ContentVideo} />
      <Route exact path="/genre/type=:type/content=:content/heading=:name" component={ContentGenre} />
      <Route exact path="/language/type=:type/content=:content/heading=:name" component={ContentLanugage} />
      <Route exact path="/content/:type/:content/:cell/:ratio" component={ContentHype} />
      <Route exact path="/payment" component={Payment} />
      {/* payemnt callback and gateway */}
      <Route exact path="/cast-producer-listings" component={CastListing} />
      <Route exact path="/hype" component={HypePage} />
      <Route exact path="/hype/:type/:name/:content_id" component={HypeDetail} /> 
      <Route exact path="/hype/:genres" component={HypeTagList} />
      <Route exact path="/about_us" component={AboutUs} />
      <Route exact path="/privacy-policy" component={PrivacyPage} />
      <Route exact path="/terms-and-conditions" component={TermsPage} />
      <Route exact path="/disclaimer" component={Disclaimer} />
      <Route exact path="/support" component={Support} />
      <Route exact path="/career" component={Career} />
      <Route exact path="/faqs" component={FAQ} />
      <Route exact path="/feedback" component={FeedBck} />
      <Route exact path="/contactus" component={ContactUs} />
      <Route exact path="/page/:page_name" component={PageMangerList} />
      {/* <Route exact path="/test" component={test} /> */}
      <Route exact path="/hype/:content" component={Tags} />
      <Route exact path="/tv_step_one" component={TvsStepOne} />
      <Route exact path="/tv_step_two" component={TvsStepTwo} />
      <Route exact path="/tv_step_three" component={TvsStepThree} />
      <Route exact path="/tv_step_four" component={TvsStepFour} />
      {/* Cart */}
      <Route exact path="/cart" component={CartHome} />
      {/* User section */}
      <Route exact path="/user" component={UserDetail} />
      <Route exact path="/order-list" component={MyOrder} />
      <Route exact path="/purchase-movies" component={MyMovies} />
      {/* live guide sectionterms_condition */}
      <Route exact path="/live_guide" component={LiveGuide} />
      <Route exact path="/coupon" component={Coupon} />
      <Route exact path="/coupons" component={CouponTvod} />
    </Switch>
  </Router>,

  document.getElementById("root")
);

serviceWorker.unregister();
