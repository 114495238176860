// sample.js
import $ from 'jquery';
import moment from 'moment';
import React, { Component } from "react";
import ApiList from "../../config/api.js";
import "../../stylesheet/partials/footer.css";
import HeaderJson from '../../util/headers.js';
import CommonApi from "../../util/common_api_handler.js"; 
export default class Footer extends Component {
  constructor() {
    super();
    this.state = {
      currentYear: moment().year(),
      cookieSection: false,
      pageListing: []
    };
  }

  componentDidMount=()=>{
    this.fetchPageMangerList()

    // ===== Scroll to Top ==== 
    $(window).scroll(function () {
      if ($(this).scrollTop() >= 2000) {        // If page is scrolled more than 50px
        $('#return-to-top').fadeIn(200);    // Fade in the arrow
      } else {
        $('#return-to-top').fadeOut(200);   // Else fade out the arrow
      }
    });   
    
    $('#return-to-top').click(function () {      // When arrow is clicked
      $('body,html').animate({
        scrollTop: 0                       // Scroll to top of body
      }, 1300);
    });

  }

  fetchPageMangerList = () => {
    const self = this;
    const apiHeaders=   HeaderJson.header()
    const apiUrl= ApiList.current.url + ApiList.endpoints.footer.pageListing;

    //API CALL FROM COMMON HEADER
    CommonApi.getCall(apiUrl, apiHeaders)
    .then((data)=>{
      if (data.statusCode === 200) {
        self.setState({
          pageListing: data.data
        })
      }
      
    })

  }
  cookieToggeler = () => {
    this.setState({
      cookieSection: false
    })
  }


  render() {
    const { cookieSection, currentYear, pageListing } = this.state;
    return (
      <div>
        <a id="return-to-top"><i className="fa fa-chevron-up"></i></a>
        <div className="container-fluid footer gemplex-light  foot main-div01" style={{ borderTop: '2px solid #26BDEB' }}>
          <div className="media-footer">
            <div className="row justify-content-center mt-1">
              <div className="col-md-5 col-12 main-div">
                <div className="row pl-2 mt-1 ">
                  <div className="col-md-3 col-4 ">
                    <a href={"/payment"} style={{ outline: 'none' }}><small className="footer-color" style={{ fontSize: ' 12px', fontFamily: 'gemplex-medium', color: '#666666' }}>Subscription</small></a>
                  </div>
                  <div className="col-md-3 col-4 ">
                    <a href={"/hype"} style={{ outline: 'none' }}><small className="footer-color" style={{ fontSize: ' 12px', fontFamily: 'gemplex-medium', color: '#666666' }}>Hype</small></a>
                  </div>
                  {pageListing ?
                    pageListing.map(function (list) {
                      return (
                        <div className="col-md-3 col-4 " key={list.page_code}>
                          <a href={"/page" + list.slug} style={{ outline: 'none' }}><small className="footer-color" style={{ fontSize: ' 12px', fontFamily: 'gemplex-medium', color: '#666666' }}>{list.page_name}</small></a>
                        </div>
                      )
                    }) : null}
                </div>
              </div>
              <div className="col-md-4 col-12 ">
                <div className=" mt-2  mb-2 media-text-style" style={{ fontSize: ' 13px', fontFamily: 'gemplex-medium', marginTop: '2.5%', color: '#666666', marginLeft: '18px' }}>
                  Available On
                </div>
                <div className="row ml-3 media-text-style">
                  {/* <a href="#" className="footer-social" style={{outline:'none'}}>
                    <img
                      style={{ width: "93%", verticalAlign:'middle'}}
                      className="p-0 ml-1   original-color brand-color"
                      // src={AndroidApp}
                      alt="Android App on Google Play"
                    />
                  </a> */}
                  {/* <a href="#" className="footer-social" style={{outline:'none'}}>
                    <img
                      style={{ height: "1.7rem",width:'4.7rem', verticalAlign:'middle' }}
                      className="p-0    original-color brand-color app-img"
                      // src={AppStore}
                      alt="Download on App Store"
                    />
                  </a> */}
                  <a className="border-all play-img media-logo-style pl-1" href="https://play.google.com/store/apps/details?id=tv.gemplex.android&hl=en" style={{ outline: 'none' }} target="_blanck"> </a>
                  <a className="border-all app-img ios-img media-logo-style" href="https://apps.apple.com/in/app/gemplex/id1479968420" style={{ outline: 'none' }} target="_blanck"> </a>
                  <a className="media-logo-style" style={{ outline: 'none' }} href="#" >
                    <img style={{ verticalAlign: 'middle' }} className="border-roku roku-img original-color brand-color" src="https://preprod-gemplex-assets.gemplex.tv/general/roku.svg" alt="Download on App Store" />
                  </a>
                  <a className="media-logo-style" style={{ outline: 'none' }} href="https://www.amazon.com/Gemplex-Media-Pvt-Ltd/dp/B07XQFFHRT/ref=sr_1_1?keywords=gemplex&qid=1574934964&sr=8-1" target="_blanck">
                    <img style={{ verticalAlign: 'middle' }} className="border-fire fire-img original-color brand-color" src="https://preprod-gemplex-assets.gemplex.tv/general/firetv.svg" alt="Download on App Store" />
                  </a>
                </div>
              </div>
              <div className="col-md-3 col-12 ">
                <div className=" mb-2 ml-3 media-text-style " style={{ fontSize: ' 13px', fontFamily: 'gemplex-medium', marginTop: '2.5%', color: '#666666' }}>
                  Connect With Us
                </div>
                <ul className="social-icons list-unstyled ml-3 media-text-style ">
                  <li className="pr-2">
                    <a className="facebook ios" href="https://www.facebook.com/gemplex" rel="noopener noreferrer" target="_blank" style={{ outline: 'none' }}>
                      <i className="fab fa-facebook-f "></i>
                    </a>
                  </li>
                  <li className="pr-2">
                    <a className="instagram ios" href="https://www.instagram.com/gemplexindia/" rel="noopener noreferrer" target="_blank" style={{ outline: 'none' }}>
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                  <li className="pr-2">
                    <a className="twitter ios" href="https://twitter.com/gemplexindia" rel="noopener noreferrer" target="_blank" style={{ outline: 'none' }}>
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a className="youtube ios" href="https://www.youtube.com/gemplex" rel="noopener noreferrer" target="_blank" style={{ outline: 'none' }}>
                      <i className="fab fa-youtube"></i>
                      {/* <img src={sample} /> */}
                    </a>
                  </li>
                  {/* <li className="pr-2">
                    <a className="google ios" href="#" target="_blank" style={{ outline: 'none' }}>
                      <i className="fab fa-google-plus-g"></i>
                    </a>
                  </li> */}
                </ul>
              </div>

            </div>
            <div className="col-md-12">
              <hr className="footer-dived" />
            </div>
            <div className="row"  >
              <div className="col-sm-12 col-md-3 col-xl-1 ml-3 media-gemplex-style">
                <a className="navbar-brand logo-gem logo-img justify-content-center click ml-0 mr-auto" style={{ outline: 'none' }} />
              </div>
              <div className="col-sm-12 col-md-8 col-xl-9 " style={{ paddingLeft: '2%' }}>
                <div className="col-md-12 col-11" style={{ marginBottom: '-2px' }}>
                  <a> <small className=" footer-font gemplex-light" style={{ fontWeight: '500', color: '#666666', fontSize: '11px', fontFamily: "gemplex-medium" }}>
                    @ 2018-{currentYear} Gemplex. All Rights Reserved </small>
                  </a>
                  <span id="vr-footer"> | </span>
                  <a href="/terms-and-conditions" target="_blanck" style={{ outline: 'none' }}>
                    <small className="footer-color footer-font gemplex-light" style={{ fontWeight: '500', color: '#666666', fontSize: '11px', fontFamily: "gemplex-medium" }}>
                    Terms & Condition
                    </small>
                  </a>
                  <a href="/privacy-policy" target="_blanck" style={{ outline: 'none' }}>
                    <small className="footer-color footer-font pl-2   gemplex-light" style={{ fontWeight: '500', color: '#666666', fontSize: '11px', fontFamily: "gemplex-medium" }} >
                    Privacy Policy
                    </small>
                  </a>
                  <a href="/disclaimer" target="_blanck" style={{ outline: 'none' }}>
                    <small className="footer-color footer-font pl-2  gemplex-light" style={{ fontWeight: '500', color: '#666666', fontFamily: "gemplex-medium" }} >
                    Disclaimer
                    </small>
                  </a>

                </div>
                <div className="col-md-12" style={{ paddingBottom: '15px' }}>
                  <small className="footer-font gemplex-light" style={{ fontWeight: '500', color: '#666666', fontSize: '11px', fontFamily: "gemplex-medium" }}>
                    Gemplex and all related channel and programming logos servies marks of and all related programming visuals and elements are the property of Gemplex media pvt.ltd.
                  </small>
                </div>
              </div>
              <div className="col-sm-12 col-md-1 col-xl-1" style={{paddingLeft:"6%"}}>
              <a href="https://aws.amazon.com/what-is-cloud-computing" target="_blanck" className="aws-img"></a>
              </div>
            </div>
          </div>
        </div>
        {cookieSection ?
          <div className="container-fluid cookie-section primary-container-padding py-3">
            <div className="row justify-content-center">
              <div className="col-md-10 my-auto">
                <p className="mb-0">If you decline, your information won’t be tracked when you visit this website. A single cookie will be used in your browser to remember your preference not to be tracked.</p>
              </div>
              <div className="col-md-2 my-auto">
                <div className="text-center">  <button className="ml-auto btn text-white primary2-bg border-0 gemplex-regular rounded px-3 py-2 mr-3" onClick={this.cookieToggeler}>Accept</button>
                  <button className="ml-auto btn text-dark primary2-border  gemplex-regular rounded px-3 py-2 mr-3" onClick={this.cookieToggeler}>Decline</button>
                </div>
              </div>
            </div>
          </div>
          : null}
      </div>
    );
  }
}
