import React, { Component } from 'react';




export default class MusicInfoModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPlayData: this.props.currentPlayData
        }
    }
    componentDidMount() {
    }
    render() {
        const { currentPlayData } = this.props
        return (
            <div>
                <div className="modal fade music-info-modal" id="music-info-modal">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content p-0">
                            <div className="modal-body p-0">


                                <button type="button" className="close position-absolute" style={{ right: 20, top: 10, zIndex: 2 }} data-dismiss="modal">&times;</button>

                                {currentPlayData ?
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-md-12 album-section">
                                                <div className="row song-info-section">
                                                    <div className="song-info-header p-3 col-xl-12">
                                                        SONG INFO
                                          </div>
                                                    <div className="col-xl-4">

                                                        <div className="album-info mt-1">
                                                            <img src={currentPlayData.base_url + currentPlayData.media_manager_id + "/" + currentPlayData.thumbnail_folder + "/" + (currentPlayData.platform_images ? currentPlayData.platform_images.thumbnails_4_3 : null)} alt="" />
                                                            <div className="album-content d-inline-block ml-2">
                                                                <div className="album-name">{currentPlayData.content_name}</div>
                                                                <div className="album-label">Album</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4">
                                                        <div className="sub-header">
                                                            Released On
                                              </div>
                                                        <div className="sub-header-text mb-3">
                                                            {currentPlayData.released_year ? currentPlayData.released_year : null}
                                                        </div>
                                                        <div className="sub-header">
                                                            Duration
                                              </div>
                                                        <div className="sub-header-text mb-3">
                                                            {currentPlayData.duration ? (currentPlayData.duration > 60 ? Math.floor(currentPlayData.duration / 60) + 'min ' + (currentPlayData.duration % 60) + 'sec' : currentPlayData.duration + 'min') : null}

                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4">
                                                        <div className="sub-header">
                                                            Language(s)
                                              </div>
                                                        <div className="sub-header-text mb-3">
                                                            {
                                                                currentPlayData.language && currentPlayData.language.length > 0 ?
                                                                    currentPlayData.language.map(function (item, i) {
                                                                        return (
                                                                            <span key={item.name}> {i == 0 ? item.name : ", " + item.name} </span>
                                                                        )
                                                                    })
                                                                    : null}
                                                        </div>
                                                        <div className="sub-header">
                                                            Label
                                              </div>
                                                        <div className="sub-header-text mb-3">
                                                            {currentPlayData.label ? currentPlayData.label : null}
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                            <div className="col-xl-12 song-section py-3">
                                                <div className="row">
                                                    <div className="music-img col-auto">
                                                        <img src={currentPlayData.base_url + currentPlayData.media_manager_id + "/" + currentPlayData.thumbnail_folder + "/" + (currentPlayData.platform_images ? currentPlayData.platform_images.thumbnails_4_3 : null)} alt="" />
                                                    </div>
                                                    <div className="music-name">
                                                        {currentPlayData.title}
                                                        <div className="music-name-sub-text">
                                                            {currentPlayData.content_name}
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-xl-12 peoples-section-music">
                                                <div className="row mb-5">
                                                    {currentPlayData.singer ?
                                                        <div className="mt-4 col-xl-4">
                                                            <div className="people-header">
                                                                SINGER(S)
                                                    </div>
                                                            {currentPlayData.singer.length > 0 ?
                                                                currentPlayData.singer.map(function (singerItem, singerI) {
                                                                    return (
                                                                        <div className="sub-section-people mt-3" key={singerItem.name}>
                                                                            <a href="">
                                                                                <img className="people-img" src={'https://a10.gaanacdn.com/images/artists/42/1859842/crop_175x175_1859842.jpg'} alt="" srcSet="" />
                                                                                <div className="people-name">
                                                                                    {singerItem.name}
                                                                                </div>
                                                                            </a>
                                                                        </div>
                                                                    )
                                                                })
                                                                : null}


                                                        </div> : null}
                                                    {currentPlayData.composer ?
                                                        <div className="mt-4 col-xl-4">
                                                            <div className="people-header">
                                                                COMPOSER(S)
                                                                {/************************************************** changes by me */}
                                                            </div>
                                                            {
                                                                currentPlayData.composer.length > 0 ?
                                                                    currentPlayData.composer.map(function (composerItem, composerI) {
                                                                        return (
                                                                            <div className="sub-section-people mt-3" key={composerItem.name}>
                                                                                <a href="">
                                                                                    <img className="people-img" src={'https://a10.gaanacdn.com/images/artists/42/1859842/crop_175x175_1859842.jpg'} alt="" srcSet="" />
                                                                                    <div className="people-name">
                                                                                        {composerItem.name}
                                                                                    </div>
                                                                                </a>
                                                                            </div>
                                                                        )
                                                                    })
                                                                    : null}
                                                        </div>
                                                        : null}
                                                    {currentPlayData.lyricist ?
                                                        <div className="mt-4 col-xl-4">
                                                            <div className="people-header">
                                                                LYRICIST(S)
                                                                {/******************************************** changes by me */}
                                                            </div>
                                                            {
                                                                currentPlayData.lyricist.length > 0 ?
                                                                    currentPlayData.lyricist.map(function (lyricistItem, lyricistI) {
                                                                        return (
                                                                            <div className="sub-section-people mt-3" key={lyricistItem.name}>
                                                                                <a href="">
                                                                                    <img className="people-img" src={'https://a10.gaanacdn.com/images/artists/42/1859842/crop_175x175_1859842.jpg'} alt="" srcSet="" />
                                                                                    <div className="people-name">
                                                                                        {lyricistItem.name}
                                                                                    </div>
                                                                                </a>
                                                                            </div>
                                                                        )
                                                                    })
                                                                    : null}
                                                        </div>
                                                        : null}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

}