import React, { Component } from 'react';
import $ from 'jquery';

import swal from 'sweetalert';
import headerJson from '../../util/headers.js';
import ApiList from "../../config/api.js";
import "../../stylesheet/information_page/feedback.css";
import CommonApi from "../../util/common_api_handler.js";
import ErrorMess from "../../util/error.js";
export default class FeedBack extends Component {
    constructor() {
        super();
        this.state = {
            header: headerJson.header(),
            maxDataValue: ""
        }
    }

    componentDidMount() {
        var dtToday = new Date();

        var month = dtToday.getMonth();
        var day = dtToday.getDate();
        var year = dtToday.getFullYear() - 12;
        if (month < 10)
            month = '0' + month.toString();
        if (day < 10)
            day = '0' + day.toString();

        var maxDate = year + '-' + month + '-' + day;
        this.setState({
            maxDataValue: maxDate
        })
    }

    phoneNumberLength = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(
                0,
                object.target.maxLength
            );
        }
    }
    postData = () => {
        const self = this;
        const userCommnet = document.getElementById('name').value;
        const emailId = document.getElementById('email_id').value;
        const phoneNumber = document.getElementById('mobile_no').value;
        const qualification = document.getElementById('qualification').value;
        const interested_area = document.getElementById('inputGroupSelect01').value;
        const dob = document.getElementById('user_dob').value;
        const resume = document.getElementById('inputGroupFile01').value;
        let dataString = {
            "name": userCommnet ? userCommnet : "",
            "email_id": emailId ? emailId : "",
            "mobile_no": phoneNumber ? phoneNumber : "",
            "qualification": qualification ? qualification : "",
            "interested_area": interested_area ? interested_area : "",
            "dob": dob ? dob : "",
            "resume": resume ? resume : "",
        }
        if (userCommnet === "") {

            document.getElementById("nameError01").innerHTML = ErrorMess.alertMessages.login.PFN;
        } else {
            document.getElementById("nameError01").innerHTML = "";
        }
        if (dob === "") {

            document.getElementById("DobError").innerHTML = ErrorMess.alertMessages.login.VDOB;
        } else {
            document.getElementById("DobError").innerHTML = "";
        }
        
        if (emailId === "") {
            document.getElementById("EmailError").innerHTML = ErrorMess.alertMessages.login.UNEE;
            
        } else{
            document.getElementById("EmailError").innerHTML ="";
        }
        
        if (phoneNumber === "") {
            document.getElementById("ContectError").innerHTML = ErrorMess.alertMessages.login.MNNE;
        }
        else if(resume) {
            document.getElementById("FileError").innerHTML = "";
            let apiUrl = ApiList.current.url + ApiList.endpoints.footer.career;
            let apiHeaders = self.state.header;
            let bodyData = dataString;

            CommonApi.postCall(apiUrl, apiHeaders, bodyData)
                .then(data => {
                    if (data.statusCode === 200) {
                        swal({ text: data.message, timer: 1500 })
                    }
                })
                
        }else{
            document.getElementById("ContectError").innerHTML = "";
            document.getElementById("FileError").innerHTML = ErrorMess.alertMessages.login.PUFILE;
        }
        this.ValidateEmail();


    }
    AllowOnlyChar = (event) => {
        var inputval = document.getElementById('name');
        inputval.value = inputval.value.replace(/[^a-zA-Z]+/, '').trimLeft();
    }
    AllowOnlyNum = (event) => {

        var inputval = document.getElementById('mobile_no');
        inputval.value = inputval.value.replace(/[^0-9]+/, '').trimLeft();

    }
    ValidateEmail(mail) {
        // eslint-disable-next-line no-useless-escape
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if (document.getElementById("email_id").value.match(mailformat)) {
            // swal({ text: ErrorMess.alertMessages.login.UNEE, timer: 1500 })
           
            return true;
        } else {
            document.getElementById("EmailError").innerHTML = ErrorMess.alertMessages.login.UNEE;
            // swal({ text: ErrorMess.alertMessages.login.UNEE, timer: 1500 })
            return false;
        }
    }
    render() {
        $('.div-data').html(this.props.getData)
        return (
            <div>
                <div className="container">
                    <div className=" mt-3 catalogue_name primary3-text" >Career</div>

                    <div className={'row py-3'} >
                        <div className="col-md-6">
                            <label className={"content-head"}>Name</label>
                            <div className="input-group">
                                <input className="form-control mb-4" name="comment" id="name" placeholder="Enter your name" required onKeyUp={this.AllowOnlyChar} />
                                
                            </div>
                            <div className="pb-2 " id="nameError01" style={{marginTop:"-15px"}}></div>
                           
                            <label className={"py-0 content-head"}>Your Email</label>
                            <div className="input-group">
                                <input className="form-control mb-4" name="comment" id="email_id" placeholder="Enter your email" required onBlur={this.ValidateEmail} />
                            </div>
                            <div className="pb-2 " id="EmailError" style={{marginTop:"-15px"}}></div>
                            <label className={"py-0 content-head"}>Contact</label>
                            <div className="input-group">
                                <input className="form-control mb-4" type="number" name="comment" id="mobile_no" placeholder="Enter mobile number" minLength={10} maxLength={10} onInput={this.phoneNumberLength} onKeyPress={this.AllowOnlyNum} required />
                            </div>
                            <div className="pb-2 " id="ContectError" style={{marginTop:"-15px"}}></div>
                            <label className={"py-0 content-head"}>Qualification</label>
                            <div className="input-group">
                                <input className="form-control mb-3" name="comment" id="qualification" placeholder="Enter Qualification" />
                            </div>
                            <label className={"py-0 content-head"}>Intrested area</label>
                            <div className="input-group">
                                <select className="custom-select mb-3" id="inputGroupSelect01">
                                    <option className="content-head"  style={{ fontSize: "13px" }}>Select Options</option>
                                    <option defaultValue="1">Creative</option>
                                    <option defaultValue="2">Branding and Marketing</option>
                                    <option defaultValue="3">Production</option>
                                    <option defaultValue="4">Sales</option>
                                    <option defaultValue="5">Admin</option>
                                    <option defaultValue="6">Technical</option>
                                    <option defaultValue="7">Finance</option>
                                    <option defaultValue="8">Legal</option>
                                    <option defaultValue ="9">Other</option>
                                </select>
                            </div>
                            <label className={"py-0 content-head"} >Date of Birth</label>
                            <div className="input-group">
                                <input type="date" id="user_dob" max={this.state.maxDataValue} onKeyDown={(e) => e.preventDefault()} className="form-control user_dob_date mb-4" style={{ fontFamily: "gemplex-light", fontSize: '12px' }} />
                             </div>
                             <div className="pb-2 " id="DobError" style={{marginTop:"-15px"}}></div>
                            <label className={"py-0 content-head"}>Attach a Resume</label>
                            <div className="input-group mb-3">
                                <div className="custom-file">
                                    <input type="file" className="custom-file-input" id="inputGroupFile01" required />
                                    <label className="custom-file-label" htmlFor="inputGroupFile01" style={{ fontSize: "14px", fontFamily: "Gemplex-regular" }}>Choose file</label>
                                </div>
                            </div>
                            <div className="pb-2 " id="FileError" style={{marginTop:"-15px"}}></div>
                            <div className="btn gradient1-bg gemplex-regular rounded mt-3 mb-3 text-white col-md-4 cursor" style={{ outline: 'none' }} onClick={this.postData}>Submit</div>

                        </div>
                        <div className="col-md-6 div-data">
                            
                        </div>
                    </div>
                </div>
            </div>

        )
    }

}