import React, { Component } from "react";
import swal from "sweetalert";

import ApiList from "../../config/api.js";
import CommonApi from "../../util/common_api_handler.js";
import ErrorMess from "../../util/error.js";
import HeaderJson from '../../util/headers.js';

export default class Index extends Component {
  constructor() {
    super();
    this.state = {
      favouritesFlag: false,
      mainData: [],
    };
  }

  componentDidMount = () => {
    this.fetchData();
  }

  fetchData = () => {
    const self = this;

    const apiUrl = ApiList.current.url + ApiList.endpoints.watchlist.watchlist;
    
    const apiHeaders = HeaderJson.header(); 
    CommonApi.getCall(apiUrl, apiHeaders)
      .then((data)=>{
        if (data.statusCode === 200) {
          self.setState({
            mainData: data.data.contentData
          });
        }
      })
  };
  
  removeData = (data) => {

    const apiUrl = ApiList.current.url + ApiList.endpoints.watchlist.watchlist;
    
    const apiHeaders = HeaderJson.header();

    const bodyData = {
      "content_id": data.content_id ,
    }; 

    CommonApi.deleteCall(apiUrl, apiHeaders, bodyData)
      .then((data)=>{
        if (data.statusCode === 200) {
          swal({ text: data.message, timer: 1500 })
          // self.setState({
          //   mainData: data.data ? data.data.content_id : null
          // })
          this.fetchData();
        }
      })
  };

  likeClick = (data) => {

    const apiUrl = ApiList.current.url + ApiList.endpoints.favourite.fetchList;
    
    const apiHeaders = HeaderJson.header();

    const bodyData = {
      "content_id": data.content_id,
      "page_type": "VIDEOS"
    }; 

    CommonApi.postCall(apiUrl, apiHeaders, bodyData)
      .then((data)=>{
        if (data.statusCode === 200) { 
          swal({ text: data.message, timer: 1500 })
         }
      })
  }

  pageRender = (data) => {
    // console.log(data)
    window.location.href = '/detail/'+data.slug +"/" + data.content_id 
  }
  render() {
    const self = this
    const { mainData } = this.state;
    return (
      <div className="container-fluid my-5 watchlist-container">
        {mainData ? (
          <div className="row justify-content-center">
            <div className="col-md-10">
              <ul className="nav nav-tabs" role="tablist">
                <li className="nav-item">
                  <a className="nav-link active" id="all-tab" data-toggle="tab" href="#all" role="tab" aria-controls="all" aria-selected="true">
                    Videos
                  </a>
                </li>
              </ul>
              <div className="tab-content">
                <div className="tab-pane fade show active row" id="all" role="tabpanel" aria-labelledby="all-tab">
                  {mainData.length > 0 ?
                    <div className="row p-3 ">
                      {mainData.map(function (allItem) {
                        return (
                          <div className="col-md-3 col-sm-6 col-6 pb-2 pt-2 cursor" key={allItem.content_id}>
                            <img className="img-fluid" onClick={() => self.pageRender(allItem)} src={allItem ? allItem.base_url + '/' + allItem.content_manager_folder + "/" + allItem.content_id + '/' + allItem.thumbnail_folder + '/' + allItem.banner_folder + '/' + allItem.thumbnail_16_9 + '/' + allItem.platform_images.thumbnails_16_9 : ''} alt="" srcSet="" />
                            <div className="watchlist-content m-0 d-flex justify-content-between pt-0">
                              <div className="cursor" onClick={() => self.pageRender(allItem)}>
                                {allItem.content_name}
                              </div>
                              <div className="dropdown dropup cursor" style={{ marginTop: '5px' }}>
                                <i className="fas fa-ellipsis-v ellipse-watch dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                  <span className="dropdown-item" onClick={() => self.likeClick(allItem)}>
                                    Add to Favourite
                              </span>
                                  <span className="dropdown-item" onClick={() => self.removeData(allItem)} >Remove</span>
                                </div>
                              </div>
                            </div>
                            {/* <div className="watchlist-series ml-0 cursor" onClick={() => self.pageRender(allItem)}>
                              {allItem.series_name ? allItem.series_name : ''}
                            </div> */}
                          </div>
                        );
                      })}
                    </div>
                    : 
                    ''}
                </div>
              </div>
            </div>
          </div>
        ) : 
        <div className="row"> <img src={"https://preprod-gemplex-assets.gemplex.tv/web/assets/Error/Watchlist.svg"} alt="Empty Watchlist" style={{marginLeft:'auto', marginRight:'auto'}} /> </div>
        }
      </div>
    );
  }
}
