// all error messages are here

module.exports = {

    "alertMessages": {
        "login": {
            "MNNE": "Please Enter your Mobile Number",                  //mobile no is not enter
            "LMNE":"Mobile Number must be 10 Digits",                      //less mobile no 
            "PASSNE":"Please Enter Your Password",                      //If Password is not enter
            "IPASSE":"The Password that you've Entered is Incorrect",   //If password is inccorect
            "EA8C":"Enter Atleast 8 to 14 Characters",                  //While registration & reset the pasword if user is enter the less than 8 digit character
            "WOTP":"Please Enter Valid OTP",                            //Wrong OTP Enter
            "RPEN":"Please Enter your New Password"   ,                 //Reset new password-If new password is not Enter
            "CPEN":"Please Enter your Confirm Password",                //Reset new password-If confirm password is not enter
            "MAPNV":"Welcome user name",                                 //Mobile no & password is valid
            "RPC":"Welcome user name"      ,                              //Registration process complet
            "UNEE" :"Please Enter Valid Email Id"  ,                    //New User Registration-If user is not enter Email,password & confirm password 
            "UNEP" :"Please Enter Valid Password Id"  ,                 //New User Registration-If user is not enter Email,password & confirm password 
           "LossI": "No Internet Connection" ,                           //If internet connection is loss while doing this all action
           "PFN": "Please Fill Your Name",                               //please fill your name here
            "MNV":"Mobile Number is Valid",                               //mobile no valid
            "MENV":"Please Enter Valid Mobile / Email id",
            "EIV":"Email Id is Valid",
            "PUFILE":"Please Upload File",                               // please upload file
            "VDOB":"Please Enter Valid Date of Birth"
        },
        "home":{
            "ATC":"Added to Cart",                                        //Cart-Add to cart
            "RTC":"Removed from Cart" ,                                  //Remove from Cart
            "CIE":"Your Cart is Empty",                                 //If Cart is empty
            "AADTC":"Already Added to Cart",                             //Alredy added To Cart
        },
        "search":{
            "IS":"No Data Found",                                       //Search-For invalid search
            "ONS":"Please Select Filter Option",                        //Filter -If options is not selected
        },
        "internet":{
            "ILOSS":"No Internet Connection",                           //If internet connection is loss while doing this action
            
        },
        "myAccount":{
            "AWL":"Add to Watchlist",                                   //WatchList -Adding watchlist
            "RWL":"Remove from Watchlist" ,                              //WatchList -Removing from  watchlist
            "RFL":"Remove from Favourite",                                //Remove rom watchlist
            "AFL":"Add to Favorite",
            "ECP":"Please Enter Correct Pin"                                //Enter Currect Pin
        },
        "myProfile":{
            "MPDU":"Profile Update Successfully",                       //My Account - My profile details is updated
            "DOBNV":"05/09/1936 is not valid date value" ,              //If birth date is not valid
            "ICITYN":"Please Enter Valid City Name",                   //CIty- Invalid city name
            "ICN":"Please Enter Valid Country Name",                  //Country - Invalid country name
            "CP":"Password Updated",                                    //Chnage password
            "CPNE":"Please Enter your Current Password",                //Change password-If current password is not enter
            "NPNM":"New Password does not match.",                      //Change password - If new password & confirm password is not match
            "CPNM":"Confirm  Password does not match"   ,               //Change password - If new password & confirm password is not match
            "NPNE":"Please Enter your new password",                    //Change password -If new paasword is not enter
            "CPE":"Please Enter your confirm password",                 //Change password -If confirm password is not enter
        },
        "subscription":{
            "SCB":"Please check this box if you want to proceed",        //Subscription - check box
            "PL":"Please verify your email & mobile no",                 //Plan listing
            "PO":"Please Select payment option",                         //Payment options

        },
        "Language":{
            "Video":"Please Select Language for Video",                   //Video
            "Music":"Please Select Language for Music",                   //Please Select language for music
            "LUS":"Language Update Successfully",
        },
        "Setting":{
            "IPE":"Password is Incorrect",                                  //Parental Control -Invalid password enter
            "AFE":"These fields are required",                               //Create Pin - If all fields are empty
            "DNS":"Please Select Atleast One Device",                       //Restrict Devices is not Selected
        },
        "music":{
            "NUOMP":"Please Select a Song",                                   //If user is newly register on music page
            "EQ":"Queue is Empty",                                          //Empty Queue
            "LE":"No Lyrics Available ",                                     //Lyrics - If lyrics is empty
            "CQ":"Are you sure you want to clear all songs from your queue", //Clear queue
        },
        "coupon":{
            "PCC":"Please Choose a Coupon"                                     //please choose a coupon
        },
        "noDataInVideo":{
            "NDF":"No Trailer Available"
        }
       
    }
}