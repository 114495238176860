import React, { Component, Fragment } from "react";
import moment from "moment";
import swal from "sweetalert";
import ContentLoader from 'react-content-loader'
import { FacebookShareButton, WhatsappShareButton, FacebookIcon, WhatsappIcon } from "react-share";
import Cookies from "universal-cookie";
import Skeleton from "react-loading-skeleton";
import Slider from "react-slick";

import ApiList from "../../../config/api.js";
import AudioPlayer from "./player";
import ArticleTopics from "../common_component/top_and_popular";
import ArticleAd from "../common_component/hype_ad";
import CommonApi from "../../../util/common_api_handler.js";
import ErrorMess from "../../../util/error.js";
import Footer from "../../partials/footer";
import Header from "../../partials/header";
import HeaderJson from '../../../util/inner_header.js';
import Navbar from "../../partials/NavigationBar.jsx";
import PageJourny from "../../../util/page_journey.js";
import RecentStories from "../common_component/recent_stories";
import RegenrateToken from "../../../util/regenrateToken.js";
import Recommended from "../common_component/HypeRecommended"
import VideoComp from "../../video/video_js_hype_v2";

import "../../../stylesheet/partials/hover-slider.css";
import "../../../stylesheet/hype_page/hypetab_section.css";
import "../../../stylesheet/hype_page/hype_blog.css";


var cookie = new Cookies();
var userTypeCookie = cookie.get('userType');

const fillHeart = "https://preprod-gemplex-assets.gemplex.tv/web/like-fill.svg";
const emptyHeart = "https://preprod-gemplex-assets.gemplex.tv/web/like.svg"

export default class HypeDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            galleryData: "",
            detailParams: {},
            // catalogue: "",
            hypeContent: [],
            contentIdPrev: "",
            contentIdNext: "",
            longDescriptions: "",
            tagsData: [],
            crewData: [],
            contentId: '',
            isFavourite: false,
            renderingStatus: false,
            type: this.props.match.params.type ? this.props.match.params.type : ''
        };
    }

    componentDidMount = async () => {
        await PageJourny.setDeviceInfo();
        let content_id = this.props.match.params.content_id;
        this.fetchDetailData(content_id);
    }

    // Prev Article Function ...
    fetchPrevData = (contentIdPrev) => {
        this.fetchDetailData(contentIdPrev);
    }

    //Next Article Function ...
    fetchNextData = (contentIdNext) => {
        this.fetchDetailData(contentIdNext);
    }

    // Detail Data Api Call ...
    fetchDetailData = (content_id) => {

        const self = this;

        const apiUrl = ApiList.current.url + ApiList.endpoints.hype.fetchById + "/" + content_id;

        const apiHeaders = HeaderJson.header();

        CommonApi.getCall(apiUrl, apiHeaders)
            .then((json) => {
                if (json.statusCode === 200) {
                    var data = json.data ? json.data : null;

                    if (data !== null) {
                        self.setState({
                            galleryData: data,
                            isFavourite: data.is_favourite,
                            longDescriptions: data.long_description.replace(/<\/?[^>]+(>|$)/g, ""),
                            detailParams: data,
                            contentIdPrev: data.previousId,
                            contentIdNext: data.nextId,
                            tagsData: data.genres,
                            crewData: data.crew,
                            contentId: data.content_id,
                            renderingStatus: true
                        })
                        data.previousId === "" ? (document.getElementById("button-prev").disabled = true) : (document.getElementById("button-prev").disabled = false);
                        data.nextId === "" ? (document.getElementById("button-next").disabled = true) : (document.getElementById("button-next").disabled = false);

                    }
                    window.scrollTo(50, 0);
                }

            })
            .catch((err) => {
                if (err.responseJSON.status_code === 403 || err.responseJSON.status_code === 400) {
                    RegenrateToken.userRegenrateToken()
                        .then((data) => {
                            self.setState({
                                renderingStatus: true
                            })
                        })
                }
            })
    };

    // Article Like Api Call ...
    favourite = () => {

        const apiUrl = ApiList.current.url + ApiList.endpoints.favourite.fetchList;

        const apiHeaders = HeaderJson.header();

        const bodyData = {
            content_id: this.state.contentId,
            "page_type": "HYPE"

        }
        CommonApi.postCall(apiUrl, apiHeaders, bodyData)
            .then((data) => {
                if (data.statusCode === 200) {
                    swal({ text: ErrorMess.alertMessages.myAccount.AFL, timer: 1500 });
                    this.setState({
                        isFavourite: true
                    })
                }
            })
    };

    // Article Dislike Api Call ...
    disableFavourite = () => {

        const apiUrl = ApiList.current.url + ApiList.endpoints.favourite.fetchList;

        const apiHeaders = HeaderJson.header();

        const bodyData = {
            content_id: this.state.contentId,
            "page_type": "HYPE"
        }

        CommonApi.deleteCall(apiUrl, apiHeaders, bodyData)
            .then((data) => {
                if (data.statusCode === 200) {
                    swal({ text: ErrorMess.alertMessages.myAccount.RFL, timer: 1500 });
                    this.setState({
                        isFavourite: false
                    })
                }
            })
    };

    //   Article Component ... 
    articleDetail = (detailParams) => {
        return (
            <div className="article-block" key={detailParams._id} >
                <div>
                    <img src={detailParams.base_url_thumbnail_16_9 + "/" + detailParams.thumbnail_16_9} alt={detailParams.title ? detailParams.title : null} className="mr-4 mb-4" />
                </div>
                <article className="long-description">
                    {this.state.longDescriptions && this.state.longDescriptions}
                </article>
            </div>
        )
    };

    // Video Component ... 
    videoDetail = (detailParams) => {
        return (
            <div className="article-block">
                <div className="mb-1"> {/* mb-1 */}
                    {detailParams ?
                        <VideoComp mainData={detailParams} video_poster={detailParams.base_url_thumbnail_16_9 + "/" + detailParams.thumbnail_16_9} video_url={detailParams.url_video} videoResolution={detailParams.video[0].transcoding_profile.resolutions && detailParams.video[0].transcoding_profile.resolutions.length >= 1 ? detailParams.video[0].transcoding_profile.resolutions : ['master.m3u8']} videoLabel={detailParams.video[0].transcoding_profile.label_name && detailParams.video[0].transcoding_profile.label_name.length >= 1 ? detailParams.video[0].transcoding_profile.label_name : ['Auto']} key={1111} />
                        : null}
                </div>
                <article className="py-2 video-long-description">
                </article>
            </div>
        )
    }

    // Music Component ... 
    podcastDetail = (detailParams) => {
        return (
            <div>
                <div className="article-block ">
                    {detailParams ? <img src={detailParams.base_url_thumbnail_16_9 + "/" + detailParams.thumbnail_16_9} alt="" className="mr-4" /> : ''}
                </div>
                {detailParams !== '' ? <AudioPlayer audio_url={detailParams.url_audio + "/" + detailParams.audio_transcode_profiles[2]} /> : null}
                <article className=" py-2" > {
                    this.state.longDescriptions
                }
                </article>
            </div>
        )
    }

    // Gallary Component ...
    gallaryDetail = (detailParams) => {
        const settings = {
            className: "gallery-slider",
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            swipeToSlide: true,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />
        };

        return (
            <div className="container-fluid blog-center-div">
                {/* {this.state.imageParams ? ( */}
                <Slider {...settings}>
                    {detailParams.gallery.map(function (item) {
                        return (
                            <span key={item._id}>
                                <div className="height-gallary card">
                                    <div>

                                        <img src={detailParams.base_url_thumbnail_8_6 + "/" + item.thumbnail_8_6}
                                            alt={detailParams.title}
                                            className="w-100"
                                            // style={{ width: "100%" }}
                                        />
                                    </div>
                                </div>
                                <div className="card content-info card-body cursor">
                                    <div className="content-count">
                                        {item.display_order.toString().length === 1 ? "0" + item.display_order : item.display_order}  /  {detailParams.gallery ? "0" + detailParams.gallery.length : null}
                                    </div>
                                    <div className="blog_title">
                                        {item !== null ? item.title : null}
                                    </div>
                                    <div className="card-title   blog_content">
                                        {item !== null ? item.description : null}
                                    </div>
                                </div>
                            </span>
                        );
                    })}
                </Slider>
                {/* // ) : null} */}
            </div>
        )
    }
    ShareComponent = () => {
        return (
            <div className="btn-group dropup">
                <button type="button" style={{ background: "transparent" }} className="border-0 dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                    <img src="https://preprod-gemplex-assets.gemplex.tv/web/share-filled.svg" className="cursor" onClick={this.openSharePanel} alt="Share_icon" style={{ width: "1.4rem" }} />
                </button>

                <div className="dropdown-menu p-2" style={{ minWidth: 50 }} >
                    <FacebookShareButton url={window.location.href}>
                        <FacebookIcon size={32} round={true} />
                    </FacebookShareButton>
                    <br />
                    <WhatsappShareButton url={window.location.href}>
                        <WhatsappIcon size={32} round={true} />
                    </WhatsappShareButton>
                    <br />
                </div>
            </div>
        )
    };
    fetchAll = () => {
        window.location.href = "/hype"
    }
    renderSkeletonTiles = () => {
        var aa = [];
        for (let i = 0; i < 5; i++) {
          aa.push(
            <div key={i}>
              <div className="mr-2" style={{ "height": "150px", "width": "170px" }}>
                <Skeleton width={"100%"} height={"100%"} />
              </div>
              <div className="mt-1" style={{ "width": "120px" }}>
                <label className={"m-0"} style={{ "width": "80px" }}>
                  <Skeleton width={"100%"} height={"100%"} />
                </label>
                <label style={{ "width": "120px" }}>
                  <Skeleton width={"100%"} height={"100%"} />
                </label>
              </div>
            </div>
          )
        }
        return aa;
      }
    render = () => {
        const {
            detailParams,
            isFavourite,
            contentIdNext,
            contentIdPrev,
            tagsData,
            crewData,
        } = this.state;
        return (
            <>
                <Header />
                <Navbar id="my-section" />
                {
                    this.state.renderingStatus === true ?
                        <div>

                            <div className="container-fluid aling">
                                <div className="col-md-12 mb-4">
                                    <div className="row">
                                        <div className="col-md-12" />
                                    </div>
                                    <div className="row ">
                                        <ArticleTopics />
                                        <div className="col-md-12 col-12 order-xl-2 order-md-1 order-1 col-xl-7">
                                            <div className="row">
                                                <div className="col-12  mt-4 center-div">
                                                    <h1 className="gemplex-regular gallery-header">
                                                        {detailParams.title ? detailParams.title : null}
                                                    </h1>
                                                    <div className="d-flex article-date mr-0 ml-0 justify-content-between">
                                                        <div className=" pl-0">
                                                            <span className="time-article">
                                                                By :
                                                            {detailParams.author ? detailParams.author : 'gexmplex.tv'}
                                                            </span>
                                                            <span className="spacing-article"> | </span>
                                                            <small className="time-article">
                                                                Updated :
                                                            {moment(detailParams.article_publish_date).format("LLL")}
                                                            </small>
                                                        </div>
                                                        <span className=" share-icon-down pr-0 d-flex justify-content-end">
                                                            {userTypeCookie === 'GUEST' ? '' :
                                                                <div className="" style={{ right: "2rem" }}>
                                                                    {isFavourite === false ? (<img className="mx-3 cursor" src={emptyHeart} style={{ width: "1.5rem" }} onClick={this.favourite} alt="Disable_Favourite_Heart" />) : (<img className="mx-3 cursor" src={fillHeart} onClick={this.disableFavourite} style={{ width: "1.5rem" }} alt="Favourite_Heart" />)}
                                                                </div>
                                                            }
                                                            <div style={{ right: "1.5rem" }}>
                                                                {this.ShareComponent()}
                                                            </div>
                                                        </span>
                                                    </div>
                                                    <hr className="horizon-article" />
                                                    <div className="media">
                                                        {this.state.type === "article" ? this.articleDetail(detailParams) : this.state.type === "video" ? this.videoDetail(detailParams) : this.state.type === "music" ? this.podcastDetail(detailParams) : this.gallaryDetail(detailParams)}
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="row">
                                                            <section className="topics-tags-outer">
                                                                {crewData.length > 0 ?
                                                                    <section className="topics-tags-outer">
                                                                        <b> <img src="https://preprod-gemplex-assets.gemplex.tv/web/static/user.svg" className="img-fluid" style={{ width: "30px" }} alt="Cast_icon" /> </b>
                                                                        <section className="topics-tat event" data-event-sub-cat="Tags" >
                                                                            {crewData.map(function (crew) {
                                                                                return (
                                                                                    <Fragment key={crew._id}>
                                                                                        {
                                                                                            crew.name ?
                                                                                                <a href={'/cast/' + crew.people_id} rel="tag">
                                                                                                    {crew.name}
                                                                                                </a>
                                                                                                : ''
                                                                                        }
                                                                                    </Fragment>
                                                                                )
                                                                            })}
                                                                        </section>
                                                                        <div className="clear" />
                                                                    </section>
                                                                    : ""}
                                                            </section>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="row">
                                                            <section className="topics-tags-outer">
                                                                <b> <img src="https://preprod-gemplex-assets.gemplex.tv/web/static/tag.svg" className="img-fluid" style={{ width: "30px" }} alt="Tags_Icon" /> </b>
                                                                {tagsData ?
                                                                    <section className="topics-tags eventtracker">
                                                                        {tagsData.map(function (tags, item) {
                                                                            return (
                                                                                <span key={tags._id} rel="tag">
                                                                                    {tags.name}
                                                                                </span>
                                                                            )
                                                                        })}
                                                                    </section>
                                                                    : null}
                                                                <div className="clear" />
                                                            </section>
                                                        </div>
                                                    </div>
                                                    <hr className="horizon-articles" />
                                                    <div className="col-12 mb-1 ">
                                                        <div className="row article-date">
                                                            <div className="col-10 pl-0 pr-0">
                                                                <button type="button" id="button-prev" className="btn btn-outline-primary btn-sm mr-1 mt-1 mb-2" onClick={() => this.fetchPrevData(contentIdPrev)}>
                                                                    <i className="fas fa-angle-left hype-btn" />
                                                                </button>
                                                                <button type="button" id="button-next" className="btn btn-outline-primary btn-sm mr-1 mt-1 mb-2" onClick={() => this.fetchNextData(contentIdNext)}>
                                                                    <i className="fas fa-angle-right hype-btn" />
                                                                </button>
                                                                <button type="button" className="btn btn-outline-primary btn-sm mr-1 mt-1 mb-2" onClick={this.fetchAll} > All <i className="fas fa-th-large hype-btn" /> </button>
                                                            </div>
                                                            <div className="col-2 share-down-icon pl-0 pr-0" style={{ top: "0.5rem" }}></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <ArticleAd />
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 mt-3">
                                            <RecentStories />
                                        </div>
                                    </div>
                                    {
                                        detailParams.genres ?
                                            <div className="row">
                                                <div className="col-md-12 mt-3">
                                                    <Recommended catalogue={['HYPE']} content_type={"HYPE"} type={"genre"} keywords={detailParams.genres} />
                                                </div>
                                            </div>
                                            : ""
                                    }
                                </div>
                            </div>

                        </div>
                        :
                        <>
                        <ContentLoader>
                            <rect x="70" y="10"  width="225" height="200" />
                        </ContentLoader>
                        <div className="row p-5 m-0">
                           {this.renderSkeletonTiles()}
                        </div>
                        <div className="row p-5 m-0">
                           {this.renderSkeletonTiles()}
                        </div>
                        </>
                }
                <Footer />
            </>
        );
    }
}

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div className={className} style={{ ...style, display: "block", color: "#26BDEB" }} onClick={onClick}>
            <img src="https://preprod-gemplex-assets.gemplex.tv/web/arrow-right-blue.svg" alt={"right arrow"} />
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div className={className} style={{ ...style, display: "block", color: "#26BDEB" }} onClick={onClick}>
            <img src="https://preprod-gemplex-assets.gemplex.tv/web/arrow-left-blue.svg" alt={"left arrow"} />
        </div>
    );
}