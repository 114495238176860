import React, { Component } from "react";
import {Redirect} from "react-router-dom";

import ApiList from "../../config/api.js";
import CommonApi from "../../util/common_api_handler.js";
import HeaderJson from '../../util/headers.js';

import "../../stylesheet/home_page/dashboard.css";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showContainer: true,
      gridData: [],
      redirect: false,
      content:'',
      baseUrl45: '',
      baseUrl43: ''
    };
  }

  componentDidMount = () => {
    this.getGrid(this.props.pageType)
  }
  getGrid = (pageType) => {
    const self = this;

    const apiUrl = ApiList.current.url + ApiList.endpoints.home.grid;

    const apiHeaders = HeaderJson.header();

    const bodyData = {
      "page_type": pageType,
    }

    CommonApi.postCall(apiUrl, apiHeaders, bodyData)
      .then((data)=>{
        if (data.statusCode === 200 && data.data) {
          self.setState({
            gridData: data ? data.data : null,
            baseUrl45: data.base_url_4_5,
            baseUrl43: data.base_url_4_3
          })
        }
      })
  }

  goDetail = (data) => { 
    if(data){
      this.setState({
        content:data.contentData[0],
        redirect : true
      })
    }
  }
  hideContainer = () => {
    this.setState({ showContainer: false });
  }
  render() {
    const { gridData, baseUrl45,baseUrl43,content } = this.state
    const self = this;
    return (
      <>
        {
          gridData.length === 8 ?
          
            <>
            {self.state.redirect === true && <Redirect to={'/detail/' + content.slug + "/" + content.content_id } />}
              <div className="container-fluid d-none d-sm-block d-md-block d-lg-block d-xl-block cursor" style={{ marginTop: -7 }}>
                <div className="row grid-row ">
                  <div className="col-12  col-sm-4 col-md-4 col-xl-4 d-type-3">
                    <div className="row">
                      <div className="col-6 cursor col-sm-12 col-md-12 col-xl-12  p-0 dashboard-grid">
                        <img className="img-fluid p-0 third-height w-100"
                          src={baseUrl43 + '/' + gridData[0].grid.thumbnail_4_3}
                          alt="reality"
                          onClick={()=>this.goDetail(gridData[0])}
                        />
                        
                        <div className="bottom-left  text-white">
                          <div className="name-style"><span> {gridData[0].title}</span></div>
                          <div className="primary-container-padding font-class">
                            {gridData[0].description}
                          </div>
                        </div>
                      </div>

                      <div className="col-6 cursor  col-sm-12 col-md-12 col-xl-12  p-0 dashboard-grid">
                        <img className="img-fluid p-0 third-height w-100"
                          src={baseUrl43 + '/' + gridData[1].grid.thumbnail_4_3}
                          alt="comedy"
                          onClick={()=>this.goDetail(gridData[1])}
                        />
                        <div className="bottom-left  text-white">
                          <div className="name-style"><span> {gridData[1].title}</span></div>
                          <div className="primary-container-padding font-class">
                            {gridData[1].description}
                          </div>
                        </div>
                      </div>

                      <div className="col-12 cursor  col-sm-12 col-md-12 col-xl-12  p-0 dashboard-grid">
                        <img
                          className="img-fluid p-0 third-height w-100"
                          src={baseUrl43 + '/' + gridData[2].grid.thumbnail_4_3}
                          alt="non_fiction"
                          onClick={()=>this.goDetail(gridData[2])}
                        />
                        <div className="bottom-left  text-white">
                          <div className="name-style"><span> {gridData[2].title}</span></div>
                          <div className="primary-container-padding font-class">
                            {gridData[2].description}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12  col-sm-4 col-md-4 col-xl-4 d-type-2">
                    <div className="row">
                      <div className="col-6 cursor col-sm-12  col-md-12 col-xl-12 p-0 dashboard-grid ">
                        <img
                          className="img-fluid p-0 second-height w-100"
                          src={baseUrl45 + '/' + gridData[3].grid.thumbnail_4_5}
                          alt="shorts"
                          onClick={()=>this.goDetail(gridData[3])}
                        />
                        <div className="bottom-left  text-white">
                          <div className="name-style"><span> {gridData[3].title}</span></div>
                          <div className="primary-container-padding font-class">
                            {gridData[3].description}
                          </div>
                        </div>
                      </div>

                      <div className=" col-6 cursor col-sm-12 col-md-12 col-xl-12 p-0 dashboard-grid">
                        <img
                          className="img-fluid p-0 second-height w-100"
                          src={baseUrl45 + '/' + gridData[4].grid.thumbnail_4_5}
                          alt="musical"
                          onClick={()=>this.goDetail(gridData[4])}
                        />
                        <div className="bottom-left  text-white">
                          <div className="name-style"><span> {gridData[4].title}</span></div>
                          <div className="primary-container-padding font-class">
                            {gridData[4].description}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12  col-sm-4 col-md-4 col-xl-4 d-type-3">
                    <div className="row">
                      <div className="col-12 cursor col-sm-12 col-md-12 col-xl-12  p-0 dashboard-grid">
                        <img
                          className="img-fluid p-0 third-height w-100"
                          src={baseUrl43 + '/' + gridData[5].grid.thumbnail_4_3}
                          alt="reality"
                          onClick={()=>this.goDetail(gridData[5])}
                        />
                        <div className="bottom-left  text-white">
                          <div className="name-style"><span> {gridData[5].title}</span></div>
                          <div className="primary-container-padding font-class">
                            {gridData[5].description}
                          </div>
                        </div>
                      </div>

                      <div className="col-6 cursor col-sm-12 col-md-12 col-xl-12  p-0 dashboard-grid">
                        <img
                          className="img-fluid p-0 third-height w-100"
                          src={baseUrl43 + '/' + gridData[6].grid.thumbnail_4_3}
                          alt="comedy"
                          onClick={()=>this.goDetail(gridData[6])}
                        />
                        <div className="bottom-left  text-white">
                          <div className="name-style"><span> {gridData[6].title}</span></div>
                          <div className="primary-container-padding font-class">
                            {gridData[6].description}
                          </div>
                        </div>
                      </div>

                      <div className="col-6 cursor  col-sm-12 col-md-12 col-xl-12  p-0 dashboard-grid">
                        <img
                          className="img-fluid p-0 third-height w-100"
                          src={baseUrl43 + '/' + gridData[7].grid.thumbnail_4_3}
                          alt="non_fiction"
                          onClick={()=>this.goDetail(gridData[7])}
                        />
                        <div className="bottom-left  text-white">
                          <div className="name-style"><span> {gridData[7].title}</span></div>
                          <div className="primary-container-padding font-class">
                            {gridData[7].description}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* for mobile */}
              <div className="container-fluid d-block d-sm-none d-md-none d-lg-none d-xl-none" style={{ marginTop: -7 }}>
                <div className="row grid-row ">
                  <div className="col-12  col-sm-6 col-md-4 col-xl-4 d-type-3">
                    <div className="row">
                      <div className="col-6  col-sm-12 col-md-12 col-xl-12  p-0 dashboard-grid">
                        <img
                          className="img-fluid p-0 third-height w-100"
                          src={baseUrl45 + '/' + gridData[0].grid.thumbnail_4_5}
                          alt={gridData[0].title}
                          onClick={()=>this.goDetail(gridData[0])}
                        />
                        <div className="bottom-left  text-white">
                          <div className="name-style"><span> {gridData[0].title}</span></div>
                          <div className="primary-container-padding font-class">
                            {gridData[0].description}
                          </div>
                        </div>
                      </div>
                      <div className="col-6  col-sm-12 col-md-12 col-xl-12  p-0 dashboard-grid">
                        <img className="img-fluid p-0 third-height w-100"
                          src={baseUrl45 + '/' + gridData[1].grid.thumbnail_4_5}
                          alt={gridData[1].title}
                          onClick={()=>this.goDetail(gridData[1])}
                        />
                        <div className="bottom-left  text-white">
                          <div className="name-style"><span> {gridData[1].title}</span></div>
                          <div className="primary-container-padding font-class">
                            {gridData[1].description}
                          </div>
                        </div>
                      </div>
                      <div className="col-12  col-sm-12 col-md-12 col-xl-12  p-0 dashboard-grid">
                        <img
                          className="img-fluid p-0 third-height w-100"
                          src={baseUrl43 + '/' + gridData[2].grid.thumbnail_4_3}
                          alt={gridData[2].title}
                          onClick={()=>this.goDetail(gridData[2])}
                        />
                        <div className="bottom-left  text-white">
                          <div className="name-style"><span> {gridData[2].title}</span></div>
                          <div className="primary-container-padding font-class">
                            {gridData[2].description}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12  col-sm-6 col-md-4 col-xl-4 d-type-2">
                    <div className="row">
                      <div className="col-6 col-sm-12  col-md-12 col-xl-12 p-0 dashboard-grid ">
                        <img
                          className="img-fluid p-0 second-height w-100"
                          src={baseUrl45 + '/' + gridData[3].grid.thumbnail_4_5}
                          alt={gridData[3].title}
                          onClick={()=>this.goDetail(gridData[3])}
                        />
                        <div className="bottom-left  text-white">
                          <div className="name-style"><span> {gridData[3].title}</span></div>
                          <div className="primary-container-padding font-class">
                            {gridData[3].description}
                          </div>
                        </div>

                      </div>
                      <div className=" col-6 col-sm-12 col-md-12 col-xl-12 p-0 dashboard-grid">
                        <img
                          className="img-fluid p-0 second-height w-100"
                          src={baseUrl45 + '/' + gridData[4].grid.thumbnail_4_5}
                          alt={gridData[4].title}
                          onClick={()=>this.goDetail(gridData[4])}
                        />
                        <div className="bottom-left  text-white">
                          <div className="name-style"><span> {gridData[4].title}</span></div>
                          <div className="primary-container-padding font-class">
                            {gridData[4].description}
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-4 col-xl-4 d-type-3">
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-12 col-xl-12  p-0 dashboard-grid">
                        <img
                          className="img-fluid p-0 third-height w-100"
                          src={baseUrl43 + '/' + gridData[5].grid.thumbnail_4_3}
                          alt={gridData[5].title}
                          onClick={()=>this.goDetail(gridData[5])}
                        />
                        <div className="bottom-left  text-white">
                          <div className="name-style"><span> {gridData[5].title}</span></div>
                          <div className="primary-container-padding font-class">
                            {gridData[5].description}
                          </div>
                        </div>
                      </div>
                      <div className="col-6 col-sm-12 col-md-12 col-xl-12  p-0 dashboard-grid">
                        <img
                          className="img-fluid p-0 third-height w-100"
                          src={baseUrl45 + '/' + gridData[6].grid.thumbnail_4_5}
                          alt={gridData[6].title}
                          onClick={()=>this.goDetail(gridData[6])}
                        />
                        <div className="bottom-left  text-white">
                          <div className="name-style"><span> {gridData[6].title}</span></div>
                          <div className="primary-container-padding font-class">
                            {gridData[6].description}
                          </div>
                        </div>
                      </div>
                      <div className="col-6  col-sm-12 col-md-12 col-xl-12  p-0 dashboard-grid">
                        <img
                          className="img-fluid p-0 third-height w-100"
                          src={baseUrl45 + '/' + gridData[7].grid.thumbnail_4_5}
                          alt={gridData[7].title}
                          onClick={()=>this.goDetail(gridData[7])}
                        />
                        <div className="bottom-left  text-white">
                          <div className="name-style"><span> {gridData[7].title}</span></div>
                          <div className="primary-container-padding font-class">
                            {gridData[7].description}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
            : ''}
        {this.state.showContainer && (
          <div
            className="row"
            id="subscribe"
            style={{ backgroundColor: "#3b3b3b" }}
          />
        )}
      </>
    );
  }
}
export default Dashboard