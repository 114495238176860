import React, { Component } from "react";
import swal from 'sweetalert'; 
import Cookies from 'universal-cookie';

import ApiList from "../../config/api.js";
import CommonApi from "../../util/common_api_handler.js"; 
import HeaderJson from '../../util/headers.js';
import RegenrateToken from "../../util/regenrateToken.js";

const cookies = new Cookies();

export default class Favourite extends Component {
  constructor() {
    super();
    this.state = {
      mainVideosData: [],
      mainMusicsData: [],
      mainHypeData: [],
      hypeData: [],
      userProfile: cookies.get('userId') ? cookies.get('userId') : "",
    };
  }
  componentDidMount=()=>{
    this.fetchData();
    this.fetchMusic();
  }

  fetchData = () => {
    const self = this;

    const apiUrl = ApiList.current.url + ApiList.endpoints.favourite.fetchList;
    
    const apiHeaders = HeaderJson.header();

    CommonApi.getCall(apiUrl, apiHeaders)
      .then((data)=>{
        if (data.statusCode === 200) {
          self.setState({
            mainVideosData: data.data ? data.data.favouritesVideosData : null, 
            mainHypeData: data.data ? data.data.favouritesHypeData : ''
          });
        }
      })
      .catch((err)=>{
        if (err.responseJSON.status === 403 || err.responseJSON.status === 400) {
          RegenrateToken.userRegenrateToken()
            .then((data) => {
              self.setState({ renderingStatus: true });
            })
        }
      })
  };

  fetchMusic = () =>{
    const self = this;

    const apiUrl = ApiList.current.url + ApiList.endpoints.music.fetchSingleSong;
    
    const apiHeaders = HeaderJson.header();

    CommonApi.getCall(apiUrl, apiHeaders)
      .then((data)=>{
        if (data.statusCode === 200) {
          self.setState({
            mainMusicData: data.data ? data.data : '',
          });
        }
      })
  }

  removeData = (content_id, data) => {

    const apiUrl = ApiList.current.url + ApiList.endpoints.favourite.fetchList;
    
    const apiHeaders = HeaderJson.header();

    const bodyData = {
      "content_id": content_id !== "" ? content_id : "",
      "page_type": data,
    }

    CommonApi.deleteCall(apiUrl, apiHeaders, bodyData)
      .then((data)=>{
        if (data.statusCode === 200) {
          swal({ text: data.message, timer: 1500 });
          this.fetchData();
        }
      })
  };

  removeMusic = (content_id, data) => {

    const apiUrl = ApiList.current.url + ApiList.endpoints.music.removeSingleSong;
    
    const apiHeaders = HeaderJson.header();

    const bodyData = {
      "media_manager_id": content_id !== "" ? content_id : "",
    }

    CommonApi.postCall(apiUrl, apiHeaders, bodyData)
      .then((data)=>{
        if (data.statusCode === 200) {
          swal({ text: data.message, timer: 1500 });
          this.fetchMusic();
        }
      })
  };

  watchListApi = (data) => { 
    const apiUrl =  ApiList.current.url + ApiList.endpoints.watchlist.watchlist;
    
    const apiHeaders = HeaderJson.header();

    const bodyData = {
      "content_id": data.content_id,
    }

    CommonApi.postCall(apiUrl, apiHeaders, bodyData)
      .then((data)=>{ 
        if (data.statusCode === 200) {
          swal({ text: data.message, timer: 1500 });
        }
      })
  }

  redirectDetailVideo = (data) => { 
    window.location.href = '/detail/'+data.slug + '/' + data.content_id 
  }

  redirectDetailMusic=(content_id)=>{
    window.location.href = '/music?content_id=' + content_id 
  }

  render() {
    const self = this
    const { mainVideosData, mainHypeData, mainMusicData } = this.state;
    return (
      <div className="container-fluid my-5 watchlist-container">
        <div className="row justify-content-center">
          <div className="col-md-10">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item">
                <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Video</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Music</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">Hype</a>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                {mainVideosData ?
                  <div className="row pt-3 pb-3">
                    {mainVideosData.map(function (allItem) {
                      return (
                        <div className="col-md-3 col-sm-6 col-6 pt-2 pb-2" key={allItem._id}>
                          <img className="img-fluid cursor" onClick={() => self.redirectDetailVideo(allItem ? allItem : '')} src={allItem ? allItem.base_url + '/' + allItem.content_manager_folder + "/" + allItem.content_id + '/' + allItem.thumbnail_folder + '/' + allItem.banner_folder + '/' + allItem.thumbnail_16_9 + '/' + allItem.platform_images.thumbnails_16_9 : ''} alt={allItem.series_name} srcSet="" />
                          <div className="watchlist-content ml-0 d-flex justify-content-between">
                            <div className="cursor">
                              {allItem.content_name}
                            </div>
                            <div className="dropdown dropup cursor" style={{ marginTop: '5px' }}>
                              <i className="fas fa-ellipsis-v ellipse-watchList dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <span className="dropdown-item" onClick={() => self.watchListApi(allItem)}> Add to WatchList </span>
                                <span className="dropdown-item" onClick={() => self.removeData(allItem.content_id ? allItem.content_id : '')} >Remove</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div> :
                  <div className="row">
                    <img src={"https://preprod-gemplex-assets.gemplex.tv/web/assets/Error/favorite.svg"} alt="" style={{ marginLeft: 'auto', marginRight: 'auto',marginTop:'5%' }} />
                  </div>}
              </div>
              <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                {mainMusicData ?
                  <div>
                    {mainMusicData.map(function (allItem, i) {
                      return (
                        <div className="wf-div mt-3" key={i}>
                          <img className="img-fluid wf-div-img cursor ml-0" onClick={() => self.redirectDetailMusic(allItem.content_id ? allItem.content_id : '')} src={allItem ? allItem.content_manager[0].base_url_media+'/' + allItem.content_manager[0].media_manager_id + "/" + allItem.content_manager[0].thumbnail_folder + '/' +  allItem.content_manager[0].platform_images.thumbnails_16_9 : ''} alt={allItem.content_name} srcSet="" />
                          <div className="watchlist-content cursor ml-0 d-flex justify-content-between">
                            {allItem.content_name}
                            <div className="dropdown dropup cursor" style={{ marginTop: '5px' }}>
                              <i className="fas fa-ellipsis-v ellipse-watchList dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <span className="dropdown-item" onClick={() => self.removeMusic(allItem.content_manager[0].media_manager_id ? allItem.content_manager[0].media_manager_id : '')} >Remove</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div> :

                  <div className="row">
                    <img src={"https://preprod-gemplex-assets.gemplex.tv/web/assets/Error/favorite.svg"} alt="" style={{ marginLeft: 'auto', marginRight: 'auto',marginTop:'5%' }} />
                  </div>}
              </div>
              <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                {mainHypeData ?
                  <div>
                    {mainHypeData.map(function (allItem) {
                      return (
                        <div className="wf-div mt-3" key={allItem.content_id}>
                          <img src={allItem.base_url_thumbnail_4_3 !== "" ? allItem.base_url_thumbnail_4_3 + "/" + allItem.thumbnail_4_3 : allItem.base_url ? allItem.base_url + allItem.gallery[0].image_file : null} className="slide-img h-100 w-100 cursor imag-size-fix hover-card" alt={allItem.title} onClick={() => self.item_click(allItem)} style={{ borderTopLeftRadius: '3px', borderTopRightRadius: '3px', width: '207px' }} />
                          <div>
                            <div className="watchlist-content ml-0 d-flex justify-content-between cursor">
                              {allItem.title}
                            </div>
                            <div className="dropdown dropup cursor" style={{ marginTop: '5px' }}>
                              <i className="fas fa-ellipsis-v ellipse-watchList dropdown-toggle cursor" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>
                              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <span className="dropdown-item" onClick={() => self.removeData(allItem.content_id ? allItem.content_id : '', "HYPE")} >Remove</span>
                              </div>
                            </div>
                          </div>
                          <div className="watchlist-series ml-0"></div>
                        </div>
                      );
                    })}
                  </div> :
                  <div className="row">
                    <img src={"https://preprod-gemplex-assets.gemplex.tv/web/assets/Error/favorite.svg"} alt="No Favourite" style={{marginTop:'5%' ,marginLeft: 'auto', marginRight: 'auto' }} />
                  </div>}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
