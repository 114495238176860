import React, { Component } from 'react';
import $ from 'jquery';

import ApiList from "../../config/api.js";
import helpers from '../../util/headers.js';
import Filter from "../partials/filter.jsx"
import Footer from '../partials/footer';
import Navbar from "../partials/NavigationBar.jsx"

import "../../stylesheet/content/video.css"
import "../../stylesheet/partials/hover-slider.css";

var img_podcast = "https://preprod-gemplex-assets.gemplex.tv/web/hype/podcast_icon.svg";
var img_play = "https://preprod-gemplex-assets.gemplex.tv/web/hype/play_icon.svg";
var img_image = "https://preprod-gemplex-assets.gemplex.tv/web/hype/image_icon.svg";
var img_article = "https://preprod-gemplex-assets.gemplex.tv/web/hype/article_icon.png";


export default class Hype extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: helpers.header(),
            slides: [],
            ContentName: this.props.match.params.content,
            slider: [],
            listDataFromChild: '',
            cellSize: this.props.match.params.cell,
            ratioSize: this.props.match.params.ratio,
            header: ''
        }
    }

    componentDidMount() {
        const self = this;
        self.fetch_main_data(this.props.match.params.type, this.props.match.params.catalogue, this.props.match.params.content.toUpperCase().replace(/\s+/g, ""))
 
    }
    fetch_main_data = (catName, pageType, content) => {
        var self = this;
        let bodyData = {
            'genreFilter': self.state.listDataFromChild ? self.state.listDataFromChild : [],
            "subCatalogue": content,
            'languageFilter': [],
            "parental_control": false,
            "page_type": pageType != null ? pageType : catName,
            "catalogue_name": catName != null ? catName : '',
            "offset": 0,
            "limit": 15,
            "catalogue_offset": 0,
            "catalogue_limit": 100
        }
        let header = this.state.headers
        $.ajax({
            url: ApiList.current.url + ApiList.endpoints.home.homeCatalogue,
            type: "POST",
            cache: false,
            headers: header,
            data: JSON.stringify(bodyData),
            success: (data) => {
                if (data.statusCode === 200 && data.data) {
                    const json_data = JSON.stringify(data) ? (data.data) : null;
                    if (json_data != null) {
                        let component_arry_var = self.state.slider.slice();
                        for (var i = 0; i < json_data.length; i++) {
                            component_arry_var.push(json_data[i]);
                        }
                        let arr_table = "";
                        let headings = ""
                        component_arry_var.map((key) => {
                            arr_table = (key.data)
                            headings = (key)
                        })
                        self.setState({
                            slider: arr_table,
                            slides: component_arry_var,
                            header: headings.sub_catalogue_name
                        }, () => {
                        })
                    }
                }
            },
            error: (err) => {
                if (err.statusCode === 403) {
                    // RegenrateToken.userRegenrateToken()
                }
            }
        });
    }
    dataGet = (dataFromChild) => {
        const self = this
        self.setState({ listDataFromChild: dataFromChild },
            () => {
                this.componentDidMount();
            });
    }
    item_click = slide_detail => {
        if (slide_detail.type === "video")
          window.location.href = "/video/" + slide_detail.content_id;
        else if (slide_detail.type === "music" || slide_detail.type === "popular")
          window.location.href = "/podcast/" + slide_detail.content_id;
        else if (slide_detail.type === "images")
          window.location.href = "/image/" + slide_detail.content_id;
        else if (slide_detail.type === "article" || slide_detail.type === "trendig")
          window.location.href = "/article/" + slide_detail.content_id;
      }
     
    render() {
        const self = this
        const { slider, header, cellSize, ratioSize } = this.state;
        return (
            <div>
                <Navbar />
                <Filter Language_type="video" showName='Genre' type={"video"} getGenre={this.dataGet} />
                <div className="series-list">
                    <div className="has-animation animate-on-hover container-fluid  primary-container-padding my-4 series-section">
                        <div className="row mb-3 justify-content-between">
                            <div className="col-xl-5 my-auto series-name-heading">
                                {header ? header : null}
                            </div>

                            <div className="col-xl-12 ">
                                <div className="border-series-slider"></div>
                            </div>
                        </div>
                        <div className="row">
                            <div className={"col-md-12 p-0 slick-slider " + (cellSize.slice(5, 15)) + " " + (ratioSize.slice(6, 13))} >
                                {
                                    slider ?
                                        slider.map(function (item, i) {
                                            return (
                                                <div className={"item d-inline-block m-3   "}>
                                                    <a className="item" style={{ "minHeight": "213px" }} key={ item._id} onClick={() => self.item_click( item)}>
                                                        <div className=" card main-card" >
                                                            <img src={ item.base_url_thumbnail_4_3 != null ?  item.base_url_thumbnail_4_3 + "/" +  item.thumbnail_4_3 :  item.base_url_thumbnail_4_3 + "/" +  item.thumbnail_4_3} className=" item-img h-100 w-100 imag-size-fix hover-card" alt={ item.video.title} style={{ borderTopLeftRadius: '3px', borderTopRightRadius: '3px', width: '207px' }} />
                                                            <div className="hover-effct">
                                                                <img src={ item.type === "video" ? img_play : ( item.type === "music" ? img_podcast : ( item.type === "article" ? img_article : img_image))} className="hype-thumbnail-icon" alt="play" />
                                                            </div>
                                                        </div>
                                                        <div className="card-bg-hype card-body" style={{ borderRadius: '0px 0px', height:'auto' }}>
                                                            <div style={{ display: 'flex' }}>
                                                                <div className="hype-catalog footer-color">{( item.genres[0] != null) ? ( item.genres[0].code) : null}</div>
                                                                <span className="" style={{ marginLeft: '5px', marginRight: '5px' }}>  </span>
                                                                {/* <div className="duration-hype"> {moment( item.article_publish_date).format('LL')}</div> */}
                                                            </div>
                                                            <div className="two-line ellips">
                                                                <div key={ item} className=" card-tit card-title footer-color  text-concats" onClick={() => self.item_click( item)}>
                                                                    { item.title ?
                                                                         item.title
                                                                        :
                                                                        null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            )
                                        }) : null
                                }

                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>

        )
    }

} 