import React, { Component } from 'react'; 
import Slider from "react-slick";
import moment from 'moment';

import ApiList from "../../../config/api.js";
import CommonApi from "../../../util/common_api_handler.js";
import HeaderJson from '../../../util/headers.js';

var img_podcast="https://preprod-gemplex-assets.gemplex.tv/web/hype/podcast_icon.svg";
var img_play="https://preprod-gemplex-assets.gemplex.tv/web/hype/play_icon.png";
var img_image ="https://preprod-gemplex-assets.gemplex.tv/web/hype/image_icon.svg";
var img_article ="https://preprod-gemplex-assets.gemplex.tv/web/hype/article_icon.svg";

class HypeRecommended extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: this.props.type,
            keywords: this.props.keywords,
            content_type: this.props.content_type,
            catalogue: this.props.catalogue,
            slides: [],
            ratio: '',
            cell_size: '',
            cName: this.props.title,
            headName: "",
            headers: HeaderJson.header(),
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        const self = this;
        let keyArr = [];
        if (self.state.type !== "people") {
            self.state.keywords.map(function (item) {
                return keyArr.push(item.genre_code ? item.genre_code : item.code)
            })
        } else {
            keyArr = [self.state.keywords]
        }

        let bodyData = {
            "type": self.state.type,
            "keywords": keyArr,
            "catalogue": this.state.catalogue,
            "offset": 0,
            "limit": 30,
            "content_type": this.state.content_type,
        }

        const apiUrl = ApiList.current.url + ApiList.endpoints.recommended.getData;

        const apiHeaders = HeaderJson.header();

        CommonApi.postCall(apiUrl, apiHeaders, bodyData)
            .then((data)=>{
                if (data.statusCode === 200 && data.data) {
                    let mainData = data.data ? data.data[0] : null
                    self.setState({
                        slides: mainData.data,
                        headName: mainData.catalogue_name,
                        ratio: mainData.ratio,
                        cell_size: mainData.cell_size,
                        baseUrl: mainData.base_url_video,
                        thumbnailFolder: mainData.thumbnail_folder
                    })
                }
            })
    }
    item_click = (data) => {
        this.setState({
          redirect : true
        })
        window.location.href = `/hype/${data.type}/${data.slug}/${data.content_id}`;
      }

    render() {
        const self = this;
        const {ratio, cell_size, cName, headName} = this.state

        let _class = 'ratio-4-5';
        let _cell_size = 'thumb-3';
        let toScroll = '2'
        if (cell_size && ratio) {
            if (ratio == '4:5') {

                _class = 'ratio-4-5'

            } else if (ratio == '4:3') {
                _class = 'ratio-4-3'

            } else if (ratio == '16:9') {
                _class = 'ratio-16-9'

            }
            if (cell_size == '3') {
                _cell_size = 'thumb-3'
                toScroll = '3'

            } else if (cell_size == '4') {
                _cell_size = 'thumb-4'
                toScroll = '4'
            } else if (cell_size == '6') {
                _cell_size = 'thumb-6'
                toScroll = '6'
            } else if (cell_size == '8') {
                _cell_size = 'thumb-8'
                toScroll = '8'
            }
        } 
        const settings = {
            className: [_class, _cell_size],
            centerMode: false,
            dots: true,
            infinite: false,
            speed: 500,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            swipeToSlide: true,
            responsive: [
                {
                    breakpoint: 4000,
                    settings: {
                        // slidesToShow: parseInt(toScroll) - 1,
                        slidesToScroll: parseInt(toScroll) - 1,
                        variableWidth: true,
                        adaptiveHeight: true
                    }
                },
                {
                    breakpoint: 2000,
                    settings: {
                        // slidesToShow: parseInt(toScroll) - 1,
                        slidesToScroll: parseInt(toScroll) - 1,
                        variableWidth: true,
                        adaptiveHeight: true
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        // slidesToShow: parseInt(toScroll) - 1,
                        slidesToScroll: parseInt(toScroll) - 1,
                        variableWidth: true,
                        adaptiveHeight: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        variableWidth: true,
                        adaptiveHeight: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        variableWidth: true,
                        adaptiveHeight: true,
                        dots: false
                    }
                }
            ]
        };


        return (
            <div>
                {this.state.slides.length > 0 ?
                    <div className="has-animation animate-on-hover container-fluid mt-4 p-0">
                        <div className="col-xl-12  mb-4 p-0" >
                            <div className="word_space ">
                                <div className="d-inline-block primary1-text gemplex-medium sub-heading" id="view">
                                    {cName ? cName : headName}
                                </div>
                                {/* <div className="behind-heading d-inline-block">
                                <a href="" className="">
                                    All
                  <div className="d-inline-block behind-heading-arrow" ><i class="fa fa-angle-right"></i></div>
                                </a>
                            </div> */}
                            </div>
                        </div>
                        {this.state.slides ? (
                            <Slider {...settings}>
                                {this.state.slides.map((slide) => {
                                    return (
                                        <span className="item" key={slide._id}>
                                            <div className="card main-card cursor" onClick={()=>self.item_click(slide)}>
                                                <img src={slide ? slide.base_url_thumbnail_4_3 + "/" + slide.thumbnail_4_3 : ''} alt="" className="slide-img h-100 w-100 imag-size-fix hover-card" onClick={() => self.item_click(slide)} style={{ borderTopLeftRadius: '3px', borderTopRightRadius: '3px', width: '207px' }} />
                                                <div className="hover-effct">
                                                    <img src={slide.type === "Videos" ? img_play : (slide.type === "Podcast" ? img_podcast : (slide.type === "Articles" ? img_article : img_image))} className="hype-thumbnail-icon" alt="play" />
                                                </div>
                                            </div>
                                            <div className="card-bg card-body" style={{ borderRadius: '0px 0px 27px 27px' }}>
                                                <div style={{ display: 'flex' }}>
                                                    <div className="hype-catalog footer-color">{(slide.genres != null) ? (slide.genres[0].code) : null}</div>
                                                    <span style={{ marginLeft: '5px', marginRight: '5px' }}></span>
                                                    <div className="duration-hype"> {moment(slide.article_publish_date).format('LL')}</div>
                                                </div>
                                                <div className="two-line ellips">
                                                    <div key={slide} className="card-tit card-title footer-color text-concats cursor" onClick={() => self.item_click(slide)}> {slide ? slide.title : null} </div>
                                                </div>
                                            </div>
                                        </span>
                                    );
                                })}
                            </Slider>
                        ) : ""}
 

                    </div>
                    : null}
            </div>

        )
    }


}
function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "none" }}
            onClick={onClick}
        >
            <img src="https://preprod-gemplex-assets.gemplex.tv/web/scroll-arrow-to-right.svg" alt="next" />
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "none" }}
            onClick={onClick}
        >
            <img src="https://preprod-gemplex-assets.gemplex.tv/web/scroll-arrow-to-left.svg" alt="next" />
        </div>
    );
}


export default HypeRecommended