import $ from 'jquery';
import raf from 'raf';
import swal from 'sweetalert';
import React, { Component } from 'react';
import ReactHowler from 'react-howler';
import { FacebookIcon, FacebookShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';
import Slider from "react-slick";
import Cookies from 'universal-cookie';

import ApiList from "../../config/api.js";
import Banner from "./MusicBanner.jsx";
import HeaderJson from '../../util/headers.js';
import CommonApi from "../../util/common_api_handler.js";
import Genre from "../home_page/Genre.jsx";
import Language from "../home_page/Language.jsx";
import SliderComp from './MusicLisiting.jsx';
import Filter from "../partials/filter";
import Footer from "../partials/footer";
import MetaData from "../partials/metaData.jsx";
import Navbar from "../partials/NavigationBar.jsx";
import Subscription from "../user/login_page/subscription_modal.jsx";
import LyricsModal from './LyricsModal.jsx';
import MusicInfoModal from './MusicInfoModal.jsx';
import Playlist from './Playlist.jsx'; 

import PlayerContentImg from '../../images/gemplex-vector logo-black.svg';

import "../../stylesheet/music_detail/index.css";
import "../../stylesheet/music_detail/info_modal.css";
import "../../stylesheet/music_detail/musicPlayer.css";
import "../../stylesheet/music_detail/playlist_modal.css";
import "../../stylesheet/music_detail/queue.css";
import "../../stylesheet/music_detail/table.css";
import "../../stylesheet/music_page/music_body.css";

const cookies = new Cookies();
const offSet = 0;
const limitRange = 13;
const catalogueOffset = 0;
const catalogueLimit = 999;

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            seeAllData: false,
            likeFlag: false,
            data: [],
            queueData: [],
            musicSrc: [],
            playing: false,
            mute: false,
            loop: false,
            volume: 0.5,
            duration: "0:00",
            loaded: false,
            listingToggleFlag: true,
            mdata: [],
            playingItemData: '',
            autoplay: false,
            isFavourite:false,
            qualityRate: 0,
            userProfile: cookies.get('userId') ? cookies.get('userId') : "",
            showCurrentDetailData: '',
            isPlaylist: false,
            urlContentParams: '',
            urlMediaParams: '',
            slider: [],
            contentId: [],
            subCatalogueFilter: '',
            userPlanCode: '',
            seeAllRatio: '',
            headers: HeaderJson.header(),
            urlContentId:'',
            itemlistdropval:"HD",
            addToFav:[],
            singlefevdata:[],
            fetchSongData:[]
        }
        this.handleToggle = this.handleToggle.bind(this)
        this.handleOnLoad = this.handleOnLoad.bind(this)
        this.handleOnEnd = this.handleOnEnd.bind(this)
        this.handleOnPlay = this.handleOnPlay.bind(this)
        this.handleStop = this.handleStop.bind(this)
        this.renderSeekPos = this.renderSeekPos.bind(this)
        this.handleLoopToggle = this.handleLoopToggle.bind(this)
        this.handleMuteToggle = this.handleMuteToggle.bind(this)
        this.handleAutoplayToggle = this.handleAutoplayToggle.bind(this)
        this.handleSeek = this.handleSeek.bind(this)
    }

    componentWillMount(){
        if(cookies.get('plan-order') === '1'){
            localStorage.setItem('queue', JSON.stringify([]))
            this.fetchSliderData(this.state.catalogNames, this.state.pageType, offSet, limitRange, catalogueOffset, catalogueLimit);
 
        }
    }
    async componentDidMount() {
        this.setState({
            urlContentId : this.props.location.search.split('?content_id=')[1] ? this.props.location.search.split('?content_id=')[1] :''
        })
      
        await Promise.all([
            this.fetchListingData(),
            cookies.get('userType') === "REGULAR" && this.fetchUserData(),
            this.setQueue(null),
            this.clearRAF(),
            this.loadSong(),
            this.shareFucntion(),
            this.fetchMyPlaylist(),
            this.fetchContentId(),
            this.fetchFavouriteSong()
        ])
        $(".dropdown-itemVal").click(function(){
            // alert('jsjsh')
            $(this).addClass("active-dropDown");
            $(".dropdown-itemVal").not(this).removeClass("active-dropDown");
            var itemvaluedrop=$(".active-dropDown").attr("value");   
            $(".item-listing").html(itemvaluedrop );
        })

    }

    fetchUserData = () => {

        const apiUrl = ApiList.current.url + ApiList.endpoints.user.userProfile;

        const apiHeaders = HeaderJson.header()
        CommonApi.getCall(apiUrl, apiHeaders)
            .then((data)=>{
                if (data.statusCode === 200) {
                    this.setState({
                        userPlanCode: data.data.subscription_plan_order,
                    });
                }
            })
    }

    fetchContentId = () =>{
        const urlContentParam = window.location.search.split('content_id=')[1]
        if(urlContentParam){
           this.myCallback(urlContentParam)
        }
    }
    shareFucntion() {
        // const myParam = window.location.search.split('content_id=')[1];
        // const media_id = window.location.search.split('media_manager_id=')[1];
        // if (window.location.search === '?content_id=undefined') {
        //     this.setState({
        //         listingToggleFlag: true
        //     })
        // }
        // else {
        //     this.setState({
        //         urlContentParams: myParam ? myParam.substring(0, 8) : ''
        //     },
        //         this.fetchData())
        // }
        // if (window.location.search.split('media_manager_id=')[1] !== 'undefined') {
        //     this.setState({
        //         urlMediaParams: media_id ? media_id : ''
        //     },
        //         this.fetchShareData())
        // }
    }

    fetchShareData = () => {
        const self = this
        const myParam = window.location.search.split('content_id=')[1];
        // const media_id = window.location.search.split('media_manager_id=')[1];

        let additionHeader = {
            content_id: myParam ? myParam.substring(0, 8) : '',
            // media_manager_id: media_id ? media_id.substring(0, 9) : ''
        }
        let header = Object.assign(additionHeader, this.state.headers)
        
        const apiUrl = ApiList.current.url + ApiList.endpoints.music.singleShare;
        
        CommonApi.getCall(apiUrl, header)
            .then((data)=>{
                if (data.statusCode === 200) {
                    this.setState({
                        data: data.data ? data.data : null,
                        listingToggleFlag: false
                    }, () => {
                        if (this.state.autoplay === true) {
                            self.renderMusicPlayer(self.state.data.content_manager)
                        }
                    })
                }
            })
    }

    loadSong = () => {
        const self = this;
        const queuelocalstr = localStorage.getItem('queue') ? JSON.parse(localStorage.getItem('queue')) : null;
        if (queuelocalstr != null && queuelocalstr.length > 0) {
            self.renderMusicPlayer([queuelocalstr[0]])
        }
        this.setState({
            playing: false
        })
    }

    fetchListingData = () => {
        const self = this

        let bodyData = {
            "genreFilter": [],
            "subCatalogue": this.state.subCatalogueFilter ? this.state.subCatalogueFilter : [],
            "languageFilter": [],
            "parental_control": false,
            'page_type': 'MUSIC',
            "offset": 0,
            "limit": 15,
            "catalogue_offset": 0,
            "catalogue_limit": 100,
            "sortBy": [],
            "catalogue_name": "MUSIC"
        }

        const apiUrl = ApiList.current.url + ApiList.endpoints.music.listing;

        const apiHeaders = HeaderJson.header();

        CommonApi.postCall(apiUrl, apiHeaders, bodyData)
            .then((data)=>{
                if (data.statusCode === 200) {
                    self.setState({
                        mdata: data.data ? data.data : null
                    })
                }
            })
    }

    onMouseEnterHandler = () => {
        document.getElementById("conatiner-bg-overlayer").style.display = "block";
        document.getElementById("conatiner-bg-overlayer").style.opacity = "1";
    }

    onMouseLeaveHandler = () => {
        document.getElementById("conatiner-bg-overlayer").style.display = "none";
        document.getElementById("conatiner-bg-overlayer").style.opacity = "0";
    }
  
    fetchData = () => {
        const self = this;


        const apiUrl = ApiList.current.url + ApiList.endpoints.music.detail + self.state.urlContentParams;

        const apiHeaders = HeaderJson.header();

        CommonApi.getCall(apiUrl, apiHeaders)
            .then((data) => {
                if (data.statusCode === 200) {
                    this.setState({
                        data: data.data ? data.data : null,
                    }, () => { 
                        if (this.state.autoplay === true) {
                            self.renderMusicPlayer(self.state.data.content_manager)
                        }
                    })
                }
            })
        //    }else if(self.state.urlContentParams !== ""){
        //     $.ajax({
        //         url: ApiList.current.url + ApiList.endpoints.music.detail + self.state.urlContentParams,
        //         type: "GET",
        //         headers: this.state.headers,
        //         success: data => {
        //             if (data.statusCode === 200) {
        //                 this.setState({
        //                     data: data.data ? data.data : null,
        //                 }, () => {
        //                     if (this.state.autoplay === true) {
        //                         self.renderMusicPlayer(self.state.data.content_manager)
        //                     }
        //                 })
        //             }
        //         }
        //     });
        //    }
        // } else {
        //     self.setState({
        //         listingToggleFlag: false,
        //     })
        //     $.ajax({
        //         url: ApiList.current.url + ApiList.endpoints.music.detail + myParam,
        //         type: "GET",
        //         headers: this.state.headers,
        //         success: data => {
        //             if (data.statusCode == 200) {
        //                 this.setState({
        //                     data: data.data ? data.data : null,
        //                 }, () => {
        //                     if (this.state.autoplay === true) {
        //                         self.renderMusicPlayer(self.state.data.content_manager)
        //                     }
        //                 })
        //             }
        //         },
        //         error: err => {
        //             if (err.status === 403) {
        //             }
        //         }
        //     });
        // }
    }


    fetchFavouriteSong=()=>{
        const apiUrl = ApiList.current.url + ApiList.endpoints.music.fetchSingleSong;

        const apiHeaders = HeaderJson.header();
        CommonApi.getCall( apiUrl, apiHeaders)
        
        .then((data)=>{
            console.log(data);
            if (data.statusCode === 200) {
              let map_array = [];
              data.data.map(function(key){
                return  map_array.push(key.content_manager[0].media_manager_id)
              })
              this.setState({
                fetchSongData:map_array
              })
            }
        })
    }
   
    favourite = (data) => {
        const self = this; 
        const apiUrl = ApiList.current.url + ApiList.endpoints.music.singleSongFav;

        const apiHeaders = HeaderJson.header();

        const bodyData = {
            media_manager_id: data.media_manager_id != null ? data.media_manager_id : "",
            content_id: this.props.location.search.split('?content_id=')[1] ? this.props.location.search.split('?content_id=')[1] : this.state.data.content_id
        }
       if(data){
        CommonApi.postCall(apiUrl, apiHeaders,bodyData)
        .then((data)=>{
            swal({ text: data.message, timer: 1500 });
            if (data.statusCode === 200) {
                self.setState({ isFavourite: true },()=>{
                   self.fetchFavouriteSong() 
                });
            }
        })
       }

    }
    disableFavourite = (data) => {

        const self = this
        const bodyData = {
            media_manager_id: data.media_manager_id != null ? data.media_manager_id : "", 
        } 
    
        const apiUrl = ApiList.current.url + ApiList.endpoints.music.removeSingleSong;
        const apiHeaders = HeaderJson.header();
    
        if(data){
            CommonApi.postCall(apiUrl, apiHeaders, bodyData)
            .then((data)=>{
              if (data.statusCode === 200) {
                self.setState({
                  isFavourite: false
                },()=>{
                    self.fetchFavouriteSong()
                })
              }
            })
        }
    };

    renderMusicPlayer = (itemData, content_id, media_manId) => {
        const self = this;
        console.log('jsonsssss',this.state.fetchSongData.indexOf(itemData[0].media_manager_id)) 
        if(this.state.fetchSongData.indexOf(itemData[0].media_manager_id) === 1){
            self.setState({
                isFavourite : true
            })
        }else{
            self.setState({
                isFavourite : false
            })
        }
        
        if(this.state.urlMediaParams !== itemData[0].media_manager_id){
            self.clearRAF()
            self.setQueue(itemData)
            if (itemData != null) {
                this.setState({
                    playingItemData: itemData[0],
                    musicSrc: itemData !== null ? itemData[0].base_url_media + "/" + itemData[0].media_manager_id + "/" + itemData[0].audio_transcode_profiles[self.state.qualityRate] : '',
                    playing: true,
                    urlMediaParams: itemData[0].media_manager_id
                })
              
                $('.player-current-song-name').text(itemData[0].title)
                $('.player-current-sub-text').text(itemData[0].content_name)
                $('.queue-data-list').css({
                    'background-image': "unset"
                });
                $('.title-table-thumb').removeClass('active')
                $('.' + itemData[0]._id).addClass('active')
            }
        }else{
            if (itemData != null) {
                this.setState({
                    playingItemData: itemData[0],
                    musicSrc: itemData !== null ? itemData[0].base_url_media + "/" + itemData[0].media_manager_id + "/" + itemData[0].audio_transcode_profiles[self.state.qualityRate] : '',
                    playing: true,
                    urlMediaParams: itemData[0].media_manager_id
                })
                $('.player-current-song-name').text(itemData[0].title)
                $('.player-current-sub-text').text(itemData[0].content_name)
                $('.queue-data-list').css({
                    'background-image': "unset"
                });
                $('.title-table-thumb').removeClass('active')
                $('.' + itemData[0]._id).addClass('active')
            }
        }
    }
   
    playerHowler = () => { 
        return (
            <ReactHowler 
                src={this.state.musicSrc} 
                onLoad={this.handleOnLoad}
                onPlay={this.handleOnPlay}
                onEnd={this.handleOnEnd}
                loop={this.state.loop}
                playing={this.state.playing}
                mute={this.state.mute}
                volume={this.state.volume}
                autoplay={this.state.autoplay}
                ref={(ref) => (this.player = ref)}
            />
        )
    }
   
    playerComponent = () => {
        const self = this;
        const {  data, userProfile,isFavourite, playing, mute, playingItemData, urlMediaParams,} = this.state
        return (
            <div className="container-fluid music-player-strip d-flex flex-row justify-content-between">
            
                <div className="current-song-content  d-flex align-items-center">
                    <img className="d-none d-xs-none d-sm-none d-md-block d-xl-block" style={{ height: 60, width: 60 }} src={playingItemData.base_url_media ? (playingItemData.base_url_media + "/" + playingItemData.media_manager_id + "/" + playingItemData.thumbnail_folder + "/" + (playingItemData.platform_images ? playingItemData.platform_images.thumbnails_4_3 : '')) : PlayerContentImg} alt={'Song_Image_Title'} />
                    <ul className="list-unstyled mt-auto ml-3 ">
                        <li className="current-song-text player-current-song-name"></li>
                        <li className="sub-text mt-2 player-current-sub-text"></li>
                    </ul>
                </div>
                <div className='align-items-center heart-section d-none d-xs-none d-sm-none d-md-flex d-xl-flex'>
                    {cookies.get('userType') === "GUEST" ? ' ' : (isFavourite === true || playingItemData.is_favourite === true) ? 
                    <img className='cursor' src={'https://preprod-gemplex-assets.gemplex.tv/web/like-fill.svg'} style={{ width: '1.5rem' }} onClick={()=>this.disableFavourite(playingItemData)} alt="Favourite Logo Fill" /> : 
                    <img className='cursor' src="https://preprod-gemplex-assets.gemplex.tv/web/like.svg" onClick={()=>this.favourite(playingItemData)} style={{ width: '1.5rem' }} alt="Favourite Logo Empty" />}
                </div>
                <div className='align-items-center icon d-none d-xs-none d-sm-none d-md-flex d-xl-flex cursor'>
                    {/* <!-- Default dropup button --> */}
                    <div className="btn-group dropup">
                        <img src={'https://preprod-gemplex-assets.gemplex.tv/web/more.svg'} className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ width: 25, height: 20 }} alt="Ellipse_Icon" />
                        <div className="dropdown-menu">
                            <button className="dropdown-item mt-1" type="button" data-toggle="modal" data-target={userProfile ? "#playlist-modal" : "#sign_up_box"} onClick={() => this.updateCurrentInfo(this.state.playingItemData, data.content_id)}><img className='mr-2' src={"https://preprod-gemplex-assets.gemplex.tv/web/Add+to+watch+list+icon.svg"} style={{ width: '1.2rem' }} alt="" title="add to watchlist" />Add to Playlist</button>
                            <button className="dropdown-item mt-1" type="button">
                                <div className="d-inline-block mt-2 mr-2"><img className='mr-2 d-inline-block align-baseline' src="https://preprod-gemplex-assets.gemplex.tv/web/share.svg" style={{ width: '1.2rem' }} alt="" />
                                </div>
                                <div className="d-inline-block mt-2 mr-2" style={{ outline: 'none' , boxShadow:'none' }}>
                                    <FacebookShareButton url={window.location.href + "&media_id=" + urlMediaParams}>
                                        <FacebookIcon size={25} round={true} />
                                    </FacebookShareButton></div>
                                <div className="d-inline-block mt-2 mr-2" style={{ outline: 'none' , boxShadow:'none' }}>
                                    <WhatsappShareButton url={window.location.href + "&media_id=" + urlMediaParams} >
                                        <WhatsappIcon size={25} round={true} />
                                    </WhatsappShareButton>
                                </div>
                            </button>
                            <button className="dropdown-item" type="button" data-toggle="modal" data-target="#music-info-modal" onClick={() => this.updateCurrentInfo(this.state.playingItemData)}><img className='mr-2' src={"https://preprod-gemplex-assets.gemplex.tv/web/info.svg"} style={{ width: '1.2rem' }} alt="Music Info Logo" /> Get Song Info</button>
                            <button className="dropdown-item mt-1" type="button" data-toggle="modal" data-target="#music-lyrics-modal" onClick={() => this.updateCurrentInfo(this.state.playingItemData)}><img className='mr-2' src={"https://preprod-gemplex-assets.gemplex.tv/web/assets/music-note.svg"} style={{ width: '1.2rem' }} alt="Lyrics Logo" />Lyrics</button>
                        </div>
                    </div>
                </div>
                <div className="d-flex align-item-center mx-5 position-relative">
                    <input type="range" id="progress_bar_song" className="song-tarck-bar e-range " min="0" defaultValue={0} onChange={this.handleSeek} max={this.state.durationSlider} />
                    <div className='d-flex align-items-center mx-2 backward'>
                        <i className="fas fa-step-backward cursor" data-toggle="tooltip" data-placement="left" title="Previous Song" onClick={this.prevSong}></i>
                    </div>
                    <div className='d-flex align-items-center  play-music-button' id='play-music' onClick={this.handleToggle}>
                        {playing ? <i className="fas fa-pause-circle cursor" data-toggle="tooltip" data-placement="left" title="Pause"></i> : <i className="fas fa-play-circle cursor" data-toggle="tooltip" data-placement="left" title="Play"></i> }
                    </div>
                    <div className='d-flex align-items-center mx-2  forward'>
                        <i className="fas fa-step-forward cursor" data-toggle="tooltip" data-placement="left" title="Next Song" onClick={this.nextSong}></i>
                    </div>
                    <div className='align-items-center mx-3 time d-none d-xs-none d-sm-none d-md-flex d-xl-flex' >
                        {`${this.state.seek !== undefined ? this.state.seek : "0:00"} / ${this.state.duration ? this.state.duration : "0:00"}`}
                    </div>
                    <div className='align-items-center mx-3 icon volume position-relative d-none d-xs-none d-sm-none d-md-flex d-xl-flex '>
                        {mute || this.state.volume === 0 ? <i className="fas fa-volume-mute cursor"></i> : <i className="fas fa-volume-up cursor"></i>}
                        <div className="volume-range">
                            <input type="range" id="volume-range" orient="vertical" className="" min='0' max='1' step='.05' value={this.state.volume} onChange={e => this.setState({ volume: parseFloat(e.target.value) })} />
                        </div>
                    </div>
                    <div className='align-items-center mx-3 icon text-secondary d-none d-xs-none d-sm-none d-md-flex d-xl-flex '>
                        <i className="fas fa-random cursor" onClick={self.shufflePlaylist}></i>
                    </div>
                    <div className='align-items-center mx-3 icon repeat d-none d-xs-none d-sm-none d-md-flex d-xl-flex ' onClick={this.handleLoopToggle}>
                        {this.state.loop ? <i className="fas fa-redo-alt cursor"></i> : <i className="fas fa-redo-alt text-muted cursor"></i>}
                    </div>
                    <div className='align-items-center quality-music-select px-3 d-none d-xs-none d-sm-none d-md-flex d-xl-flex'>
                        <div className="rail-select">
                            <div className="container">
                                <div className="dropup">
                                    <button type="button" className="btn btn-outline-primary dropdown-toggle p-1 pl-3 pr-3" data-toggle="dropdown" style={{borderRadius:"20px"}}>
                                        <span className="item-listing">HD</span> <span className="caret"><i className="fas fa-caret-up"></i></span>
                                    </button> 
                                    {playingItemData ? 
                                        <div className="dropdown-menu">
                                            {playingItemData.audio_transcode_profiles_label.map(function (tpitem, i) {
                                                return (
                                                    <a className="dropdown-item dropdown-itemVal" key={tpitem} value={tpitem} ><div className="row"><div className="col-8">{tpitem}</div><div className="col"><i className="far fa-check-circle font-weight-bold" style={{display:"none"}}></i></div></div> </a>  
                                                )
                                            })}
                                        </div>
                                    :null}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='align-items-center mx-3 autoplay autoplayed d-none d-xs-none d-sm-none d-md-flex d-xl-flex '>
                        <div style={{ visibility: "hidden" }} > Autoplay</div> <button style={{ visibility: "hidden" }} className='ml-3 border-0 br-5 text-white primary2-bg  px-3 cursor' onClick={this.handleAutoplayToggle}>{this.state.autoplay ? "ON" : "OFF"}</button>
                    </div>
                </div>
                <div className="align-items-center ad-music-player d-none d-xs-none d-sm-none d-md-flex d-xl-flex " >
                    <img className="" src={"https://preprod-gemplex-assets.gemplex.tv/web/assets/gemv1.jpg"} alt="" />
                </div>
            </div>
        )
    }

    clearQueue = () => {
        const self = this
        self.setState({
            queueData: [],
            musicSrc: [],
            playingItemData: '',
            playing: false,
            seek: undefined,
            duration: '',
            durationSlider:''
        }, () => {
            this.playerHowler();
            this.clearRAF()
            document.getElementById('progress_bar_song').value = 0
            $('.player-current-song-name').text('')
            $('.player-current-sub-text').text('')
            this.playerComponent()
            localStorage.setItem('queue', JSON.stringify(this.state.queueData))
        })
    }

    handleToggle() {
        const queuelocalstr = localStorage.getItem('queue') ? JSON.parse(localStorage.getItem('queue')) : null;

        if (cookies.get('userType') === "GUEST") {
            $(".openSignIn").click()
        } else if (cookies.get('userType') === "REGULAR") {
            if (this.state.userPlanCode < 1) {
                $(".open-subscription-modal").click()
            } else {
                queuelocalstr.length > 0 ? this.setState({ playing: !this.state.playing }) : this.setState({ playing: false })
            }
        }
    }

    handleOnLoad() { 
        this.setState({
            // loaded: true,
            // duration: this.player.duration()
        })
    }

    handleAutoplayToggle() {
        this.setState({
            autoplay: !this.state.autoplay
        })
    }

    handleOnPlay() {
        this.setState({
            playing: true,
            duration: (this.player.duration() > 60 ? Math.floor(this.player.duration() / 60) + ':' + (this.player.duration() % 60).toFixed() : " "),
            durationSlider: this.player.duration()
        }, function () {
            this.renderSeekPos()
        })
    }

    handleOnEnd() {
        // this.player.stop()
        const currentSong = this.state.playingItemData
        const self = this
        const queuelocalstr = localStorage.getItem('queue') ? JSON.parse(localStorage.getItem('queue')) : null;
        if (queuelocalstr != null) {
            for (let i = 0; i < queuelocalstr.length; i++) {
                if (queuelocalstr[i]._id === currentSong._id) {
                    if (queuelocalstr[i + 1] != null) {
                        self.renderMusicPlayer([queuelocalstr[i + 1]])
                    }
                }
                else if (this.state.loop === true) {
                    self.renderMusicPlayer(queuelocalstr[i]._id)
                }

            }
        }
        this.clearRAF()
    }

    handleSeek() {
        this.player.seek($('#progress_bar_song').val())
    }

    handleStop() {
        this.player.stop()
        this.setState({
            playing: false // Need to update our local state so we don't immediately invoke autoplay
        })

        this.renderSeekPos()
    }

    handleLoopToggle() {
        const self = this
        self.setState({
            loop: !self.state.loop
        })
    }

    handleMuteToggle() {
        const self = this
        self.setState({
            mute: !self.state.mute
        })
    }

    renderSeekPos() {
        const self = this; 
        $('.' + this.state.playingItemData._id).css({
            'background-image': "linear-gradient(to right, #26bdeb78 " + ((self.player.seek() / this.player.duration()) * 100) + "%,white 0%)"
        }); 
        let minutes = Math.floor(this.player.seek().toFixed() / 60);
        let seconds = this.player.seek().toFixed() - minutes * 60; 
        self.setState({
            seek: minutes + ":" + ("0" + seconds).slice(-2),
            seekSlider: this.player.seek()
        })
        $('#progress_bar_song').val(self.player.seek())
        if (self.state.playing) {
            self._raf = raf(self.renderSeekPos)
        }
    }

    clearRAF() {
        raf.cancel(this._raf)
    }

    setQueue = (itemData) => {
        const self = this
        const queuelocalstr = localStorage.getItem('queue') ? JSON.parse(localStorage.getItem('queue')) : null;
        var shouldAppend = true
        if (queuelocalstr !== null && queuelocalstr.length > 0) {
            if (itemData) {
                if (itemData.length === 1) {
                    queuelocalstr.map(function (item, i) {
                        if (item._id === itemData[0]._id) {
                             shouldAppend = false
                        }
                        if (queuelocalstr.length === i + 1 && shouldAppend) {
                             self.setState({ queueData: [itemData[0], ...queuelocalstr] }, function () { localStorage.setItem('queue', JSON.stringify(self.state.queueData)) })
                            
                        }
                        
                    })
                } else {

                    var finalArr = []
                    itemData.map(function (multiDataItem, multiI) {
                        let shouldAppendMulti = true
                        queuelocalstr.map(function (item, i) {
                            if (item._id === multiDataItem._id) {
                                shouldAppendMulti = false
                            }
                            if (queuelocalstr.length === i + 1 && shouldAppendMulti === true) {
                                finalArr.push(multiDataItem)
                            }
                        })
                        if (itemData.length == multiI + 1) {
                            self.setState({
                                queueData: [...finalArr, ...queuelocalstr]
                            }, function () {
                                localStorage.setItem('queue', JSON.stringify(self.state.queueData))
                            })
                        }
                    })
                }
            } else {
                self.setState({
                    queueData: queuelocalstr
                })
            }
        }
        else {
            if (itemData != null) {
                self.setState({
                    queueData: [...itemData]
                }, function () {
                    localStorage.setItem('queue', JSON.stringify(self.state.queueData))
                })
            }
        }
    }

    nextSong = () => {
        const currentSong = this.state.playingItemData
        const self = this
        const queuelocalstr = localStorage.getItem('queue') ? JSON.parse(localStorage.getItem('queue')) : null;
        if (queuelocalstr != null) {
            for (let i = 0; i < queuelocalstr.length; i++) {
                if (queuelocalstr[i]._id === currentSong._id) {
                    if (queuelocalstr[i + 1] != null) {
                        self.renderMusicPlayer([queuelocalstr[i + 1]])
                    }
                    else {
                        swal({text:"No next song to play", timer:1500})
                    }
                }
            }
        }
    }

    prevSong = () => {
        const currentSong = this.state.playingItemData
        const self = this
        const queuelocalstr = localStorage.getItem('queue') ? JSON.parse(localStorage.getItem('queue')) : null;
        if (queuelocalstr != null) {
            for (let i = 0; i < queuelocalstr.length; i++) {
                if (queuelocalstr[i]._id === currentSong._id) {
                    if (queuelocalstr[i - 1] != null) {
                        self.renderMusicPlayer([queuelocalstr[i - 1]])
                    }
                    else {
                        swal({text:"No prev song to play", timer:1500})
                    }
                }
            }
        }
    }

    shufflePlaylist = () => {
        const self = this
        let queuelocalstr = localStorage.getItem('queue') ? JSON.parse(localStorage.getItem('queue')) : null;
        var currentIndex = queuelocalstr.length, temporaryValue, randomIndex;
        // While there remain elements to shuffle...
        if(queuelocalstr.length > 1){
            while (0 !== currentIndex) {
                // Pick a remaining element...
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex -= 1;
                // And swap it with the current element.
                temporaryValue = queuelocalstr[currentIndex];
                queuelocalstr[currentIndex] = queuelocalstr[randomIndex];
                queuelocalstr[randomIndex] = temporaryValue;
            }
            if (queuelocalstr != null) {
                self.setState({
                    queueData: [...queuelocalstr]
                }, function () {
                    localStorage.setItem('queue', JSON.stringify(self.state.queueData))
                    self.renderMusicPlayer([queuelocalstr[0]])
                })
            }
        }
    }

    changeBitRate = (e) => {
        
        this.setState({
            qualityRate: e.target.value,
            musicSrc : this.state.playingItemData  !== null ? this.state.playingItemData.base_url_media + "/" + this.state.playingItemData.media_manager_id + "/" + this.state.playingItemData.audio_transcode_profiles[e.target.value] : '',
        })
    }

    myCallback = (dataFromChild, heading) => {
        this.setState({
            listingToggleFlag: false,
            urlContentParams: dataFromChild
        }, () => {
            this.fetchData(dataFromChild);
        })
        this.clearRAF()
    }

    handleFilterUpdate = (callBack, ratio) => {
        this.setState({
            subCatalogueFilter: callBack,
            seeAllData: true,
            seeAllRatio: ratio
        }, () => {
            this.fetchListingData()
        })
    }

    // Clear Queue Data Function...
    queueDeleteElement = elementData => {
        const self = this
        const queuelocalstr = localStorage.getItem('queue') ? JSON.parse(localStorage.getItem('queue')) : null;
        if (queuelocalstr.length === 1) {
            this.clearQueue()
        } else if (queuelocalstr != null && elementData) {
            let tempArr = []

            queuelocalstr.map(function (item, i) {
                if (item._id !== elementData[0]._id) {
                    tempArr.push(item)
                }
                if (queuelocalstr.length === i + 1) {
                    self.setState({
                        queueData: [...tempArr]
                    }, function () {
                        self.renderMusicPlayer([self.state.queueData[0]])
                        localStorage.setItem('queue', JSON.stringify(self.state.queueData))
                    })
                }
            })
        }
    }

    updateCurrentInfo = (toSetData, data) => {
        this.setState({
            showCurrentDetailData: toSetData,
            contenId: data
        })

    }

    playListData = (dataFromChild) => {
        const self = this;
        localStorage.setItem('playlist', JSON.stringify(dataFromChild))
        self.setState({
            slides: dataFromChild
        })
    }

    fetchMyPlaylist = () => {

        const apiUrl = ApiList.current.url + ApiList.endpoints.music.fetchMyPlaylist;

        const apiHeaders = HeaderJson.header();

        CommonApi.getCall(apiUrl, apiHeaders)
            .then((data) => {
                if(data.statusCode === 200){
                    this.setState({ slides: data.data })
                }
                
            })
    }

    customePlaylistDetail = (playlist_name) => {

        const self = this

        const apiUrl = ApiList.current.url + ApiList.endpoints.music.fetchPlaylist + "/" + playlist_name;

        const apiHeaders = HeaderJson.header();

        CommonApi.getCall(apiUrl, apiHeaders)
            .then((data) => {
                let contentIds = data.data.content_id;
                let singleQueue = [];
                data.data.map((key) => {
                    return singleQueue.push(key.content_manager[0]);
                })
                let jsonData = {"content_manager":singleQueue}
                this.setState({
                    data: jsonData ? jsonData : null,
                    listingToggleFlag: false,
                },()=>{ 
                    self.renderMusicPlayer(singleQueue, contentIds)
                })
            })
    }

    seeAllPage(id) {
        this.setState({
            listingToggleFlag: false,
            urlContentParams: id
        }, () => {
            this.fetchData(id)
        })
        var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?content_id=' + id;
        window.history.pushState({ path: newurl }, '', newurl);
    
    }
    fetchSliderData = (catName, pageType, offSet, limitRange, catalogueOffset, catalogueLimit) => {
        const self = this;
        
        const apiUrl = ApiList.current.url + ApiList.endpoints.home.homeCatalogue;
    
        const apiHeaders = HeaderJson.header();
    
        const bodyData = {
          "genreFilter": self.state.listDataFromChild ? self.state.listDataFromChild : [],
          "subCatalogue": [],
          "languageFilter": self.state.listLanguage ? self.state.listLanguage : [],
          "sortBy": self.state.sortByData ? self.state.sortByData : '',
          "parental_control": false,
          "page_type": pageType,
          "catalogue_name": catName,
          "offset": offSet,
          "limit": limitRange,
          "catalogue_offset": catalogueOffset,
          "catalogue_limit": catalogueLimit,
        }
    
        CommonApi.postCall(apiUrl, apiHeaders, bodyData)
          .then((data)=>{
            
            if (data.statusCode === 200 && data.data) {
              let mdata = data.data
              let component_arry_var = self.state.slider.slice();
              for (let i = 0; i < mdata.length; i++) { 
                component_arry_var.push(<SliderComp slider_data={mdata[i]} pageType={pageType} catName={catName} key={i} />);
              }
              self.setState({
                slider: component_arry_var,
                mdata: data.data
              })
            }
          })
      }
      fetchGenreData = (dataFromChild) => {
        const self = this
        self.setState({
          listDataFromChild: dataFromChild,
          slider:[]
        }, () => {
          self.fetchSliderData(self.state.catalogNames, self.state.pageType, offSet, limitRange, catalogueOffset, catalogueLimit);
        });
      }
      
      fetchSortData = (childSorting) => {
        const self = this;
        self.setState({
          sortByData: childSorting,
          slider:[]
        }, () => {
          self.fetchSliderData(self.state.catalogNames, self.state.pageType, offSet, limitRange, catalogueOffset, catalogueLimit);
        })
      }
    
    fetchLanguageData=(data)=>{
        const self = this;
       
        self.setState({
          listLanguage : data,
          slider:[]
        }, () => {
          
          self.fetchSliderData(self.state.catalogNames, self.state.pageType, offSet, limitRange, catalogueOffset, catalogueLimit);
        })
      }

    redirectPage=()=>{
        this.setState({
            listingToggleFlag: true,
            isPlaylist: false,
            seeAllData : false
        })
        var uri = window.location.toString();
	        if (uri.indexOf("?") > 0) {
	    var clean_uri = uri.substring(0, uri.indexOf("?"));
	    window.history.replaceState({}, document.title, clean_uri);
	    }
    }
    render() {
        const {  data, mdata, seeAllRatio, userProfile, queueData, playing, listingToggleFlag, playingItemData, slides, urlMediaParams, isPlaylist } = this.state
        const self = this;
        let _class = '';
        let _cell_size = 'thumb-3';
        let toScroll = '2'
        if (this.props && this.props.slider_data) {
            if (this.props.slider_data.ratio === '4:5') {
                _class = 'ratio-4-5'
            } else if (this.props.slider_data.ratio === '4:3') {
                _class = 'ratio-4-3'
            } else if (this.props.slider_data.ratio === '16:9') {
                _class = 'ratio-16-9'
            }
            if (this.props.slider_data.cell_size === '3') {
                _cell_size = 'thumb-3'
                toScroll = '3'
            } else if (this.props.slider_data.cell_size === '4') {
                _cell_size = 'thumb-4'
                toScroll = '4'
            } else if (this.props.slider_data.cell_size === '6') {
                _cell_size = 'thumb-6'
                toScroll = '6'
            } else if (this.props.slider_data.cell_size === '8') {
                _cell_size = 'thumb-8'
                toScroll = '8'
            }
        }
        const settings = {
            className: [_class, _cell_size],
            centerMode: false,
            dots: true,
            infinite: false,
            speed: 500,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            swipeToSlide: true,
            responsive: [
                {
                    breakpoint: 4000,
                    settings: {
                        slidesToScroll: parseInt(toScroll) - 1,
                        variableWidth: true,
                        adaptiveHeight: false,
                    }
                },
                {
                    breakpoint: 2000,
                    settings: {
                        slidesToScroll: parseInt(toScroll) - 1,
                        variableWidth: true,
                        adaptiveHeight: false,
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToScroll: parseInt(toScroll) - 1,
                        variableWidth: true,
                        adaptiveHeight: true,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        variableWidth: true,
                        adaptiveHeight: true,
                        dots: false,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        variableWidth: true,
                        adaptiveHeight: true,
                        dots: false,
                    }
                }
            ]
        };

        const title_data = "Gemplex - Stream The New!";
        const description_data = "Gemplex. Watch original TV Shows and Movies on Gemplex. Online Streaming Now.";
        const keywords_data = "gemplex, online, streaming, movies, tv shows, latest, streaming new";
        const title_tag = "Gemplex - Stream The New!";

        return (
            <>
                          <MetaData  title={title_data} description={description_data} keywords={keywords_data} mainTitle={title_tag} />

                <Navbar active={'music'} />
                <div className="conatiner-bg-overlayer" id='conatiner-bg-overlayer'></div>
                <div className="d-none d-xs-none d-sm-none d-md-block d-xl-block  text-center queue-section shadow-sm" onMouseEnter={this.onMouseEnterHandler}
                    onMouseLeave={this.onMouseLeaveHandler}>
                    <div className="queue-heading text-left d-flex">
                        <div className="mr-auto ml-2" style={{ height: 27 }}>Queue</div>
                        <i className="fas fa-random  show-on-hover my-auto text-primary mr-3 cursor" data-toggle="tooltip" data-placement="left" title="Shuffle" onClick={self.shufflePlaylist}></i>
                        <i className="fas fa-times show-on-hover my-auto mr-3 cursor" data-toggle="tooltip" data-placement="bottom" title="Clear Queue" onClick={this.clearQueue}></i>
                     </div>
                    <div className="queue-data-list-contaniner text-left">
                        {queueData.length > 0 ?
                            queueData.map(function (item, i) {
                                return (
                                    <div className={"queue-data-list position-relative " + item._id} key={item._id} >
                                        <div className="text-nowrap position-relative">
                                            <img src={item.base_url_media ? (item.base_url_media + "/" + item.media_manager_id + "/" + item.thumbnail_folder + "/" + (item.platform_images ? item.platform_images.thumbnails_4_3 : null)) : PlayerContentImg} alt={item.content_name} onClick={() => self.renderMusicPlayer([item])} />
                                            <div className="show-on-hover position-absolute queue-song-detail-section" onClick={() => self.renderMusicPlayer([item])}>
                                                <div className='mt-0 main-queue-heading'>{item.title}</div>
                                                <span className='text-sub-color d-inline-block text-truncate'>{item.content_name ? item.content_name : null}</span>
                                            </div>
                                            <div className='cancel-queue-data' onClick={() => self.queueDeleteElement([item])} />
                                        </div>
                                    </div>
                                )})
                            :null
                        }
                    </div>
                </div>
                {listingToggleFlag ?
                    <div className="listing-toggle-div">
                        {this.state.seeAllData ?
                            <div className="series-list">
                                <div className="has-animation animate-on-hover container-fluid  primary-container-padding my-4 series-section">
                                    <div className="row mb-3 justify-content-between">
                                    <div className=" col-md-12 px-3 cursor" onClick={this.redirectPage}>  <small><i className="fas fa-chevron-left mr-1"></i> Back</small></div>
                                    </div>
                                     <div className="row mb-3 justify-content-between">
                                        {mdata.length > 0 ?
                                            mdata.map(function (item, i) {
                                                return (
                                                    <div className="col-xl-5 my-auto series-name-heading">
                                                        {item.sub_catalogue_name}
                                                    </div>
                                                )})
                                            : null
                                        }
                                        <div className="col-xl-12 ">
                                            <div className="border-series-slider"></div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 p-0 slick-slider">
                                            {mdata.length > 0 && mdata.data !== '' ?
                                                mdata.map(function (item, i) {
                                                    return (
                                                        <>
                                                            {item.data.map((key) => {
                                                                return (
                                                                    <div className={`item-music d-inline-block m-3${seeAllRatio === "169" ? ` slick-slider ratio-16-9 `: seeAllRatio === "45" ? ` slick-slider ratio-4-5` : seeAllRatio === "43"?` slick-slider ratio-4-5` : "" }`} key={key._id}>
                                                                            <div className="item-content position-realtive ">
                                                                                <div className="item" onClick={() => self.seeAllPage(key.content_id, key.media_manager_id)}>
                                                                                    <div className="item-content position-realtive h-100">
                                                                                        <div className="image w-100 h-100">
                                                                                            <img src={seeAllRatio === "169" ? key.base_url + '/' + key.content_manager_folder + "/" + key.content_id + '/' + key.thumbnail_folder + '/' + key.banner_folder + '/' + key.thumbnail_16_9 + '/' + key.platform_images.thumbnails_16_9 : seeAllRatio === "45" ? key.base_url + '/' + key.content_manager_folder + "/" + key.content_id + '/' + key.thumbnail_folder + '/' + key.banner_folder + '/' + key.thumbnail_4_5 + '/' + key.platform_images.thumbnails_4_5 : seeAllRatio === "43" ? key.base_url + '/' + key.content_manager_folder + "/" + key.content_id + '/' + key.thumbnail_folder + '/' + key.banner_folder + '/' + key.thumbnail_4_3 + '/' + key.platform_images.thumbnails_4_3 : ''}
                                                                                                className="slide-img h-100 w-100 hover-card" alt={key.series_name} />
                                                                                        </div>
                                                                                        <div className="play-button-content" style={{ outline: 'none' }}>
                                                                                        </div>
                                                                                        <a className="video-play-button" style={{ outline: 'none' }} key={item._id}>
                                                                                            <span></span>
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="d-inline-block  serires-name mt-2" style={{ width: '100%' }} >
                                                                                <div className="gemplex-bold text-truncate primary2-text">
                                                                                    {key.content_name ? key.content_name : key.title}
                                                                                </div>
                                                                                <div className="duration-span">
                                                                                    <span>{key.released_year ? key.released_year : null}<span className="seprator"> | </span><span> {key.duration ? (key.duration > 60 ? Math.floor(key.duration / 60) + 'hr ' + (key.duration % 60) + 'min' : key.duration + 'min') : null} </span></span>
                                                                                </div>
                                                                            </div>
                                                                    </div>
                                                                )
                                                            })
                                                            }
                                                        </>
                                                    )
                                                })
                                                :<h3>no data is available</h3>   
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <>
                                <Banner catalogueId={'MUSIC'} bannerSong={() => this.bannerCallBack(data)} />
                                <Filter showName='Genre' type='music' Language_type={"MUSIC"} getGenre={this.fetchGenreData} getLanguage={this.fetchLanguageData} fetchSortData={self.fetchSortData} />
                                <div className="container-fluid ">
                                    <div className="row primary-container-padding">
                                        <div className="col-xl-12 col-md-9 col-sm-9 p-0 mt-3">
                                            {mdata.length > 0 ?
                                                mdata.map(function (item, i) {
                                                    return (
                                                        <SliderComp slider_data={item} callbackFromParent={self.myCallback} key={item.sub_catalogue_id} callback={self.handleFilterUpdate} />
                                                    )
                                                })
                                                :<h3>no data is available</h3>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="has-animation animate-on-hover container-fluid">
                                    {slides ?
                                        <div className="row primary-container-padding">
                                            <div className="col-xl-12 col-md-9 col-sm-9 p-0 mt-3">
                                                <div className="col-xl-12  mb-2 p-0" >
                                                    <div className="word_space ">
                                                        <div className="d-inline-block primary1-text gemplex-medium sub-heading" id="view">
                                                            My Playlist
                                                        </div>
                                                        <div className="behind-heading d-inline-block">
                                                            <a onClick={this.fetchCatalogue}>
                                                            All
                                                            <div className="d-inline-block behind-heading-arrow" ><i className="fa fa-angle-right"></i></div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-12 p-0" >
                                                    <Slider {...settings}>
                                                        {this.state.slides.map(function (slide) {
                                                            return (
                                                                <div className="item" onClick={() => self.customePlaylistDetail(slide._id)} key={slide._id} style={{ width: '207px ', height: '155px' }}>
                                                                    <div className="item-content position-realtive">
                                                                        <div className="image w-100">
                                                                            <img src='https://vignette.wikia.nocookie.net/whatever-you-want/images/1/19/Mp3-Hindi-Songs-FunRocker.Com_2.jpg/revision/latest?cb=20130531025809' className="slide-img w-100 hover-card" alt={slide.series_name} />
                                                                        </div>
                                                                        <div className="play-button-content" style={{ outline: 'none' }}></div>
                                                                        <a className="video-play-button" style={{ outline: 'none' }} key={slide._id}>
                                                                            <span></span>
                                                                        </a>
                                                                        <div className="home-primary premium-icon position-absolute"> </div>
                                                                    </div>
                                                                    <div className="d-inline-block text-truncate serires-name" style={{ width: '100%' }} >
                                                                        <div className="gemplex-bold  primary2-text">  {slide.playlist_name ? slide.playlist_name : null}</div> <div className="duration-span">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                    </Slider>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                                <Language Language_type={'music'} type="MUSICS" />
                                <Genre type={'music'} />
                            </>
                        }
                    </div>
                    :
                    <>
                        {isPlaylist ?
                            null
                            :
                            <div className="container-fluid">
                                {data ?
                                    <div className="position-relative">
                                        <div className="row">
                                            <div className="col-xl-12 p-0 position-relative">
                                                <div className="col-md-12 music-bg-conatiner ">
                                                    <img className='music-bg-img' src={data.base_url ? data.base_url + '/' + data.content_manager_folder + "/" + data.content_id + '/' + data.thumbnail_folder + '/' + data.banner_folder + '/' + data.thumbnail_16_9 + '/' + (data.platform_images ? data.platform_images.thumbnails_16_9 : null) : null} alt="" />
                                                </div>
                                                <div className="col-md-12 music-main-content primary-container-padding">
                                                    <div className="row mb-2">
                                                        <div className=" col-md-12 px-3 text-white cursor" onClick={this.redirectPage}>  <small><i className="fas fa-chevron-left mr-1"></i> Back</small>  </div>
                                                    </div>
                                                    <div className="row ">
                                                        <div className="col-md-3">
                                                            <img className="img-fluid song-img" src={data.base_url ? data.base_url + '/' + data.content_manager_folder + "/" + data.content_id + '/' + data.thumbnail_folder + '/' + data.banner_folder + '/' + data.thumbnail_4_3 + '/' + (data.platform_images ? data.platform_images.thumbnails_4_3 : null) : PlayerContentImg} alt="" />
                                                        </div>
                                                        <div className="col-md-3 my-auto ">
                                                            <ul className="list-unstyled">
                                                                <li>
                                                                    <h3 className="gemplex-bold"> {data.content_name ? data.content_name : null} </h3>
                                                                    <div> 
                                                                        <small className="album-header">Album / {data && data.content_manager ? data.content_manager.length + " song(s)" : null}</small>
                                                                    </div>
                                                                    <div className="mt-4">
                                                                        <button className="primary2-bg border-0 text-white gemplex-regular px-4 py-1 mr-3 main-play-all-button" style={{ borderRadius: 18, fontSize: 14, outline: 'none' }} onClick={() => self.renderMusicPlayer(data.content_manager)}><i className="fas fa-play mr-1"></i> <span className="btn-text">Play All</span> </button>
                                                                        <button className="dropdown-item mt-1" type="button" style={{ padding: '0', background: 'transparent', width: 'auto', outline:'none' }}>
                                                                            <div className="d-inline-block mt-2 mr-2"><img className='mr-2 d-inline-block align-baseline' src="https://preprod-gemplex-assets.gemplex.tv/web/share.svg" style={{ width: '1.2rem' }} alt="" />
                                                                            </div>
                                                                            <div className="d-inline-block mt-2 mr-2">
                                                                                <FacebookShareButton url={window.location.href} >
                                                                                    <FacebookIcon size={25} round={true} />
                                                                                </FacebookShareButton></div>
                                                                            <div className="d-inline-block mt-2 mr-2">
                                                                                <WhatsappShareButton url={window.location.href} >
                                                                                    <WhatsappIcon size={25} round={true} />
                                                                                </WhatsappShareButton>
                                                                            </div>
                                                                        </button>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <img src={"https://preprod-gemplex-web.s3.ap-south-1.amazonaws.com/gemplex-web_ad600-200.jpg"} className=" d-none d-xs-none d-sm-none d-md-block d-xl-block " style={{ height: 200, width: 600 }} alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-4">
                                                        <div className="col-md-11 music-detail-table">
                                                            <div className="table-responsive-sm">
                                                                <table className="table music-table-list">
                                                                    <thead>
                                                                        <tr>
                                                                            <th scope="col">#</th>
                                                                            <th scope="col">Title</th>
                                                                            <th scope="col">Singer</th>
                                                                            <th scope="col">Music by</th>
                                                                            <th scope="col">Lyrics by</th>
                                                                            <th scope="col"></th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {data && data.content_manager ?
                                                                            data.content_manager.map(function (itemcm, i) {
                                                                                return (
                                                                                    <tr key={itemcm._id}>
                                                                                        <th scope="row" className="align-middle" onClick={() => self.renderMusicPlayer([itemcm], data.content_id, itemcm.media_manager_id)}>{i + 1}</th>
                                                                                        <td onClick={() => self.renderMusicPlayer([itemcm], data.content_id, itemcm.media_manager_id)}>
                                                                                            <div className={"title-table-thumb position-relative d-inline-block " + itemcm._id}>
                                                                                                <div className="thumb-play-overlay">
                                                                                                    <i className="far fa-play-circle"></i>
                                                                                                </div>
                                                                                                {playing ? <div className="thumb-bars-overlay">
                                                                                                    <div id="bars">
                                                                                                        <div className="bar"></div>
                                                                                                        <div className="bar"></div>
                                                                                                        <div className="bar"></div>
                                                                                                        <div className="bar"></div>
                                                                                                        <div className="bar"></div>
                                                                                                        <div className="bar"></div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                    :
                                                                                                    null
                                                                                                }
                                                                                                <img className="w-100 h-100 br-5" src={itemcm.base_url_media + "/" + itemcm.media_manager_id + "/" + itemcm.thumbnail_folder + "/" + (itemcm.platform_images ? itemcm.platform_images.thumbnails_4_3 : null)} alt="" />
                                                                                            </div>
                                                                                            <div className="d-inline-block ml-2">{itemcm.title}</div>
                                                                                        </td>
                                                                                        <td onClick={() => self.renderMusicPlayer([itemcm], data.content_id, itemcm.media_manager_id)} >{itemcm.singer.length > 0 ?
                                                                                            itemcm.singer.map(function (singerItem, singerI) {
                                                                                                return (
                                                                                                    <span key={singerItem.name} >{singerI === 0 ? singerItem.name : " ," + singerItem.name}  </span>
                                                                                                )
                                                                                            }) : null
                                                                                        }</td>
                                                                                        <td onClick={() => self.renderMusicPlayer([itemcm], data.content_id, itemcm.media_manager_id)}>{itemcm.composer.length > 0 ?
                                                                                            itemcm.composer.map(function (composerItem, composerI) {
                                                                                                return (
                                                                                                    <span key={composerItem.name}>{composerI === 0 ? composerItem.name : " ," + composerItem.name}  </span>
                                                                                                )
                                                                                            })
                                                                                            :
                                                                                            null
                                                                                        }
                                                                                        </td>
                                                                                        <td onClick={() => self.renderMusicPlayer([itemcm], data.content_id, itemcm.media_manager_id)}>{itemcm.lyricist.length > 0 ?
                                                                                            itemcm.lyricist.map(function (lyricistItem, lyricistI) {
                                                                                                return (
                                                                                                    <span key={lyricistItem.name} >{lyricistI === 0 ? lyricistItem.name : " ," + lyricistItem.name}  </span>
                                                                                                )
                                                                                            })
                                                                                            :
                                                                                            null
                                                                                        }</td>
                                                                                        <td>
                                                                                            <div className="btn-group dropup">
                                                                                                <img src={"https://preprod-gemplex-assets.gemplex.tv/web/more.svg"} className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ width: 25, height: 20 }} alt="Gemplex_Options" />
                                                                                                <div className="dropdown-menu table-drop-menu">
                                                                                                    <button className="dropdown-item mt-1" type="button" data-toggle="modal" data-target={userProfile ? "#playlist-modal" : "#sign_up_box"} onClick={() => self.updateCurrentInfo(itemcm, data.content_id)}><img className='mr-2' src={"https://preprod-gemplex-assets.gemplex.tv/web/Add+to+watch+list+icon.svg"} style={{ width: '1.2rem' }} alt="" title="add to watchlist" />Add to Playlist</button>
                                                                                                    <button className="dropdown-item mt-1" type="button">
                                                                                                        <div className="d-inline-block mt-2 mr-2"><img className='mr-2 d-inline-block align-baseline' src="https://preprod-gemplex-assets.gemplex.tv/web/share.svg" style={{ width: '1.2rem' }} alt="" />
                                                                                                        </div>
                                                                                                        <div className="d-inline-block mt-2 mr-2">
                                                                                                            <FacebookShareButton url={window.location.href + "&media_id=" + urlMediaParams} >
                                                                                                                <FacebookIcon size={25} round={true} />
                                                                                                            </FacebookShareButton></div>
                                                                                                        <div className="d-inline-block mt-2 mr-2">
                                                                                                            <WhatsappShareButton url={window.location.href + "&media_id=" + urlMediaParams} >
                                                                                                                <WhatsappIcon size={25} round={true} />
                                                                                                            </WhatsappShareButton>
                                                                                                        </div>
                                                                                                    </button>
                                                                                                    <button className="dropdown-item mt-1" type="button" data-toggle="modal" data-target="#music-info-modal" onClick={() => self.updateCurrentInfo(itemcm)}><img className='mr-2' src={"https://preprod-gemplex-assets.gemplex.tv/web/info.svg"} style={{ width: '1.2rem' }} alt="" /> Get Song Info</button>
                                                                                                    <button className="dropdown-item mt-1" type="button" data-toggle="modal" data-target="#music-lyrics-modal" onClick={() => self.updateCurrentInfo(itemcm)}><img className='mr-2' src={"https://preprod-gemplex-assets.gemplex.tv/web/assets/music-note.svg"} style={{ width: '1.2rem' }} alt="" />Lyrics</button>
                                                                                
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                            :
                                                                            null
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
                                }
                                {/* {data && data.content_manager ? <Singer managerData={data.content_manager[0]} /> : null} */}
                            </div>
                        }
                    </>
                }
                {self.state.musicSrc.length > 0 ? this.playerHowler() : null}
                {this.playerComponent()}
                <Footer />
                <div style={{ height: 65 }} ></div>
                <MusicInfoModal currentPlayData={this.state.showCurrentDetailData} />
                <Playlist mediaId={playingItemData.media_manager_id} userProfile={this.state.userProfile ? this.state.userProfile : null} song_content_id={this.state.contenId ? this.state.contenId : null} currentMusic={this.state.showCurrentDetailData ? this.state.showCurrentDetailData : null} getPlaylist={self.playListData} />
                <LyricsModal currentPlayData={this.state.showCurrentDetailData} />
                <Subscription />
            </>
        )
    }
}

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "none" }}
            onClick={onClick}
        >
            <img src="https://preprod-gemplex-assets.gemplex.tv/web/scroll-arrow-to-right.svg" alt="next" />
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "none" }}
            onClick={onClick}
        >
            <img src="https://preprod-gemplex-assets.gemplex.tv/web/scroll-arrow-to-left.svg" alt="prev" />
        </div>
    );
}
export default Index