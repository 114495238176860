import React, { Component } from 'react';

import '../../stylesheet/partials/breadcrumb.css'

export default class Breadcrumb extends Component {
    constructor() {
        super();
        this.state = {
        }
    }

    render() {
        return (
            <div>
               {/* <div className="container-fluid shadow-sm mb-2"> */}
          {/* <div className="row"> */}
            {/* {mdata.length != 0 ? */}
              {/* <div className="col-xl-12" style={{ padding: '0.5rem 3rem' }}> */}
            {/* <div class=""><span class="pr-3">  Series  </span><span class="px-3">  Non Fiction  </span><span class="px-3">  Shorts  </span><span class="px-3">  Reality Show  </span></div> */}
                {/* <div className="">
                {
                  mdata.map(function (mdataI,loop) {
                    return (
                      <span className={loop==0?'pr-3':'px-3'} s >  {mdataI.sub_catalogue_name}  </span>
                    )
                  })
                }
  </div> */}
              {/* </div>  */}
              {/* : null} */}

          {/* </div> */}
        {/* </div> */}
        {/* <div className="container-fluid mb-3"> */}
          {/* <div className="row"> */}
            {/* <div className="col-xl-12" style={{padding:'0.5rem 3rem'}}> */}
  {/* <span className='h6 primary1-text '>Series</span>
  <span className="mx-2 h5 primary2-text"> <i class="fa fa-angle-right"></i></span>
  <span className='mx-2 h6 text-secondary'>Comedy</span> */}
            {/* </div> */}
          {/* </div> */}
        {/* </div> */}
            </div>

        )
    }

}