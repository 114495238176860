import React, { Component } from 'react';
import $ from 'jquery';
import Header from "../partials/header";
import NavBar from "../partials/NavigationBar.jsx";
import Footer from '../partials/footer';
import helpers from '../../util/headers.js';
import RegenrateToken from "../../util/regenrateToken.js";
import ApiList from "../../config/api.js" 
import Contacts from "./contact_us";
import FeedBack from "./feedback";
import Carrer from "./career";
import Support from "./support";
import Textual from "./about_us"; 
import FAQ from "./faqs";

export default class Name extends Component {
    constructor(props) {
        super(props);
        this.state = {
            headers: helpers.header(),
            data:''
        }
    }

    componentDidMount() {
        this.fetchData();
    }
    fetchData(){
        let page_code = this.props.match.params.page_name
        let header =   this.state.headers;
        let dataString ={
            'slug':"/"+page_code
        }
        $.ajax({
            url: ApiList.current.url + ApiList.endpoints.footer.pageDetails,
            type: "POST",
            headers: header,
            data: JSON.stringify(dataString),
            success: (data) =>{
                if(data.statusCode === 200){
                    const decodeData = data.data ? data.data.html_content !== "" ? atob(data.data.html_content) : data.data.collapse !== "" ? data.data : "" : "" ;
                    this.setState({
                        data: decodeData
                    })
                }
            },
            error: (err) => {
                if (err.statusCode === 403) {
                  RegenrateToken.userRegenrateToken()
                }
              }
        })
    }
    render() {
        const { data} = this.state
        return (
            <div>
                <Header />
                <NavBar />
                    {this.props.match.params.page_name === "contact-us" ? <Contacts getData={data} /> : this.props.match.params.page_name === "support" ? <Support getData={data} /> : this.props.match.params.page_name === "career" ? <Carrer getData={data} /> : this.props.match.params.page_name === "feedback" ? <FeedBack getData={data} /> :this.props.match.params.page_name === "faqs" ? <FAQ getData={data} /> :  this.props.match.params.page_name !== null ? <Textual getData={data} /> : ''  }
                <Footer />
            </div>

        )
    }

}