// Node Module Import Here:
import React, { Component } from "react";
import Form from "react-validation/build/form";
import axios from "axios";
import Cookies from 'universal-cookie';
import {Redirect} from 'react-router-dom'
import swal from 'sweetalert';
import $ from "jquery";
import { OldSocialLogin as SocialLogin } from 'react-social-login';

// import moment from 'moment'
// // import DatePicker from "react-datepicker";
// // import 'react-datepicker/dist/react-datepicker.css'

// Component Import Here:
// import RegenrateToken from "../../../util/regenrateToken.js";
import ApiList from "../../../config/api.js";
import ErrorMess from "../../../util/error.js";
import Setting from "./SettingPage.jsx";
import CommonApi from "../../../util/common_api_handler.js";
import PreferLanguage from "./PreferdLanguage";
import Subscription from "./UserSubscription.jsx";
import Navbar from "../../partials/NavigationBar.jsx";
import Footer from "../../partials/footer";
import HeaderJson from '../../../util/headers.js';

// Css File Import Here:
import "../../../stylesheet/user/user_detail/index.css";
//import date file
import DatePick from './DatePick'

const cookies = new Cookies();
export default class UserDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headers: HeaderJson.header(),
      showPassowrd: false,
      profileImage: "",
      userData: [],
      name: "",
      email: "",
      city: "",
      country: "",
      aboutMe: "",
      dob: "",
      pageRendering: false,
      textData: "test",
      newPasswordFlag: false,
      newCnfrmPasswordFlag: false,
      value: " ",
      changePass: false,
      genderValue: "",
      maxDataValue: ''
     
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeGender = this.handleChangeGender.bind(this);
    this.sendData = this.sendData.bind(this);
  }
  componentWillMount() {
    this.fetchData();
  }
  componentDidMount = () => {
    if (cookies.get('userType') !== 'GUEST') {
      var dtToday = new Date();

      var month = dtToday.getMonth();
      var day = dtToday.getDate();
      var year = dtToday.getFullYear() - 12;
      if (month < 10)
        month = '0' + month.toString();
      if (day < 10)
        day = '0' + day.toString();

      var maxDate = year + '-' + month + '-' + day;
      this.setState({
        maxDataValue: maxDate
      })
      $('input[type="date"]').keydown(false);

      document.querySelector('input[type="file"]').addEventListener("change", function () {
        if (this.files && this.files[0]) {
          const img = document.getElementById("user_img"); // $('img')[0]
          img.src = URL.createObjectURL(this.files[0]); // set src to file url
        }
      });
    }

  //   $("#user_dob").on("change", function() {
  //     this.setAttribute(
  //         "data-date",
  //         moment(this.value, "YYYY/MM/DD")
  //         .format( this.getAttribute("data-date-format") )
  //     )
  // }).trigger("change")
  // console.log(alert)
  };

  // USER DATA GET API CALL
  fetchData = () => {

    const apiUrl = ApiList.current.url + ApiList.endpoints.user.userProfile;

    const apiHeaders = HeaderJson.header();

    CommonApi.getCall(apiUrl, apiHeaders)
      .then((data) => {
        if (data.statusCode === 200) {
          this.setState({
            userData: data.data,
            pageRendering: true
          });
        }
      })
      .catch((err) => {
      
      })
  };

  // USER ALREADY FILL EMAIL FIELD DISABLE
  disableEmail = (e) => {
    const emailField = document.getElementById("user_email");
    emailField.disabled = ((e === this.state.userData.email) && this.state.userData.email) !== false ? e : ''
    // emailField.disabled = val !== undefined ? val.length > 0 : ''
  };
  disableMobile = (e) => {
    const mobileField = document.getElementById("user_mobile_01")
    mobileField.disabled = ((e === this.state.userData.mobile_no) && this.state.userData.mobile_no) !== false ? e : ''
    // mobileField.disabled = e !== undefined || e !== null ? e.length === 10 : ''
  };

  // USER DATA CHANGE API CALL
  sendData = () => {
    const email = document.getElementById("user_email").value;
    const first_name = document.getElementById("user_first").value;
    const city = document.getElementById("user_city").value;
    const dob = document.getElementById("user_dob").value;
    const country = document.getElementById("user_country").value;
    const about = document.getElementById("user_about").value;
    const gender = document.getElementById("inputGroupSelect02").value;
  //  let genderValue=gender.options[gender.selectedIndex].value

    let additionHeader = {
      fullname: first_name ? first_name : '',
      email: email ? email : '',
      city: city ? city : '',
      country: country ? country : '',
      dob: dob ? dob : '',
      gender: gender ? gender : '',
      about_me: about ? about : '',

     
    }
    console.log("additionHeader",additionHeader);
    $.ajax({
      url: ApiList.current.url + ApiList.endpoints.user.userDetails,
      type: "POST",
      headers: HeaderJson.header(),
      enctype: 'multipart/form-data',
      cache: false,
      contentType: false,
      processData: false,
      data: JSON.stringify(additionHeader),
      success: data => {
        if (data.statusCode === 200) {
          this.setState({
            profile: data.data
          });
          swal({ text: ErrorMess.alertMessages.myProfile.MPDU, timer: 1500 })
        }
      },
      error: err => {
        if (err.statusCode === 403) {
        }
      }
    });


  };
  // CHANGE EVENT
  handleChange(e) {
    this.setState({ defaultValue: e.target.value });
  }
  // USER CHANGE PASSWORD  API CALL
  validationPassword = () => {
    const newChangePassword = document.getElementById("change_password").value;
    const confirmChangePassword = document.getElementById("confirm_change_password").value

    if (!newChangePassword) {
      document.getElementById('change_new_password').style.visibility = 'visible'
    } else {
      document.getElementById('change_new_password').style.visibility = 'hidden';
      this.setState({
        newPasswordFlag: true,
      })
    }

    if (typeof newChangePassword !== "undefined") {
      if (!newChangePassword.match(/^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/)) {
        document.getElementById('change_new_password').style.visibility = 'visible'
      } else {
        document.getElementById('change_new_password').style.visibility = 'hidden';
        this.setState({
          newPasswordFlag: true,
        })
      }
    }
    if (!confirmChangePassword) {
      document.getElementById('change_cnfrm_password').style.display = 'block'
    } else {
      document.getElementById('change_cnfrm_password').style.display = 'none';
      this.setState({
        newCnfrmPasswordFlag: true,
      })
    }

    if (typeof confirmChangePassword !== "undefined") {
      if (!confirmChangePassword.match(/^.*(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&]).*$/)) {
        document.getElementById('change_cnfrm_password').style.display = 'block'
      } else {
        document.getElementById('change_cnfrm_password').style.display = 'none';
        this.setState({
          newCnfrmPasswordFlag: true,
        })
      }
    }
  };
  changeCurrentPassword = () => {
    var self = this
    const currentPassword = document.getElementById("current_password").value
    const newChangePassword = document.getElementById("change_password").value;
    const confirmChangePassword = document.getElementById("confirm_change_password").value
    let bodyData = {
      old_password: currentPassword,
      new_password: newChangePassword,
    }
    let header = this.state.headers
    if (newChangePassword !== confirmChangePassword) {
      document.getElementById("change_cnfrm_password").style.display = "block";
      document.getElementById("change_cnfrm_password").innerHTML = "New Password and confirm Password does not match";

    }
    if (currentPassword === "") {
      document.getElementById("change_current_password").style.visibility = "visible";
      // swal({ text: "Please enter Current Password", timer: 1500 })
    } else {
      if (self.validationPassword()) {
        if (newChangePassword === '')
          swal({ text: "Please enter Password", timer: 1500 })
        else if (confirmChangePassword === '')
          swal({ text: "Please enter confirm password", timer: 1500 })
        if (newChangePassword !== confirmChangePassword) {
          swal({ text: "Password did not match: Please try again...!!!", timer: 1500 })
        } else {
          if (currentPassword === newChangePassword) {
            swal({ text: "This Password Is Alredy Exits", timer: 1500 })
            return false;
          } else {
            axios.post(ApiList.current.url + ApiList.endpoints.user.changePassword, bodyData, {
              headers: header,
            })
              .then(response => response.data)
              .then((data) => {
                if (data.statusCode === 200) {
                  this.setState({
                    userData: data.data
                  });
                  swal({ text: "Password Change Successfully ...!!!", timer: 1500 })
                }
              })
              .catch((err) => {
              })
          }
        }
      }
    }
  }
  handleUpload = (e) => {
    const files = e.target.files[0];
    const formData = new FormData();
    formData.append("profile_image", files)

    axios({
      url: ApiList.current.url + ApiList.endpoints.user.userDetails,
      method: 'POST',
      enctype: 'multipart/form-data',
      headers: HeaderJson.header(),
      cache: false,
      contentType: false,
      processData: false,
      data: formData,
    })
      .then((response) => {
        if (response.data.statusCode === 200) {
          this.setState({
            profile: response.data.data
          });
        }
      })
      .catch((err) => {

      })
  }
  handleChangeGender(event) {
    this.setState({ genderValue: event.target.value });
  }
  connectFacebook = (user) => {
    let bodyData = {
      "facebook_id": user._profile != null ? user._profile.id : '',
      "type": "facebook",
    }
    let header = this.state.headers
    axios.post(ApiList.current.url + ApiList.endpoints.social.socialNotification, bodyData, {
      headers: header,
    })
      .then((response) => response.data)
      .then((data) => {
        if (data.statusCode === 200) {
          swal({ text: "Your Connect With Facebook Successfully...!!!", timer: 1500 })
        }
      })
      .catch(function (err) {
      })
  }
  handleSocialLoginFailure = err => {
    console.error(err);
  };
  emailVerification = () => {
    const userEmailId = document.getElementById('user_email').value
    let bodyData = {
      "email": userEmailId
    }
    axios.post(ApiList.current.url + ApiList.endpoints.emailVerification.emailVerify, bodyData, {
      headers: this.state.headers
    })
      .then(response => response.data)
      .then(data => {
        if (data.statusCode) {
          swal({ text: data.message, timer: 1500 });
        } else {
          swal({ text: 'Oops! Some Error Occured, please try again later!', timer: 1500 });
        }
      })
  }
  mobileVerification = () => {
    const userMobile = document.getElementById("user_mobile_01").value;
    if (userMobile.length > 0) {

      const apiUrl = ApiList.current.url + ApiList.endpoints.otp.otpVerify;

      const apiHeaders = HeaderJson.header();

      const bodyData = {
        "mobile_no": userMobile
      }

      CommonApi.postCall(apiUrl, apiHeaders, bodyData)
        .then((data) => {
          if (data.statusCode === 200) {
            $('.open-otp-modal').click()
            swal({ text: data.message, timer: 1500 })
          }
        })
    } else {
      swal({ text: "Please Enter Mobile Number", timer: 1500 })
    }
  }
  otpVerify = () => {
    let userMobile = document.getElementById("user_mobile_01").value;
    let otpData = document.getElementById("mobile_verfiy").value;

    const apiUrl = ApiList.current.url + ApiList.endpoints.user.verify;

    const apiInerUrl = ApiList.current.url + ApiList.endpoints.user.userDetails;

    const apiHeaders = HeaderJson.header();

    const bodyData = {
      "mobile_no": userMobile,
      "otp": otpData
    }

    const innerBodyData = {
      "mobile_no": userMobile,
      "user_id": cookies.get('userId') ? cookies.get('userId') : '',
    }
    CommonApi.postCall(apiUrl, apiHeaders, bodyData)
      .then((data) => {
        if (data.statusCode === 200) {
          CommonApi.postCall(apiInerUrl, apiHeaders, innerBodyData)
            .then((data) => {
              $('.close-otp').click()
              swal({ text: "Mobile Number Is Update", timer: 1500 })
            })
        }
      })

  }
  isNumber = (event) => {
    var key = window.event ? event.keyCode : event.which;
    if (event.keyCode === 8 || event.keyCode === 46) {
      return true;
    } else if (key < 48 || key > 57) {
      return false;
    } else {
      return true;
    }
  }
  onlyCharValid = (event) => {
    const user_name = document.getElementById('user_first');
    const user_city = document.getElementById('user_city');
    const user_country = document.getElementById('user_country');
    user_name.value = user_name.value.replace(/[^a-zA-Z\s]+/, '').trimLeft();
    user_city.value = user_city.value.replace(/[^a-zA-Z\s]+/, '').trimLeft();;
    user_country.value = user_country.value.replace(/[^a-zA-Z\s]+/, '').trimLeft();;

  }
  render = () => {
    const self = this;
    const { userData } = this.state;

    if (cookies.get('userType') === 'GUEST') {
      return (
        <>
          <Redirect push to={{ pathname: '/'}} /> 
        </>
      )
    } else {
      return (
        <div>
          <Navbar />
          <div className="container-fluid user-detail-module mb-3">
            <div className="row ">
              <div className="col-md-3 text-center mt-3 user-left-section border-right" >
                <div className="col-md-12 mt-5">
                  <img src={userData && userData.profile_image !== null ? userData.profile_image : 'https://preprod-gemplex-assets.gemplex.tv/web/static/unprofile.jpeg'} id="user_img" alt="default-user" style={{ width: "10rem", height: "10rem" }} className="rounded-circle" />
                  <div className="pencil-edit">
                    <input type="file" id="file_item" accept=".jpg,.png" onChange={(e) => self.handleUpload(e)} className="custom-file-input file-search" />
                    <i className="fas fa-pencil-alt pencil-edit-user" />
                  </div>
                  <div className="h5 gemplex-bold mt-3">
                    {userData && userData.name ? userData.name : null}
                  </div>
                </div>
                <div className="left-horizontal my-4" />
                <div className="col-md-12 col-sm-12 col-12 text-left">
                  <SocialLogin
                    provider='facebook'
                    appId='780784942305643'
                    callback={this.handleSocialLogin}
                  >
                    <div className="h6 primary2-text mx-4 cursor" style={{ fontFamily: "Gemplex-regular", outline: 'none', }} >
                      <i className="fab fa-facebook-square facebook-icon pr-2" style={{ fontSize: "20px" }} />
                      Connect with Facebook
                  </div>
                  </SocialLogin>

                </div>
                {/* <div className="left-horizontal" /> */}
                <hr className="my-1 mt-1 mb-1 left-horizontal"></hr>
                <div className="col-md-12 col-sm-12 col-12 text-left">
                  <a className="dropdown-item text-dark extra-option" href="/watchlist_favourite" >
                    <img src={"https://preprod-gemplex-assets.gemplex.tv/web/Add+to+watch+list+icon.svg"} alt={"add"} style={{ width: "20px" }} id="user_watch_list01" className="mr-2" />
                    Watch List
              </a>
                </div>
                <hr className="my-1 mt-1 mb-1 left-horizontal"></hr>
                <div className="col-md-12 col-sm-12 col-12 text-left">
                  <a className="dropdown-item text-dark extra-option" href="/purchase-movies" id="user_order04">
                    <img src={"https://preprod-gemplex-assets.gemplex.tv/web/assets/my+movies++(1).svg"} alt={"user_purchase_movies"} className="mr-1" width="20" height="20" style={{ opacity: '0.6' }} id="user_order04" /> My Movies
              </a>

                </div>
                <hr className="my-1 mt-1 mb-1 left-horizontal"></hr>
                <div className="col-md-12 col-sm-12 col-12 text-left">
                  <a className="dropdown-item text-dark extra-option" href="/order-list" id="user_order04">
                    <img className="mr-2" src={"https://preprod-gemplex-assets.gemplex.tv/web/assets/Order+list+icon+(1).svg"} alt="" width="20" height="20" style={{ opacity: '0.6' }} id="user_order04" /> My Purchase
              </a>
                </div>
                <hr className="my-1 mt-1 mb-1 left-horizontal"></hr>
                {
                  userData && userData.is_password_set === true ?
                    <div className="col-md-12 col-sm-12 col-12 text-left " style={{ paddingLeft: "38px", opacity: "0.7", outline: "none" }}>
                      <i className="fas fa-lock" style={{color:"#aaa"}}></i>
                      <button className="btn change-pass-btn mb-1  cursor" type="button" onClick={this.openSideBar} data-toggle="modal" data-target="#exampleModal"><span style={{color:"rgba(142, 146, 146, 1)"}}>Change Password</span></button></div> : ''
                }
              </div>

              {/* modal password********************************************************************************** */}
              <div
                className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" >
                <div className="modal-dialog modal-dialog-centered modal-size" role="document" >
                  <div className="modal-content">
                    <div className="modal-header border-0">
                      <h5 className="modal-title text-center ml-auto" id="exampleModalLabel" >
                        Change Password
                      </h5>

                      <button type="button" className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>

                    <div className="modal-body">
                      <div className="container">
                        <div className="form-wrapper py-2">
                          <div className="form-group mt-0 mb-0">
                            <label className="form-label " htmlFor="current_password">
                              Current Password
                            </label>
                            <div className="input-group mb-1">
                              <input type="password" id="current_password" className="form-control form-input password-field" aria-describedby="basic-addon1" onInput={this.maxLengthCheck} style={{ borderRadius: "0px", paddingTop: "10px" }} onChange={this.handleChange} />
                            </div>
                            <small className="error-txt" id="change_current_password" style={{ visibility: "hidden" }}>
                              {ErrorMess.alertMessages.myProfile.CPNE}
                            </small>
                          </div>
                        </div>
                        <div className="form-wrapper py-2">
                          <div className="form-group mt-0 mb-0">
                            <label className="form-label " htmlFor="change_password">
                              Enter New Password
                            </label>
                            <div className="input-group mb-1">
                              <input type="password" id="change_password" className="form-control form-input password-field" aria-describedby="basic-addon1" onInput={this.maxLengthCheck} style={{ borderRadius: "0px", paddingTop: "10px" }}
                              />
                              <small className="error-txt" id="change_new_password" style={{ visibility: "hidden" }}>
                                {ErrorMess.alertMessages.myProfile.NPNE}
                              </small>
                            </div>
                          </div>
                        </div>
                        <div className="form-wrapnewChangePasswordper py-2">
                          <div className="form-group mt-0 mb-0">
                            <label className="form-label" htmlFor="confirm_change_password">
                              Enter Confirm Password
                            </label>
                            <div className="input-group mb-1">
                              <input type="password" id="confirm_change_password" className="form-control form-input password-field" aria-describedby="basic-addon1"
                                onInput={this.maxLengthCheck}
                                style={{ borderRadius: "0px", paddingTop: "10px" }} />
                              <small className="error-txt" id="change_cnfrm_password" style={{ display: "none" }}   >
                                {ErrorMess.alertMessages.myProfile.CPE}
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer change-pass-btn">
                      <button type="button" className="btn br18 mt-0 ml-4 gradient1-bg  pt-2 pb-2 border-0 text-white gemplex-regular primary-container-padding py-1 user-save-detail-button" onClick={this.changeCurrentPassword}>
                        Save changes
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* modal password********************************************************************************** */}
              <div className="col-md-9 right-section position-relative">
                <ul
                  className="nav  nav-pills nav-justified mt-3 position-realtive"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a className="nav-link active" id="my-profile-tab" data-toggle="tab" href="#my-profile" role="tab" aria-controls="my-profile" aria-selected="true">
                      My Profile
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link" id="subscription-tab" data-toggle="tab" href="#current-subscription" role="tab" aria-controls="subscription" aria-selected="false">
                      Subscription
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" id="language-tab" data-toggle="tab" href="#language" role="tab" aria-controls="language" aria-selected="false" >
                      Language Preferences
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" id="setting-tab" data-toggle="tab" href="#setting" role="tab" aria-controls="setting" aria-selected="false" >
                      Settings
                    </a>
                  </li>
                </ul>
                <div className="horizontal my-2 " />
                <div className="tab-content" id="myabContent">
                  <div
                    className="tab-pane fade show active h-100"
                    id="my-profile"
                    role="tabpanel"
                    aria-labelledby="my-profile-tab"
                  >
                    <div className="container-fluid ">
                      {self.state.pageRendering === true
                        ?
                        <Form onSubmit={this.handleSubmit} className="profile-form">
                          <div className="row mt-4">
                            <div className="col-md-6 primary-container-padding">
                              <label className="input-size" htmlFor="">
                                Name
                            </label>
                              <input type="text" className="form-control field-color" id="user_first" pattern="[A-Za-z]" defaultValue={userData && userData.name ? userData.name : null} onChange={this.handleChange} onKeyUp={this.onlyCharValid} />
                            </div>
                            <div className="col-md-6 primary-container-padding">
                              <label className="input-size" htmlFor="">
                                Email
                            </label>
                              <input type="text" className="form-control field-color" onMouseOver={() => this.disableEmail(userData.email)} id="user_email" defaultValue={userData && userData.email ? userData.email : null} onChange={this.handleChange} />
                              {userData && userData.is_email_verified === true ? <small className="" style={{ float: 'right', color: 'green' }}>Verified Successfully</small> : <small className="cursor" onClick={this.emailVerification} style={{ float: 'right', color: 'red', textDecoration: 'underline' }}>Verify Your Email</small>}
                            </div>
                          </div>

                          <div className="row mt-4">
                            <div className="col-md-6 primary-container-padding">
                              <label className="input-size" htmlFor="">
                                Mobile Number
                            </label>
                              <input type="text" className="form-control field-color" onMouseOver={() => this.disableMobile(userData.mobile_no ? userData.mobile_no : '')} id="user_mobile_01" defaultValue={userData && userData.mobile_no ? userData.mobile_no : ''} onChange={this.handleChange} />
                              {userData && userData.is_otp_verified === true ? <small className="" style={{ float: 'right', color: 'green' }}>Verified Successfully</small> : <small className="cursor" onClick={this.mobileVerification} style={{ float: 'right', color: 'red', textDecoration: 'underline' }}>Verify Your Mobile Number</small>}
                            </div>

                            <div className="col-md-6 primary-container-padding">
                              <label className="input-size" htmlFor="">
                                City
                            </label>
                              <input type="text" id="user_city" className="form-control field-color" onKeyUp={this.onlyCharValid} onChange={this.handleChange} defaultValue={userData && userData.city ? userData.city : null} />
                            </div>
                          </div>
                          <div className="row mt-4">
                            <div className="col-md-6 primary-container-padding">
                              <label className="input-size" htmlFor="">
                                Country
                            </label>
                              <input type="text" defaultValue={userData && userData.country ? userData.country : null} onKeyUp={this.onlyCharValid} onChange={this.handleChange} id="user_country" className="form-control field-color" />
                            </div>
                            <div className="col-md-6">
                              <div className="row">
                                <div className="col-md-6 primary-container-padding">
                                  <label className="input-size" required htmlFor="">
                                    Gender
                                </label>
                                  <select className="custom-select input-lg field-color" id="inputGroupSelect02" defaultValue={userData && userData.gender ? userData.gender : ''} onChange={(e) => this.handleChangeGender(e)} >
                                    <option value="Male">Male</option> 
                                    <option value="Female">Female</option>
                                    <option value="Other">Other</option>
                      
                                  </select>
                                  <i className="fa fa-chevron-down" />
                                </div>
                                <div className="col-md-6 primary-container-padding">
                                  <label className="input-size" htmlFor="">
                                    Date of Birth
                                </label>
                                    <DatePick  id="user_dob" onKeyDown={(e) => e.preventDefault()} className="form-control field-color user_dob_date" defaultValue={userData && userData.dob ? userData.dob : ''} />
                             
                                  {/* <input type="date" id="user_dob" max={this.state.maxDataValue} onKeyDown={(e) => e.preventDefault()} className="form-control field-color user_dob_date" defaultValue={userData && userData.dob ? userData.dob : ''} style={{ padding: '.375rem .45rem' }}/> */}
                                    {/* <DatePicker/> */}
                                    {/* </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row mt-4">
                            <div className="col-md-12 primary-container-padding">
                              <label className="input-size" htmlFor="user_about">
                                About me
                            </label>
                              <textarea name="user_about" id="user_about" className="form-control field-color" defaultValue={userData && userData.about_me ? userData.about_me : ''} ></textarea>
                              {/* <textarea    onChange={(event)=>this.handleChangeText(event)}></textarea> */}
                            </div>
                          </div>
                          <button type="button" className="btn br18 gradient1-bg border-0 text-white gemplex-regular primary-container-padding py-1 user-save-detail-button" onClick={this.sendData} >
                            Save
                        </button>
                        </Form>
                        : ''
                      }
                    </div>
                  </div>
                  <div className="tab-pane fade h-100" id="current-subscription" role="tabpanel" aria-labelledby="subscription-tab" >
                    <Subscription />
                  </div>
                  <div className="tab-pane fade h-100" id="language" role="tabpanel" aria-labelledby="language-tab" >
                    <PreferLanguage />
                  </div>
                  <div className="tab-pane h-100" id="setting" role="tabpanel" aria-labelledby="setting-tab" >
                    <Setting />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
          <span >
            <span
              className="d-inline-block open-otp-modal"
              data-toggle="modal"
              data-backdrop="static"
              data-target="#otp_modal_box"
              style={{ margin: "0 0 0 10px", cursor: "pointer" }}
            >
              {/* <span className="border-align ">subscription</span> */}
            </span>
          </span>
          <div className="modal fade" id="otp_modal_box" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered " role="document">
              <div className="modal-content" style={{ color: 'black' }}>
                <div className="modal-header border-0 pb-0 pt-0">
                  <button type="button" className="close close-otp" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body ">
                  <div className="otp-modal-verfiy">
                    <h5 className="text-center pb-2">OTP Verfication</h5>
                    <div id="reset_hidden_field">
                      <div id="divOuter">
                        <div id="divInner">
                          <input id="mobile_verfiy" onKeyPress={(event) => this.isNumber(event)} type="text" maxLength="4" />
                        </div>
                      </div>
                    </div>
                    <div className="otp-verfiy-btn">
                      <button type="button" className="btn btn-success" onClick={this.otpVerify}>verfiy</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };
}
