import React, { Component } from "react";

import SliderComp from "./VideoSlider.jsx"
import Filter from '../partials/filter'
 
class RComp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slider: [],
      catalog_name: this.props.catalogueName,
      pageType: this.props.pageType,
      apiData: this.props.api_data,
      mdata: [],
      sortData:this.props.sortData ? this.props.sortData : '',
      genreData:this.props.genreData ? this.props.genreData : '',
      languageData:this.props.languageData ? this.props.languageData : '',
      filterData: this.props.filterData,
     };
  }
   
  render() {
    const { catalogueName, bread } = this.props;
    const { apiData, pageType ,sortData, genreData,languageData} = this.state;
    return (
      <div>
        {bread !== 'false' ?
          <div>
            <Filter />
          </div> : null}
        <div className="container-fluid mt-2">
          <div className="col-xl-12">
            {catalogueName !=='' ? <div className="col-xl-12 mt-4 p-0" >
              <div className="catalogue_name ml-3 primary3-text">
                {catalogueName}
              </div>
            </div> : null}
          </div>
          <div className="row">
            {
              apiData != null ?
                apiData.catalogueData.map(function (item, i) {
                  return (
                    <SliderComp slider_data={item}  sortData={sortData} genreData={genreData} languageData={languageData}  key={i} pageType ={pageType} />
                  )
                })
                : 
                null
            }
          </div>
        </div>
      </div>
    );
  }
}

export default RComp;
