import $ from "jquery";
import Cookies from "universal-cookie";
import UAParser from "ua-parser-js";
import ApiList from "../config/api.js";
import CountryList from "./list.json";
var country_list;
var userOss,
  userOsVersion,
  userBrowsers,
  userBrowserVersion,
  userBrowserVendor,
  userBrowserModel,
  country,
  ip,
  location;
const cookies = new Cookies();
const current = new Date();
const nextYear = new Date();

nextYear.setFullYear(current.getFullYear() + 1);

export default {
  setDeviceInfo: () => {
    return new Promise(function(resolve, reject) {
      country_list = $.ajax({
        url: ApiList.current.url + ApiList.endpoints.commonApi.common,
        method: "GET",
        success: data => {
          if (data.statusCode === 200) {
            country = data.data.result;
            ip = data.data.ip;
            var object = CountryList.GROUP.find(
              o =>
                Object.entries(o).find(
                  ([k, value]) => k === "code" && value === country
                ) !== undefined
            );
            location = object.dial_code;
            var parser = new UAParser();
            var userOs = parser.getOS();
            var userDevice = parser.getDevice();
            var userBrowser = parser.getBrowser();
            if (userOs && userOs.name && userOs.version) {
              userOss = userOs.name ? userOs.name : "NULL";
              userOsVersion = userOs.version ? userOs.version : "NULL";
            }
            if (userBrowser && userBrowser.name && userBrowser.version) {
              userBrowsers = userBrowser.name;
              userBrowserVersion = userBrowser.version;
            }
            if (userDevice && userDevice.vendor && userDevice.model) {
              userBrowserVendor = userBrowser.vendor;
              userBrowserModel = userBrowser.model;
            }
            if (
              (cookies.get("ip") &&
                cookies.get("location") &&
                cookies.get("userOss") &&
                cookies.get("userBrowsers") &&
                cookies.get("userBrowserVendor")) === undefined
            ) {
              cookies.set("userOss", userOss, { path: "/", expires: nextYear });
              cookies.set("location", location, { path: "/", expires: nextYear });
              cookies.set("userOsVersion", userOsVersion, { path: "/", expires: nextYear });
              cookies.set("userBrowsers", userBrowsers, { path: "/", expires: nextYear });
              cookies.set("userBrowserVersion", userBrowserVersion, { path: "/", expires: nextYear });
              cookies.set("userBrowserVendor", userBrowserVendor, { path: "/", expires: nextYear });
              cookies.set("userBrowserModel", userBrowserModel, { path: "/", expires: nextYear });
              cookies.set("country", country, { path: "/", expires: nextYear });
              cookies.set("ip", ip, { path: "/", expires: nextYear });
            }
            let header = {
              "Cache-Control": "no-cache",
              "Content-Type": "application/json",
              device_type: "WEB",
              country_code: country ? country : "",
              ip: ip ? ip : ip,
              device_id: userBrowserVersion ? userBrowserVersion : "",
              browser_version: userBrowserVersion ? userBrowserVersion : "",
              device_name: userBrowsers ? userBrowsers : "",
              device_model: userBrowsers ? userBrowsers : "",
              os_version: userOsVersion ? userOsVersion : "",
              os: userOss ? userOss : ""
            };
            if (
              cookies.get("userType") !== "REGULAR" &&
              (cookies.get("xAccessToken") === undefined ||
                cookies.get("userId") === undefined)
            ) {
              $.ajax({
                url: ApiList.current.url + ApiList.endpoints.guest.guest,
                method: "POST",
                headers: header,
                success: response => {
                  if (response.statusCode === 200) {
                    var xAccessToken, userId, userType;
                    userId = response.data.user_id;
                    userType = response.data.user_type;
                    xAccessToken = response.data.x_access_token;

                    cookies.get("userType") !== "REGULAR" &&
                      cookies.set("userType", userType, {
                        path: "/",
                        expires: nextYear
                      });
                    cookies.set("userId", userId, {path: "/", expires: nextYear});
                    if (cookies.get("xAccessToken") === undefined) {
                      cookies.set("xAccessToken", xAccessToken, {
                        path: "/",
                        expires: nextYear
                      });
                    }
                    resolve("done");
                  }
                },
                error: err => {
                  reject();
                }
              });
            } else {
              resolve();
            }
          }
        }
      });
    });
  }
};
