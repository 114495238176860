/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'; 

import ApiList from "../../config/api.js";
import CommonApi from "../../util/common_api_handler.js";
import HeaderJson from '../../util/inner_header.js';
import PageJourny from "../../util/page_journey.js";
import RegenrateToken from "../../util/regenrateToken.js";
import Footer from "../partials/footer"
import Navbar from "../partials/NavigationBar.jsx";

import '../../stylesheet/cast/index.css';


export default class PageName extends Component {
    constructor() {
        super();
        this.state = {
            castData: null,
            castList: [],
            aToZ: true,
            sortByValue: '',
            sortByAlfhabates: ''
        }
    }
    componentDidMount() {
        const self = this;
        PageJourny.setDeviceInfo()
            .then((data) => {
                self.fetchCasting();
            });
    }
    fetchCasting = () => {
        const self = this;

        const apiUrl = ApiList.current.url + ApiList.endpoints.cast.castList;

        const apiHeader = HeaderJson.header()

        const bodyData = {
            "offset": 0,
            "limit": 90,
            "sort_by": this.state.sortByValue,
            "alphabet_search": this.state.sortByAlfhabates
        }

        CommonApi.postCall(apiUrl, apiHeader, bodyData)
            .then((data) => {
                if (data.statusCode === 200 && data.data) {
                    self.setState({
                        baseUrl: data,
                        castList: data.data
                    })
                }
            })
            .catch((err) => {
                if (err.responseJSON.status === 403 || err.responseJSON.status === 400) {
                    RegenrateToken.userRegenrateToken()
                        .then((data) => {
                            self.setState({
                                renderingStatus: true
                            });
                        })
                }
            })
    }
    changeAtoZ = () => {
        this.setState({
            aToZ: !this.state.aToZ
        })
    }
    changeSortBy = (sortBy) => {
        this.setState({
            sortByValue: sortBy
        }, () => {
            this.fetchCasting()
        })
    }
    changeAlphaBy = (alphabates) => {
        this.setState({
            sortByAlfhabates: alphabates
        }, () => {
            this.fetchCasting()
        })
    }
    render() {
        const { castList, aToZ, baseUrl } = this.state
        return (
            <div>
                <Navbar />
                <div className="series-list">
                    <div className="has-animation animate-on-hover container-fluid  primary-container-padding my-4 series-section ">
                        <div className="row mb-3 justify-content-between">
                            <div className="col-xl-12 my-auto series-name-heading">
                                <div className="sorting-section text-center">
                                    <ul style={{ float: 'left' }}>
                                        <p className="gemplex-regular mr-3 font-weight-bold"  >People</p>
                                        <li className="sorting-sec cursor" id="popular_val" onClick={() => this.changeSortBy('popular')}>Popular</li>
                                        <li className="sorting-sec">|</li>
                                        <li className="sorting-sec cursor" id="latest_val" onClick={() => this.changeSortBy('latest')} >Latest</li>
                                        <li className="sorting-sec">|</li>
                                        <li className="sorting-sec cursor" onClick={this.changeAtoZ}>A to Z</li>
                                    </ul>
                                    { aToZ === false ?
                                            <ul id="filters" style={{ float: 'left' }}>
                                                <li className="sorting-sec cursor" >-</li>
                                                <li><div data-filter=".All" className="ajaxClass" onClick={() => this.changeAlphaBy('')} attr="All">All</div></li>
                                                <li><div data-filter=".A" className="ajaxClass" onClick={() => this.changeAlphaBy('A')} attr="A">A</div></li>
                                                <li><div data-filter=".B" className="ajaxClass" onClick={() => this.changeAlphaBy('B')} attr="B">B</div></li>
                                                <li><div  data-filter=".C" className="ajaxClass" onClick={() => this.changeAlphaBy('C')} attr="C">C</div></li>
                                                <li><div  data-filter=".D" className="ajaxClass" onClick={() => this.changeAlphaBy('D')} attr="D">D</div></li>
                                                <li><div  data-filter=".E" className="ajaxClass" onClick={() => this.changeAlphaBy('E')} attr="E">E</div></li>
                                                <li><div  data-filter=".F" className="ajaxClass" onClick={() => this.changeAlphaBy('F')} attr="F">F</div></li>
                                                <li><div  data-filter=".G" className="ajaxClass" onClick={() => this.changeAlphaBy('G')} attr="G">G</div></li>
                                                <li><div  data-filter=".H" className="ajaxClass" onClick={() => this.changeAlphaBy('H')} attr="H">H</div></li>
                                                <li><div  data-filter=".I" className="ajaxClass" onClick={() => this.changeAlphaBy('I')} attr="I">I</div></li>
                                                <li><div  data-filter=".J" className="ajaxClass" onClick={() => this.changeAlphaBy('J')} attr="J">J</div></li>
                                                <li><div  data-filter=".K" className="ajaxClass" onClick={() => this.changeAlphaBy('K')} attr="K">K</div></li>
                                                <li><div  data-filter=".L" className="ajaxClass" onClick={() => this.changeAlphaBy('L')} attr="L">L</div></li>
                                                <li><div  data-filter=".M" className="ajaxClass" onClick={() => this.changeAlphaBy('M')} attr="M">M</div></li>
                                                <li><div  data-filter=".N" className="ajaxClass" onClick={() => this.changeAlphaBy('N')} attr="N">N</div></li>
                                                <li><div  data-filter=".O" className="ajaxClass" onClick={() => this.changeAlphaBy('O')} attr="O">O</div></li>
                                                <li><div  data-filter=".P" className="ajaxClass" onClick={() => this.changeAlphaBy('P')} attr="P">P</div></li>
                                                <li><div  data-filter=".Q" className="ajaxClass" onClick={() => this.changeAlphaBy('Q')} attr="Q">Q</div></li>
                                                <li><div  data-filter=".R" className="ajaxClass" onClick={() => this.changeAlphaBy('R')} attr="R">R</div></li>
                                                <li><div  data-filter=".S" className="ajaxClass" onClick={() => this.changeAlphaBy('S')} attr="S">S</div></li>
                                                <li><div  data-filter=".T" className="ajaxClass" onClick={() => this.changeAlphaBy('T')} attr="T">T</div></li>
                                                <li><div  data-filter=".U" className="ajaxClass" onClick={() => this.changeAlphaBy('U')} attr="U">U</div></li>
                                                <li><div  data-filter=".V" className="ajaxClass" onClick={() => this.changeAlphaBy('V')} attr="V">V</div></li>
                                                <li><div  data-filter=".W" className="ajaxClass" onClick={() => this.changeAlphaBy('W')} attr="W">W</div></li>
                                                <li><div  data-filter=".X" className="ajaxClass" onClick={() => this.changeAlphaBy('X')} attr="X">X</div></li>
                                                <li><div  data-filter=".Y" className="ajaxClass" onClick={() => this.changeAlphaBy('Y')} attr="Y">Y</div></li>
                                                <li><div  data-filter=".Z" className="ajaxClass" onClick={() => this.changeAlphaBy('Z')} attr="Z">Z</div></li>
                                            </ul>
                                            : null }
                                </div>
                            </div>
                            <div className="col-xl-12 ">
                                <div className="border-series-slider"></div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 p-0 slick-slider">
                                {castList ? castList.map((item, i) => {
                                            return (
                                                <div className="item d-inline-block m-3" key={i}>
                                                    <a className="item" href={'/cast/' + item._id} key={item._id}>
                                                        <div className="item-content position-realtive h-100 image-border">
                                                            <div className="image w-100 h-100">
                                                                <img src={baseUrl.people_file_base_url + "/" + item.file_name} className="slide-img h-100 w-100 hover-card cast-img" alt="" />
                                                            </div>
                                                        </div>
                                                        <div className="d-inline-block  serires-name mt-2" style={{ width: '100%' }} >
                                                            <h6 className="gemplex-bold text-truncate primary2-text">
                                                                {item ? item.name : item.name}
                                                            </h6>
                                                            <p className="thumb-details-div mb-2">
                                                                {item ? item.designations[0].name : ''}
                                                            </p>
                                                        </div>
                                                    </a>
                                                </div>
                                            )
                                        })
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }

}