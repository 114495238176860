import React, { Component } from 'react';
import $ from 'jquery'
import axios from 'axios';
import GoogleLogin from 'react-google-login';
import Cookies from 'universal-cookie';

import { OldSocialLogin as SocialLogin } from 'react-social-login'
import ApiList from "../../../config/api.js";
import CommonApi from "../../../util/common_api_handler.js"
 

const cookies = new Cookies();
export default class SocialLogins extends Component {
    constructor() {
        super();
        this.state = {
            err: '',
        }
    }
    componentDidMount = () => {
 
    }
    handleSocialLoginFailure = err => {
        console.error(JSON.stringify(err));
    };
  
    handleUpdateName = () => {
        return (
            <SocialLogin
            provider='facebook'
            appId='780784942305643'
            callback={this.handleSocialLogin}
          >
            <img src={"https://preprod-gemplex-assets.gemplex.tv/web/assets/facebook.svg"} className="" alt="" style={{ width: '29px' }} />
          </SocialLogin>
        )
    }
    responseGoogle = (response) => {
        let loginType = "gmail"


        const apiUrl = ApiList.current.url + ApiList.endpoints.user.social;

        const apiHeaders = {
            "Cache-Control": "no-cache",
            "Content-Type": "application/json",
            "user_id": this.state.userSentId,
            "device_type": 'WEB',
            "user_type": cookies.get('userType') ? cookies.get('userType') : 'GUEST',
            "x_access_token": cookies.get('xAccessToken') ? cookies.get('xAccessToken') : '',
            "country_code": cookies.get('country') ? cookies.get('country') : '',
            "ip": cookies.get('ip') !== null ? cookies.get('ip') : cookies.get('ip'),
            "device_id": cookies.get('userBrowserVersion') ? cookies.get('userBrowserVersion') : '',
            "browser_version": cookies.get('userBrowserVersion') ? cookies.get('userBrowserVersion') : '',
            "device_name": cookies.get('userBrowsers') ? cookies.get('userBrowsers') : '',
            "device_model": cookies.get('userBrowsers') ? cookies.get('userBrowsers') : '',
            "os_version": cookies.get('userOsVersion') ? cookies.get('userOsVersion') : '',
            "os": cookies.get('userOss') ? cookies.get('userOss') : '',
        }

        const bodyData = {
            "fullname": response.Qt != null ? response.Qt.vW + " " + response.Qt.wU : '',
            "email": response.Qt != null ? response.Qt.zu : '',
            "gmail_id": response.Qt != null ? response.googleId : '',
            "profile_image": response.Qt != null ? response.Qt.UK : '',
            "type": loginType,
        }

        CommonApi.postCall(apiUrl, apiHeaders, bodyData)
            .then((data) => {
                if (data.statusCode === 200) {
                    cookies.remove('xAccessToken');
                    cookies.remove('userId');
                    cookies.remove('userType')
                    var xAccessToken, userId, userType, userPassword, userName, userEmail, userMobile;
                    userId = data.data._id;
                    userName = data.data.name;
                    userEmail = data.data.email;
                    userMobile = data.data.mobile_no;
                    userPassword = data.data.password;
                    userType = data.data.user_type;
                    xAccessToken = data.data.x_access_token
                    // Cookies Save Here...
                    cookies.set('userName', userName, { path: '/' });
                    cookies.set('userEmail', userEmail, { path: '/' });
                    cookies.set('userMobile', userMobile, { path: '/' });
                    cookies.set('userPassword', userPassword, { path: '/' });
                    cookies.set('userType', userType, { path: '/' });
                    cookies.set('userId', userId, { path: '/' });
                    cookies.set('xAccessToken', xAccessToken, { path: '/' });

                    window.location.href = window.location
                }
            })
    }

    handleSocialLogin = (user) => {
        const loginType = 'facebook';
        const bodyData = {
            "fullname": user._profile.name != null ? user._profile.name : '',
            "email": user._profile.email != null ? user._profile.email : '',
            "facebook_id": user._profile.id != null ? user._profile.id : '',
            "profile_image": user._profile.profilePicURL != null ? user._profile.profilePicURL : '',
            "type": loginType
        }

        const apiUrl = ApiList.current.url + ApiList.endpoints.user.social;

        let apiHeaders = {
            "Cache-Control": "no-cache",
            "Content-Type": "application/json",
            "device_type": 'WEB',
            "country_code": cookies.get('country') ? cookies.get('country') : '',
            "ip": cookies.get('ip') !== null ? cookies.get('ip') : cookies.get('ip'),
            "device_id": cookies.get('userBrowserVersion') ? cookies.get('userBrowserVersion') : '',
            "browser_version": cookies.get('userBrowserVersion') ? cookies.get('userBrowserVersion') : '',
            "device_name": cookies.get('userBrowsers') ? cookies.get('userBrowsers') : '',
            "device_model": cookies.get('userBrowsers') ? cookies.get('userBrowsers') : '',
            "os_version": cookies.get('userOsVersion') ? cookies.get('userOsVersion') : '',
            "os": cookies.get('userOss') ? cookies.get('userOss') : '',
            "user_id": this.state.userSentId,
            "user_type": cookies.get('userType') ? cookies.get('userType') : 'GUEST',
            "x_access_token": cookies.get('xAccessToken') ? cookies.get('xAccessToken') : '',
        }

        CommonApi.postCall(apiUrl, apiHeaders, bodyData)
            .then((data) => {
                if (data.statusCode === 200) {
                    cookies.remove('xAccessToken');
                    cookies.remove('userId');
                    var xAccessToken, userId, userType, userPassword, userName, userEmail, userMobile;
                    userId = data.data._id;
                    userName = data.data.name;
                    userEmail = data.data.email;
                    userMobile = data.data.mobile_no;
                    userPassword = data.data.password;
                    userType = data.data.user_type;
                    xAccessToken = data.data.x_access_token
                    cookies.set('userName', userName, { path: '/' });
                    cookies.set('userEmail', userEmail, { path: '/' });
                    cookies.set('userMobile', userMobile, { path: '/' });
                    cookies.set('userPassword', userPassword, { path: '/' });
                    cookies.set('userType', userType, { path: '/' });
                    cookies.set('userId', userId, { path: '/' });
                    cookies.set('xAccessToken', xAccessToken, { path: '/' });

                    window.location.href = window.location;
                }
            })
    }

    handleSocialLoginFailure = (err) => {
        console.log(err)
    }

    render = () => {
        return (
            <div>
                <div className="login-social-icon  text-center col-md-12" style={{ display: "flex" }} >
                    <div className="col cursor p-0 mr-2">
                      
                        {this.handleUpdateName()}

                    </div>
                    <div className="col cursor p-0 ml-2">
                        <GoogleLogin
                            clientId="734411559966-0udcbh9nm4adp4i1ndherumskhd4l36b.apps.googleusercontent.com"
                            render={renderProps => (
                                <img src={"https://preprod-gemplex-assets.gemplex.tv/web/assets/search.svg"} className="" alt="" style={{ width: '25px' }} onClick={renderProps.onClick} disabled={renderProps.disabled} />
                            )}
                            buttonText="Login"
                            onSuccess={this.responseGoogle}
                            onFailure={this.responseGoogle}
                        />
                    </div>
                </div>
            </div>
        )
    }
}