import React, { Component } from 'react';
import moment from 'moment';

import ApiList from "../../../config/api.js";
import CommonApi from "../../../util/common_api_handler.js";
import HeaderJson from '../../../util/headers.js';
import Navbar from "../../partials/NavigationBar.jsx";

import "../../../stylesheet/content/series-list.css";
import "../../../stylesheet/partials/slider-dots.css";
import "../../../stylesheet/partials/slide-style.css";
import "../../../stylesheet/partials/hover-slider.css";

var img_podcast="https://preprod-gemplex-assets.gemplex.tv/web/hype/podcast_icon.svg";
var img_play="https://preprod-gemplex-assets.gemplex.tv/web/hype/play_icon.png";
var img_image ="https://preprod-gemplex-assets.gemplex.tv/web/hype/image_icon.svg";
var img_article ="https://preprod-gemplex-assets.gemplex.tv/web/hype/article_icon.svg";

export default class PageName extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tags: this.props.match.param.content,
            mainData: [],
            slider: [],
        }
    }
    componentDidMount() {
        this.fetchData();
    }
    fetchData() {
        const self = this;

        const genre = this.props.match.param.content;

        const apiUrl = ApiList.current.url + ApiList.endpoints.hype.hypeTrendingTopicsOnClick + "/" + genre;

        const apiHeaders = HeaderJson.header();

        CommonApi.getCall(apiUrl, apiHeaders)
            .then((data)=>{
                if (data.statusCode === 200) {
                    const json_data = JSON.stringify(data) ? (data.data) : null;
                    if (json_data != null) {
                        let component_arry_var = self.state.slider.slice();
                        for (var i = 0; i < json_data.length; i++) {
                            component_arry_var.push(json_data[i]);
                        }
                        self.setState({
                            slider: component_arry_var
                        })
                    }
                }
            })
    }
    item_click = (slide_detail) => {
        if (slide_detail.type === "Videos")
            window.location.href = "/video/" + slide_detail.content_id;
        else if (slide_detail.type === "Podcast" || slide_detail.type === "popular")
            window.location.href = "/podcast/" + slide_detail.content_id;
        else if (slide_detail.type === "Gallery")
            window.location.href = "/image/" + slide_detail.content_id;
        else if (slide_detail.type === "Articles" || slide_detail.type === "trendig")
            window.location.href = "/article/" + slide_detail.content_id;
    }
    render() {
        const self = this
        const { slider, tags } = this.state
        return (
            <div>
                <Navbar />
                <div className="series-list">
                    <div className="has-animation animate-on-hover container-fluid  primary-container-padding my-4 series-section">
                        <div className="row mb-3 justify-content-between">
                            <div className="col-xl-5 my-auto series-name-heading">
                                {tags ? tags : null}
                            </div>
                            <div className="col-xl-12 ">
                                <div className="border-series-slider"></div>
                            </div>
                        </div>
                        <div className="row ratio-4-3 thumb-4-hype">
                            <div className="col-md-12 p-0">
                                {
                                    slider.map(function (item, i) {
                                        return (
                                            <div className="item d-inline-block m-3">
                                                <span className="item">
                                                    <div className=" card main-card" >
                                                        <img src={item.hypeData[0].base_url_thumbnail_4_3 != null ? item.hypeData[0].base_url_thumbnail_4_3 + item.hypeData[0].thumbnail_4_3 : item.hypeData[0].base_url_thumbnail_4_3 ? item.hypeData[0].base_url_thumbnail_4_3 + item.hypeData[0].gallery[0].image_file : null} className="slide-img h-100 w-100 imag-size-fix hover-card" alt={item.hypeData[0].title} onClick={() => self.item_click(item)} style={{ borderTopLeftRadius: '3px', borderTopRightRadius: '3px', width: '207px' }} />
                                                        <div className="hover-effct">
                                                            <img src={item.type === "Videos" ? img_play : (item.type === "Podcast" ? img_podcast : (item.type === "Articles" ? img_article : img_image))} className="hype-thumbnail-icon" alt="play" />
                                                        </div>
                                                    </div>
                                                    <div className="card-bg card-body card-body-hype">
                                                        <div style={{ display: 'flex' }}>
                                                            <div className="hype-catalog footer-color">{(item.hypeData[0].genres != null) ? (item.hypeData[0].genres[0].code) : null}</div>
                                                            <span className="" style={{ marginLeft: '5px', marginRight: '5px' }}> <vr /> </span>
                                                            <div className="duration-hype"> {moment(item.hypeData[0].article_publish_date).format('LL')}</div>
                                                        </div>
                                                        <div className="two-line ellips">
                                                            <div key={item} className=" card-tit card-title footer-color  text-concats-all" onClick={() => self.item_click(item)}> {item.hypeData[0].title ? item.hypeData[0].title : null} </div>
                                                        </div>
                                                    </div>
                                                </span>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}