import React, { Component } from "react";
import Slider from "react-slick";

import '../../stylesheet/details/slider.css'
import "../../stylesheet/partials/slider-dots.css"
import "../../stylesheet/partials/slide-style.css"
import "../../stylesheet/partials/hover-slider.css"


class SliderCom extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectToCast: false,
            slides: [],
            baseUrl: "",
            contentId: "",
            castDesignation: "",
            castName: ""
        };
        this.click = this.click.bind(this);
    }
    componentDidMount = () => {
        this.setState({
            baseUrl: this.props.baseUrl
        })
        this.Set_data(this.props.slider_data)

    }
    Set_data = (data) => {
        var self = this
        if (data != null && data.length !== 0) {
            let arr = [];
            for (var i = 0; i < data.length; i++) {
                arr.push(data[i])
            }
            self.setState({
                slides: arr
            })
        }
    }

    click() {
        const { slides } = this.state;
        this.setState({
            slides:
                slides.length === 6 ? [1, 2, 3, 4, 5, 6, 7, 8, 9] : [1, 2, 3, 4, 5, 6]
        });
    }

    castRedirection = (data) => {
        const self = this
        if (data.people_id) { 
            self.setState({
                redirectToCast: true,
                contentId: data.people_id, 
            })
            window.location.href = `/cast/${data.people_id}`
        }
    }

    render = () => {
        const self = this
        const settings = {
            className: "cast",
            centerMode: false,
            dots: false,
            infinite: true,
            speed: 500,
            slidesToScroll: 1,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            swipeToSlide: true,
            responsive: [
                {
                    breakpoint: 2000,
                    settings: {
                        slidesToScroll: 1,
                        infinite: false,
                        variableWidth: true,
                        adaptiveHeight: false,
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToScroll: 1,
                        infinite: true,
                        variableWidth: true,
                        adaptiveHeight: false,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        variableWidth: true,
                        adaptiveHeight: true,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        variableWidth: true,
                        adaptiveHeight: true,
                        swipeToSlide: true,

                    }
                }
            ]
        };
        const { baseUrl, slides } = this.state;
        return (
            <div>
                <div className="cast-container mt-3 has-animation">
                    <div className="col-xl-12 mt-5 mb-4 p-0">
                        <div className="catalogue_name primary3-text" id="view">
                            {this.props.name ? this.props.name : null}
                        </div>
                    </div>
                    {slides ?
                        <Slider {...settings}>
                            {slides.map(function (slide) {
                                return (
                                    <span id="cast_page" className={slide.people_id ? 'cursor' : ''} onClick={() => { self.castRedirection(slide) }} key={slide.people_id}>
                                        <div key={slide._id} className="item text-center">
                                            <img src={slide.file_name ? baseUrl + "/" + slide.file_name : 'https://preprod-gemplex-assets.gemplex.tv/web/static/unprofile.jpeg'} className="slide-img hover-card" alt={''} />
                                            <div>{slide.name ? slide.name : null}</div>
                                        </div>
                                    </span>
                                );
                            })}
                        </Slider> : null
                    }
                </div>
            </div>
        );
    }
}
function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div className={className}
            style={{ ...style, display: 'none' }}
            onClick={onClick}>
            <img
                src="https://preprod-gemplex-assets.gemplex.tv/web/scroll-arrow-to-right.svg"
                alt=""
            />
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div className={className}
            style={{ ...style, display: 'none' }}
            onClick={onClick}>
            <img src="https://preprod-gemplex-assets.gemplex.tv/web/scroll-arrow-to-left.svg" alt="" />
        </div>
    );
}

export default SliderCom;
