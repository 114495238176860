import Cookies from 'universal-cookie';
const cookies = new Cookies();

const headerJson = {
  header: function (header) {
    if (cookies.get('userType') === "GUEST") {
      header = {
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
        "device_type": 'WEB',
        "user_type": cookies.get('userType') ? cookies.get('userType') : '',
        "x_access_token": cookies.get('xAccessToken') ? cookies.get('xAccessToken') : '',
        "user_id": cookies.get('userId') ? cookies.get('userId') : sessionStorage.getItem("regularUserId"),
        "country_code": cookies.get('country') ? cookies.get('country') : '',
        "ip": cookies.get('ip') !== null ? cookies.get('ip') : cookies.get('ip'),
        "device_id": cookies.get('userBrowserVersion') ? cookies.get('userBrowserVersion') : '',
        "browser_version": cookies.get('userBrowserVersion') ? cookies.get('userBrowserVersion') : '',
        "device_name": cookies.get('userBrowsers') ? cookies.get('userBrowsers') : '',
        "device_model": cookies.get('userBrowsers') ? cookies.get('userBrowsers') : '',
        "os_version": cookies.get('userOsVersion') ? cookies.get('userOsVersion') : 'NULL',
        "os": cookies.get('userOss') ? cookies.get('userOss') : 'NULL',
      }
    }
    else if (cookies.get('userType') === "REGULAR") {
      header = {
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
        "device_type": 'WEB',
        "user_type": cookies.get('userType') ? cookies.get('userType') : '',
        "x_access_token": cookies.get('xAccessToken') ? cookies.get('xAccessToken') : '',
        "user_id": cookies.get('userId') ? cookies.get('userId') : sessionStorage.getItem("regularUserId"),
        "country_code": cookies.get('country') ? cookies.get('country') : '',
        "ip": cookies.get('ip') !== null ? cookies.get('ip') : cookies.get('ip'),
        "device_id": cookies.get('userBrowserVersion') ? cookies.get('userBrowserVersion') : '',
        "browser_version": cookies.get('userBrowserVersion') ? cookies.get('userBrowserVersion') : '',
        "device_name": cookies.get('userBrowsers') ? cookies.get('userBrowsers') : '',
        "device_model": cookies.get('userBrowsers') ? cookies.get('userBrowsers') : '',
        "os_version": cookies.get('userOsVersion') ? cookies.get('userOsVersion') : 'NULL',
        "os": cookies.get('userOss') ? cookies.get('userOss') : 'NULL',
      }
    }
    else {
      header = {
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
        "device_type": 'WEB'
      }
    }
    return header;
  },
}

export default headerJson;

