import React, { Component } from 'react';
import $ from 'jquery';
import Header from "../partials/header";
import helpers from '../../util/headers.js';
import NavBar from "../partials/NavigationBar.jsx";
import Footer from '../partials/footer';
import ApiList from "../../config/api.js"
import '../../stylesheet/legal/terms.css'
import "../../stylesheet/information_page/font_color.css"
export default class Name extends Component {
    constructor() {
        super();
        this.state = {
            headers: helpers.header(),
        }
    }

    componentDidMount() {
        this.fetchData();
    }
    fetchData(){
            let dataString = {
                "slug":"/terms-and-conditions"
            }
            let header = this.state.headers
            $.ajax({
                url: ApiList.current.url + ApiList.endpoints.footer.pageDetails,
                headers: header,
                method:'POST',
                data: JSON.stringify(dataString),
                success: (data) =>{
                    if(data.statusCode === 200){
                        $('.terms-data').html(atob(data.data.html_content));
                    }
                }
        })
    }
    render() {
        return (
            <div>
                <Header />
                <NavBar />
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 mt-5">
                                <div className="terms-data footer-page">
                                </div>
                            </div>
                        </div>
                    </div>
                <Footer />
            </div>

        )
    }

}