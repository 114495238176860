 
import React, { Component } from "react";
import Slider from "react-slick";

import ApiList from "../../config/api.js";
import CommonApi from "../../util/common_api_handler.js";
import HeaderJson from '../../util/headers.js';

import "../../stylesheet/partials/hover-slider.css";
import "../../stylesheet/partials/slide-style.css";
import "../../stylesheet/partials/slider-dots.css";


var next = "https://preprod-gemplex-assets.gemplex.tv/web/scroll-arrow-to-left.svg";
var prev = "https://preprod-gemplex-assets.gemplex.tv/web/scroll-arrow-to-right.svg";
var seeall16= "https://preprod-gemplex-assets.gemplex.tv/web/assets/16-9-see-all.jpg"; 

class SliderCom extends Component {
  state = {
    cell_size: "",
    headerCode: "",
    heading: "",
    ratioData: "",
    slides: [],
    show: false,
  };

  componentDidMount() {
    this.getData(this.props.slider_data);
  }

  getData = (codeData) => {
    const self = this;

    const apiUrl = ApiList.current.url + ApiList.endpoints.home.homeCatalogue;

    const apiHeaders = HeaderJson.header();

    const bodyData = {
      'genreFilter': this.props.genreData ?  this.props.genreData : [],
      'subCatalogue': codeData.catalogue_code,
      'languageFilter': this.props.languageData ? this.props.languageData : [],
      "page_type": "VIDEOS",
      "parental_control": false,
      "catalogue_name": "VIDEOS",
      "offset": 0,
      "limit": 15,
      "catalogue_offset": 0,
      "catalogue_limit": 100,
      "sortBy": this.props.sortData ? this.props.sortData : [],
    }

    CommonApi.postCall(apiUrl, apiHeaders, bodyData)
      .then((data)=>{
        if (data.statusCode === 200 && data.data) {
          let mdata = data.data[0]
          self.setState({
            slides: mdata.data,
            ratioData: mdata.ratio,
            cell_size: mdata.cell_size,
            heading: mdata.sub_catalogue_name,
            headerCode: mdata.genre_name ? mdata.genre_name : mdata.sub_catalogue_code
          })
        }
      })
  }

  Set_data = data => {
    if (data != null) {
      this.setState({
        slides: data.data,
        heading: data.genre_name ? data.genre_name : data.sub_catalogue_name,
      });
    }
  }

  click = () => {
    this.setState({
      slides:
        this.state.slides.length === 6 ? [1, 2, 3, 4, 5, 6, 7, 8, 9] : [1, 2, 3, 4, 5, 6]
    });
  }

  detailsSection = () => {
    this.setState({ show: !this.state.show });
  };


  render() {
    const { heading, ratioData, cell_size, headerCode } = this.state;
    let ratio = '';
    let _class = '';
    let _cell_size = 'thumb-3';
    if (ratioData && ratioData != null) {
      if (ratioData === '4:5') {
        _class = 'ratio-4-5'
        ratio = 43
      } else if (ratioData === '4:3') {
        _class = 'ratio-4-3'
        ratio = 45
      } else if (ratioData === '16:9') {
        _class = 'ratio-16-9'
        ratio = 169
      }
      if (cell_size === '3') {
        _cell_size = 'thumb-3'
      } else if (cell_size === '4') {
        _cell_size = 'thumb-4'
      } else if (cell_size === '6') {
        _cell_size = 'thumb-6'
      } else if (cell_size === '8') {
        _cell_size = 'thumb-8'
      }
    }
    const settings = {
      className: [_class, _cell_size],
      centerMode: false,
      dots: true,
      infinite: false,
      speed: 500,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      swipeToSlide: true,
      responsive: [
        {
          breakpoint: 4000,
          settings: {
            slidesToScroll: 1,

            variableWidth: true,
            adaptiveHeight: false
          }
        },
        {
          breakpoint: 2000,
          settings: {
            slidesToScroll: 1,

            variableWidth: true,
            adaptiveHeight: false
          }
        },
        {
          breakpoint: 1024,
          settings: {

            slidesToScroll: 1,
            variableWidth: true,
            adaptiveHeight: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: true,
            adaptiveHeight: true,
            dots: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: true,
            adaptiveHeight: true,
            dots: false


          }
        }
      ]
    };
    return (
      <div className="has-animation video-slider-section animate-on-hover col-xl-6  primary-container-padding mt-3">
        <div className="col-xl-12  mb-2 p-0" >
          <div className="word_space ">
            <div className="d-inline-block primary1-text gemplex-medium sub-heading" id="view">
              {heading ? heading : null}
            </div>
            <div className="behind-heading d-inline-block">
              <a href={"/content/video/" + this.props.pageType + "/" + headerCode + "/cell=" + _class + "/ratio=" + _cell_size} className="">
                All
                  <div className="d-inline-block behind-heading-arrow" ><i className="fa fa-angle-right"></i></div>
              </a>
            </div>
          </div>
        </div>
        {this.state.slides.length > 0 ? (
          <Slider {...settings}>
            {this.state.slides.map(function (slide) {
              return (
                <a href={'/detail/'+slide.slug + '/' + slide.content_id } key={slide._id} className="item">
                  <div className="item-content position-realtive h-100">
                    <div className="image w-100 h-100">
                      <img src={ratio === 43 ? slide.base_url + '/' + slide.content_manager_folder + "/" + slide.content_id + '/' + slide.thumbnail_folder + '/' + slide.banner_folder + '/' + slide.thumbnail_4_3 + '/' + slide.platform_images.thumbnails_4_3 : (ratio === 45 ? slide.base_url + '/' + slide.content_manager_folder + "/" + slide.content_id + '/' + slide.thumbnail_folder + '/' + slide.banner_folder + '/' + slide.thumbnail_4_5 + '/' + slide.platform_images.thumbnails_4_5 : slide.base_url + '/' + slide.content_manager_folder + "/" + slide.content_id + '/' + slide.thumbnail_folder + '/' + slide.banner_folder + '/' + slide.thumbnail_16_9 + '/' + slide.platform_images.thumbnails_16_9)} className="slide-img h-100 w-100 hover-card" alt={slide.series_name} />
                    </div>
                    <div className="play-button-content" style={{ outline: 'none' }}></div>
                    <span className="video-play-button" style={{ outline: 'none' }} href={'/detail/' + slide.slug + '/' + slide.content_id} key={slide._id}>
                      <span></span>
                    </span>

                    {slide.monetization_flag ?
                      <div className="home-primary premium-icon position-absolute">
                      </div>
                      : null}
                  </div>
                  <div className="d-inline-block text-truncate serires-name" style={{ width: '100%' }} >
                    <div className="gemplex-bold  primary2-text">  {slide.content_name ? slide.content_name : null}</div> <div className="duration-span"> {
                      slide.duration ? <span>2019<span className="seprator"> | </span><span>  {slide.duration ? (slide.duration > 60 ? Math.floor(slide.duration / 60) + 'hr ' + (slide.duration % 60) + 'min' : slide.duration + 'min') : null} </span></span> : null}</div>
                  </div>
                </a>
              );
            })}
            <a className="item" href={this.props.catName ? "/content/video/" + this.props.pageType + "/" + this.props.catName + "/" + headerCode + "/cell=" + _class + "/ratio=" + _cell_size : "/content/video/" + this.props.pageType + "/" + headerCode + "/cell=" + _class + "/ratio=" + _cell_size}>
              <div className="item-content position-realtive h-100">
                <div className="image w-100 h-100">
                  <img
                    src={ratio === 43 ? seeall16 : (ratio === 45 ? seeall16 : seeall16)}
                    className="slide-img h-100 w-100 hover-card"
                    alt='See All Content'
                  />
                </div>
              </div>
            </a>
          </Slider>
        )
          :
          null
        }
      </div>
    );
  }
}

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style, display: "none" }} onClick={onClick}>
      <img src={prev} alt="prev" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style, display: "none" }} onClick={onClick}>
      <img src={next} alt="next" />
    </div>
  );
}

export default SliderCom;
