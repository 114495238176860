import React, { Component } from "react";
import Slider from "react-slick";
import moment from 'moment';
import axios from "axios";
import helpers from '../../../util/headers.js';
import ApiList from "../../../config/api.js";


class Recent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headers: helpers.header(),
      slider: [],
    };
  }

  componentDidMount=()=>{
    this.fetchData()
  }

  fetchData = () => {
    const self = this;
    
    axios.get(ApiList.current.url + ApiList.endpoints.hype.hypeRecentStories,{
      headers: self.state.headers,
    })
    .then(res=>res.data)
    .then(data=>{
      if (data.statusCode === 200) {
        const json_data = JSON.stringify(data) ? (data.data) : null;
        if (json_data != null){
          let component_arry_var = self.state.slider.slice();
          for (var i = 0; i < json_data.length; i++) {
            component_arry_var.push(json_data[i]);
          }
          self.setState({
            slider: component_arry_var
          })
        }
      }
    })

  }

  itemClick = (slide_detail) => { 
    window.location.href = "/hype/"+slide_detail.type+"/"+ slide_detail.hypeData[0].slug +"/" +slide_detail.content_id 
  }

  render=()=>{
    const self = this;
    const { slider } = this.state;
    let _class = '';
    let _cell_size = 'thumb-3';
    let toScroll = '2';
    
    if (this.props && this.props.slider_data) {

      if (this.props.slider_data.ratio === '4:5') {
        _class = 'ratio-4-5'
      } else if (this.props.slider_data.ratio === '4:3') {
        _class = 'ratio-4-3'
      } else if (this.props.slider_data.ratio === '16:9') {
        _class = 'ratio-16-9'
      }

      if (this.props.slider_data.cell_size === '3') {
        _cell_size = 'thumb-3'
        toScroll = '3'
      } else if (this.props.slider_data.cell_size === '4') {
        _cell_size = 'thumb-4'
        toScroll = '4'
      } else if (this.props.slider_data.cell_size === '6') {
        _cell_size = 'thumb-6'
        toScroll = '6'
      } else if (this.props.slider_data.cell_size === '8') {
        _cell_size = 'thumb-8'
        toScroll = '8'
      }

    }

    const settings = {
      className: ['ratio-4-3', 'thumb-6'],
      dots: false,
      infinite: true,
      speed: 500,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      swipeToSlide: true,
      responsive: [
        {
          breakpoint: 4000,
          settings: {
            slidesToScroll: parseInt(toScroll) - 1,
            slidesToShow: 6,
            variableWidth: true,
            adaptiveHeight: false
          }
        },
        {
          breakpoint: 2000,
          settings: {
            slidesToScroll: parseInt(toScroll) - 1,
            variableWidth: true,
            adaptiveHeight: false
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToScroll: parseInt(toScroll) - 1,
            variableWidth: true,
            adaptiveHeight: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: true,
            adaptiveHeight: true
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: true,
            adaptiveHeight: true,
          }
        }
      ]
    };
    return (
      <div>
        <div className="has-animation animate-on-hover container-fluid p-1">
          <div className="col-md-12  mb-3 p-0" style={{ height: 20 }}>
            <div className="word_space">
              <div className="d-inline-block mb-3 font-hype  primary3-text gemplex-medium sub-heading" id="view" >
                Recent Stories
              </div>
              <div className="behind-heading d-inline-block">
                {/* <a href="" className="">
                  All
                  <div className="d-inline-block behind-heading-arrow">
                    <i className="fa fa-angle-right" />
                  </div>
                </a> */}
              </div>
            </div>
          </div>
          {slider.length > 0 ? (
            <Slider {...settings}>
              {slider.map((slide)=>{ 
                return (
                  <span className="item" key={slide._id}>
                    <div className="card main-card cursor"onClick={() => self.itemClick(slide)} >
                      <img src={slide.hypeData.length > 0 ? slide.base_url_thumbnail_4_3 +"/"+slide.hypeData[0].thumbnail_4_3 : ''} alt={slide.hypeData[0] ? slide.hypeData[0].title : null} className="slide-img h-100 w-100 imag-size-fix hover-card"  style={{ borderTopLeftRadius: '3px', borderTopRightRadius: '3px', width: '207px' }} />
                      <div className="hover-effct">
                        <img src={slide.type === "Videos" ? "https://preprod-gemplex-assets.gemplex.tv/web/hype/play_icon.svg" : (slide.type === "Podcast" ? "https://preprod-gemplex-assets.gemplex.tv/web/hype/podcast_icon.svg" : (slide.type === "Articles" ? "https://preprod-gemplex-assets.gemplex.tv/web/hype/article_icon.svg" : "https://preprod-gemplex-assets.gemplex.tv/web/hype/image_icon.svg"))} className="hype-thumbnail-icon" alt="play" />
                      </div>
                    </div>
                    <div className="card-bg card-body" style={{ borderRadius: '0px 0px 27px 27px' }}>
                      <div style={{ display: 'flex' }}>
                       
                        {/* <div className="hype-catalog footer-color">{(slide.genres.length > 0) ? (slide.genres[0].code) : null}</div> */}
                        <div className="hype-catalog">{(slide.genres.length > 0) ? (slide.genres[0].code) : null}</div>
                        <span className="" style={{ marginLeft: '5px', marginRight: '5px' }}></span>
                        <div className="duration-hype"> {moment(slide.article_publish_date).format('LL')}</div>
                      </div>
                      <div className="two-line ellips">
                        <div key={slide} className=" card-tit card-title footer-color text-concats cursor" onClick={() => self.itemClick(slide)}> {slide.hypeData[0] ? slide.hypeData[0].title : null} </div>
                      </div>
                    </div>
                  </span>
                );
              })}
            </Slider>
          ) : ""}
        </div>
      </div>
    );
  }
}
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none" }}
      onClick={onClick}
    >
      <img src="https://preprod-gemplex-assets.gemplex.tv/web/scroll-arrow-to-right.svg" alt="next" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none" }}
      onClick={onClick}
    >
      <img src="https://preprod-gemplex-assets.gemplex.tv/web/scroll-arrow-to-left.svg" alt="next" />
    </div>
  );
}

export default Recent;
