import React, { Component } from 'react';
import $ from 'jquery';

import ApiList from "../../config/api.js";
import HeaderJson from '../../util/inner_header.js';
import CommonApi from "../../util/common_api_handler.js";
import PageJourny from "../../util/page_journey.js";
import RegenrateToken from "../../util/regenrateToken.js";

import '../../stylesheet/partials/search.css'
import "../../stylesheet/partials/click.css" 
export default class Search extends Component {
    constructor() {
        super();
        this.state = {
            searchFlag: false,
            searchData: [],
            dataFlag: false,
            searchValue: '',
        }
    }

    componentDidMount() {
        $(document).on('keyup', '.search-reault-section li', function (e) {
            var code = e.keyCode || e.charCode
            if (code === 40) {
                $(this).removeClass()
                $(this).next().focus().addClass('active');
            } else if (code === 38) {
                $(this).removeClass()
                $(this).prev().focus().addClass('active');
            }
        })
    }

    elasticSearchCall = (e) => {
        const self = this;
        var value = e.target.value;
        PageJourny.setDeviceInfo()
            .then((data) => {
                if (value.length > 1) {
                    const apiUrl = ApiList.current.url + ApiList.endpoints.search.searchApi + "/" + value;

                    const apiHeaders = HeaderJson.header();

                    CommonApi.getCall(apiUrl, apiHeaders)
                        .then((data)=>{
                            let arrElement = []
                            if (data.statusCode === 200 && data.data.length !== 0) {
                                for (var i = 0; i < data.data.length; i++) {
                                    arrElement.push(data.data[i])
                                }
                                self.setState({
                                    searchFlag: true,
                                    searchData: arrElement,
                                    dataFlag: true
                                })
                            } else {
                                self.setState({
                                    dataFlag: false, 
                                })
                            }
                        })
                        .catch((err)=>{
                            if (err.responseJSON.status_code === 403 || err.responseJSON.status_code === 400) {
                                RegenrateToken.userRegenrateToken()
                                    .then((data) => {
                                        this.elasticSearchCall()
                                    })
                            }
                        })
                } else {
                    this.setState({
                        searchFlag: false
                    })
                }
            });
    }
    
    openSearch = () => {
        $('.search-elastic').toggleClass('expanded')
        $('.search-elastic').focus().val('')
        this.setState({
            searchFlag: false
        })
    }

    render() {
        const self = this;
        const { searchFlag, dataFlag } = self.state
        return (
            <div>
                <div className="d-login">
                    <div className="search-part position-relative">
                        <input id="search" name="search" style={{ boxShadow: 'none', outline: 'none' }} className="search-elastic none" tabIndex='1' type="text" onKeyUp={this.elasticSearchCall} placeholder="Search" />
                        <button id="search_submit" className="search-button mx-3 gemplex-regular click cursor" aria-label="Search" value="Rechercher" onClick={this.openSearch} />
                        {searchFlag === true ?
                            <div className="search-reault-section">
                                {dataFlag === true ?
                                    <ul className="list-unstyled">
                                        {this.state.searchData.map((item, i) => {
                                            return (
                                                <li tabIndex={i} key={item._id}>
                                                    <img style={{ width: '2rem' }} src={item._source.base_url + "/" + item._source.content_manager_folder + "/" + item._source.content_id + "/" + item._source.thumbnail_folder + "/" + item._source.media_folder + "/" + item._source.thumbnail_4_5 + "/" + item._source.thumbnails_4_5} alt="Gemplex Search" />
                                                    <a className="ml-3 gemplex-medium" href={"/detail/" + item._source.slug + '/' + item._source.content_id}> {item._source.title} {item._source.release_year}</a>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                    :
                                    <ul className="list-unstyled">
                                        <li>
                                            <h6>No Data found </h6>
                                        </li>
                                    </ul>
                                }
                            </div> : null}
                    </div>
                </div>
                <div className="m-login">
                    <div className="search-part position-relative">
                        <div className="input-group mobile-search">
                            <input type="text" className="form-control rounded-0" style={{ boxShadow: 'none', outline: 'none' }} placeholder="Search" aria-label="Recipient's username" onKeyUp={this.elasticSearchCall} aria-describedby="basic-addon2" />
                        </div>
                        {searchFlag === true ?
                            <div className="search-reault-section">
                                {dataFlag === true ?
                                    <ul className="list-unstyled">
                                        {this.state.searchData.map((item, i) => {
                                            return (
                                                <li tabIndex={i} key={item._id}>
                                                    <img style={{ width: '2rem' }} src={item._source.thumbnail} alt="Gemplex search" />
                                                    <a className="ml-3 gemplex-medium" href={"/detail/" + item._source.slug + '/' + item._source.content_id}> {item._source.title} ({item._source.release_year})</a>
                                                </li>)
                                        })}
                                    </ul>
                                    :
                                    <ul className="list-unstyled">
                                        <li>
                                            <h6>No Data found </h6>
                                        </li>
                                    </ul>
                                }
                            </div> : null}
                    </div>
                </div>
            </div>
        )
    }
}