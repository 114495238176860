var exports = module.exports = {};
var $ = require('jquery');
try{
    // AJAX GET CALL WRITE HERE...
    exports.getCall = (apiUrl, apiHeaders)=>{
        return new Promise((resolve,reject)=>{ 
            $.ajax({
                url:apiUrl,
                type:"GET",
                headers : apiHeaders,
                cache: false,
                success:(data)=>{
                    resolve(data)
                },
                error:(err)=>{ 
                    reject(err)
                }
            })

        })
    }

    // AJAX POST CALL WRITE HERE...
    exports.postCall = (apiUrl, apiHeaders, bodyData)=>{
        
        return new Promise((resolve,reject)=>{
            
            $.ajax({
                url:apiUrl,
                type:"POST",
                headers : apiHeaders,
                cache: false,
                data:JSON.stringify(bodyData),
                success:(data)=>{ 
                    resolve(data)
                },
                error:(err)=>{ 
                    reject(err)
                }
            })
            
        })
    }

    // AJAX DELETE CALL WRITE HERE ...
    exports.deleteCall = (apiUrl, apiHeaders, bodyData)=>{

        return new Promise((resolve,reject)=>{
            
            $.ajax({
                url:apiUrl,
                type:'DELETE',
                headers : apiHeaders,
                data:JSON.stringify(bodyData),
                success:(data)=>{ 
                    resolve(data)
                },
                error:(err)=>{ 
                    reject(err)
                }
            })
            
        })
    }

     // AJAX POST CALL WRITE HERE...
     exports.multiPostCall = (apiUrl, apiHeaders, bodyData)=>{
        
        return new Promise((resolve,reject)=>{
            
            $.ajax({
                url:apiUrl,
                type:'POST',
                headers : apiHeaders,
                enctype: 'multipart/form-data',
                cache: false,
                contentType: false,
                processData: false,
                data:JSON.stringify(bodyData),
                success:(data)=>{ 
                    resolve(data)
                },
                error:(err)=>{ 
                    reject(err)
                }
            })
            
        })
    }
} catch (ex) {
    // console.log(ex)
}