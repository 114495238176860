import React from 'react';
import videojs from 'video.js'
import $ from 'jquery';
import axios from 'axios';
import ApiList from "../../config/api.js";
import helpers from '../../util/headers.js';

import '../../stylesheet/videojs/videojs.css';
var player;
var current_playtime;
export default class VideoPlayer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mainData: this.props.video_params,
            videoUrl: this.props.videoUrl,
            resolution: [],
            lable: '',
            contentID: this.props.contentId,
            header: helpers.header(),
            srtVideoUrl: this.props.srtBaseUrl,
            playTime: this.props.playingTime,
            playVideoFlag:false,
            vodSection:false
        };

    }

    componentDidMount=()=>{
        this.playing()
    }
    playing=()=>{

        require('silvermine-videojs-quality-selector')(videojs);
        var videoPlayTime = require('videojs-playtime');
        videojs.plugin('videoPlayTime', videoPlayTime);

        player = videojs('my_video_1', {
            controls: true,
            responsive: true,
            fluid: true,
            autoplay: true,
            playbackRates: false,
            techCanOverridePoster: true,
            textTrackSettings: false,
            seekButtons: require('videojs-seek-buttons'),
            plugins: {
                controls: true,
                seekButtons: {
                    forward: 30,
                    back: 30
                },
                videoPlayTime: null

            },

        })


        // Append Dynamic Url in Video Tag...
        const data = this.state.mainData.label_name;

        const self = this
        player = videojs('my_video_1', {
            plugins: {
              videoJsResolutionSwitcher: {
                default: 'low',
                dynamicLabel: true
              }
            }
          });
          player.src(
            data.map(function(key, i){ 
               return [
                    {
                      src: self.state.videoUrl + self.state.mainData.resolutions[i],
                      type: 'application/x-mpegURL',
                      label: key,  
                    },
                  ]
            })  
           )

          
        
        var tracks = player.textTracks();

        player.controlBar.addChild('QualitySelector');
        for (var i = 0; i < tracks.length; i++) {
            var track = tracks[i];
            // Find the English captions track and mark it as "showing".
            if (track.kind === 'captions' && track.language === 'en') {
                track.mode = 'showing';
            }
        }
        player.on('ended', function () {
            // this.props.nextVideo.click()
            $('#play-next').click()
        });
        // hide the video and this current page confirm..
        $(".hide-page-content").click(function () {
            $(".video-js-section").hide("slow");
            // $("").show("slow");
        })
        player.playTime();
        player.currentTime(this.state.playTime) 

        // var threshold = 4;
        var thresholdReached = false;
        // player = this;
        player.on('timeupdate', function () {
            if (player.currentTime() >= !thresholdReached) {
                thresholdReached = true;
                if (!player.paused()) {
                    current_playtime = player.currentTime()
                }
            }
        });
    }
    componentWillReceiveProps=(prevProps)=>{
       const self = this
        if(this.props !== prevProps){
           if(this.props.video_params || this.props.videoUrl){
            this.setState({
                mainData:  this.props.video_params,
                videoUrl : this.props.videoUrl
            },()=>{
                self.playing()
            })
           }
        }
    }
    // destroy player on unmount
    componentWillUnmount = () => {
        const self = this
        player.dispose();
        self.props.scroll('yes')
    }
    videoSection = () => {
        const self = this;
        if (player.paused()) {
            player.play();
        }
        else {
            player.pause();
            self.props.scroll('yes') 
            player.dispose();//for video stop scroll 
            self.lastWatchMoviesApi()
        } 
        // player.pause();
        // localStorage.setItem('lastplayed', current_playtime) 
    }
    lastWatchMoviesApi = () => {
        const self = this
        let dataString = {
            "video_id": this.state.mainData.media_manager_id,
            "last_min_watched": current_playtime
        }
        axios.post(ApiList.current.url + ApiList.endpoints.video.lastWatch, dataString, {
            headers: self.state.header
        })
            .then(response => response.data)
            .then(data => { 
            })
    }

    // wrap the player in a div with a `data-vjs-player` attribute
    // so videojs won't create additional wrapper in the DOM
    // see https://github.com/videojs/video.js/pull/3856
    render=()=>{
        const self = this;
        const { poster } = this.props;
        const { mainData, srtVideoUrl } = this.state;
        return (
            

            <div>
                <div className="mt-2" style={{ float: 'right', color: 'white' }}>
                    <i className="fa fa-times close-vod cursor" onClick={self.videoSection} aria-hidden="true" />
                </div>
                <div className="row video-js-section">
                    <div className="col-xl-12 p-0" >
                    <video poster={poster ? poster : null} id="my_video_1" className="w-100 video-js vjs-fluid vjs-default-skin vjs-big-play-centered hover-card" width="640" height="264">
                    {mainData.srt.map(function (item, i) {
                        return (
                            <track kind='captions' src={srtVideoUrl + mainData.media_manager_id + "/" + item.srt_url} srclang={item.language_name} label={item.language_name} />
                        )
                    })}
                    </video>
                    </div>
                </div>
            </div>
        )
    }
}



// {mainData ?
//     <video poster={poster ? poster : null} id="my_video_1" className="w-100 video-js vjs-fluid vjs-default-skin vjs-big-play-centered hover-card" >
//         {mainData.label_name.map(function (item, i) {
//             return (
//                 <source src={mainData.resolutions[i] ? videoUrl + mainData.resolutions[i] : 'master.m3u8'} style={{display:'none'}} type="application/x-mpegURL" label={item ? (item ? (item) : "Auto") : " "} key={i} ></source>                                        // <source src={'https://dev-gemplex-movies.gemplex.tv/transcoder/assets/180000040/master.m3u8?Expires=1564296544&Key-Pair-Id=APKAIRSV4TUQATCEP5JQ&Signature=A6sLbiriXLJHbh5pP~9gWNga6Y5V1-1w1Tfw0tKwP5IlqogfQf7MJgZ7MtW-OsKEIEOjgPe2MrIL7KhPHRPp6~tuSW5HOzA029jtynXcjt4xl-oSmsUxSVdJTte9Fczfle8N~aP~uIHTEjsblLFDJkiqkGK5vs4vGvioyPkNvQacwDzm8TOewUFNJryr8ZfyRLu-eJHQ-vlOi8oWjuZ4B2jq-XLXQy0K3Xzy0NqhUEDyG9UaJnWfBosPVRK9dIYU7RnDcX4DDzYU74nDJP0Y3qobHa2WVd3MZN5mtgAD92HTpi0e0OT31cqg6oEr53X-Q3UuCMM2xqDe6UWk8IUGlQ__'} type="application/x-mpegURL"  key={item} ></source>
//             )
//         })}
//         {mainData.srt.map(function (item, i) {
//             return (
//                 <track kind='captions' src={srtVideoUrl + mainData.media_manager_id + "/" + item.srt_url} srclang={item.language_name} label={item.language_name} />
//             )
//         })}
//         {/* <source src={video_params} type="application/x-mpegURL" label="auto" selected="true"></source> */}
//     </video>
//     :
//     <video id="my_video_1" className="w-100 video-js vjs-default-skin vjs-big-play-centered hover-card" ></video>
// }
// <button id="play-next" className="d-none" type="hidden" onClick={this.props.nextVideo}></button>