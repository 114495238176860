
import React, { Component } from "react";
import Cookies from 'universal-cookie';
import {Redirect} from "react-router-dom";

import ApiList from "../../../config/api.js";
import CommonApi from "../../../util/common_api_handler.js";
import HeaderJson from '../../../util/headers.js';

import userProfile from "../../../images/static/profile-user.svg"

const cookies = new Cookies();

export default class LogoutButton extends Component {

  constructor(){
    super();
    this.state={
      watchRedirect: false,
      profileRedirect : false,
      orderRedirect: false,
      purchaseRedirect: false,
      planRedirect : false
    }
  }

  signOut = () => {
    const apiUrl = ApiList.current.url + ApiList.endpoints.user.logOutUser;
    const apiHeaders = HeaderJson.header();
    CommonApi.postCall(apiUrl, apiHeaders)
      .then((data)=>{
        if (data.statusCode === 200) {
          setTimeout(function () {
            window.location.href = "/"
          }, 500)
        }
      })
      /**
       * Remove cookies
       */
        this.removeCookieList();
  }
  // signOut=()=>{
  //   const apiUrl = ApiList.current.url + ApiList.endpoints.user.logOutUser;
  //   const apiHeaders = HeaderJson.header();
  //   CommonApi.postCall(apiUrl, apiHeaders)
  //     .then((data)=>{
  //       // if (data.statusCode === 200) {
  //       //   // setTimeout(function () {
  //       //   //   window.location.href = "/"
  //       //   // }, 500)
          
  //       // }
  //       // alert(JSON.stringify(data))
        
      
  //     })
  //     /**
  //      * Remove cookies
  //      */
  //     window.location.href = "/";
  //       this.removeCookieList();
  // }
  

  removeCookieList=()=>{

    cookies.remove('c_user', { path: '/', domain: '.facebook.com' });
    cookies.remove('c_user', { path: '/' });
    cookies.remove('xAccessToken', { path: '/' });
    cookies.remove('userId', { path: '/' });
    cookies.remove('c_user', { path: '/' });
    cookies.remove('plan-order', { path: '/' });
    cookies.remove('userName', { path: '/' });
    cookies.remove('userEmail', { path: '/' });
    cookies.remove('userMobile', { path: '/' });
    cookies.remove('userPassword', { path: '/' });
    cookies.remove('userType', { path: '/' });
    cookies.remove('userBrowserVersion', { path: '/' });
    cookies.remove('userBrowsers', { path: '/' });
    localStorage.removeItem("queue")

  }

  watchListAccess = () =>{
    this.setState({
      watchRedirect : true
    })
  }
  
  redirectProfile=()=>{
    this.setState({
      profileRedirect : true
    })
  }

  redirectOrder=()=>{
    this.setState({
      orderRedirect : true
    })
  }

  redirectPurchase=()=>{
    this.setState({
      purchaseRedirect : true
    })
  }

  redirectPlan=()=>{
    this.setState({
      planRedirect : true
    })
  }
  render() {
    return (
      <div style={{ display: "inline-block" }}>
        <div className="dropdown">
          <div className="dropdown-toggle cursor" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <img src={userProfile} id="user_logout_btn" data-toggle="tooltip" data-placement="bottom" title={cookies.get('userName')} alt="user_profile_img" style={{width:'30px'}} />
            {/* <i className="fa fa-user-circle primary1-text login-logo" /> */}
          </div>
          <div className="dropdown-menu" style={{ marginLeft: "-8.15rem" }} aria-labelledby="dropdownMenuButton">
            {this.state.watchRedirect === true && <Redirect push to={{ pathname: '/watchlist_favourite'}} />}
            <span className="dropdown-item text-dark" id="user_watch_list01" onClick={this.watchListAccess}>
              <img src={"https://preprod-gemplex-assets.gemplex.tv/web/Add+to+watch+list+icon.svg"} alt="User Watch List Icon" style={{ width: "15px" }} id="user_watch_list01" className="mr-1" />
              Watch List
            </span>
            {this.state.profileRedirect === true && <Redirect push to={{ pathname: '/user'}} />}
            <span className="dropdown-item text-dark"  id="user_account02" onClick={this.redirectProfile}>
              <i className="fa fa-user-circle dropdown-login" id="user_account02" /> My Account
            </span>
            {this.state.orderRedirect === true && <Redirect push to={{ pathname: '/order-list'}} />}
            <span className="dropdown-item text-dark"  id="user_order03" onClick={this.redirectOrder}>
              <img src={"https://preprod-gemplex-assets.gemplex.tv/web/assets/Order+list+icon.svg"} alt="User Order List Icon" width="15" height="15" style={{ opacity: '0.6' }} id="user_order04" /> My Purchase
            </span>
            {this.state.purchaseRedirect === true && <Redirect push to={{ pathname: '/purchase-movies'}} />}
            <span className="dropdown-item text-dark" id="user_movies04" onClick={this.redirectPurchase}>
              <img src={"https://preprod-gemplex-assets.gemplex.tv/web/assets/my+movies+.svg"} alt="User Movies Icon" width="15" height="15" style={{ opacity: '0.6' }} id="user_order04" /> My Movies
            </span>
            {this.state.planRedirect === true && <Redirect push to={{ pathname: '/payment'}} />}
            <span className="dropdown-item text-dark"  id="user_subscrip03" onClick={this.redirectPlan}>
              <i className="fa fa-ticket-alt mr-1 dropdown-login" id="user_subscrip03" />Subscription
              Plans
            </span>
            <span className="dropdown-item text-dark" onClick={this.signOut} id="logout">
              <i className="fa fa-sign-out-alt mr-1 dropdown-login" id="logout" />Logout
            </span>
          </div>
        </div>
      </div>
    );
  }
}
