/* eslint-disable no-new-object */
import Cookies from "universal-cookie"; 

import ApiList from "../config/api.js";
import CommonApi from "./common_api_handler.js";

const cookies = new Cookies();

export default {
  userRegenrateToken: () => {
    return new Promise((resolve, reject) => {
      let old_token_test = cookies.get("xAccessToken") ? cookies.get("xAccessToken") : "";
      let oldToken = {
        old_token: old_token_test
      };
      let header = {
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
        device_type: "WEB",
        user_type: cookies.get("userType") ? cookies.get("userType") : "",
        x_access_token: old_token_test,
        user_id: cookies.get("userId") ? cookies.get("userId") : "",
        country_code: cookies.get("country") ? cookies.get("country") : "",
        ip: cookies.get("ip") !== null ? cookies.get("ip") : cookies.get("ip"),
        device_id: cookies.get("userBrowserVersion") ? cookies.get("userBrowserVersion") : "",
        browser_version: cookies.get("userBrowserVersion") ? cookies.get("userBrowserVersion") : "",
        device_name: cookies.get("userBrowsers") ? cookies.get("userBrowsers") : "",
        device_model: cookies.get("userBrowsers") ? cookies.get("userBrowsers") : "",
        os_version: cookies.get("userOsVersion") ? cookies.get("userOsVersion") : "",
        os: cookies.get("userOss") ? cookies.get("userOss") : ""
      };

      const apiUrl =
        ApiList.current.url + ApiList.endpoints.user.userRegenrateToken;

      const apiHeaders = header;

      CommonApi.postCall(apiUrl, apiHeaders, oldToken)
        .then(data => {
          if (data.statusCode === 200) {
            cookies.remove("xAccessToken", { path: "/" });
            if (data.data !== "" && cookies.get("xAccessToken") === undefined) {
              var xAccessToken = data.data;
              cookies.set("xAccessToken", xAccessToken, { path: "/" });
            }
            resolve("done");
          }
        })
        .catch(err => {
          if (err.responseJSON.statusCode === 401) {
            cookies.remove("xAccessToken", { path: "/" });
            cookies.remove("userType", { path: "/" });
            cookies.remove("userPassword", { path: "/" });
            cookies.remove("userEmail", { path: "/" });
            cookies.remove("userId", { path: "/" });
            cookies.remove("userMobile", { path: "/" });
            cookies.remove("userName", { path: "/" });
            resolve("done"); 
          }
          reject(err);
        });
    });
  }
};
