import moment from 'moment';
import React, { Component } from "react";
import Slider from "react-slick";
import {Redirect} from "react-router-dom";

import "../../stylesheet/partials/slider-dots.css";
import "../../stylesheet/partials/slide-style.css";
import "../../stylesheet/partials/hover-slider.css";
import "../../stylesheet/partials/slide-style.css";
import "../../stylesheet/partials/slider-dots.css";

var img_podcast = "https://preprod-gemplex-assets.gemplex.tv/web/hype/podcast_icon.svg";
var img_play = "https://preprod-gemplex-assets.gemplex.tv/web/hype/play_icon.png";
var img_image = "https://preprod-gemplex-assets.gemplex.tv/web/hype/image_icon.svg";
var img_article = "https://preprod-gemplex-assets.gemplex.tv/web/hype/article_icon.svg";

class SliderCom extends Component {
  state = {
    slideContent: [],
    slides: [],
    heading: "",
    headingCode: '',
    redirectToCode:false,
    redirect : false,
    seeAllRedirect : false
  };

  componentDidMount = () => {
    var self = this
    this.SetData(this.props.slider_data);
    if (this.props && this.props.slider_data) {
      if (this.props.slider_data.ratio === '4:5') {
        self.setState({
          ratio: '45'
        })
      } else if (this.props.slider_data.ratio === '4:3') {
        self.setState({
          ratio: '43'
        })
      } else if (this.props.slider_data.ratio === '16:9') {
        self.setState({
          ratio: '169'
        })
      }
    }
  }
 
  sellAllRedirectFunction = () =>{
    const self = this;
    self.setState({
      seeAllRedirect : true
    })
  }

  SetData = data => {
    if (data != null) {
      this.setState({
        slides: [data],
        heading: data.sub_catalogue_name ? data.sub_catalogue_name : null,
        headingCode: data.sub_catalogue_code ? data.sub_catalogue_code : null
      });
    }
  };

  click = () => {
    this.setState({
      slides:
        this.state.slides.length === 6 ? [1, 2, 3, 4, 5, 6, 7, 8, 9] : [1, 2, 3, 4, 5, 6]
    });
  }

  item_click = (data) => {
    this.setState({
      redirect : true
    })
    window.location.href = "/hype/" + data.type + "/" + data.slug + "/" + data.content_id
  }

  sendData = (data) => {
    this.setState({
      redirectToCode:true
    })
    window.location.href ="/" + data.genres[0].code
  }
  render = () => {
    const self = this;
    let _class = '';
    let _cell_size = 'thumb-3';
    let toScroll = '2'
    if (this.props && this.props.slider_data) {
      if (this.props.slider_data.ratio === '4:5') {
        _class = 'ratio-4-5';
      } else if (this.props.slider_data.ratio === '4:3') {
        _class = 'ratio-4-3';
      } else if (this.props.slider_data.ratio === '16:9') {
        _class = 'ratio-16-9';
      }
      if (this.props.slider_data.cell_size === '2') {
        _cell_size = 'thumb-3';
        toScroll = '2';
      }else if (this.props.slider_data.cell_size === '3') {
        _cell_size = 'thumb-3';
        toScroll = '3';
      } else if (this.props.slider_data.cell_size === '4') {
        _cell_size = 'thumb-4';
        toScroll = '4';
      } else if (this.props.slider_data.cell_size === '6') {
        _cell_size = 'thumb-6';
        toScroll = '6';
      } else if (this.props.slider_data.cell_size === '8') {
        _cell_size = 'thumb-8';
        toScroll = '8';
      }
    }
    const settings = {
      className: ['ratio-4-3', 'thumb-6'],
      centerMode: false,
      dots: true,
      infinite: false,
      speed: 500,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      swipeToSlide: true,
      responsive: [
        {
          breakpoint: 4000,
          settings: {
            slidesToScroll: parseInt(toScroll) - 1,
            variableWidth: true,
            adaptiveHeight: true
          }
        },
        {
          breakpoint: 2000,
          settings: {
            slidesToScroll: parseInt(toScroll) - 1,
            variableWidth: true,
            adaptiveHeight: true
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToScroll: parseInt(toScroll) - 1,
            variableWidth: true,
            adaptiveHeight: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: true,
            adaptiveHeight: true,
            dots: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: true,
            adaptiveHeight: true,
            dots: false
          }
        }
      ]
    };
    const { slides, heading,  headingCode } = this.state;
    return (
      <div className="has-animation animate-on-hover container-fluid p-3" style={{ "minHeight": "213px" }}>
        <div className="col-xl-12  mb-3 p-0" >
          <div className="word_space ">
            <div className="d-inline-block primary1-text gemplex-medium sub-heading" id="view">
              {heading
                ? heading
                : null}
            </div>
            <div className="behind-heading d-inline-block">
              {this.state.seeAllRedirect === true && <Redirect push to={{ pathname: this.props.catName ? "/content/HYPE/" + headingCode + "/cell=" + _class + "/ratio=" + _cell_size : "/content/HYPE/" + headingCode + "/cell=" + _class + "/ratio=" + _cell_size}}  />}
              <div className="d-inline-block behind-heading-arrow" onClick={this.sellAllRedirectFunction} >All<i className="fa fa-angle-right"></i></div>
            </div>
          </div>
        </div>
        {slides.length > 0 ?
          <Slider {...settings}>
            {slides[0].data.map(function (slide) {
              return (
                <span className="item" style={{ "minHeight": "213px" }} key={slide._id}>
                  <div className="card main-card cursor" onClick={()=>self.item_click(slide)}>
                    <img src={slide.base_url_thumbnail_4_3 != null ? slide.base_url_thumbnail_4_3 + "/" + slide.thumbnail_4_3 : slide.base_url_thumbnail_4_3 + "/" + slide.thumbnail_4_3} className="slide-img h-100 w-100 imag-size-fix hover-card" alt={slide.title} style={{ borderTopLeftRadius: '3px', borderTopRightRadius: '3px', width: '207px' }} />
                    <div className="hover-effct">
                      <img src={slide.type === "video" ? img_play : (slide.type === "music" ? img_podcast : (slide.type === "article" ? img_article : img_image))} className="hype-thumbnail-icon" alt="Hype Logo Icons" />
                    </div>
                  </div>
                  <div className="card-bg-hype card-body" style={{ borderRadius: '0px 0px 27px 27px' }}>
                    <div style={{ display: 'flex' }}>
                      {/* VCOMMENT - ONCLICK DISABLED */}
                      {/* <div className="hype-catalog footer-color cursor" onClick={()=>self.sendData(slide)}> */}
                      <div className="hype-catalog">
                        {(slide.genres[0] != null) ? (slide.genres[0].code) : null}
                      </div>
                      <span style={{ marginLeft: '5px', marginRight: '5px' }}> </span>
                      <div className="duration-hype"> {moment(slide.article_publish_date).format('LL')}</div>
                    </div>
                    <div className="two-line ellips cursor">
                      <div key={slide} className=" card-tit card-title footer-color  text-concats" onClick={()=>self.item_click(slide)}>
                        {slide.title ? slide.title : null}
                      </div>
                    </div>
                  </div>
                </span>
              );
            })}
          </Slider>
          :
          ''
        }
      </div>
    );
  }
}

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none" }}
      onClick={onClick}
    >
      <img src="https://preprod-gemplex-assets.gemplex.tv/web/scroll-arrow-to-right.svg" alt="next" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none" }}
      onClick={onClick}
    >
      <img src="https://preprod-gemplex-assets.gemplex.tv/web/scroll-arrow-to-left.svg" alt="next" />
    </div>
  );
}

export default SliderCom;
