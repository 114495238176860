import React, { Component } from 'react';
import $ from 'jquery' 
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
import Cookies from 'universal-cookie';

import ApiList from "../../config/api.js";
import CommonApi from "../../util/common_api_handler.js";
import ErrorMess from "../../util/error.js";
import HeaderJson from '../../util/headers.js';

import '../../stylesheet/cart/index.css';
import "../../stylesheet/payment/offer.css"

var list_data
const cookies = new Cookies();

export default class CastListing extends Component {
    constructor() {
        super();
        this.state = {
            listingData: [],
            totalPrice: [],
            couponData: [],
            codeValue: [],
            contentIdList: []
        }
    }

    componentDidMount = () => {
        const self = this
        const CODE = window.location.search !== "" ? window.location.search.split("?")[1].split("=")[1] : this.props.contentData !== undefined ? this.props.contentData.code : '';
        const mainData = this.props.mainJson
        let cartData = mainData.cartData ? mainData.cartData : [] 
        let price = []
        let contentId = []
        cartData.map((key) => {
            return price.push(parseInt(cookies.get('country') === 'IN' ? key.monetization.tvod.price_inr : key.monetization.tvod.price_usd))
        }) 
        cartData.map((item) => {
            return contentId.push(item.content_id)
        })
        this.setState({
            listingData: cartData,
            contentIdList: contentId,
            codeValue: CODE,
            totalPrice: price.reduce((a, b) => a + b, 0)
        })

       
        $('.container-fluid').on('click', 'tr i', function (e) {
            e.preventDefault();
            var cid = $(this).attr('id');
            self.deleteList(cid)
            setTimeout(function () {
                $(this).parents('tr').remove();
            }, 100)
        });

        self.fetchCoupon()
    }

    deleteList = (content_id) => {
        const self = this
        
        const apiUrl = ApiList.current.url + ApiList.endpoints.user.deleteCart;

        const apiHeaders = HeaderJson.header();

        const bodyData = {
            "content_ids": [content_id]
        }

        CommonApi.postCall(apiUrl, apiHeaders, bodyData)
            .then((data)=>{
                if (data.statusCode === 200) {
                    this.props.removeContent(data.data)
                    list_data = this.state.listingData;
                    list_data.splice(list_data.findIndex(e => e.content_id === content_id), 1);
                    let price = []
                    list_data.map((key) => {
                       return price.push(parseInt(cookies.get('country') === 'IN' ? key.monetization.tvod.price_inr : key.monetization.tvod.price_usd))
                    })
                    self.setState({
                        totalPrice: price.reduce((a, b) => a + b, 0)
                    })
                }
            })
    }

    fetchCoupon = (subPlan) => {

        const apiUrl = ApiList.current.url + ApiList.endpoints.subscriptionPlan.cuponCode;

        const apiHeaders = HeaderJson.header();

        const bodyData = {
            "content_type": "TVOD",
        }

        CommonApi.postCall(apiUrl, apiHeaders, bodyData)
            .then((data)=>{
                if (data.data.statusCode === 200) {
                    this.setState({
                        couponData: data.data.data
                    })
                }
            })
    }
    apply = () => {
        let couponValue = document.getElementById("coupon-tvod").value
        if (couponValue === "") {
            swal({ text: ErrorMess.alertMessages.coupon.PCC, timer: 1000 })
        } else {    
            const apiUrl = ApiList.current.url + ApiList.endpoints.coupon.validate;
    
            const apiHeaders = HeaderJson.header();
            
            const bodyData = {
                "content_type": "TVOD",
                "coupon_code": couponValue,
                "actual_amount": this.state.totalPrice.toString(),
                // "specific_content_price": this.props.contentData.price,
                // "content_id": this.props.contentData.content_id_list
                "specific_content_price": this.state.totalPrice.toString(),
                "content_id": this.state.contentIdList
            }
    
            CommonApi.postCall(apiUrl, apiHeaders, bodyData)
                .then((data)=>{
                    if (data.statusCode === 200) {
                        this.setState({
                            totalPrice: data.data.final_amount
                        })
                    } else if (data.statusCode === 404){
                        swal({ text: data.message, timer: 1000 })
                    }
                })

        }
    }
    checkOut = () => {
        var result = this.state.listingData.map(data => ({ "content_id": data.content_id, "expire_in_days": parseInt(data.monetization.tvod.validity), "price": data.monetization.tvod.price_inr }));
      
        const apiUrl = ApiList.current.url + ApiList.endpoints.user.createOrderId;

        const apiHeaders = HeaderJson.header();

        const bodyData = {
            "voucher": " ",
            "actual_amount": this.state.totalPrice.toString(),
            "final_amount": this.state.totalPrice.toString(),
            "payment_gateway": "",
            "content_detail": result
        }

        CommonApi.postCall(apiUrl, apiHeaders, bodyData)
            .then((data)=>{
                if (data.statusCode === 200) {
                    window.location.href = ApiList.current.paymentGateway + `/subscription-plan/${data.data.order_id}/${apiHeaders.user_id}/abcd/${cookies.get('country')}`
                }
            })
    }

    couponList = () => {
        $('.tvod-list-open').click()
    }

    render() {
        const self = this;
        const { listingData, couponData } = self.state;
        return (
            <div>
                <div className="container-fluid cart-align cart-top-border">
                    {
                        listingData.length !== 0 ?
                            <div className="row">
                                <div className="col-lg-9 col-md-12 col-sm-12 col-12 cart-right-border">

                                    <table className="table">
                                        <thead className="">
                                            <tr>
                                                <th className="common-dark" scope="col">Thumbnails</th>
                                                <th className="common-dark" scope="col">Title</th>
                                                <th className="common-dark" scope="col">Price</th>
                                                <th className="common-dark" scope="col">Validity</th>
                                                <th className="common-dark" scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {listingData.map(function (item) {
                                                return (
                                                    <tr key={item.content_id}>
                                                        <td style={{ width: '250px' }}><img src={item.base_url + '/' + item.content_manager_folder + "/" + item.content_id + '/' + item.thumbnail_folder + '/' + item.banner_folder + '/' + item.thumbnail_16_9 + '/' + item.platform_images.thumbnails_16_9} className="img-fluid cart-img" alt={item.content_name} /></td>
                                                        <td className="common-dark" style={{ width: '350px' }} >{item.content_name}</td>
                                                        {
                                                            cookies.get('country') === 'IN' ?
                                                            <td className="common-dark" ><p id="product_val" >&#8377; {item.monetization.tvod.price_inr}</p></td>
                                                            :
                                                            <td className="common-dark" ><p id="product_val" >&#x24; {item.monetization.tvod.price_usd}</p></td>
                                                        }
                                                        
                                                        <td className="common-dark">{item.monetization.tvod.validity}Days</td>
                                                        <td ><i className="far fa-trash-alt cursor" id={item.content_id}
                                                        //  onClick={() => { swal({ text: ErrorMess.alertMessages.home.RTC, timer: 1500 }) }}
                                                         ></i></td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-lg-3">
                                    <div className="shopping-cart">
                                        <div className="totals">
                                            <h6 className="mb-2">Apply Coupon</h6>
                                            <Link to={{
                                                pathname: "/coupons",
                                                state: {
                                                    content_id_list: this.state.contentIdList
                                                }
                                            }} >
                                                <u className="mb-3 cursor tvod-coupon-list" id="coupon_get">Get Coupon Here</u>
                                            </Link>
                                            <div className="input-group mb-3 mt-3">
                                                <input type="text" className="form-control" id="coupon-tvod" placeholder="Coupon Enter" aria-label="Recipient's username" aria-describedby="basic-addon2" defaultValue={this.state.codeValue} />
                                                <div className="input-group-append">
                                                    <button className="btn btn-outline-secondary" type="button" onClick={this.apply}>Apply</button>
                                                </div>
                                            </div>
                                            <h6 className="mb-4">Order Summary</h6>
                                            <div className="totals-item">
                                                <label>Subtotal</label>
                                                <div className="totals-value" id="cart-subtotal">{this.state.totalPrice}</div>
                                            </div>
                                            <div className="totals-item totals-item-total grand-total-align">
                                                <label>TOTAL PAYABLE</label>
                                                <div className="totals-value" id="cart-total">{this.state.totalPrice}</div>
                                                <small className="taxes-font">(Prices are inclusive of all taxes)</small>
                                            </div>
                                            <div className="totals-item">
                                                <button className="checkout gradient1-bg checkout-btn" onClick={self.checkOut}>Checkout</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="text-center">
                                {/* <i className=" fas fa-cart-plus pt-2" style={{ color: 'saddlebrown', fontSize: '175px' }}></i> */}
                                <h3 className=" font-weight-bold" style={{ opacity: '0.7', color: '#26bdeb', padding: '5rem' }}>{ErrorMess.alertMessages.home.CIE}</h3>
                            </div>
                    }
                </div>
                <span>
                    <span
                        className="d-inline-block tvod-list-open"
                        data-toggle="modal"
                        // data-backdrop="static"
                        data-target="#tvod-plan-list"
                        style={{ margin: "0 0 0 10px", cursor: "pointer" }}
                    >
                    </span>
                </span>
                <div className="modal fade" id="tvod-plan-list" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered  modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header border-0 pb-0">
                                <button type="button" className="close outline" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body pt-0">
                                <h5 className="modal-title text-center heading-divider pb-3" id="exampleModalLabel">Coupons</h5>
                                <div className="container-fluid mt-2" >
                                    <div className="row ">
                                        <div className="col-3 col-xl-3 col-md-3 offer-right-divider">
                                            <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                                <a className="nav-link active tabs-css text-left tvod-copuon-btn" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">All</a>
                                                {/* <a className="nav-link tabs-css text-left my-2 tvod-copuon-btn" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">Platform Coupon</a> */}
                                            </div>
                                        </div>
                                        <div className="col-9 col-xl-9 col-md-9">
                                            <div className="tab-content" id="v-pills-tabContent">
                                                <div className="tab-pane  show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                                    <div className="container" style={{ height: '21rem', overflowY: 'auto' }}>
                                                        {couponData
                                                            ? <div className="row main-row justify-content-center">
                                                                {
                                                                    couponData.map(function (item) {

                                                                        return (
                                                                            <div className="coupon_area" key={item._id} onClick={() => self.fetchCouponValue(item.coupon_codes)}>
                                                                                <div className="coupon_content">
                                                                                    <div>{item.content_type}</div>
                                                                                    <h6 className="mt-1">{item.discount_type}</h6>
                                                                                    <div className="code_text">code</div>
                                                                                    <div className="code cursor" id="coupon_code">{item.coupon_amount}</div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                            : ""
                                                        }
                                                    </div>
                                                </div>
                                                <div className="tab-pane " id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">B</div>
                                                <div className="tab-pane " id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">C</div>
                                                <div className="tab-pane " id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">D</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
