import React, { Component } from 'react';
import LazyLoad from 'react-lazyload';

import ApiList from "../../config/api.js";
import CommonApi from "../../util/common_api_handler.js";
import HeaderJson from '../../util/headers.js';
import Filter from "../partials/filter.jsx"
import Footer from '../partials/footer';
import Navbar from "../partials/NavigationBar.jsx"


import "../../stylesheet/content/video.css"


class VideoAllPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slides: [],
      contentName: this.props.match.params.content,
      slider: [],
      listDataFromChild: [],
      listDataFromLanguage: [],
      listDataFromSort: [],
      cellSize: this.props.match.params.cell,
      ratioSize: this.props.match.params.ratio,
    }
  }

  componentDidMount=()=>{
    const self = this;
    self.fetchMainData(this.props.match.params.type, this.props.match.params.catalogue, this.props.match.params.content)
  }

  fetchMainData = (catName, pageType, content) => {
    var self = this;

    const apiUrl = ApiList.current.url + ApiList.endpoints.home.homeCatalogue;

    const apiHeaders = HeaderJson.header()

    const bodyData = {
      'genreFilter': self.state.listDataFromChild ? self.state.listDataFromChild : [],
      "subCatalogue": [content],
      'languageFilter': this.state.listDataFromLanguage,
      'sortBy': self.state.listDataFromSort ? self.state.listDataFromSort : [],
      "page_type": pageType != null ? pageType : catName,
      "catalogue_name": catName != null ? catName : '',
      "parental_control": false,
      "offset": 0,
      "limit": 100,
      "catalogue_offset": 0,
      "catalogue_limit": 100
    }

    CommonApi.postCall(apiUrl, apiHeaders, bodyData)
      .then((data)=>{
        if (data.statusCode === 200 && data.data) {
          const json_data = JSON.stringify(data)? (data.data) : null;
          if (json_data != null) {
            let component_arry_var = self.state.slider.slice();
            for (var i = 0; i < json_data.length; i++) {
              component_arry_var.push(json_data[i]);
            }
            let arr_table = ""
            component_arry_var.map((key)=>{
             return arr_table = (key.data)
            })
            self.setState({
              slider: arr_table,
              slides: component_arry_var,
              heading: component_arry_var ? component_arry_var[0].sub_catalogue_name : ''
            })
          }
        }
      })
  }

  dataGet = (dataFromChild) => {
    const self = this
    self.setState({
      listDataFromChild: dataFromChild
    },() => {
      self.componentDidMount();
      });
  }

  getLanuageFilter = (language) => {
    const self = this
    self.setState({
      listDataFromLanguage: language
    },() => {
      self.componentDidMount();
       
      });
  }

  getSortingFilter = (sorting) => {
    const self = this
    self.setState({
      listDataFromSort: sorting
    },() => {
      self.componentDidMount();
      });
  }


  render() {
    const self = this
    const { slider, contentName, cellSize, ratioSize, heading } = this.state;

    return (
      <div>
        <Navbar />
        <Filter Language_type="video" showName='Genre' type={"video"} getGenre={self.dataGet} getLanguage={self.getLanuageFilter} getSorting={self.getSortingFilter}/>
        <div className="series-list">
          <div className="has-animation animate-on-hover container-fluid  primary-container-padding my-4 series-section ">
            <div className="row mb-3 justify-content-between">
              <div className="col-xl-5 my-auto series-name-heading">
                {heading ? heading : contentName}
              </div>
              <div className="col-xl-12 ">
                <div className="border-series-slider"></div>
              </div>
            </div>
            <div className="row">
              <div className={"col-md-12 p-0 slick-slider " + (cellSize.slice(5, 15)) + " " + (ratioSize.slice(6, 13))} >
                {
                  slider ?
                    slider.map(function (item, i) {
                      return (
                       
                        <div className={"item d-inline-block m-3"} key={i}>
                          {(cellSize.slice(5, 15)) === "ratio-16-9" ?
                           <LazyLoad height={200}>
                              <a className={"item"} href={'/detail/'+item.slug+"/" + item.content_id } key={item._id}>
                                <div className="item-content position-realtive h-100">
                                  <div className="image w-100 h-100">
                                    <img src={item.base_url + "/" + item.content_manager_folder + "/" + item.content_id + '/' + item.thumbnail_folder + '/' + item.banner_folder + '/' + item.thumbnail_16_9 + '/' + item.platform_images.thumbnails_16_9}
                                      className="slide-img h-100 w-100 hover-card"
                                      alt={item.content_name ? item.content_name : null}
                                    />
                                  </div>
                                  <img src="https://preprod-gemplex-assets.gemplex.tv/web/play-button.svg" className="play-video-button-img " alt='' />
                                  <div className="play-button-content" style={{ outline: 'none' }}>
                                  </div>
                                  <div className="series-number">
                                    {(item.display_order != null ? item.display_order : '').toString().length === 1 ? "0" + item.display_order : item.display_order}
                                  </div>
                                  {item.monetization_flag ? 
                                    <div className="home-primary premium-icon position-absolute">
                                    </div>
                                    : null}
                                </div>
                                <div className="d-inline-block  serires-name mt-0" style={{ width: '100%' }}>
                                  <div className="d-inline-block text-truncate serires-name" style={{ width: '100%' }} >
                                    <div className="gemplex-bold  primary2-text">  {item.content_name ? item.content_name : null}</div> <div className="duration-span">
                                      <span>{item.released_year ? item.released_year : null}<span className="seprator"> | </span><span> {item.duration ? (item.duration > 60 ? Math.floor(item.duration / 60) + 'hr ' + (item.duration % 60) + 'min' : item.duration + 'min') : null} </span></span></div>
                                  </div>
                                </div>
                              </a>
                              </LazyLoad>
                              :
                              (cellSize.slice(5, 15)) === "ratio-4-3" ?
                              <LazyLoad height={200}>
                                <a className={"item"} href={'/detail/'+item.slug +"/" + item.content_id } key={item._id}>
                                  <div className="item-content position-realtive h-100">
                                    <div className="image w-100 h-100">
                                      <img
                                        src={item.base_url + "/" + item.content_manager_folder + "/" + item.content_id + '/' + item.thumbnail_folder + '/' + item.banner_folder + '/' + item.thumbnail_4_3 + '/' + item.platform_images.thumbnails_4_3}
                                        className="slide-img h-100 w-100 hover-card"
                                        alt={item.content_name ? item.content_name : null}
                                      />

                                    </div>
                                    <img src={"https://preprod-gemplex-assets.gemplex.tv/web/play-button.svg"} className="play-video-button-img " alt='' />
                                    <div className="play-button-content" style={{ outline: 'none' }}>
                                    </div>
                                    <div className="series-number">
                                      {(item.display_order != null ? item.display_order : '').toString().length === 1 ? "0" + item.display_order : item.display_order}
                                    </div>
                                    {item.monetization_flag ? <div className="home-primary premium-icon position-absolute"> </div> : null}
                                  </div>
                                  <div className="d-inline-block  serires-name mt-0" style={{ width: '100%' }}>
                                    <div className="d-inline-block text-truncate serires-name" style={{ width: '100%' }} >
                                      <div className="gemplex-bold  primary2-text"> {item.content_name ? item.content_name : null} </div> <div className="duration-span">
                                        <span>{item.released_year ? item.released_year : null}<span className="seprator"> | </span><span> {item.duration ? (item.duration > 60 ? Math.floor(item.duration / 60) + 'hr ' + (item.duration % 60) + 'min' : item.duration + 'min') : null} </span></span></div>
                                    </div>
                                  </div>
                                </a> 
                                </LazyLoad>
                                :
                                 (cellSize.slice(5, 15)) === "ratio-4-5" ?
                                 <LazyLoad height={200}>
                                <a className={"item"} href={'/detail/'+ item.slug+"/" + item.content_id + '/'} key={item._id}>
                                  <div className="item-content position-realtive h-100">
                                    <div className="image w-100 h-100">
                                      <img src={item.base_url + "/" + item.content_manager_folder + "/" + item.content_id + '/' + item.thumbnail_folder + '/' + item.banner_folder + '/' + item.thumbnail_4_5 + '/' + item.platform_images.thumbnails_4_5}
                                        className="slide-img h-100 w-100 hover-card"
                                        alt={item.content_name ? item.content_name : null}
                                      />

                                    </div>
                                    <img src={"https://preprod-gemplex-assets.gemplex.tv/web/play-button.svg"} className="play-video-button-img " alt='' />
                                    <div className="play-button-content" style={{ outline: 'none' }}>
                                    </div>
                                    <div className="series-number">
                                      {(item.display_order != null ? item.display_order : '').toString().length === 1 ? "0" + item.display_order : item.display_order}
                                    </div>
                                    {item.monetization_flag ? <div className="home-primary premium-icon position-absolute"> </div> : null}
                                  </div>
                                  <div className="d-inline-block  serires-name mt-0" style={{ width: '100%' }}>
                                    <div className="d-inline-block text-truncate serires-name" style={{ width: '100%' }} >
                                      <div className="gemplex-bold  primary2-text"> {item.content_name ? item.content_name : null} </div> <div className="duration-span">
                                        <span>{item.released_year ? item.released_year : null}<span className="seprator"> | </span><span> {item.duration ? (item.duration > 60 ? Math.floor(item.duration / 60) + 'hr ' + (item.duration % 60) + 'min' : item.duration + 'min') : null} </span></span></div>
                                    </div>
                                  </div>
                                </a>
                                </LazyLoad>
                                :
                                null
                          }
                        </div>
                      )
                    }) : null
                }

              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>

    )
  }

}
export default VideoAllPage