import React, { Component } from "react";

import ApiList from "../../../config/api.js";
import CommonApi from "../../../util/common_api_handler.js";
import PageJourny from "../../../util/page_journey.js";
import RegenrateToken from "../../../util/regenrateToken.js";
import HeaderJson from '../../../util/headers.js';
import Navbar from "../../partials/NavigationBar.jsx";
import Footer from "../../partials/footer";

import "../../../stylesheet/user/my_order/index.css";

export default class MyOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contentData: [],
        }
    }
    componentDidMount() {
        const self = this;
        PageJourny.setDeviceInfo()
            .then((data) => {
                self.fetchSuccessData()
            });
    }
    fetchSuccessData = () => {

        const self = this;

        const apiUrl = ApiList.current.url + ApiList.endpoints.subscriptionPlan.tvodSuccess;

        const apiHeaders = HeaderJson.header();

        CommonApi.getCall(apiUrl, apiHeaders)
            .then((data) => {
                if (data.statusCode === 200) {
                    let jsonData = data.data;
                    self.setState({
                        contentData: jsonData,
                        renderingStatus: true
                    })
                }
                if (data.statusCode === 400) {
                    self.setState({
                        renderingStatus: true
                    })
                }
            })
            .catch((err) => {
                if (err.responseJSON.status === 403 || err.responseJSON.status === 400) {
                    RegenrateToken.userRegenrateToken()
                        .then((data) => {
                            self.setState({
                                renderingStatus: true
                            });
                        })
                }
            })
    }

    downLoad = (item) => {
        window.open(item)
    }
    render = () => {
        const self = this
        const { renderingStatus, contentData } = this.state;

        return (
            <>
                <Navbar />{

                    renderingStatus ?
                        <div className="container-fluid cart-align cart-top-border">
                            <div className="row">
                                {
                                    contentData.length > 0 ?
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 ">

                                            <table className="table">
                                                <thead className="">
                                                    <tr className="header-text">
                                                        <th scope="col" className="header-list"><h6>Order Id</h6></th>
                                                        <th scope="col" className="header-list"><h6>Trasaction Type</h6></th>
                                                        <th scope="col" className="header-list"><h6>Trasaction Id</h6></th>
                                                        <th scope="col" className="header-list"><h6>Trasaction Status</h6></th>
                                                        <th scope="col" className="header-list"><h6>Invoice</h6></th> 
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {contentData.map(function (item) {
                                                        return (
                                                            <tr key={item._id} className="header-text">
                                                                <td className="data-list">{item.order_id}</td>
                                                                <td className="data-list">{item.transaction_type}</td>
                                                                <td className="data-list" ><p id="product_val" >{item.transaction_id}</p></td>
                                                                <td className="data-list"> {item.payment_status ? <p style={{ color: '#008000', fontWeight: 'bold' }}>Success</p> : <p style={{ color: '#ff0000', fontWeight: 'bold' }}>Failed</p>} </td>
                                                                <td className="data-list">
                                                                    {/* <img src="https://preprod-gemplex-assets.gemplex.tv/web/invoice+icon.svg" alt="invoice" style={{ width: '25px' }} className="order-invoice cursor" onClick={() => { self.downLoad(item.s3_invoice_url) }} /> */}
                                                                    <button type="button" className="btn btn-sm ml-2" onClick={() => { self.downLoad(item.s3_invoice_url) }}  style={{color:'white', backgroundColor:'#0070cc', border:'none', outline:'none'}}>Download</button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                        : <h3 className="empty-cart-align header-text">Your Order Is Empty</h3>
                                }
                            </div>
                        </div>
                        :
                        <div className="container-fluid cart-align cart-top-border">
                            <div className="row">

                                <div className="col-lg-12 col-md-12 col-sm-12 col-12 ">

                                    <table className="table">
                                        <thead className="">
                                            <tr className="header-text">
                                                <th scope="col" className="header-list"><h6>Order Id</h6></th>
                                                <th scope="col" className="header-list"><h6>Trasaction Type</h6></th>
                                                <th scope="col" className="header-list"><h6>Trasaction Id</h6></th>
                                                <th scope="col" className="header-list"><h6>Trasaction Status</h6></th>
                                                <th scope="col" className="header-list"><h6>Invoice</h6></th>
                                                <th scope="col" className="header-list"></th>
                                            </tr>
                                        </thead>

                                    </table>
                                </div>
                            </div>
                        </div>
                }
                <Footer />
            </>
        )
    }


}





