import React, { Component } from 'react';
import LazyLoad from 'react-lazyload';

import ApiList from "../../config/api.js";
import CommonApi from "../../util/common_api_handler.js";
import HeaderJson from '../../util/inner_header.js';
import Filter from "../partials/filter.jsx"
import Footer from '../partials/footer';
import Navbar from "../partials/NavigationBar.jsx"

import "../../stylesheet/content/video.css";


class Video extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slides: [],
      contentName: this.props.match.params.content,
      heading: this.props.match.params.name,
      slider: [],
      listDataFromChild: '',
    }
  }

  componentDidMount = () => {
    const self = this;
    self.fetch_main_data(this.props.match.params.type, this.props.match.params.catalogue, this.props.match.params.content)
  }
  fetch_main_data = (catName, pageType, content) => {
    var self = this;

    const apiUrl = ApiList.current.url + ApiList.endpoints.home.homeCatalogue

    const apiHeaders = HeaderJson.header()

    const bodyData = {
      'genreFilter': [],
      "subCatalogue": "",
      'languageFilter': [content],
      "parental_control": false,
      "page_type": catName,
      "catalogue_name": catName,
      "offset": 0,
      "limit": 15,
      "catalogue_offset": 0,
      "catalogue_limit": 100
    }

    CommonApi.postCall(apiUrl, apiHeaders, bodyData)
      .then((data) => {
        if (data.statusCode === 200 && data.data) {
          const json_data = JSON.stringify(data) ? (data.data) : null;
          if (json_data != null) {
            let component_arry_var = self.state.slider.slice();
            for (var i = 0; i < json_data.length; i++) {
              component_arry_var.push(json_data[i]);
            }
            let arr_table = ""
            component_arry_var.map((key) => {
              return arr_table = (key.data)
            })
            self.setState({
              slider: arr_table,
              slides: component_arry_var
            })
          }
        }
      })
  }
  dataGet = (dataFromChild) => {
    const self = this
    self.setState({ listDataFromChild: dataFromChild },
      () => {
        this.componentDidMount();
      });
  }

  render() {
    const { slider, slides, heading } = this.state;
    return (
      <div>
        <Navbar />
        <Filter Language_type="video" showName='Genre' type={"video"} getGenre={this.dataGet} />
        <div className="series-list">
          <div className="has-animation animate-on-hover container-fluid  primary-container-padding my-4 series-section">
            <div className="row mb-3 justify-content-between">
              <div className="col-xl-5 my-auto series-name-heading">
                {heading ? heading : null}
              </div>
              <div className="col-xl-12">
                <div className="border-series-slider"></div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 p-0">
                {slider ?
                  slider.map(function (item, i) {
                    return (
                      <LazyLoad width={200} height={200} key={i}>
                        <a href={'/detail/' + item.slug + "/" + item.content_id} key={i} className={"item d-inline-block m-3" + (slides[0].ratio === "16:9" ? ' thumb-ratio-16-9 ' : slides[0].ratio === "4:5" ? ' thumb-ratio-4-5' : "")}>
                          <div className="item-content position-realtive h-100">
                            <div className="image w-100 h-100">
                              <img src={slides[0].ratio === "16:9" ? item.base_url + '/' + item.content_manager_folder + "/" + item.content_id + '/' + item.thumbnail_folder + '/' + item.banner_folder + '/' + item.thumbnail_16_9 + '/' + item.platform_images.thumbnails_16_9 : slides[0].ratio === "4:5" ? item.base_url + "/" + item.thumbnail_folder + "/" + item.platform_images.thumbnails_4_5 : ''} className="slide-img h-100 w-100 hover-card" alt={item.content_name} />
                            </div>
                            <img src="https://preprod-gemplex-assets.gemplex.tv/web/play-button.svg" alt="Gemplex Play Button" className="play-video-button-img " />
                            <div className="play-button-content" style={{ outline: 'none' }}>
                            </div>
                            <div className="series-number">
                              {(item.display_order != null ? item.display_order : '').toString().length === 1 ? "0" + item.display_order : item.display_order}
                            </div>
                          </div>
                          <div className="d-inline-block  serires-name mt-2" style={{ width: '100%' }} >
                            <div className="gemplex-bold text-truncate primary2-text">
                              {item.content_name}
                            </div>
                            <div className="thumb-details-div mb-2">
                              <span>
                                {item.released_year ? item.released_year : null}
                                <span className="seprator"> | </span>
                                <span> {item.duration ? (item.duration > 60 ? Math.floor(item.duration / 60) + 'hr ' + (item.duration % 60) + 'min' : item.duration + 'min') : null} </span>
                              </span>
                            </div>
                          </div>
                        </a>
                      </LazyLoad>
                    )
                  }) :
                  <div className="fit-content-class">
                    <img src="https://preprod-gemplex-assets.s3.ap-south-1.amazonaws.com/web/gemplex/empty-screens/Videos.svg" className="img-fluid" alt="empty-img" />
                    <div className="text-center">No Data Found</div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>

    )
  }

}
export default Video