import React, { Component } from 'react';
import ApiList from "../../config/api.js";
import CommonApi from "../../util/common_api_handler.js";
import HeaderJson from '../../util/headers.js';
import Footer from "../partials/footer";
import Header from "../partials/header";
import Navbar from "../partials/NavigationBar.jsx";

import '../../stylesheet/coupon/index.css';

export default class Coupon extends Component {
    state = {
        couponList: [],
        couponMessage: ''
    };

    componentDidMount = () => {

        const apiUrl = ApiList.current.url + ApiList.endpoints.subscriptionPlan.cuponCode;

        const apiHeaders = HeaderJson.header()

        const bodyData = {
            "content_type": "SVOD",
            "sub_platform_code": this.props.location.state.sub_platform_code.toUpperCase() ? this.props.location.state.sub_platform_code.toUpperCase() : ''
        }
        CommonApi.postCall(apiUrl, apiHeaders,bodyData)
            .then((data)=>{
                if (data.statusCode === 200) {
                    this.setState({
                        couponList: data.data !== [] ? data.data : []
                    });
                } else if (data.statusCode === 401) {
                    this.setState({
                        couponMessage: data.message
                    })
                }
                else if (data.statusCode === 404) {
                    this.setState({
                        couponMessage: data.message
                    })
                }
            })
    }

    redirectToPayment = (code) => {
        window.location.href = "/payment";
    }

    renderCoupons = () => {
        var couponList = this.state.couponList.map((coupon) => {
            return (
                <div className="col-md-2 col-sm-4 col-6 mb-2 " key={coupon.coupon_codes}>
                    <div className="text-center coupon-item background-color p-1">
                        <h6 className="font-weight-bold coupon-primary"> {coupon.subscription} </h6>
                        <p className="mb-1">{coupon.discount_type === "flat"?(<div className="" style={{fontSize:"20px"}}>&#8377; {coupon.coupon_amount} <span className="">off</span> </div>):(<div className="" style={{fontSize:'20px'}}> {coupon.coupon_amount} <span>%</span> off</div>)}</p><br></br>
                        <button className="button btn-primary coupon-btn rounded mb-1 text-white" onClick={() => this.redirectToPayment(coupon.coupon_codes)}>{coupon.coupon_codes}</button>
                    </div>
                </div >
            )
        })
        return couponList;
    }
    redirectBackToPayment = () => {
        window.location.href = "/payment"
    }
    render = () => {
        const { couponMessage } = this.state
        return (
            <>
                <Header />
                <Navbar />
                <div className="container">
                    <div className="text-primary font-weight-bold">
                        <div className="d-flex  mt-4 mb-4 justify-content-between">
                            <div id="backButton" className="cursor" onClick={this.redirectBackToPayment}><span>Back</span></div>
                            <div>{"Coupon & Offers"}</div>
                            <div><span><i className="fas fa-times cursor coupon-exit" onClick={this.redirectBackToPayment}></i></span></div>
                        </div>
                        <div className="text-center"><p className=" mb-0" >All</p></div>
                        <hr className="mt-0" />
                    </div>
                    {
                        couponMessage === "" ?
                            <div className="row mb-4">
                                {this.renderCoupons()}
                            </div>
                            :
                            <div className="row mb-4">

                                <div className="col align-self-center">
                                    <h3 style={{color:'#26bdbe',padding:'10%', textAlign:'center'}}>{couponMessage}</h3>
                                </div>
                            </div>
                    }
                </div>
                <Footer />
            </>
        )
    }
}
