import React, { Component } from 'react';
import Slider from "react-slick";
import LiveGuideDefaultLogo from '../../images/gemplex-vector logo-black.svg'
// import prev from "../../images/scroll-arrow-to-left.svg";
// import next from "../../images/scroll-arrow-to-right.svg";
import moment from 'moment';
import $ from 'jquery';
import '../../stylesheet/live_guide/table.css'
var prev ="https://preprod-gemplex-assets.gemplex.tv/web/scroll-arrow-to-left.svg";
var next ="https://preprod-gemplex-assets.gemplex.tv/web/scroll-arrow-to-right.svg";

class Table extends Component {
    constructor() {
        super();
        this.state = {
        }
    }

    componentDidMount() {
        let slotTimer = setInterval(() => {
            $('.fixedTable-header table thead tr th').removeClass('active')
            let hour = moment().format('HH')
            let min = moment().format('mm')
            let y = (min / 60)
            let z = (y * 2) * 100
            if (z > 100) {
                z = z - 100
            }
            let classSlot = hour + (parseInt(min) > 30 ? '30' : '00')
            $('.' + classSlot).addClass('active')
            $('.pointer').css('left', z + "%")

        }, 1000 );
        this.scrollTable()
        //# sourceURL=coffeescript
    }

    scrollTable = () => {
        (function () {
            var demo, fixedTable;
            fixedTable = function (el) {
                var $body, $header, $sidebar;
                $body = $(el).find('.fixedTable-body');
                $sidebar = $(el).find('.fixedTable-sidebar table');
                $header = $(el).find('.fixedTable-header table');
                return $($body).scroll(function () {
                    $($sidebar).css('margin-top', -$($body).scrollTop());
                    return $($header).css('margin-left', -$($body).scrollLeft());
                });
            };
            demo = new fixedTable($('#demo'));
        }).call(this);
    }

    render() {
        let fixhedth = [];
        for (var i = 0; i < 24; i++) {
            for (var j = 0; j < 2; j++) {
                fixhedth.push({
                    min: j === 1 ? '30' : '00',
                    hour: i
                })
            }
        }
        const settings = {
            className: ['live-guide-slider'],
            centerMode: false,
            dots: false,
            infinite: false,
            speed: 500,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            swipeToSlide: true,
            responsive: [
                {
                    breakpoint: 4000,
                    settings: {
                        slidesToScroll: 1,

                        variableWidth: true,
                        adaptiveHeight: false
                    }
                },
                {
                    breakpoint: 2000,
                    settings: {
                        slidesToScroll: 1,

                        variableWidth: true,
                        adaptiveHeight: false
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {

                        slidesToScroll: 1,
                        variableWidth: true,
                        adaptiveHeight: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        variableWidth: true,
                        adaptiveHeight: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        variableWidth: true,
                        adaptiveHeight: true,
                        dots: false


                    }
                }
            ]
        };
        return (
            <div className="container-fluid">
                <div className='row primary-container-padding mt-4'>
                    <div className="media">
                        <img className="align-self-start mr-3" src={LiveGuideDefaultLogo} style={{width:'10%'}} alt="Generic placeholder image" />
                        <div className="media-body">
                            <h5 className="mt-0">Top-aligned media</h5>
                            <p>Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus viverra turpis. Fusce condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.</p>

                        </div>
                    </div>
                </div>



                <div className='row primary-container-padding mt-4'>
                    <div className="col-md-12 p-0">
                        <Slider {...settings}>
                            {fixhedth.map(function (item) {
                                return (
                                    <div className="item">
                                        <div className="item-content">
                                            <div className="day-name"> Friday   </div>
                                            <div className="full-date">10/04/2014</div>
                                        </div>
                                    </div>
                                )
                            })}



                        </Slider>
                    </div>
                </div>
                <div className="row mt-5 primary-container-padding position-relative">
                    <div className="col-md-12 p-0">
                        <div className="fixedTable" id="demo">
                            <header className="fixedTable-header">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            {fixhedth.map(function (item) {
                                                return (
                                                    <th className={item.hour + item.min}>
                                                        {item.hour}:{item.min}
                                                        <span className='pointer'></span>
                                                    </th>
                                                )
                                            })}
                                        </tr>
                                    </thead>
                                </table>
                            </header>
                            <aside className="fixedTable-sidebar">
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <td> <img src={"https://akamaividz.zee5.com/resources/0-9-tvhd_0/list/170x120/tvhd_386x386_634196922.png"} className="img-fluid" alt="" /> </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <img src={'https://akamaividz.zee5.com/resources/0-9-bigmagic_1786965389/list/170x170/colourbigmagicandroidapp38.png'} className="img-fluid" alt="" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <img src={"https://akamaividz.zee5.com/resources/0-9-162/list/170x170/rbharat386x386_996253913.png"} className="img-fluid" alt="" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><img src={"https://akamaividz.zee5.com/resources/0-9-pictures/list/170x170/picture386x386_1563464759.png"} className="img-fluid" alt="" /></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <img src={'https://akamaividz.zee5.com/resources/0-9-131/list/170x170/9xm_386x386.png'} className="img-fluid" alt="" />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>6</td>
                                        </tr>
                                        <tr>
                                            <td>7</td>
                                        </tr>
                                        <tr>
                                            <td>8</td>
                                        </tr>
                                        <tr>
                                            <td>9</td>
                                        </tr>
                                        <tr>
                                            <td>10</td>
                                        </tr>
                                        <tr>
                                            <td>11</td>
                                        </tr>
                                        <tr>
                                            <td>12</td>
                                        </tr>
                                        <tr>
                                            <td>13</td>
                                        </tr>
                                        <tr>
                                            <td>14</td>
                                        </tr>
                                        <tr>
                                            <td>15</td>
                                        </tr>
                                        <tr>
                                            <td>16</td>
                                        </tr>
                                        <tr>
                                            <td>17</td>
                                        </tr>
                                        <tr>
                                            <td>18</td>
                                        </tr>
                                        <tr>
                                            <td>19</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </aside>
                            <div className="fixedTable-body">
                                <table className="table table-bordered">
                                    <tbody>
                                        <tr>
                                            {fixhedth.map(function (item) {
                                                return (
                                                    <td className={item.hour + item.min}>
                                                        {item.hour}:{item.min}
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                        <tr>
                                            <td>a</td>
                                            <td>b</td>
                                            <td>c</td>
                                            <td colSpan="3">
                                                <a>ahjsdhjabjh</a>
                                            </td>
                                            <td>e</td>
                                            <td>f</td>
                                            <td>g</td>
                                            <td>h</td>
                                            <td>i</td>
                                            <td>j</td>
                                            <td>k</td>
                                            <td>l</td>
                                            <td>a</td>
                                            <td>b</td>
                                            <td>c</td>
                                            <td>d</td>
                                            <td>e</td>
                                            <td>f</td>
                                            <td>g</td>
                                            <td>h</td>
                                            <td>i</td>
                                            <td>j</td>
                                            <td>k</td>
                                            <td>l</td>
                                        </tr>
                                        <tr>
                                            <td>a</td>
                                            <td>b</td>
                                            <td>c</td>
                                            <td>d</td>
                                            <td>e</td>
                                            <td>f</td>
                                            <td>g</td>
                                            <td>h</td>
                                            <td>i</td>
                                            <td>j</td>
                                            <td>k</td>
                                            <td>l</td>
                                            <td>a</td>
                                            <td>b</td>
                                            <td>c</td>
                                            <td>d</td>
                                            <td>e</td>
                                            <td>f</td>
                                            <td>g</td>
                                            <td>h</td>
                                            <td>i</td>
                                            <td>j</td>
                                            <td>k</td>
                                            <td>l</td>
                                        </tr>
                                        <tr>
                                            <td>a</td>
                                            <td>b</td>
                                            <td>c</td>
                                            <td>d</td>
                                            <td>e</td>
                                            <td>f</td>
                                            <td>g</td>
                                            <td>h</td>
                                            <td>i</td>
                                            <td>j</td>
                                            <td>k</td>
                                            <td>l</td>
                                            <td>a</td>
                                            <td>b</td>
                                            <td>c</td>
                                            <td>d</td>
                                            <td>e</td>
                                            <td>f</td>
                                            <td>g</td>
                                            <td>h</td>
                                            <td>i</td>
                                            <td>j</td>
                                            <td>k</td>
                                            <td>l</td>
                                        </tr>
                                        <tr>
                                            <td>a</td>
                                            <td>b</td>
                                            <td>c</td>
                                            <td>d</td>
                                            <td>e</td>
                                            <td>f</td>
                                            <td>g</td>
                                            <td>h</td>
                                            <td>i</td>
                                            <td>j</td>
                                            <td>k</td>
                                            <td>l</td>
                                            <td>a</td>
                                            <td>b</td>
                                            <td>c</td>
                                            <td>d</td>
                                            <td>e</td>
                                            <td>f</td>
                                            <td>g</td>
                                            <td>h</td>
                                            <td>i</td>
                                            <td>j</td>
                                            <td>k</td>
                                            <td>l</td>
                                        </tr>
                                        <tr>
                                            <td>a</td>
                                            <td>b</td>
                                            <td>c</td>
                                            <td>d</td>
                                            <td>e</td>
                                            <td>f</td>
                                            <td>g</td>
                                            <td>h</td>
                                            <td>i</td>
                                            <td>j</td>
                                            <td>k</td>
                                            <td>l</td>
                                            <td>a</td>
                                            <td>b</td>
                                            <td>c</td>
                                            <td>d</td>
                                            <td>e</td>
                                            <td>f</td>
                                            <td>g</td>
                                            <td>h</td>
                                            <td>i</td>
                                            <td>j</td>
                                            <td>k</td>
                                            <td>l</td>
                                        </tr>
                                        <tr>
                                            <td>a</td>
                                            <td>b</td>
                                            <td>c</td>
                                            <td>d</td>
                                            <td>e</td>
                                            <td>f</td>
                                            <td>g</td>
                                            <td>h</td>
                                            <td>i</td>
                                            <td>j</td>
                                            <td>k</td>
                                            <td>l</td>
                                            <td>a</td>
                                            <td>b</td>
                                            <td>c</td>
                                            <td>d</td>
                                            <td>e</td>
                                            <td>f</td>
                                            <td>g</td>
                                            <td>h</td>
                                            <td>i</td>
                                            <td>j</td>
                                            <td>k</td>
                                            <td>l</td>
                                        </tr>
                                        <tr>
                                            <td>a</td>
                                            <td>b</td>
                                            <td>c</td>
                                            <td>d</td>
                                            <td>e</td>
                                            <td>f</td>
                                            <td>g</td>
                                            <td>h</td>
                                            <td>i</td>
                                            <td>j</td>
                                            <td>k</td>
                                            <td>l</td>
                                            <td>a</td>
                                            <td>b</td>
                                            <td>c</td>
                                            <td>d</td>
                                            <td>e</td>
                                            <td>f</td>
                                            <td>g</td>
                                            <td>h</td>
                                            <td>i</td>
                                            <td>j</td>
                                            <td>k</td>
                                            <td>l</td>
                                        </tr>
                                        <tr>
                                            <td>a</td>
                                            <td>b</td>
                                            <td>c</td>
                                            <td>d</td>
                                            <td>e</td>
                                            <td>f</td>
                                            <td>g</td>
                                            <td>h</td>
                                            <td>i</td>
                                            <td>j</td>
                                            <td>k</td>
                                            <td>l</td>
                                            <td>a</td>
                                            <td>b</td>
                                            <td>c</td>
                                            <td>d</td>
                                            <td>e</td>
                                            <td>f</td>
                                            <td>g</td>
                                            <td>h</td>
                                            <td>i</td>
                                            <td>j</td>
                                            <td>k</td>
                                            <td>l</td>
                                        </tr>
                                        <tr>
                                            <td>a</td>
                                            <td>b</td>
                                            <td>c</td>
                                            <td>d</td>
                                            <td>e</td>
                                            <td>f</td>
                                            <td>g</td>
                                            <td>h</td>
                                            <td>i</td>
                                            <td>j</td>
                                            <td>k</td>
                                            <td>l</td>
                                            <td>a</td>
                                            <td>b</td>
                                            <td>c</td>
                                            <td>d</td>
                                            <td>e</td>
                                            <td>f</td>
                                            <td>g</td>
                                            <td>h</td>
                                            <td>i</td>
                                            <td>j</td>
                                            <td>k</td>
                                            <td>l</td>
                                        </tr>
                                        <tr>
                                            <td>a</td>
                                            <td>b</td>
                                            <td>c</td>
                                            <td>d</td>
                                            <td>e</td>
                                            <td>f</td>
                                            <td>g</td>
                                            <td>h</td>
                                            <td>i</td>
                                            <td>j</td>
                                            <td>k</td>
                                            <td>l</td>
                                            <td>a</td>
                                            <td>b</td>
                                            <td>c</td>
                                            <td>d</td>
                                            <td>e</td>
                                            <td>f</td>
                                            <td>g</td>
                                            <td>h</td>
                                            <td>i</td>
                                            <td>j</td>
                                            <td>k</td>
                                            <td>l</td>
                                        </tr>
                                        <tr>
                                            <td>a</td>
                                            <td>b</td>
                                            <td>c</td>
                                            <td>d</td>
                                            <td>e</td>
                                            <td>f</td>
                                            <td>g</td>
                                            <td>h</td>
                                            <td>i</td>
                                            <td>j</td>
                                            <td>k</td>
                                            <td>l</td>
                                            <td>a</td>
                                            <td>b</td>
                                            <td>c</td>
                                            <td>d</td>
                                            <td>e</td>
                                            <td>f</td>
                                            <td>g</td>
                                            <td>h</td>
                                            <td>i</td>
                                            <td>j</td>
                                            <td>k</td>
                                            <td>l</td>
                                        </tr>
                                        <tr>
                                            <td>a</td>
                                            <td>b</td>
                                            <td>c</td>
                                            <td>d</td>
                                            <td>e</td>
                                            <td>f</td>
                                            <td>g</td>
                                            <td>h</td>
                                            <td>i</td>
                                            <td>j</td>
                                            <td>k</td>
                                            <td>l</td>
                                            <td>a</td>
                                            <td>b</td>
                                            <td>c</td>
                                            <td>d</td>
                                            <td>e</td>
                                            <td>f</td>
                                            <td>g</td>
                                            <td>h</td>
                                            <td>i</td>
                                            <td>j</td>
                                            <td>k</td>
                                            <td>l</td>
                                        </tr>
                                        <tr>
                                            <td>a</td>
                                            <td>b</td>
                                            <td>c</td>
                                            <td>d</td>
                                            <td>e</td>
                                            <td>f</td>
                                            <td>g</td>
                                            <td>h</td>
                                            <td>i</td>
                                            <td>j</td>
                                            <td>k</td>
                                            <td>l</td>
                                            <td>a</td>
                                            <td>b</td>
                                            <td>c</td>
                                            <td>d</td>
                                            <td>e</td>
                                            <td>f</td>
                                            <td>g</td>
                                            <td>h</td>
                                            <td>i</td>
                                            <td>j</td>
                                            <td>k</td>
                                            <td>l</td>
                                        </tr>
                                        <tr>
                                            <td>a</td>
                                            <td>b</td>
                                            <td>c</td>
                                            <td>d</td>
                                            <td>e</td>
                                            <td>f</td>
                                            <td>g</td>
                                            <td>h</td>
                                            <td>i</td>
                                            <td>j</td>
                                            <td>k</td>
                                            <td>l</td>
                                            <td>a</td>
                                            <td>b</td>
                                            <td>c</td>
                                            <td>d</td>
                                            <td>e</td>
                                            <td>f</td>
                                            <td>g</td>
                                            <td>h</td>
                                            <td>i</td>
                                            <td>j</td>
                                            <td>k</td>
                                            <td>l</td>
                                        </tr>
                                        <tr>
                                            <td>a</td>
                                            <td>b</td>
                                            <td>c</td>
                                            <td>d</td>
                                            <td>e</td>
                                            <td>f</td>
                                            <td>g</td>
                                            <td>h</td>
                                            <td>i</td>
                                            <td>j</td>
                                            <td>k</td>
                                            <td>l</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

}
function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style }}
            onClick={onClick}
        >
            <img src={next} alt={next} />
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style }}
            onClick={onClick}
        >
            <img src={prev} alt={next} />
        </div>
    );
}

export default Table;