import React, { Component } from "react";
import swal from "sweetalert";
import { Redirect } from "react-router-dom";
import $ from "jquery";
import Cookies from "universal-cookie";
import MetaTags from 'react-meta-tags';
import Slider from "react-slick";
import { WhatsappShareButton, WhatsappIcon, FacebookShareButton, FacebookIcon, } from "react-share";

import ApiList from "../../config/api.js";
import CommonApi from "../../util/common_api_handler.js";
import VideoComp from "../video/video_js_test";
import ErrorMess from "../../util/error.js";
import HeaderJson from "../../util/headers.js";

import "../../stylesheet/details/movieinfo.css";
import "../../stylesheet/partials/slider-dots.css";
import "../../stylesheet/partials/slide-style.css";
import "../../stylesheet/partials/hover-slider.css";
import "../../stylesheet/details/series_slider.css";
import "../../stylesheet/partials/click.css";

const cookies = new Cookies();
var userType = cookies.get("userType");
const catalogue_type = "VIDEOS";
let userAgeMatch = [];

class MovieDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: this.props.userProfile !== null ? this.props.userProfile : "",
      watchlistFlag: false,
      userParentalAge: this.props.userProfile.age_limit != null ? this.props.userProfile.age_limit : "",
      playVideoFlag: false,
      vodSection: false,
      tvodFlag: false,
      svodFlag: false,
      cManagerVideo: "",
      mainData: this.props.description != null ? this.props.description : "",
      addCartAlready: false,
      isFavourite: this.props.description.is_favourite !== null ? this.props.description.is_favourite : "",
      isWatchList: this.props.description.is_watchlist !== null ? this.props.description.is_watchlist : "",
      height: '0px',
      overflow: 'hidden',
      redirectToPayment: false
    };
  }

 
  favourite = () => {

    const contentId = this.props.description;

    const bodyData = {
      content_id: contentId != null ? contentId.content_id : "",
      page_type: catalogue_type
    };

    const apiUrl = ApiList.current.url + ApiList.endpoints.favourite.fetchList;

    const apiHeaders = HeaderJson.header();

    CommonApi.postCall(apiUrl, apiHeaders, bodyData)
      .then((data) => {
        if (data.statusCode === 200) {
          swal({ text: ErrorMess.alertMessages.myAccount.AFL, timer: 1500 })
          this.setState({
            isFavourite: true
          })
        }
      })
  };

  disableFavourite = () => {

    const contentId = this.props.description;

    const bodyData = {
      content_id: contentId != null ? contentId.content_id : "",
      page_type: catalogue_type
    };

    const apiUrl = ApiList.current.url + ApiList.endpoints.favourite.fetchList;

    const apiHeaders = HeaderJson.header();

    CommonApi.deleteCall(apiUrl, apiHeaders, bodyData)
      .then((data) => {
        if (data.statusCode === 200) {
          swal({ text: ErrorMess.alertMessages.myAccount.RFL, timer: 1500 });
          this.setState({
            isFavourite: false
          })
        }
      })

  };
  addToWatchList = () => {
    const self = this;

    let contentId = this.props.description;

    const bodyData = {
      content_id: contentId != null ? contentId.content_id : ""
    };

    const apiUrl = ApiList.current.url + ApiList.endpoints.watchlist.watchlist;

    const apiHeaders = HeaderJson.header();

    CommonApi.postCall(apiUrl, apiHeaders, bodyData)
      .then((data) => {
        if (data.statusCode === 200) {
          self.setState({ isWatchList: true });
          self.state.isWatchList === true && swal({ text: ErrorMess.alertMessages.myAccount.AWL, timer: 1500 });
        }
      })
  };

  removeToWatchlist = () => {
    const self = this;

    let contentId = this.props.description;

    const bodyData = {
      content_id: contentId != null ? contentId.content_id : ""
    };

    const apiUrl = ApiList.current.url + ApiList.endpoints.watchlist.watchlist;

    const apiHeaders = HeaderJson.header();

    CommonApi.deleteCall(apiUrl, apiHeaders, bodyData)
      .then((data) => {
        if (data.statusCode === 200) {
          self.setState({ isWatchList: false });
          self.state.isWatchList === false && swal({ text: ErrorMess.alertMessages.myAccount.RWL, timer: 1500 });
        }
      })
  }

  choosePlan = () => {

    if (userType !== "GUEST") {
      window.removeEventListener('scroll', this.noScroll);
      this.setState({
        redirectToPayment: true
      })
    } else {

      $(".openSignIn").click();

    }

  };

  byAt = id => {

    const self = this;

    if (userType !== "GUEST") {

      let contentId = this.props.description.content_id ? this.props.description.content_id : id;

      self.addCart(contentId);

    } else {

      $(".openSignIn").click();

    }

  };

  addCart = (contentId) => {
    const self = this;

    const bodyData = {
      content_id: contentId
    };

    const apiUrl = ApiList.current.url + ApiList.endpoints.user.userCart;

    const apiHeaders = HeaderJson.header();

    CommonApi.postCall(apiUrl, apiHeaders, bodyData)
      .then((data) => {
        if (data.statusCode === 200) {
          self.props.reverseData(data);
          self.setState({
            addCartAlready: true
          })
          swal({ text: ErrorMess.alertMessages.home.ATC, timer: 1500 })
        } else if (data.statusCode === 401) {
          swal({ text: ErrorMess.alertMessages.home.AADTC, timer: 1500 })
        }
      })
  };

  noScroll = (event) => {

    window.scrollTo(0, 0);
  }
  allowScoll = (value) => {
    if (value !== null) {
      window.removeEventListener('scroll', this.noScroll);
      this.setState({
        playVideoFlag: false
      })
      this.props.callApi(this.props.description)
    }
  }
  playtrailer = (monetization, contentManger) => {
    const self = this
    if (userType === "GUEST") {

      $(".openSignIn").click();

    } else if (userType === "REGULAR") {
      if (this.state.userParentalAge) {
        if (this.props.deviceList[0]) {
          if (this.props.deviceList[0].parental_lock === true) {
            if (this.props.deviceList.length > 0) {
              if (self.state.userData.parental_lock_pin !== "") {
                this.setState({ cManagerVideo: contentManger }, function () {
                  if (userAgeMatch > self.state.userParentalAge) {
                    $('.parental-lock-pin-modal').click()
                  } else {
                    self.setState({ playVideoFlag: true });
                  }
                });
              } else {
                window.addEventListener("scroll", this.noScroll);
                this.setState({ cManagerVideo: contentManger },
                  () => {
                    this.setState({ playVideoFlag: true })
                  });
              }
            } else {
              window.addEventListener("scroll", this.noScroll);
              this.setState({ cManagerVideo: contentManger },
                () => {
                  this.setState({ playVideoFlag: true })
                });
            }
          }
          else {
            window.addEventListener("scroll", this.noScroll);
            this.setState({ cManagerVideo: contentManger }, () => {
              this.setState({ playVideoFlag: true })
            });
          }
        }
      }
      else {
        window.addEventListener("scroll", this.noScroll);
        this.setState({ cManagerVideo: contentManger }, () => {
          this.setState({ playVideoFlag: true })
        });
      }
    }
  };
  playVideo = (monetization, contentManger) => {
    const self = this;
    if (contentManger.hasOwnProperty('deviceLimitExceeded')) {
      swal({ text: "Limit of 5 devices Reached, Please Deregister Device to Continue", timer: 8500, fontSize: "12px" })
    } else {
      if (userType === "GUEST") {

        $(".openSignIn").click();

      } else if (userType === "REGULAR") {

        if (monetization.avod.is_checked === true) {
          window.addEventListener("scroll", this.noScroll);
          this.setState({ cManagerVideo: contentManger },
            () => {
              this.setState({ playVideoFlag: true })
            });

        } else if (monetization && (monetization.svod.is_checked === true || monetization.tvod.is_checked === true)) {

          if (contentManger.resolutions.length > 0) {
            //  {(cookies.get('userType') === "REGULAR" && this.props.deviceList[0] ? (this.props.deviceList[0].parental_lock === true && (self.state.userData.parental_lock_pin !== "" ? (<span className="ml-2"> {(userAgeMatch[0]) >= userParentalAge ? (<i className="fas fa-lock"></i>) : ("")} </span>) : (""))) : "")}
            if (this.state.userParentalAge) {
              if (this.props.deviceList[0]) {
                if (this.props.deviceList[0].parental_lock === true) {
                  if (this.props.deviceList.length > 0) {
                    if (self.state.userData.parental_lock_pin !== "") {
                      $(window).scrollTop(0);
                      this.setState({ cManagerVideo: contentManger }, function () {
                        if (userAgeMatch > self.state.userParentalAge) {
                          $('.parental-lock-pin-modal').click()
                        } else {
                          $(window).scrollTop(0);
                          self.setState({ playVideoFlag: true });
                          window.addEventListener("scroll", this.noScroll)

                        }
                      });
                    } else {
                      window.addEventListener("scroll", this.noScroll);
                      this.setState({ cManagerVideo: contentManger }, () => {
                        this.setState({ playVideoFlag: true })
                      });
                    }
                  } else {
                    window.addEventListener("scroll", this.noScroll);
                    this.setState({ cManagerVideo: contentManger }, () => {
                      this.setState({ playVideoFlag: true })
                    });
                  }
                }
                else {
                  window.addEventListener("scroll", this.noScroll);
                  this.setState({ cManagerVideo: contentManger }, () => {
                    this.setState({ playVideoFlag: true })
                  });
                }
              }
            } else {
              window.addEventListener("scroll", this.noScroll);
              this.setState({ cManagerVideo: contentManger }, () => {
                this.setState({ playVideoFlag: true })
              });
            }

          } else if (!(contentManger.resolutions > 0)) {
            window.addEventListener("scroll", this.noScroll);
            self.setState({
              vodSection: true,
              svodFlag: monetization.svod.is_checked,
              tvodFlag: monetization.tvod.is_checked
            })
          } else {
          }
        } else if (monetization && (monetization.svod.is_checked === false || monetization.tvod.is_checked === false)) {
          self.setState({
            vodSection: true,
            svodFlag: monetization.svod.is_checked,
            tvodFlag: monetization.tvod.is_checked
          })
        }
      }
    }
  };
  closeVodSection = () => {
    const self = this;
    window.removeEventListener('scroll', this.noScroll);
    self.setState({
      vodSection: false
    });
  };

  shareWhat = (description) => {
    var text = description.content_name;
    var url = description.base_url + "/" + description.content_manager_folder + "/" + description.content_id + "/" + description.thumbnail_folder + "/" + description.banner_folder + "/" + description.thumbnail_16_7 + "/" + description.platform_images.thumbnails_16_7
    var message =  encodeURIComponent(text) + " - " +encodeURIComponent(url);
    var whatsapp_url = "https://wa.me/?text="+ message;
    window.location.href = whatsapp_url;
  }

  changeSeason = () => {
    var e = document.getElementById("seasonDrop");
    var strUser = e.options[e.selectedIndex].value;
    window.location.href = "/detail/" + strUser;
  };

  listSeriesRedirect = () => {
    var e = document.getElementById("seasonDrop");
    var strUser = e.options[e.selectedIndex].value;
    window.location.href = "/content/series/" + strUser;
  };

  playNext = () => {
    const self = this;
    this.setState({ playVideoFlag: false });

    const mainD = this.props.description ? this.props.description : "";
    for (var i = 0; i < mainD.content_manager.length; i++) {
      if (mainD.content_manager[i]._id === self.state.cManagerVideo._id) {
        const nextData = mainD.content_manager[i + 1] ? mainD.content_manager[i + 1]._id : "";
        if (nextData) {
          self.setState({
            cManagerVideo: mainD.content_manager[i + 1]
          }, function () {
            self.setState({ playVideoFlag: true });
          }
          );
        }
      }
    }
  };


  verifyPin = () => {
    const pinSet = document.getElementById("reset_partitioned").value;
    let userSetPin = this.props.userProfile.parental_lock_pin;
    if (pinSet === "") {
      swal({ text: 'Please Enter Pin', timer: 1500 });
      // $(".close-modal").click();
    } else {
      if (pinSet === userSetPin) {
        $(".close-modal").click();
        this.setState({ playVideoFlag: true });
        window.addEventListener("scroll", this.noScroll)
        document.getElementById("reset_partitioned").value = ""
      } else {
        swal({ text: ErrorMess.alertMessages.myAccount.ECP, timer: 1500 });
      }
    }
  };

  
  noDataFunction=()=>{
    swal({ text: ErrorMess.alertMessages.noDataInVideo.NDF, timer: 1500 });
  }

  render() {
    const self = this;
    const { description } = this.props;
    const {
      playVideoFlag,
      isWatchList,
      userParentalAge,
      svodFlag,
      tvodFlag,
      vodSection,
      isFavourite,
      cManagerVideo
    } = this.state;
    var styleRowBgImage = null;
    if (description != null && description.length !== 0) {
      styleRowBgImage = {
        backgroundImage: "url(" + description.base_url + "/" + description.content_manager_folder + "/" + description.content_id + "/" + description.thumbnail_folder + "/" + description.banner_folder + "/" + description.thumbnail_16_7 + "/" + description.platform_images.thumbnails_16_7 + ")"
      };
      userAgeMatch.push(description.age_limit.name);
      var hours = Math.floor(description.duration / 60);

      var divisor_for_minutes = description.duration % 60;

      var obj = { hr: hours, min: divisor_for_minutes };

    }
    let option = [];
    if (description.is_series) {
      if (
        description.seriesData.length > 0 &&
        description.seriesData[0].seasons.length > 0
      ) {
        description.seriesData[0].seasons.map(item => {
          if (description.season === item.SEASON) {
          return  option.push( <option value={item.content_id} selected> Season {item.SEASON} </option> );
          } else {
          return  option.push(<option value={item.content_id} key={item.content_id}>Season {item.SEASON}</option>);
          }

        });
      }
    }
    let toScroll = "4";
    const settings = {
      className: ["ratio-4-3", "thumb-6", "series"],
      centerMode: false,
      dots: false,
      infinite: false,
      speed: 500,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      swipeToSlide: true,
      responsive: [
        {
          breakpoint: 4000,
          settings: {
            slidesToScroll: parseInt(toScroll) - 1,

            variableWidth: true,
            adaptiveHeight: false
          }
        },
        {
          breakpoint: 2000,
          settings: {
            slidesToScroll: parseInt(toScroll) - 1,

            variableWidth: true,
            adaptiveHeight: false
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToScroll: parseInt(toScroll) - 1,
            variableWidth: true,
            adaptiveHeight: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: true,
            adaptiveHeight: true,
            dots: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: true,
            adaptiveHeight: true,
            dots: false
          }
        }
      ]
    };

    return (
      <div>
        <div className="container-fluid gemplex-regular video-background-section hide-component">
          {playVideoFlag ? (
            <div className="container-fluid" id="video_section">
              <div className="row justify-content-center ">
                {cManagerVideo ? (
                  <div className="text-center video-detail-heading-section">
                    {cManagerVideo.title ? cManagerVideo.title : null}
                  </div>
                ) : null}
              </div>
              {cManagerVideo ? (
                <div id="myvideo">
                  <VideoComp
                    videoUrl={`${description.base_url_media}/${cManagerVideo.media_manager_id}/`}
                    contentId={description.media_manager_id}
                    video_params={cManagerVideo}
                    playingTime={cManagerVideo.last_min_watched}
                    nextVideo={this.playNext}
                    srtBaseUrl={description.bucket_base_url + "/" + description.putfolder + "/"}
                    poster={description.base_url + "/" + description.media_manager_id + "/" + description.thumbnail_folder + "/" + description.platform_images.thumbnails_16_7}
                    hideAll={self.hideContent}
                    scroll={this.allowScoll}
                  />
                </div>
              ) : null}
            </div>
          ) : null}
          {(vodSection) ? (
            <div className="row justify-content-center align-items-center t-vod-false primary-container-padding position-relative" style={styleRowBgImage} >
              <div className="position-absolute vod-layer" />
              <i className="fa fa-times close-vod cursor" onClick={this.closeVodSection} aria-hidden="true" />
              {svodFlag ? (
                <div className="col-xl-4 col-md-4 col-sm-8 col-12 text-center primary-container-padding t-vod-vod-section">
                  <div>
                    <div className=" text-1 mb-3">
                      Get premium membership now
                    </div>
                    <div className=" cursor text-3 mt-2">
                      {this.state.redirectToPayment === true && <Redirect push to={{pathname: "/payment"}} />}
                      <div onClick={this.choosePlan}>Choose your Plan</div>
                    </div>
                    <div className="hr-line" />
                    <div className="text-2">
                      Existing member? Please logout and sign in again to
                      refresh
                    </div>
                  </div>
                </div>
              ) : null}
              {tvodFlag ?
                <div className="col-xl-4 col-md-4 col-sm-8 col-12 text-center primary-container-padding t-vod-vod-section">
                  <div className="text-1 mb-3">
                    You can also Rent this video
                       </div>
                  <div className="cursor text-3 mt-2">
                    <div className="pricing-div" onClick={this.byAt}>
                      Rent for <i className="fa fa-rupee-sign" />
                      {description.monetization.tvod.price_inr}
                    </div>
                  </div>
                  <div className="hr-line" />
                  <div className="text-2">Available on rent for {description.monetization.tvod.validity} days</div>
                </div>
                : null}
            </div>
          ) : null}
          {/* </div> */}

          {(description) ? (
            //movie info block is start ..
            <div
              className="row my-0 py-5 position-relative movie-info-section-row"
              style={{ ...styleRowBgImage }}
            >
              <div className="position-absolute cover-gradient" />
              <div className="col primary-container-padding text-white" >
                <div className="h4  mb-1 ml-0 p-0 rentdiv-for-mobile01">
                  <p className="h2">
                    {description.content_name
                      ? description.content_name
                      : "Data not found."}
                  </p>
                  {description.monetization_flag ? (
                    <div className="premium-icon d-inline-block ml-2" alt="" />
                  ) : null}
                </div>
                <div className="d-flex">
                  <p className=" mb-0 text_opacity">
                    <span>
                      {obj ? obj.hr + " hr " + obj.min + " min " : ""}
                    </span>
                    <span className="ml-2">
                      {description.released_year
                        ? description.released_year
                        : null}
                    </span>
                    <span className="ml-2">
                      {description.age_limit
                        ? description.age_limit.name + "+"
                        : null}
                    </span>
                    {(cookies.get('userType') === "REGULAR" && this.props.deviceList[0] ? (this.props.deviceList[0].parental_lock === true && (self.state.userData.parental_lock_pin !== "" ? (userParentalAge ? (<span className="ml-2"> {(userAgeMatch[0]) >= userParentalAge ? (<i className="fas fa-lock"></i>) : (" ")} </span>) : (" ")) : (""))) : "")}
                  </p>
                  <div className="ml-auto rentlogo-for-mobile01 " style={{ padding: '0px!important' }}>
                    {description.monetization ? (
                      description.monetization.tvod.is_checked ? (
                        description.content_manager[0].resolutions.length > 0 ? null :
                          cookies.get('userType') !== 'GUEST' ? (
                            <span>
                              {this.props.userProfile.subscription_plan_order === 1 ?
                                <div className="text-center cursor t-vod-section br-5" onClick={() => self.byAt(description.content_id)}>
                                  <div className="watch-section py-1">Rent Now</div>
                                  <div className="price-section">
                                    <span className="rupee-sign">
                                      {cookies.get('country') === 'IN' ? <i className="fa fa-rupee-sign" /> : <i className="fas fa-dollar-sign" />}
                                    </span>
                                    <span className="amount">
                                      {cookies.get('country') === 'IN' ? description.monetization.tvod.price_inr : description.monetization.tvod.price_usd}
                                    </span>
                                    <div className="amount" style={{ fontSize: '12px', paddingBottom: "5px" }}>
                                      For {description.monetization.tvod.validity} days
                                    </div>
                                  </div>
                                </div>
                                : null
                              }
                            </span>
                          ) :
                            <div className="text-center cursor t-vod-section br-5" onClick={() => self.byAt(description.content_id)} >
                              <div className="watch-section py-1">Rent Now</div>
                              <div className="price-section">
                                <span className="rupee-sign">
                                  {cookies.get('country') === 'IN' ? <i className="fa fa-rupee-sign" /> : <i className="fas fa-dollar-sign" />}
                                </span>
                                <span className="amount">
                                  {cookies.get('country') === 'IN' ? description.monetization.tvod.price_inr : description.monetization.tvod.price_usd}
                                </span>
                                <div className="amount" style={{ fontSize: '12px', paddingBottom: "5px", fontWeight: 'bolder' }}>
                                  For {description.monetization.tvod.validity} days
                                </div>
                              </div>
                            </div>
                      ) : null
                    ) : null}
                  </div>
                </div>
                <div className="mt-1">
                  <div className="col-xl-6  col-12 col-sm-2 d-inline p-0">
                    <img src="https://preprod-gemplex-assets.gemplex.tv/web/play-button.svg" className="play-video-button-img mr-3 my-2 cursor" style={{ outline: "none" }}
                      onClick={() => self.playVideo(description.monetization ? description.monetization : null, description.content_manager[0] ? description.content_manager[0] : null)}
                      alt={"Play Button"}
                    />
                    {description.trailers_teaser.length > 0 ? (
                      <button className="align-middle  gemplex-regular text-white px-4 py-2 mr-3 rounded my-2 add-to-watchlist-button click cursor" style={{ outline: "none" }}
                        onClick={() => {
                          self.playtrailer(
                            description.monetization,
                            description.trailers_teaser[0]
                          )
                        }}
                      >
                        Watch Trailer
                      </button>
                    ) : (
                        <button
                          className="align-middle  gemplex-regular text-white px-4 py-2 mr-3 rounded my-2 add-to-watchlist-button click cursor" style={{ outline: "none", border: "1px solid #8e9092" }} onClick={this.noDataFunction} >
                          Watch Trailer
                      </button>
                      )}
                    {cookies.get("userType") !== "GUEST" ? (
                      <span>
                        {isWatchList === false ?
                          <img src={"https://preprod-gemplex-assets.gemplex.tv/web/Add to watch list icon.svg"} className="play-video-button-img mr-3 my-2 ml-3 cursor" style={{ outline: "none", width: "22px" }} onClick={this.addToWatchList} alt="" />
                          :
                          <img src={"https://preprod-gemplex-assets.gemplex.tv/web/static/remove+from+watchlist+(1).svg"} className="play-video-button-img mr-3 my-2 ml-3 cursor" style={{ outline: "none", width: "22px" }} onClick={this.removeToWatchlist} alt="" />
                        }
                      </span>
                    ) : null}
                  </div>
                  <div className="col-xl-1 col-sm-2 d-inline p-0">
                    {cookies.get("userType") === "GUEST" ? (
                      null
                    ) : (
                        <span>
                          {isFavourite === false ?
                            <img src="https://preprod-gemplex-assets.gemplex.tv/web/like.svg" style={{ width: "22px" }} alt="Gemplex Like Empty" id="api_flag_unlike" className="mx-2 cursor" onClick={this.favourite} />
                            :
                            <img src="https://preprod-gemplex-assets.gemplex.tv/web/like-fill.svg" style={{ width: "22px" }} alt="Gemplex Like Fill" id="api_flag_like" className="mx-2 cursor" onClick={this.disableFavourite} />
                          }
                        </span>
                      )}
                  </div>
                  <div className="col-xl-1 col-sm-2 d-inline p-0">
                    <div className="btn-group dropup">
                      <button type="button" style={{ background: "transparent", outline: "none" }} className="border-0 dropdown-toggle cursor" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <img src="https://preprod-gemplex-assets.gemplex.tv/web/share-filled.svg" onClick={this.openSharePanel} alt="share" style={{ width: "1.2rem" }} />
                      </button>
                      <div className="dropdown-menu p-2 click text-center" style={{ minWidth: 50 }}>
                       
                         
                        <WhatsappShareButton
                          url={window.location.href}
                          title={description.content_name}
                          image={description.base_url + "/" + description.content_manager_folder + "/" + description.content_id + "/" + description.thumbnail_folder + "/" + description.banner_folder + "/" + description.thumbnail_16_7 + "/" + description.platform_images.thumbnails_16_7}
                          className="m-2 cursor">
                          <WhatsappIcon size={32} round={true} />
                        </WhatsappShareButton>
                        
                        <FacebookShareButton
                          url={window.location.href}
                          title={description.content_name}
                          image={description.base_url + "/" + description.content_manager_folder + "/" + description.content_id + "/" + description.thumbnail_folder + "/" + description.banner_folder + "/" + description.thumbnail_16_7 + "/" + description.platform_images.thumbnails_16_7}
                          className="m-2 cursor">
                          <FacebookIcon size={32} round={true} />
                        </FacebookShareButton>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row  mt-4">
                  <div className="col-xl-12">
                    <small style={{ color: "#9e9e9e" }}>
                      By playing a video, you agree to our
                      <a
                        href="/terms-and-conditions"
                        target="_blanck"
                        className="primary1-text ml-1"
                      >
                        Terms of Use
                      </a>
                    </small>
                  </div>
                  <div className="col-xl-8 mt-3">
                    <p>
                      {description.long_description
                        ? description.long_description
                        : null}
                    </p>
                  </div>
                </div>

                {description.crew && description.crew.length > 0 ? (
                  <div className="row justify-content-start mt-2 px-3">
                    <div className="d-inline-block text_opacity" style={{ width: 170 }} >
                      Director
                    </div>
                    {description.crew.map(function (crew, crew1) {
                      return (
                        <span key={crew._id}>
                          {crew1 === 0 ? (
                            <span>
                              {crew.designation_name === "Director " ? (
                                <a
                                  href={"/cast/" + crew.people_id}
                                  className="primary2-text mr-1 ml-0"
                                >
                                  {crew.name}
                                </a>
                              ) : null}
                            </span>
                          ) : (
                              <span>
                                {crew.designation_name === "Director " ? (
                                  <a
                                    href={"/cast/" + crew.people_id}
                                    className="primary2-text mr-1 ml-0"
                                  >
                                    {crew.name}
                                  </a>
                                ) : null}
                              </span>
                            )}
                        </span>
                      );
                    })}
                  </div>
                ) : null}

                {description.cast ? (
                  <div className="row justify-content-start mt-1 px-3">
                    <div className="d-inline-block text_opacity" style={{ width: 170 }}>
                      Starring
                    </div>
                    {description.cast.map(function (starring, is) {
                      return is === 0 ? (
                        <a href={"/cast/" + starring.people_id} className="primary2-text mr-1 ml-0" key={starring.people_id} >
                          {starring.is_star ? starring.name : null}
                        </a>
                      ) : (
                          <a
                            href={"/cast/" + starring.people_id}
                            className="primary2-text mr-1 ml-0"
                            key={starring.people_id}
                          >
                            {starring.is_star ? starring.name : null}
                          </a>
                        );
                    })}
                  </div>
                ) : null}
                {description.genre ? (
                  <div className="row justify-content-start px-3 mt-1 ">
                    <div
                      className="d-inline-block text_opacity"
                      style={{ width: 170 }}
                    >
                      Genres
                    </div>
                    <div className="">
                      {description.genre.map((item, i) => {
                        return (
                          <span className="" key={item.name}>
                            {i === 0 ? item.name : " ,  " + item.name}
                          </span>
                        );
                      })}
                    </div>
                  </div>
                ) : null}
                {/* {description.content_manager ? (description.content_manager[0].media_manager.srt>0?
                <div className="row justify-content-start mt-1 px-3">
                  <div className="d-inline-block text-secondary" style={{ width: 170 }} >
                    Subtitles    </div>
                  <div className="">
                    {description.content_manager[0].media_manager.srt.map(function (item, i) {
                      return (
                        <span>{i == 0 ? item.language_name : ' ,  ' + item.language_name}</span>
                      )
                    })}
                  </div>
                </div> : null) : null}
              {description.content_manager ?
                <div className="row justify-content-start px-3 mt-1">
                  <div className="d-inline-block text-secondary" style={{ width: 170 }} >
                    Audio Languages    </div>
                  <div className="">
                    {description.content_manager[0].media_manager.audio.map((item, i) => {
                      return (
                        <span>{i == 0 ? item.language_name + (item.sound_type == '5.1' ? '[5.1]' : '') : ' ,  ' + item.language_name + (item.sound_type == '5.1' ? '[5.1]' : '')}   </span>
                      )
                    })}
                  </div>
                </div>
                : null} */}
              </div>
              {/* left image */}
              {/* <div className="col-xl-2 col-sm-12 mt-5  align-right " id="adimage">
              <img src="https://creative-ads.org/wp-content/uploads/2017/09/Pizza-Hut-Mobile-ordering-ad.jpg" className="img-fluid" alt="" />
            </div> */}
            </div>
          ) : (
              "Data not found."
            )}
        </div>

        {/* series section */}
        {(description.is_series === true) ? (
          <div className="has-animation animate-on-hover container-fluid  primary-container-padding my-4 series-section hide-component">
            <div className="row mb-3 justify-content-between">
              <div className="col-xl-5 season-dropdown">
                <div className="rail-select d-inline-block">
                  <div className="select-side">
                    <div className="downArrow" alt="" />
                  </div>
                  <select className="form-control" id="seasonDrop" onChange={this.changeSeason} >
                    {option ? option : null}
                  </select>
                </div>
                <div className="word_space d-inline-block ml-2">
                  <div className=" text-secondary gemplex-regular sub-heading" id="view" >
                    Episodes ({description.content_manager.length})
                  </div>
                </div>
              </div>
              <div className="col-xl-auto my-auto">
                <div className="ml-auto">
                  <span onClick={this.listSeriesRedirect} className="primary2-text cursor" >
                    See all
                  </span>
                </div>
              </div>
              <div className="col-xl-12">
                <div className="border-series-slider" />
              </div>
            </div>

            {description.content_manager ? (
              <Slider {...settings}>
                {description.content_manager.map(function (item, i) {
                  return (
                    <div className="item cursor" onClick={() => self.playVideo(description.monetization, item) } key={item.display_order} >
                      <div className="item-content position-realtive h-100">
                        <div className="image w-100 h-100">
                          <img
                            src={ description.base_url + "/" + description.content_manager_folder + "/" + description.content_id + "/" + description.thumbnail_folder + "/" + description.media_folder + "/" + description.thumbnail_16_9 + "/" + item.platform_images.thumbnails_16_9 }
                            className="slide-img h-100 w-100 hover-card"
                            alt={item.title}
                          />
                        </div>
                        <img src="https://preprod-gemplex-assets.gemplex.tv/web/play-button.svg" className="play-video-button-img" alt="video-play" />
                        <div className="play-button-content" style={{ outline: "none" }} />
                        <div className="series-number">
                          {item.display_order.toString().length === 1 ? "0" + item.display_order : item.display_order}
                        </div>
                      </div>
                      <div className="d-inline-block serires-name mt-2" style={{ width: "100%" }} >
                        <div className="gemplex-bold text-truncate primary2-text">
                          {item.title}
                        </div>
                        <div className="thumb-details-div mb-2">
                          <span className="mr-1">
                            {item.duration ? item.duration > 60 ? Math.floor(item.duration / (60 * 60)) + " hr " + Math.floor((item.duration % (60 * 60)) / 60) + " min " : item.duration + "min" : ""}
                          </span>
                          <span className="mx-1">
                            {description.released_year ? description.released_year : ""}
                          </span>
                          <span className="mx-1">
                            {description.age_limit ? description.age_limit.name + "+"  : ""}
                          </span>
                        </div>
                        <div className="short-disc">
                          {item.short_desciption ? item.short_desciption : ""}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            ) : (
                ""
              )}
            {/* <div className="col-xl-12 p-0">
              <div className="border-series-slider" />
            </div> */}
          </div>
        ) : (
            ""
          )}
        {(description.trailers_teaser !== "") ? (
          <div className="has-animation animate-on-hover container-fluid  primary-container-padding my-4 series-section hide-component">
            {description.trailers_teaser.length > 0 ? (
              <div
                className="d-inline-block primary1-text gemplex-medium sub-heading"
                id="view"
              >
                Trailer
              </div>
            ) : (
                ""
              )}
            {description.trailers_teaser ? (
              <Slider {...settings}>
                {description.trailers_teaser.map(function (item, i) {
                  return (
                    <div
                      className="item cursor"
                      onClick={() =>
                        self.playVideo(description.monetization, item)
                      }
                      key={item.display_order}
                    >
                      <div className="item-content position-realtive h-100">
                        <div className="image w-100 h-100">
                          <img
                            src={
                              description.base_url +
                              "/" +
                              description.content_manager_folder +
                              "/" +
                              description.content_id +
                              "/" +
                              description.thumbnail_folder +
                              "/" +
                              description.trailers_folder +
                              "/" +
                              description.thumbnail_16_9 +
                              "/" +
                              item.platform_images.thumbnails_16_9
                            }
                            className="slide-img h-100 w-100 hover-card"
                            alt={" "}
                          />
                        </div>
                        <img
                          src="https://preprod-gemplex-assets.gemplex.tv/web/play-button.svg"
                          className="play-video-button-img "
                          alt=""
                        />
                        <div
                          className="play-button-content"
                          style={{ outline: "none" }}
                        />
                      </div>
                      <div
                        className="d-inline-block  serires-name mt-2"
                        style={{ width: "100%" }}
                      >
                        <div className="gemplex-bold text-truncate primary2-text">
                          {item.title}
                        </div>
                        <div className="thumb-details-div mb-2">
                          <span>
                            {item.duration
                              ? item.duration > 60
                                ? Math.floor(item.duration / (60 * 60)) +
                                "hr " +
                                Math.floor((item.duration % (60 * 60)) / 60) +
                                "min"
                                : item.duration + "sec"
                              : ""}
                          </span>
                          <span className="ml-2">
                            {item.released_year ? item.released_year : ""}
                          </span>
                          <span className="ml-2">
                            {item.age_limit ? item.age_limit.name + "+" : ""}
                          </span>
                        </div>
                        <div className="short-disc text-truncate">
                          {item.short_desciption ? item.short_desciption : ""}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            ) : (
                ""
              )}
            {/* <div className="col-xl-12 p-0">
              <div className="border-series-slider" />
            </div> */}
          </div>
        ) : (
            ""
          )}
        {(description.extras !== "") ? (
          <div className="has-animation animate-on-hover container-fluid  primary-container-padding my-4 series-section hide-component">
            {description.extras.length > 0 ? (
              <div
                className="d-inline-block primary1-text gemplex-medium sub-heading"
                id="view"
              >
                Extras
              </div>
            ) : (
                ""
              )}
            {description.extras ? (
              <Slider {...settings}>
                {description.extras.map(function (iteme, i) {
                  return (
                    <div
                      className="item cursor"
                      onClick={() =>
                        self.playVideo(description.monetization, iteme)
                      }
                      key={iteme.display_order}
                    >
                      <div className="item-content position-realtive h-100">
                        <div className="image w-100 h-100">
                          <img
                            src={
                              description.base_url +
                              "/" +
                              description.content_manager_folder +
                              "/" +
                              description.content_id +
                              "/" +
                              description.thumbnail_folder +
                              "/" +
                              description.extras_folder +
                              "/" +
                              description.thumbnail_16_9 +
                              "/" +
                              iteme.platform_images.thumbnails_16_9
                            }
                            className="slide-img h-100 w-100 hover-card"
                            alt={" "}
                          />
                        </div>
                        <img
                          src="https://preprod-gemplex-assets.gemplex.tv/web/play-button.svg"
                          className="play-video-button-img "
                          alt=""
                        />
                        <div
                          className="play-button-content"
                          style={{ outline: "none" }}
                        />
                      </div>
                      <div
                        className="d-inline-block  serires-name mt-2"
                        style={{ width: "100%" }}
                      >
                        <div className="gemplex-bold text-truncate primary2-text">
                          {iteme.title}
                        </div>
                        <div className="thumb-details-div mb-2">
                          <span>
                            {iteme.duration
                              ? iteme.duration > 60
                                ? Math.floor(iteme.duration / (60 * 60)) +
                                "hr " +
                                Math.floor(
                                  (iteme.duration % (60 * 60)) / 60
                                ) +
                                "min"
                                : iteme.duration + "sec"
                              : ""}
                          </span>
                          <span className="ml-2">
                            {iteme.released_year ? iteme.released_year : ""}
                          </span>
                          <span className="ml-2">
                            {iteme.age_limit ? iteme.age_limit.name + "+" : ""}
                          </span>
                        </div>
                        <div className="short-disc text-truncate ">
                          {iteme.short_desciption ? iteme.short_desciption : ""}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            ) : null}
            {/* <div className="col-xl-12 p-0">
              <div className="border-series-slider" />
            </div> */}
          </div>
        ) : (
            ""
          )}
        {(description.interviews !== "") ? (
          <div className="has-animation animate-on-hover container-fluid  primary-container-padding my-4 series-section hide-component">
            {description.interviews.length > 0 ? (
              <div
                className="d-inline-block primary1-text gemplex-medium sub-heading"
                id="view"
              >
                Interviews
              </div>
            ) : (
                ""
              )}
            {description.interviews ? (
              <Slider {...settings}>
                {description.interviews.map(function (item, i) {
                  return (
                    <div
                      className="item cursor"
                      onClick={() =>
                        self.playVideo(description.monetization, item)
                      }
                    >
                      <div className="item-content position-realtive h-100">
                        <div className="image w-100 h-100">
                          <img
                            src={
                              description.base_url +
                              "/" +
                              description.content_manager_folder +
                              "/" +
                              description.content_id +
                              "/" +
                              description.thumbnail_folder +
                              "/" +
                              description.interviews_folder +
                              "/" +
                              description.thumbnail_16_9 +
                              "/" +
                              item.platform_images.thumbnails_16_9
                            }
                            className="slide-img h-100 w-100 hover-card"
                            alt={" "}
                          />
                        </div>
                        <img
                          src="https://preprod-gemplex-assets.gemplex.tv/web/play-button.svg"
                          className="play-video-button-img"
                          alt=""
                        />
                        <div
                          className="play-button-content"
                          style={{ outline: "none" }}
                        />
                      </div>
                      <div
                        className="d-inline-block  serires-name mt-2"
                        style={{ width: "100%" }}
                      >
                        <div className="gemplex-bold text-truncate primary2-text">
                          {item.title}
                        </div>
                        <div className="thumb-details-div mb-2">
                          <span>
                            {item.duration
                              ? item.duration > 60
                                ? Math.floor(item.duration / (60 * 60)) +
                                "hr " +
                                Math.floor((item.duration % (60 * 60)) / 60) +
                                "min"
                                : item.duration + "min"
                              : ""}
                          </span>
                          <span className="ml-2">
                            {item.released_year ? item.released_year : ""}
                          </span>
                          <span className="ml-2">
                            {item.age_limit ? item.age_limit.name + "+" : ""}
                          </span>
                        </div>
                        <div className="short-disc">
                          {item.short_desciption ? item.short_desciption : ""}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            ) : (
                ""
              )}
            {/* <div className="col-xl-12 p-0">
              <div className="border-series-slider" />
            </div> */}
          </div>
        ) : (
            ""
          )}
        <span>
          <span
            className="d-inline-block parental-lock-pin-modal"
            data-toggle="modal"
            data-target="#parental_lock_modal"
            style={{ margin: "0 0 0 10px", cursor: "pointer" }}
          >
            {" "}
          </span>
        </span>

        <div
          className="modal fade"
          id="parental_lock_modal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered " role="document">
            <div className="modal-content" style={{ color: "black" }}>
              <div className="modal-body ">
                <button
                  style={{
                    position: "absolute",
                    right: "1.5rem",
                    outline: "none"
                  }}
                  type="button"
                  className="close-modal"
                  data-dismiss="modal"
                >
                  &times;
                </button>
                <div className="row justify-content-center align-items-center primary-container-padding position-relative">
                  <div className="position-absolute vod-layer" />
                  <div className="col-xl-12 col-md-12 col-sm-8 col-12 text-center primary-container-padding ">
                    <div className="container" style={{ padding: "1rem" }}>
                      <p>Please Enter Your 4 Digit Pin</p>
                      <div className="" id="reset_hidden_field">
                        <div id="divOuter" className="ml-5">
                          <div id="divInner">
                            <input
                              id="reset_partitioned"
                              type="password"
                              className="outline check-pin"
                              maxLength="4"
                            />
                            {/* INPUT TYPE NUMBER FOR HIDE PASSWORD */}
                          </div>
                        </div>
                      </div>
                      <button
                        type="button"
                        className="btn btn-sm mt-4 btn-primary"
                        onClick={self.verifyPin}
                      >
                        Confirm
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none" }}
      onClick={onClick}
    >
      <img src="https://preprod-gemplex-assets.gemplex.tv/web/scroll-arrow-to-right.svg" alt="next" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none" }}
      onClick={onClick}
    >
      <img src="https://preprod-gemplex-assets.gemplex.tv/web/scroll-arrow-to-left.svg" alt="next" />
    </div>
  );
}
export default MovieDetail;
