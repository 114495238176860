import React,{useState} from 'react'
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'

function DatePick(props) {

    const [selectedDate,setSelectedDate]=useState(null)
    const today = moment();
const disableFutureDt = current => {
  return current.isBefore(today)
}
 

//    const userDatas=[];
//    const dobs=""
    return (
        <div>
            <DatePicker 
            selected={selectedDate} 
            // selected={props.input.value ? moment(props.input.value, 'yyyy-MM-dd') : moment()}
            onChange={date=>setSelectedDate(date)}
            dateFormat="yyyy-MM-dd"
            placeholderText="YYYY-MM-DD"
            onKeyDown={(e) => e.preventDefault()} 
            id={props.id?props.id:"datepicker"}
              className={props.className ? props.className:""}
              defaultValue={props.defaultValue}
              max={props.max}
              isValidDate={disableFutureDt}
        />
        </div>
    )
}

export default DatePick;