import React, { Component } from 'react';

import ApiList from "../../config/api.js";
import CommonApi from "../../util/common_api_handler.js";
import CartLisiting from "./CartListing.jsx";
import Footer from "../partials/footer.jsx";
import HeaderJson from "../../util/headers.js";
import Header from "../partials/header";
import Navbar from "../partials/NavigationBar.jsx";
import PageJourny from "../../util/page_journey.js";
import RegenrateToken from "../../util/regenrateToken.js";

import GemLogo from "../../images/static/Gemplex Loader.gif"


export default class CartIndex extends Component {
    constructor(props) {
        super(props)
        this.state = {
            renderingStatus: false, 
            passNavigation: '',
            mainData:[]
        }
    }
    componentDidMount() {
        const self = this
        PageJourny.setDeviceInfo()
            .then((data) => {
                self.fetchLanguage()
            });
    }
    fetchLanguage = () => {
        const self = this;

        const apiUrl = ApiList.current.url + ApiList.endpoints.user.userProfile;

        const apiHeaders = HeaderJson.header();

        CommonApi.getCall(apiUrl, apiHeaders)
            .then((data) => {
                if (data.statusCode === 200) {
                    this.setState({
                        mainData: data.data,
                        renderingStatus: true
                    });
                }
            })
            .catch((err) => {
                if (err.responseJSON.status === 403 || err.responseJSON.status === 400) {
                    RegenrateToken.userRegenrateToken()
                        .then((data) => {
                            self.setState({
                                renderingStatus: true
                            });
                        })
                }
            })
    }
    removeCart = (data) => {
        let json = [];
        data.map((key) => {
            return json.push(key)
        })
        this.setState({
            passNavigation: json
        })
    }
    render() {
        const { renderingStatus,mainData ,passNavigation } = this.state
        return (
            <div>
                {
                    renderingStatus === true ?
                        <div>
                            <Header />
                            <Navbar navBarData={passNavigation} />
                            <CartLisiting removeContent={this.removeCart} mainJson={mainData} contentData={this.props.location.state} />
                            <Footer />
                        </div>
                        :
                        <img src={GemLogo} alt="" style={{ top: "50%", left: "50%", position: "absolute", transform: "translate(-50%,-50%)" }} />
                }
            </div>
        )
    }
}