import React, { Component } from 'react';
import ApiList from "../../config/api.js";
import CommonApi from "../../util/common_api_handler.js"
import Navbar from '../partials/NavigationBar.jsx';
import HeaderJson from '../../util/headers.js';
import PageJourny from "../../util/page_journey.js";
import Footer from "../partials/footer";
import Watchlist from './WatchlistPage.jsx'
import Favourites from './FavouritePage.jsx';
import RegenrateToken from "../../util/regenrateToken.js";

import '../../stylesheet/watchlist_favourite/index.css'


export default class Watchlist_Favourite extends Component {
    constructor() {
        super();
        this.state = {
            switchFlag: true,
        }
    }

    componentDidMount() {
        PageJourny.setDeviceInfo()
    }

    favourites = () => {
        const self = this;
        const apiUrl = ApiList.current.url + ApiList.endpoints.favourite.fetchList;
        const apiHeaders = HeaderJson.header();
        CommonApi.getCall(apiUrl, apiHeaders)
            .then((data)=>{
                if (data.statusCode === 200) {
                    self.setState({
                        mainVideosData: data.data ? data.data.favouritesVideosData : null,
                        mainMusicData: data.data ? data.data.favouritesMusicData : '',
                        mainHypeData: data.data ? data.data.favouritesHypeData : ''
                    });
                }
            })
            .catch(err => {
                if (err.responseJSON.status === 403 || err.responseJSON.status === 400) {
                    RegenrateToken.userRegenrateToken()
                }
            })
    }

    switchHandler = () => {
        const flagVar = this.state.switchFlag; 
        this.setState({ switchFlag: false })
    }

    switchFavHandler = () => {
        const flagVar = this.state.switchFlag; 
        this.setState({ switchFlag: true })
    }
    render() {
        return (
            <>
                <Navbar />
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className='col-md-10 pt-4 pl-2'>
                            <button type="button" className={this.state.switchFlag ? 'btn-secondary mx-2 btn' : 'btn-primary mx-2 btn'} style={{ outline: 'none', boxShadow: 'none' }} onClick={this.switchHandler}>Watchlist</button>
                            <button type="button" className={this.state.switchFlag ? 'btn-primary mx-2 btn' : 'btn-secondary mx-2 btn'} style={{ outline: 'none', boxShadow: 'none' }} onClick={this.switchFavHandler}>Favourites</button>
                        </div>
                    </div>
                </div>
                {this.state.switchFlag === true ? <Favourites /> : <Watchlist />}
                <Footer />
            </>
        )
    }

}