import React, { Component } from 'react';

import "../../stylesheet/information_page/faqs.css"
export default class FAQ extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapse: this.props.getData
        }
    }

    componentDidMount() {

    }
    render() {
        return (
            <div>
                {this.props.getData ?
                    <div className="container">
                        <div className="mt-3 catalogue_name primary3-text" >FAQ</div>
                        <div id="accordionExample" className={'py-3 accordion form-group'}>
                            {this.props.getData.collapse.map((key, i) => { 
                                return (
                                    <div className="card card-border mb-2 card-height" key={key.collapse_title}>
                                        <div className="card-header cursor" id="headingOne">
                                            <h5 className="mb-0">
                                                <div className={'p-2 card-height'} data-toggle="collapse" data-target={`#d${i}`} aria-expanded="true" aria-controls="collapseOne">
                                                    {key.collapse_title}
                                                </div>
                                            </h5>
                                        </div>
                                        <div id={"d"+i} className="collapse inner-color p-0" aria-labelledby="headingOne" data-parent="#accordionExample">
                                            <div className="card-body inner-content" style={{height:'auto'}}>
                                                {key.collapse_description}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    : ''
                }
            </div>

        )
    }

}