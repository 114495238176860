import React, { Component } from "react";
import Slider from "react-slick"; 
import $ from 'jquery';


import ApiList from "../../config/api.js";
import CommonApi from "../../util/common_api_handler.js"
import HeaderJson from '../../util/headers.js';

import '../../stylesheet/home_page/genre.css';


var prev = "https://preprod-gemplex-assets.gemplex.tv/web/scroll-arrow-to-left.svg";
var next = "https://preprod-gemplex-assets.gemplex.tv/web/scroll-arrow-to-right.svg";

class SliderCom extends Component {
  state = {
    slides: [],
    baseUrl: '',
    redirect: false,
    content : '',
    header : ''
  };

  componentDidMount = () => {
    const self = this;
    let hasScrolled = false;
    $(window).scroll(() => {
      if (window.pageYOffset > 1000 && !hasScrolled) {
        self.fetchGenre(100, 9);
        hasScrolled = true;
      }
    });
    self.fetchGenre(9, 0);
  }

  fetchGenre = (limit, offset) => {
    const self = this;

    const apiUrl = ApiList.current.url + ApiList.endpoints.genre.genre;

    const apiHeaders = HeaderJson.header();

    const bodyData = {
      "limit": limit,
      "offset": offset,
      "type": self.props.type
    }

    CommonApi.postCall(apiUrl, apiHeaders, bodyData)
      .then((data) => {
        if (data.statusCode === 200 && data.data) {
          const json_data = data.data;
          let component_arry_var = self.state.slides.slice();
          for (let i = 0; i < json_data.length; i++) {
            component_arry_var.push(json_data[i]);
          }
          self.setState({
            slides: component_arry_var,
            baseUrl: data.base_url,
          });
        }
      })
  }

  click = () => {
    const { slides } = this.state;
    this.setState({
      slides:
        slides.length === 6 ? [1, 2, 3, 4, 5, 6, 7, 8, 9] : [1, 2, 3, 4, 5, 6]
    });
  }

  sentGenreData = (content, header) => {
    const self = this;
    self.setState({
      redirect: true,
      content : content,
      header : header
    }) 
    window.location.href = '/genre/type=MOVIES/content='+content+'/heading='+header
  }

  render() {
    const self = this;
    let _class = "";
    if (this.props && this.props.slider_data) {
      if (this.props.slider_data.props.slider_data.ratio == "4:5") {
        _class = "ratio-4-5";
      } else if (this.props.slider_data.props.slider_data.ratio == "4:3") {
        _class = "ratio-4-3";
      } else if (this.props.slider_data.props.slider_data.ratio == "16:9") {
        _class = "ratio-16-9";
      }
    }
    const settings = {
      className: 'genre-slider',
      centerMode: false,
      dots: true,
      infinite: false,
      speed: 500,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      swipeToSlide: true,
      responsive: [
        {
          breakpoint: 4000,
          settings: {
            slidesToScroll: 8,
            slidesToShow: 8,
            variableWidth: true,
            adaptiveHeight: true
          }
        },
        {
          breakpoint: 2000,
          settings: {
            slidesToScroll: 8,
            slidesToShow: 8,
            variableWidth: true,
            adaptiveHeight: true
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToScroll: 8,
            slidesToShow: 8,
            variableWidth: true,
            adaptiveHeight: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: true,
            adaptiveHeight: true,
            dots: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: true,
            adaptiveHeight: true,
            dots: false
          }
        }
      ]
    };

    const { slides, baseUrl } = this.state;
    return (
      <div className="container-fluid has-animation my-4 primary-container-padding cursor">
        {slides.length !== 0 ? (
          <div className="col-md-12 mt-5 mb-2 p-0" >
            <div className="word_space ">
              <div className="d-inline-block catalogue_name text-uppercase" id="view">
                Genre
                  </div>
              <div className="behind-heading d-inline-block">
              </div>
            </div>
          </div>
        ) : null}
        <div className="container-fluid p-0">
          {self.state.slides ? (
            <Slider {...settings}>
              {self.state.slides.map((slides) => {
                return (
                  <div className="item position-relative" key={slides._id}>
                    <div className="item-genre cursor" onClick={() => self.sentGenreData(slides ? slides.code : null, slides ? slides.name : "")}>
                      <div className="genre-image w-100 h-100">
                        <img
                          src={baseUrl + "/" + slides.thumbnail_4_3}
                          className=" h-100 w-100 "
                          alt={slides.code}
                        />
                      </div>
                      <div className="genre-overlay"></div>
                      <div className="img-contain">
                        <a href="#" className="g-name">{slides.name}</a>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          ) : null}
        </div>

      </div>
    );
  }
}

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style, display: "none" }} onClick={onClick} >
      <img src={next} alt={next} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style, display: "none" }} onClick={onClick} >
      <img src={prev} alt={next} />
    </div>
  );
}

export default SliderCom;