import React, { Component } from 'react';

import "../../stylesheet/fonts/fonts.css"



export default class StepFour extends Component {
    constructor() {
        super();
        this.state = {
        }
    }
    render() {
        const style = {
            fontSize: '18px',
            backgroundImage: 'linear-gradient(to right, #0070CC , #26BDEB)',
        }
        const styleheader = {
            fontSize: '16px',
            color: '#0070CC',
            background: 'transparent',
            border: '2px solid',
            borderRadius: '.25rem',
            'border-image-source': 'linear-gradient(to right, #0070CC , #26BDEB)',
            'border-image-slice': '1',
        }
        const inputStyle = {
            border: '1px solid #9e9e9e',
            color: '#9e9e9e'

        }
        return (
            <div>
                <div class="container mt-5 gemplex-regular">
                    <div class="row justify-content-md-center ">

                        <div class="col-md-5 text-center">
                            <div> <button className="btn my-2 w-100 py-3 gemplex-thin" style={styleheader}>Enjoy your first month. It's Free</button></div>
                            <div className="my-2"> <small>Step 3 of 3 </small></div>
                            <div className="h5 my-2 mb-4 gemplex-medium text-left">Set up your credit or debit card.</div>
                            <div className="my-2 gemplex-thin"><input type="text" style={inputStyle} placeholder="First Name" className="px-4 py-3 w-100 rounded " /></div>
                            <div className="my-2 gemplex-thin"><input type="text" style={inputStyle} placeholder="Last Name" className="px-4 py-3 w-100 rounded " /></div>
                            <div className="my-2 gemplex-thin"><input type="text" style={inputStyle} placeholder="Card Number" className="px-4 py-3 w-100 rounded " /></div>
                            <div className="my-2 gemplex-thin"><input type="text" style={inputStyle} placeholder="Expiry Date(MM/YY)" className="px-4 py-3 w-100 rounded " /></div>
                            <div className="my-2 gemplex-thin"><input type="text" style={inputStyle} placeholder="Security Code (CVV)" className="px-4 py-3 w-100 rounded " /></div>
                            <div className="px-2 "> <div className="mt-3 mb-2 text-left gemplex-medium">Your Plan</div>
                                <div className="w-75 d-inline-block text-left "> <div>Unlimited Streaming in SD at</div><div>RS.500 a month after free trail.</div>  </div><div className="gemplex-medium w-25 d-inline-block text-right justify-item-center ">Change</div>
                                <div className="my-3 text-left"> <small>By ticking the tickbox, you agree to our Terms of Use, Privacy Statement, and that you are over 18. You may cancel at any time during your free trail and will not be charged. Gemplex will automatically continue your memebership at the end of your free trail and charge the membership fe (currently rs 500) to your payment method on a monthly basis until you cancel.</small> </div>
                                <div className="my-2 text-left">  <input type="checkbox" name="" id="" /> I agree </div>
                            </div>
                            <button className="btn mt-4 w-75 py-2 gemplex-bold text-white" style={style}>Continue</button>
                        </div>

                    </div>
                </div>
            </div>

        )
    }

}