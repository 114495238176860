import React, { Component } from "react";

import "../../../stylesheet/hype_page/hypetab_section.css";
import "../../../stylesheet/hype_page/hype_player.css"


export default class Player extends Component {
  constructor(props) {
    super(props);
    this.state = {
      audioUrl: this.props.audio_url ? this.props.audio_url : '',
      volume: 0.5
    };
  }

  componentDidMount=()=>{
    (function(){

      var pcastPlayers = document.querySelectorAll('.pcast-player');
      var speeds = [ 1, 1.5, 2, 2.5, 3 ]    
      
      for(var  i=0;i<pcastPlayers.length;i++) {
        var player = pcastPlayers[i];
        var audio = player.querySelector('audio');
        var play = player.querySelector('.pcast-play');
        var pause = player.querySelector('.pcast-pause');
        var progress = player.querySelector('.pcast-progress');
        var speed = player.querySelector('.pcast-speed'); 
        var currentTime = player.querySelector('.pcast-currenttime');
        var duration = player.querySelector('.pcast-duration');
        var volumeslider = player.querySelector(".volume_range")
        
        var currentSpeedIdx = 0;
    
        pause.style.display = 'none';
        
        var toHHMMSS = function ( totalsecs ) {
            var sec_num = parseInt(totalsecs, 10); // don't forget the second param
            var hours   = Math.floor(sec_num / 3600);
            var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
            var seconds = sec_num - (hours * 3600) - (minutes * 60);
    
            if (hours   < 10) {hours   = "0"+hours; }
            if (minutes < 10) {minutes = "0"+minutes;}
            if (seconds < 10) {seconds = "0"+seconds;}
            
            var time = hours+':'+minutes+':'+seconds;
            return time;
        }
        
        // eslint-disable-next-line no-loop-func
        audio.addEventListener('loadedmetadata', function(){
          progress.setAttribute('max', Math.floor(audio.duration));
          duration.textContent  = toHHMMSS(audio.duration);
        });
        
        // eslint-disable-next-line no-loop-func
        audio.addEventListener('timeupdate', function(){
          progress.setAttribute('value', audio.currentTime);
          currentTime.textContent  = toHHMMSS(audio.currentTime);
        });
        
        // eslint-disable-next-line no-loop-func
        play.addEventListener('click', function(){
          this.style.display = 'none';
          pause.style.display = 'inline-block';
          pause.focus();
          audio.play();
        }, false);

        // eslint-disable-next-line no-loop-func
        pause.addEventListener('click', function(){
          this.style.display = 'none';
          play.style.display = 'inline-block';
          play.focus();
          audio.pause();
        }, false);
     
        // eslint-disable-next-line no-loop-func
        progress.addEventListener('click', function(e){
          audio.currentTime = Math.floor(audio.duration) * (e.offsetX / e.target.offsetWidth);
        }, false);
    
        // eslint-disable-next-line no-loop-func
        speed.addEventListener('click', function(){
          currentSpeedIdx = currentSpeedIdx + 1 < speeds.length ? currentSpeedIdx + 1 : 0;
          audio.playbackRate = speeds[currentSpeedIdx];
          this.textContent  = speeds[currentSpeedIdx] + 'x';
          return true;
        }, false);
          
        // eslint-disable-next-line no-loop-func
          volumeslider.addEventListener("mousemove", function(){
           audio.volume = volumeslider.value / 100;
           var element
             if(audio.volume === 0) {
               element = document.getElementById("volumn_up")
              element.classList.remove('fa-volume-up');
              element.classList.add('fa-volume-off'); 
             }
             else{
              element  = document.getElementById("volumn_up")
             element.classList.remove('fa-volume-off');
             element.classList.add('fa-volume-up'); 
             }
            return true;
          },
          false
          );
      }
    })(this);
  }
  

  render=()=>{
    const {audioUrl} = this.state;
    return (
      <div>
        <div className="pcast-player">
          <div className="pcast-player-controls">
            <button className="pcast-play mr-1 ml-1 mt-1"><i className="fa fa-play"></i><span>Play</span></button>
            <button className="pcast-pause mr-1 ml-1 mt-1"><i className="fa fa-pause"></i><span>Pause</span></button> 
            <span className="pcast-currenttime pcast-time mr-1 ml-0">00:00</span>
            <progress className="pcast-progress mr-1 ml-1" value="0"></progress>
            <span className="pcast-duration pcast-time mr-2 ml-2">00:00</span>
            <button className="pcast-speed mr-1 ml-1">1x</button>
            <button className="pcast-mute mr-1 ml-1">
              <i className="fa fa-volume-up" id="volumn_up"></i>
              <div className="volume-range">
                <input type="range" id="volume_range" orient="vertical" className="volume_range" min='0'max='100'step='0.5'/>
              </div>
            </button>
          </div>
          {audioUrl !== '' ?  <audio src={this.props.audio_url}></audio> : null}
          </div>
      </div>
    );
  }
}
