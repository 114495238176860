import React, { Component } from "react";
import Cookies from 'universal-cookie';


import Footer from "../partials/footer";
import Navigation from "../partials/NavigationBar.jsx";
import PageJourny from "../../util/page_journey.js";
import SubPlans from "./sub_plans";

const cookies = new Cookies();

class Payment extends Component {

  componentDidMount() {
    PageJourny.setDeviceInfo()
  }

  render() {
    const userProfile = cookies.get('userId') ? cookies.get('userId') : "";
    return (
      <>
        <Navigation />
        <SubPlans user_id={userProfile} />
        <Footer />
      </>
    );
  }
}
export default Payment;