import React, { Component } from "react";
import Cookies from 'universal-cookie';
import swal from 'sweetalert';
import $ from 'jquery';
import Form from "react-validation/build/form";
import GoogleLogin from 'react-google-login';
import { OldSocialLogin as SocialLogin } from 'react-social-login'

import ApiList from "../../../config/api.js";
import ErrorMess from "../../../util/error.js";
import CommonApi from "../../../util/common_api_handler.js";
import HeaderJson from '../../../util/headers.js';
import RegenrateToken from "../../../util/regenrateToken.js";

import LogoBlack from "../../../images/logo-black.png";

import "../../../stylesheet/user/user_detail/setting.css";

const cookies = new Cookies();
const current = new Date();
const nextYear = new Date();
nextYear.setFullYear(current.getFullYear() + 1);

var filterDevice = {};
var varData = [];
var this_ref_delete_row_device
export default class Setting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headers: HeaderJson.header(),
      devices: {},
      modalOpen: true,
      parentalLimit: "",
      passwordCheck: "",
      prefillAge: '',
      deviceArrayList: [],
      userPassword: [],
      renderingStatus: false,
      parentalSetCheck: [],
      deviceListArray: [],
      socialUserId: cookies.get('userId')
    };
  }
  componentWillMount = () => {
    this.fetchData();
  }

  componentDidMount = () => {

    $(document).on('click', '.remove-device', function () {
      this_ref_delete_row_device = $(this);
    })
  }

  fetchDevice = () => {
    const apiUrl = ApiList.current.url + ApiList.endpoints.user.userDevices;
    const apiHeaders = HeaderJson.header();

    CommonApi.getCall(apiUrl, apiHeaders)
      .then((data) => {
        if (data.statusCode === 200) {
          this.setState({
            deviceArrayList: data.data[0].devices
          })
        }
      })
  }

  fetchData = () => {

    const apiUrl = ApiList.current.url + ApiList.endpoints.user.userProfile;

    const apiHeaders = HeaderJson.header();


    CommonApi.getCall(apiUrl, apiHeaders)
      .then((data) => {
        if (data.statusCode === 200) {
          filterDevice = data.data.devices ? data.data.devices.filter(item => item.parental_lock === true) : []
          filterDevice.map((key) => {
            return varData.push(key.device_id)
          })
          this.fetchDevice()
          this.setState({
            prefillAge: data.data.age_limit,
            userData: data.data.devices,
            userPassword: data.data.password,
            parentalSetCheck: data.data.parental_lock_pin,
            renderingStatus: true,
            passwordCheck: data.data.is_password_set
          }, () => {
            if (this.state.prefillAge === "none") {
              document.getElementById("all_age").classList.add("active")
            } else if (this.state.prefillAge === "7") {
              document.getElementById("all_age").classList.add("active")
              document.getElementById("ageseven").classList.add("active")
            } else if (this.state.prefillAge === "13") {
              document.getElementById("all_age").classList.add("active")
              document.getElementById("ageseven").classList.add("active")
              document.getElementById("agethirteen").classList.add("active")
            } else if (this.state.prefillAge === "16") {
              document.getElementById("all_age").classList.add("active")
              document.getElementById("ageseven").classList.add("active")
              document.getElementById("agethirteen").classList.add("active")
              document.getElementById("agesixteen").classList.add("active")
            } else if (this.state.prefillAge === "18") {
              document.getElementById("all_age").classList.add("active")
              document.getElementById("ageseven").classList.add("active")
              document.getElementById("agethirteen").classList.add("active")
              document.getElementById("agesixteen").classList.add("active")
              document.getElementById("ageeighteen").classList.add("active")
            }
          });
        }
      })
  };

  historyClear = () => {
    const apiUrl = ApiList.current.url + ApiList.endpoints.user.userClearHistory;
    const apiHeaders = HeaderJson.header();
    const bodyData = {
      "clear_search_history": true,
    }
    CommonApi.postCall(apiUrl, apiHeaders, bodyData)
      .then((data) => {
        swal({ text: data.message, timer: 1500 })
      })
  }

  validateNumber = (event) => {
    var key = window.event ? event.keyCode : event.which;
    if (event.keyCode === 8 || event.keyCode === 46) {
      return true;
    } else if (key < 48 || key > 57) {
      return false;
    } else {
      return true;
    }
  }
  // on toggle event check modal open..
  modalontoggle = () => {
    const ariavalue = document.getElementById("parental_lock").getAttribute("aria-pressed");
    if (ariavalue === "true") {
      this.state.passwordCheck === true ? $('.open-mobile-confirm-modal').click() : $('.open-socail-confirm-modal').click()
    } else {
      document.getElementById("parental_lock").classList.remove("active")
      document.getElementById("main_page").style.display = "none";
      document.getElementById("device_list").style.display = "none";
    }
  }
  // first save the pin in user Clear history api and pass the input field value in body

  savePin = () => {
    var pinLock = document.getElementById('partitioned').value;
    const apiUrl = ApiList.current.url + ApiList.endpoints.user.userClearHistory;
    const apiHeaders = HeaderJson.header();
    const bodyData = {
      "parental_lock": true,
      "parental_lock_pin": pinLock
    }
    CommonApi.postCall(apiUrl, apiHeaders, bodyData)
      .then((data) => {
        if (data.statusCode === 200) {
          this.setState({
            deviceArrayList: []
          }, () => {
            this.fetchData()
          })
          swal({ text: "Your Pin Successfully Set", timer: 1500 })
          document.getElementById("reset_pin").style.display = "block";
          document.getElementById("create_pin").style.display = "none";
        } else {
          swal({ text: ErrorMess.alertMessages.Setting.AFE, timer: 1500 })
        }
      })
  }

  savePin2 = () => {
    const newpinLock = document.getElementById('reset_partitioned_change').value;
    const apiUrl = ApiList.current.url + ApiList.endpoints.user.parentalResetPin
    const apiHeaders = HeaderJson.header();
    const bodyData = {
      "old_pin": this.state.parentalSetCheck,
      "new_pin": newpinLock
    }
    if (newpinLock !== "") {
      CommonApi.postCall(apiUrl, apiHeaders, bodyData)
        .then((data) => {
          if (data.statusCode === 200) {
            swal({ text: data.message, timer: 1500 })
            $('#reset_input_field').val('')
            document.getElementById('resetpinbtn').style.display = "block"
            document.getElementById("reset_hidden_field").style.display = "block"
            document.getElementById("reset_input_field").style.display = "none"
            document.getElementById("main_page").style.display = "block";
            document.getElementById("conferm_pin").style.display = "none";
            document.getElementById("reset_pin_btn").style.display = "block"
          }
        })
    } else {
      swal({ text: "Kindly Enter Pin", timer: 1500 })
      document.getElementById("reset_partitioned_change").style.display = "inline";
      document.getElementById("reset_hidden_field").style.display = "none";
    }
  }

  resetPin = () => {
    document.getElementById("reset_pin_btn").style.display = "none";
    document.getElementById("resetpinbtn").style.display = "none";
    document.getElementById("reset_input_field").style.display = "block"
    document.getElementById("conferm_pin").style.display = "inline";
    document.getElementById("reset_hidden_field").style.display = "none"
  }

  cancelPin = () => {
    document.getElementById("reset_pin_btn").style.display = "block";
    document.getElementById("resetpinbtn").style.display = "block";
    document.getElementById("reset_input_field").style.display = "none"
    document.getElementById("conferm_pin").style.display = "none";
    document.getElementById("reset_hidden_field").style.display = "block"
  }

  none = () => {
    const ageSeven = document.getElementById('all_age').value;
    if (document.getElementById("all_age").getAttribute("class") === "active") {
      this.setState({
        parentalLimit: 'none'
      })
      document.getElementById("ageseven").classList.remove("active");
      document.getElementById("agethirteen").classList.remove("active");
      document.getElementById("agesixteen").classList.remove("active");
      document.getElementById("ageeighteen").classList.remove("active");
      document.getElementById("ageseven").classList.remove("active");
    } else {
      this.setState({
        parentalLimit: ageSeven
      })
      document.getElementById("all_age").setAttribute("class", "active");
    }
  }

  ageseven = () => {
    const ageSeven = document.getElementById('ageseven').value;
    if (document.getElementById("ageseven").getAttribute("class") === "active") {
      this.setState({
        parentalLimit: 'none'
      })
      document.getElementById("ageseven").classList.remove("active");
      document.getElementById("agethirteen").classList.remove("active");
      document.getElementById("agesixteen").classList.remove("active");
      document.getElementById("ageeighteen").classList.remove("active");
      document.getElementById("ageseven").setAttribute("class", "active");
    } else {
      this.setState({
        parentalLimit: ageSeven
      })
      document.getElementById("ageseven").setAttribute("class", "active");
    }
  }

  agethirteen = () => {
    const ageThirteen = document.getElementById('agethirteen').value;
    const ageSeven = document.getElementById('ageseven').value;
    if (document.getElementById("agethirteen").getAttribute("class") === "active") {
      this.setState({
        parentalLimit: ageSeven
      })
      document.getElementById("agethirteen").classList.remove("active");
      document.getElementById("agesixteen").classList.remove("active");
      document.getElementById("ageeighteen").classList.remove("active");
      document.getElementById("agethirteen").setAttribute("class", "active");
      document.getElementById("ageseven").setAttribute("class", "active");
    } else {
      this.setState({
        parentalLimit: ageThirteen
      })
      document.getElementById("agethirteen").setAttribute("class", "active");
      document.getElementById("ageseven").setAttribute("class", "active");
    }
  }

  agesixteen = () => {
    const ageThirteen = document.getElementById('agethirteen').value;
    const ageSixteen = document.getElementById('agesixteen').value;
    if (document.getElementById("agesixteen").getAttribute("class") === "active") {
      this.setState({
        parentalLimit: ageThirteen
      })
      document.getElementById("agesixteen").classList.remove("active");
      document.getElementById("ageeighteen").classList.remove("active");
      document.getElementById("agesixteen").setAttribute("class", "active");
      document.getElementById("ageseven").setAttribute("class", "active");
      document.getElementById("agethirteen").setAttribute("class", "active");
    } else {
      this.setState({
        parentalLimit: ageSixteen
      })
      document.getElementById("agesixteen").setAttribute("class", "active");
      document.getElementById("ageseven").setAttribute("class", "active");
      document.getElementById("agethirteen").setAttribute("class", "active");
    }
  }

  ageeighteen = () => {
    const ageEighteen = document.getElementById('ageeighteen').value
    const ageSixteen = document.getElementById('agesixteen').value;
    if (document.getElementById("ageeighteen").getAttribute("class") === "active") {
      this.setState({
        parentalLimit: ageSixteen
      })
      document.getElementById("ageeighteen").classList.remove("active");
      document.getElementById("ageeighteen").setAttribute("class", "active");
      document.getElementById("agesixteen").setAttribute("class", "active");
      document.getElementById("ageseven").setAttribute("class", "active");
      document.getElementById("agethirteen").setAttribute("class", "active");
    } else {
      this.setState({
        parentalLimit: ageEighteen
      })
      document.getElementById("ageeighteen").setAttribute("class", "active");
      document.getElementById("agesixteen").setAttribute("class", "active");
      document.getElementById("ageseven").setAttribute("class", "active");
      document.getElementById("agethirteen").setAttribute("class", "active");
    }
  }

  handleSocialLoginFailure = err => {
    console.error(err);
  };


  responsessGoogle = (response) => {

    const loginType = "gmail"

    const apiUrl = ApiList.current.url + ApiList.endpoints.user.social;

    const apiHeaders = HeaderJson.header();

    const bodyData = {
      "gmail_id": response.w3 != null ? response.w3.Eea : '',
      "type": loginType,
    }

    CommonApi.postCall(apiUrl, apiHeaders, bodyData)
      .then((data) => {
        if (data.statusCode === 200) {
          if (cookies.get('userId') === data.data._id) {
            $('.close-modal-social').click()
            document.getElementById("parental_lock").classList.add("active")
            document.getElementById("main_page").style.display = "block"
            document.getElementById("container-progressbar").style.display = "inline";
            document.getElementById("device_list").style.display = "block";
          } else {
            swal({ text: ErrorMess.alertMessages.Setting.IPE, timer: 1500 })
            document.getElementById("parental_lock").classList.remove("active")
            document.getElementById("main_page").style.display = "none"
            document.getElementById("device_list").style.display = "none";
            document.getElementById("container-progressbar").style.display = "none";
          }
        }
      }) 
  }

  deviceList = () => {
    const self = this;
    var videoArray = [];
    $(`#device_id:checked`).each(function (i) {
      videoArray[i] = $(this).val()
    });
    self.setState({ deviceListArray: videoArray });
  }

  saveParentalLock = () => {
    const ageLimitUrl = ApiList.current.url + ApiList.endpoints.user.userClearHistory;
    const apiHeaders = HeaderJson.header();
    const selectAge = this.state.parentalLimit.toString() ? this.state.parentalLimit.toString() : this.state.prefillAge ? this.state.prefillAge : 'none';
    let bodyData = {
      "age_limit": selectAge,
    }
    const SaveDeviceUrl = ApiList.current.url + ApiList.endpoints.user.devicePin;
    let deviceBody = {
      "parental_lock_status": true,
      "device_id": (typeof this.state.deviceListArray !== 'undefined' && this.state.deviceListArray.length > 0) ? this.state.deviceListArray : []
    }
    if(selectAge === "none" && !(this.state.deviceListArray.length > 0)) {
      swal({ text: "Parental Lock Updated Successfully", timer: 1500 })
    } else if(selectAge ==="none" && (this.state.deviceListArray.length > 0)){
      swal({ text: "Please Select Age", timer: 1500 })
    } else if(selectAge !== "none" && !(this.state.deviceListArray.length > 0)){
      swal({ text: 'Please Select At Least One Device', timer: 1500 })
    }
    else {
      CommonApi.postCall(ageLimitUrl, apiHeaders, bodyData)
        .then((data) => {
          if (data.statusCode === 200) {
            CommonApi.postCall(SaveDeviceUrl, apiHeaders, deviceBody)
              .then((data) => {
                if (data.statusCode === 200) {
                  swal({ text: data.message, timer: 1500 })
                }
              })
              .catch((err) => {
                if (err.responseJSON.status_code === 403 || err.responseJSON.status_code === 400 || err.status === 403 || err.status === 400) {
                  RegenrateToken.userRegenrateToken()
                    .then((data) => {
                      $('#specific_device').click()
                    })
                }
              })
          }
        })
    }
}


  getonlynum = (event) => {
    var key = window.event ? event.keyCode : event.which;
   if ( key < 48 || key > 57 ) {
        return false;
    } else {
        return true;
    }
  }

  closemodal = () => {
    $("#parental_lock").trigger('click');
  }

  // User Password Verify Api...
  passwordVerify = () => {
    var userPassword = document.getElementById('current_password_value').value;
    if (userPassword === "") {
      swal({ text: ErrorMess.alertMessages.Setting.IPE, timer: 1500 })
    } else {
      const apiUrl = ApiList.current.url + ApiList.endpoints.user.validateParentalPassword;
      const apiHeaders = HeaderJson.header();
      const bodyData = {
        password: userPassword,
      }
      CommonApi.postCall(apiUrl, apiHeaders, bodyData)
        .then((data) => {
          if (data.statusCode === 200) {
            $('.close-modal-mobile').click()
            document.getElementById("parental_lock").classList.add("active")
            document.getElementById("main_page").style.display = "block";
            document.getElementById("container-progressbar").style.display = "inline";
            document.getElementById("device_list").style.display = "block";
          } else { 
            swal({ text: ErrorMess.alertMessages.Setting.IPE, timer: 1500 })
            document.getElementById("parental_lock").classList.remove("active")
            document.getElementById("main_page").style.display = "none"
            document.getElementById("device_list").style.display = "none";
            document.getElementById("container-progressbar").style.display = "none";
          }

        })
    }
  }

  // User Device Remove Api ...
  deviceRemove = (item) => {
    const apiUrl = ApiList.current.url + ApiList.endpoints.user.removeDevice;
    const apiHeaders = HeaderJson.header();
    const bodyData = {
      "device": item,
    }
    if (cookies.get('userBrowserVersion') !== item) {
      CommonApi.postCall(apiUrl, apiHeaders, bodyData)
        .then((data) => {
          if (data.statusCode === 200) {
            this_ref_delete_row_device.closest("tr").remove()
            swal({ text: "Device Remove Succesfully...!!", timer: 1500 })
          }
        })
    } else {
      swal({ text: "Cannot Deregister Current Device", timer: 1500 })
    }
  }

  handleSocialLogin = (user) => {
    const loginType = "facebook"
    const apiUrl = ApiList.current.url + ApiList.endpoints.user.social;
    const apiHeaders = HeaderJson.header();
    let bodyData = {
      "facebook_id": user._profile.id != null ? user._profile.id : '',
      "type": loginType
    }
    CommonApi.postCall(apiUrl, apiHeaders, bodyData)
      .then((data) => {
        cookies.remove('xAccessToken', { path: '/' });
        if (data.statusCode === 200) {
          if (cookies.get('userId') === data.data._id) {
            $('.close-modal-social').click()
            document.getElementById("parental_lock").classList.add("active")
            document.getElementById("main_page").style.display = "block"
            document.getElementById("container-progressbar").style.display = "inline";
            document.getElementById("device_list").style.display = "block";
          } else {
            swal({ text: ErrorMess.alertMessages.Setting.IPE, timer: 1500 })
            document.getElementById("parental_lock").classList.remove("active")
            document.getElementById("main_page").style.display = "none"
            document.getElementById("device_list").style.display = "none";
            document.getElementById("container-progressbar").style.display = "none";
          }
        }
      })
  }

  InputTypeNumber=(event)=>{
    var inputValueReset=document.getElementById("reset_partitioned_change");
    inputValueReset.value = inputValueReset.value.replace(/[^0-9]+/, '').trimLeft();
  }

  render() {
    const self = this
    const { parentalSetCheck } = this.state;
    return (
      <>
        {
          this.state.renderingStatus === true
            ?
            <div>

              <div className="container-fluid">
                <div className="col-md-12 pl-0">
                  <Form onSubmit={this.handleSubmit}>
                    <div className="row ">
                      <div className="col-md-12  ">
                        {/* <h6 className="setting-tab-head">Privacy Settings</h6> */}
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-12  parental-control">
                        <h5 className="head-parental color-mode01">Parental Control</h5>
                        <div className="row justify-content-between">
                          <div className="col-md-7 col-sm-8 col-12">
                            <div className="parental-para">Please select an age below</div>
                            <div className="parental-para-2">
                              Any content that is restricted for the audience above the
                              selected age would require a PIN
                            </div>
                          </div>
                          <div className="col-md-3 col-sm-4 col-12">
                            <button type="button" className="btn btn-lg btn-toggle" style={{ backgroundColor: '#26bdeb' }} id="parental_lock" onClick={this.modalontoggle} data-toggle="button" aria-pressed="false" autoComplete="off" >
                              <div className="handle"></div>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="main_page" style={{ display: 'none' }}>
                      {
                        parentalSetCheck === null ?
                          <div id="pin_age_bar_modal">
                            <div className="mt-4" id="create_pin" >
                              <div className="row mt-4">
                                <div className="col-md-12 ">
                                  <h5 className="create-pin-text pin-text-content mode-color02" style={{ fontSize: "17px" }}>Create Pin</h5>
                                </div>
                              </div>
                              <div className="row  pl-0 pr-0">
                                <div className="col-md-10 col-6">
                                  <div className=" ">
                                    <div id="divOuter">
                                      <div id="divInner">
                                        <input id="partitioned" type="password" maxLength="4" onKeyPress={this.getonlynum} />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-2  col-5 pl-0">
                                  <div style={{ paddingTop: "0.5%" }}>
                                    <button type="button" className="btn br18 gradient1-bg border-0  text-white gemplex-regular  py-1 pl-4 pr-4" id="save_pin" onClick={this.savePin}>
                                      Save
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          :
                          <div className="mt-4" id="reset_pin">
                            <div className="col-md-12 pl-0">
                              <h5 className="mode-color02">Reset Pin</h5>
                            </div>
                            <div className="row">
                              <div className="col-md-9 col-sm-10 col-6 pr-0">
                                <div id="reset_hidden_field">
                                  <div id="divOuter">
                                    <div id="divInner">
                                      <input id="reset_partitioned" type="password" maxLength="4" defaultValue={this.state.parentalSetCheck} disabled />
                                      {/* INPUT TYPE NUMBER FOR HIDE PASSWORD */}
                                    </div>
                                  </div>
                                </div>
                                <div id="reset_input_field" style={{ display: 'none' }}>
                                  <div id="divOuter">
                                    <div id="divInner">
                                      <input id="reset_partitioned_change" type="text" maxLength="4" onKeyPress={this.InputTypeNumber} />
                                      {/* INPUT TYPE NUMBER FOR HIDE PASSWORD */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-2 offset-md-1 col-sm-2 col-5 " id="resetpinbtn">
                                <div className="" style={{ paddingTop: "0.5%" }} id="reset_pin_btn">
                                  <button type="button" className="btn br18 gradient1-bg border-0  text-white gemplex-regular  py-1 pl-3 pr-3" onClick={this.resetPin}>
                                    Reset
                                  </button>
                                </div>
                              </div>
                              <div className="col-md-3" id="conferm_pin" style={{ display: "none" }}>
                                <button type="button" className="btn br18 gradient1-bg border-0 text-white gemplex-regular py-1  pl-4 pr-4" onClick={this.cancelPin}>Cancel</button>
                                <button type="button" className="btn br18 gradient1-bg border-0 ml-1 text-white gemplex-regular py-1 pl-4 pr-4" id="save_pin2" onClick={this.savePin2}>Save</button>
                              </div>
                            </div>
                          </div>
                      }
                      <div className="row mt-4" id="container-progressbar" style={{ display: "none" }}>
                        <div className="col-md-12 " >
                          <div className="" >
                            <div className="" >
                              {/* for all age .. */}
                              <div className="row mt-4">
                                <div className="col pl-0">
                                  <h6 className=" mb-0 pin-text-content mode-color02">Viewing Restrictions</h6>
                                  <p style={{ opacity: "0.7", fontFamily: 'Gemplex-light', fontSize: '14px' }} className="mb-0">Videos with these ratings require a PIN: 18+</p>
                                  <h6 className="font-weight-bold" style={{ opacity: "0.7", fontFamily: 'Gemplex-light', fontSize: '14px' }}>Tap age to set Restrictions</h6>
                                </div>
                              </div>
                              <div className="row for-all mt-4">
                                <div className="col-md-10 " >

                                  <div className="container-progressbar">
                                    <ul className="progressbar">
                                      <li className="active cursro" onClick={this.none} id="all_age" value="none">None</li>
                                      <li className="cursro" onClick={this.ageseven} value="7" id="ageseven">7+</li>
                                      <li className="cursro" onClick={this.agethirteen} value="13" id="agethirteen">13+</li>
                                      <li className="cursro" onClick={this.agesixteen} value="16" id="agesixteen">16+</li>
                                      <li className="cursro" onClick={this.ageeighteen} value="18" id="ageeighteen">18+</li>
                                    </ul>
                                  </div>
                                </div>
                                <div className="col-md-2"> </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-4" style={{ display: "none" }} id="device_list">
                      <div>
                        <div className="col-md-12  parental-control">
                          <table className="table devices-table">
                            <tbody>
                              <tr className="device-head-title">
                                <td scope="col" style={{ padding: "0px", fontFamily: "Gemplex-regular" }}>
                                  Your Restrict Devices
            </td>
                                <td scope="col"></td>
                              </tr>
                              {/* {this.tableRender()} */}

                              {self.state.deviceArrayList.map(function (item, i) {
                                return (
                                  <tr key={i}>
                                    <th>
                                      <input
                                        type="checkbox"
                                        id="device_id"
                                        className="dropdown-check styling-dropdown cursor"
                                        defaultValue={item.device_id}
                                        defaultChecked={(varData.indexOf(item.device_id) !== -1) ? 'checked' : ''}
                                        onClick={() => self.deviceList(item.device_id)}
                                        style={{ width: "20px", height: "20px" }}
                                        autoComplete="off"
                                      />
                                    </th>
                                    <th scope="row" className="register-device-name">
                                      {item.device_model}
                                    </th>
                                    <th className="remove-device cursor" onClick={() => self.deviceRemove(item.device_id)}> Deregister </th>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </table>

                        </div>
                        <div className="col-md-2 offset-md-10">
                          <span>
                            <button type="button" id="specific_device" className="btn br18 gradient1-bg border-0 text-white gemplex-regular  py-1 pl-4 pr-4 mt-4" onClick={this.saveParentalLock} > Save </button>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-md-12  parental-control">
                        <h5 className="clear-history-clas color-mode01">Clear Watch History</h5>
                        <div className="row" style={{ paddingTop: "10px" }}>
                          <div className="col-8 col-md-10">
                            <div className="parental-para">
                              Click on the button to clear your watch history
                            </div>
                          </div>
                          <div className="col-4 col-md-2">
                            <button type="button" onClick={this.historyClear} className="btn br18 gradient1-bg border-0 text-white gemplex-regular primary-container-padding py-1 user-save-detail-button py-1 pl-4 pr-4">
                              Clear
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
              <div className="modal fade password-check" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-size">
                  <div className="modal-content" style={{ overflow: "hidden" }}>
                    <div className="modal-body text-dark container-fluid position-relative">
                      <button style={{ position: "absolute", right: "1.5rem", outline: "none" }} type="button" className="close-modal" data-dismiss="modal" >
                        &times;
                      </button>
                      <div className="row text-center justify-content-center position-relative">
                        <div className="container-fluid p-0">
                          <div className="row pb-0 pt-4 pr-4 pl-4 login-logo-sections">
                            <div className="col-md-12 mb-4  position-relative">
                              <img src={LogoBlack} style={{ width: "8rem" }} alt="" />
                            </div>
                          </div>
                          <div className="row mt-2 px-4 pt-4 pb-2 text-center justify-content-center">
                            <div className="col-md-12 mt-2" >
                              <div className="form-grou  ">
                                <label htmlFor="exampleInputPassword1">Enter Password</label>
                                <input type="password" className="form-control" id="exampleInputPassword1" placeholder="Password" />
                              </div>
                            </div>
                          </div>
                          <div className="row mt-2  pb-2 text-center justify-content-center">
                            <div className="col-md-12 mt-2" >
                              <button type="button" className="btn br18 gradient1-bg border-0 text-white gemplex-regular py-1" data-toggle="modal" data-target="#exampleModal" >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!--Modal-- for conferm password> */}
              <span>
                <span className="d-inline-block open-mobile-confirm-modal" data-toggle="modal" data-backdrop="static" data-target="#confermverifications" style={{ margin: "0 0 0 10px", cursor: "pointer" }}>
                </span>
              </span>
              <div className="modal fade" id="confermverifications" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
                  <span style={{ position: 'absolute', right: "1.5rem" }} type="button" className="close-modal cursor outline" data-dismiss="modal" />
                  <div className="modal-content">
                    <div className="modal-header border-0 pt-0 pb-0">
                      <button type="button" className="close close-modal-mobile" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true" onClick={this.closemodal}>&times;</span>
                      </button>
                    </div>
                    <div className="modal-body pt-2 pb-2">
                      <div className="container">
                        <div className="form-wrapper focused py-2">
                          <div className="form-group">
                            <label className="form-label" htmlFor="current_password_value">
                              Enter Your Password
                            </label>
                            <div className="input-group mb-1">
                              <input type="password" id="current_password_value" className="form-control form-input password-field" aria-describedby="basic-addon1" onInput={this.maxLengthCheck} style={{ borderRadius: "0px", paddingTop: "10px" }} onChange={this.handleChange} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-10 modal-footer change-pass-btn p-0 pb-3">
                      <button type="button" className="btn br18 btn-block m-0 gradient1-bg  pt-2 pb-2 border-0 text-white gemplex-regular primary-container-padding py-1 user-save-detail-button" onClick={this.passwordVerify}>
                        Verify
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <span>
                <span className="d-inline-block open-socail-confirm-modal"
                  data-toggle="modal"
                  data-backdrop="static"
                  data-target="#socialconfermverification"
                  style={{ margin: "0 0 0 10px", cursor: "pointer" }}
                >
                </span>
              </span>

              {/* <!-- Modal --> for verification through social media */}
              <div className="modal fade" id="socialconfermverification" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-sm" role="document">
                  <div className="modal-content">

                    <div className="modal-header border-0 p-0">
                      <button type="button" className="close close-modal-social" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body text-center">
                      <h5 className="verify-social-header">Verify With</h5>
                      <div className="login-social-icon  text-center col-md-12" style={{ display: "flex" }} >
                        <div className="col cursor p-0 ml-1">
                          <SocialLogin
                            provider='facebook'
                            appId='780784942305643'
                            callback={this.handleSocialLogin}
                          >
                            <img src={"https://preprod-gemplex-assets.gemplex.tv/web/assets/facebook.svg"} className="" alt="" style={{ width: '29px' }} />
                          </SocialLogin>
                        </div>
                        <div className="col cursor p-0 ml-2">
                          <GoogleLogin
                            clientId="734411559966-0udcbh9nm4adp4i1ndherumskhd4l36b.apps.googleusercontent.com"
                            render={renderProps => (
                              <img src={"https://preprod-gemplex-assets.gemplex.tv/web/assets/search.svg"} className="" alt="" style={{ width: '25px' }} onClick={renderProps.onClick} disabled={renderProps.disabled} />
                            )}
                            buttonText="Login"
                            onSuccess={this.responsessGoogle}
                            onFailure={this.responsessGoogle}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            :
            ''
        }
      </>

    );
  }
}
