import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import Slider from "react-slick";

import ApiList from "../../config/api.js";
import CommonApi from "../../util/common_api_handler.js";
import HeaderJson from '../../util/headers.js';

import '../../stylesheet/home_page/banner.css'


class Banner extends Component {
  constructor() {
    super();
    this.state = {
      slides: [],
      baseUrl:'',
      headers: HeaderJson.header(),
    };
    this.click = this.click.bind(this);
  }

  componentDidMount=()=>{
    this.fetch_banner_details(this.props.catalogueId);
  }
  
  fetch_banner_details = catalogueId => {

    const apiUrl = ApiList.current.url + ApiList.endpoints.banner.bannerAll + "/" + catalogueId;
     
    const apiHeaders = HeaderJson.header();

    CommonApi.getCall(apiUrl, apiHeaders)
      .then((data)=>{
        if (data.statusCode === 200 && data.data) {
          let dataslide = [] 
          for (var i = 0; i < data.data.length; i++) { 
            dataslide.push(data.data[i])
          }
          this.setState({
            slides: dataslide,
            baseUrl:data.base_url_16_5
          }) 
        }
      })
  };

  click() {
    const { slides } = this.state;
    this.setState({
      slides:
        slides.length === 6 ? [1, 2, 3, 4, 5, 6, 7, 8, 9] : [1, 2, 3, 4, 5, 6]
    });
  }

  render=()=>{ 
    const self = this
    const settings = {
      className: "Banner",
      centerMode: false,
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      swipeToSlide: true,
      adaptiveHeight: true,
      centerPadding: "50px",
      autoplay: true,
      speed: 1000,
      autoplaySpeed: 5000,
      fade: true,
      cssEase: 'linear' 
    };
    const {baseUrl} = this.state 
    return (
      <div>
        <div className="container-fluid" style={{ padding: '0.01rem' }}>
          {this.state.slides.length !== 0 ?
            <Slider {...settings}>
              {this.state.slides.map(function (slide) {
                return (
                  <span className="position-relative" onClick={self.callSongIndex} key={slide._id} style={{ cursor:'default !important' }}>
                    <img src={baseUrl+"/"+slide.banner_16_5}   className="w-100 img-fluid" alt={slide.alternate_text} />
                    <div className="background-cover-gradient"></div>
                    <div className="row justify-content-center">
                      <div className="col-lg-10 col-8 banner-details-section"> 
                      </div>
                    </div>
                  </span>
                );
              })}
            </Slider> : 
          <>
          <div className="w-100 banner-response" style={{marginTop:'-4px'}}>
            <Skeleton width={"100%"} height={"100%"} />
          </div>
          <div className="w-100 inner-banner-response" >
            <Skeleton width={"100%"} height={"100%"} />
            <div className="ml-5" style={{ "position": "absolute", "left": "0", "width": "200px", "height": "90px", "display": "inline-flex" }}>
              {/* <img src={playButton} className="" alt='' style={{ "height": "40px", "width": "40px", "position": "absolute", "left": "0", "marginTop": "2px" }} /> */}
              {/* <label className="m-0 ml-5" style={{ "height": "30px", "width": "150px", "position": "absolute", "left": "0" }}>
                <SkeletonTheme height={"30px"} highlightColor={"#fafafa"} color={"#d5d5d5"}>
                  <Skeleton width={"100%"} height={"100%"} />
                </SkeletonTheme>
              </label>
              <label className="m-0 ml-5 mt-5" style={{ "height": "30px", "width": "80px", "position": "absolute", "left": "0" }}>
                <SkeletonTheme height={"30px"} highlightColor={"#fafafa"} color={"#d5d5d5"}>
                  <Skeleton width={"100%"} height={"100%"} />
                </SkeletonTheme>
              </label> */}
            </div>
          </div>
        </>
          }
        </div>
      </div>
    );
  }
}
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: 'transparent' }}
      onClick={onClick}
    ><img src="https://preprod-gemplex-assets.gemplex.tv/web/arrow-right-blue.svg" alt="next" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "transparent" }}
      onClick={onClick}
    >
      <img src="https://preprod-gemplex-assets.gemplex.tv/web/arrow-left-blue.svg" alt="next" />
    </div>
  );
}

export default Banner;
