import React, { Component } from 'react';
import "../../stylesheet/fonts/fonts.css"



export default class StepTwo extends Component {
    constructor() {
        super();
        this.state = {
        }
    }


    render() {
        const style = {
            fontSize: '18px',
            backgroundImage: 'linear-gradient(to right, #0070CC , #26BDEB)',
        }
        const styleheader = {
            fontSize: '16px',
            color: '#0070CC',
            background: 'transparent',
            border: '2px solid',
            borderRadius: '.25rem',
            'border-image-source': 'linear-gradient(to right, #0070CC , #26BDEB)',
            'border-image-slice': '1',
        }
        const inputStyle = {
            border: '1px solid #9e9e9e',
            color: '#9e9e9e'

        }
        return (
            <div>
                <div class="container my-5 gemplex-regular">
                    <div class="row justify-content-md-center py-5">

                        <div class="col-md-5 text-center">
                            <div> <button className="btn my-5 w-100 py-3 gemplex-thin" style={styleheader}>Enjoy your first month. It's Free</button></div>
                            <div>Step 2 of 3</div>
                            <div className="h3 gemplex-medium">Sign up to start your free month.</div>
                            <div className="mt-2">Just two more steps and you're and you're finished!</div>
                            <div className="mt-2">We hate paperwork,too.</div>
                            <div className="h5 gemplex-medium mt-4 mb-3 ">Create your account.</div>
                            <div className="my-2 gemplex-thin"><input type="text" style={inputStyle} placeholder="Email" className="px-4 py-3 w-100 rounded " /></div>
                            <div className="my-2 gemplex-thin"><input type="password" style={inputStyle} placeholder="Password" className="px-4 py-3 w-100 rounded " /></div>
                            <button className="btn mt-5 w-75 py-3 gemplex-bold text-white" style={style}>Continue</button>
                        </div>

                    </div>
                </div>
            </div>

        )
    }

}