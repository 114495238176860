import React, { Component } from "react";



import ApiList from "../../config/api";
import Banner from "./Banner.jsx";
import Footer from "../partials/footer";
import Grid from "./GridDashbord.jsx";
import Genre from "./Genre.jsx";
import Header from "../partials/header";
import HomeSectionComponent from "./HomeSectionComponent.jsx";
import LoginInvoke from '../../util/login';
import Languages from "./Language.jsx";
import MetaData from "../partials/metaData.jsx";
import Navigation from "../partials/NavigationBar.jsx";
import PageJourny from "../../util/page_journey.js";


class HomePage extends Component {

  async componentDidMount() {

    await PageJourny.setDeviceInfo();
    LoginInvoke.loginInvoked();
  }

  render() {
    const title_data = "Gemplex - Stream The New!";
    const description_data = "Gemplex. Watch original TV Shows and Movies on Gemplex. Online Streaming Now.";
    const keywords_data = "gemplex, online, streaming, movies, tv shows, latest, streaming new";
    const title_tag = "Gemplex - Stream The New!";
    return (
      <>
        <MetaData  title={title_data} description={description_data} keywords={keywords_data} mainTitle={title_tag} />
        <Header />
        <Navigation />
        <Banner catalogueId="HOME" />
        <Grid pageType="HOME" />
        {ApiList.endpoints.home.homeCatArr.map(function (catName, i) {
          return (
            <HomeSectionComponent key={i} catalogueName={catName} pageType="HOME" bread={"false"} />
          )
        })}
        <Languages Language_type={"video"} type="MOVIES" />
        <Genre type={"video"} />
        <Footer />
      </>
    );
  }
}

export default HomePage;