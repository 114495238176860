import React, { Component } from 'react';
import $ from 'jquery';

import ApiList from "../../config/api.js"
import helpers from '../../util/headers.js';
import RegenrateToken from "../../util/regenrateToken.js";
import Navbar from "../partials/NavigationBar.jsx"

import "../../stylesheet/content/series-list.css";
import "../../stylesheet/partials/slider-dots.css";
import "../../stylesheet/partials/slide-style.css";
import "../../stylesheet/partials/hover-slider.css";
var playButton="https://preprod-gemplex-assets.gemplex.tv/web/play-button.svg";


export default class PageName extends Component {
    constructor() {
        super();
        this.state = {
            subCatalog: [],
            genre: [],
            mainData: [],
            contentData: [],
            options:[],
            headers: helpers.header(),
        }
    }

    componentDidMount() {

        // this.fetchData(Params.query('catalogue_id'), Params.query('genre'), Params.query('subCatalog'))
        const content_id = this.props.match.params.content_id ? this.props.match.params.content_id : null;
        if (content_id) {
            this.fetch_video(content_id);
        }
    }
    fetch_video = (content_id) => {
        const self = this
        $.ajax({
            url: ApiList.current.url + ApiList.endpoints.detail.videoDetail + content_id,
            type: "GET",
            headers: this.state.headers,
            success: (data) => {
                if (data.statusCode === 200) {
                    let mainData = data.data
                    self.setState({
                        mainData: mainData,
                        contentData: mainData.content_manager
                    });
                    let option=[];
                    
                    if (mainData !=null && mainData.seriesData.length > 0 && mainData.seriesData[0].seasons) {
                        mainData.seriesData[0].seasons.map((item, i)=>{
                            if (mainData.season === item.SEASON) {
                                option.push(<option key={i} value={item.content_id} selected>Season {item.SEASON}</option>)
                            } else {
                                option.push(<option key={i} value={item.content_id}>Season {item.SEASON}</option>)
                            }
                        })
                    }
                    self.setState({
                        options:option
                    })
                }
            },
            error: (err) => {
                if (err.statusCode === 403) {
                    RegenrateToken.userRegenrateToken()
                  }
            }
        });
    }
    fetchData = (catalogue_id, genre, subCatalog) => {
        const self = this;
        let datastring = {
            "videoFilter": [{
                "catalogId": catalogue_id,
                "catalog": {
                    "subCatalog": subCatalog,
                    "genre": genre
                }
            }]
        }

        let header = {
            "Content-Type": "application/json",
            "xAccessToken": "alshfla",
            "deviceType": "2",
            "countryCode": "356",
            "parentalControl": false,
            "Cache-Control": "no-cache"
        }
        $.ajax({
            url: ApiList.current.url + ApiList.endpoints.content.fetchData,
            type: "POST",
            data: JSON.stringify(datastring),
            headers: header,
            success: (data) => {
                if (data.statusCode === 200) {
                    if (subCatalog) {
                        self.setState({
                            subCatalog: data.data[0].subCatalogueData
                        })
                    }
                    if (genre) {
                        self.setState({
                            genre: data.data[0].genre
                        })
                    }
                }
            },
            error: (err) => {
            }
        });
    }
    changeSeason = () => {
        var e = document.getElementById("seasonDrop");
        var strUser = e.options[e.selectedIndex].value;
        window.location.href = '/content/series/' + strUser
    }
    render() {
        const {mainData, contentData ,options} = this.state
        let option = [];
     
        return (
            <div>
                <Navbar />
                <div className="series-list">
                    <div className="has-animation animate-on-hover container-fluid  primary-container-padding my-4 series-section ">
                        <div className="row mb-3 justify-content-between">
                            <div className="col-xl-5 my-auto series-name-heading">
                                {mainData.content_name ? mainData.content_name : null}

                            </div>
                            <div className="col-xl-auto my-auto season-dropdown">
                                <div className="ml-auto">  <div className="rail-select d-inline-block">
                                    <div className="select-side">
                                        <div className="downArrow" alt="" />
                                    </div>
                                    <select className="form-control" id="seasonDrop" onChange={this.changeSeason}>
                                        {options}
                                    </select>
                                </div></div>
                            </div>
                            <div className="col-xl-12 ">
                                <div className="border-series-slider"></div>
                            </div>
                        </div>
                        <div className="row ratio-4-3 thumb-4">
                            <div className="col-md-12 p-0">
                                {
                                    contentData.map(function (item, i) { 
                                        return (
                                            <div className="item d-inline-block m-3" key={i}>
                                                <a  href={'/detail/' +mainData.slug +"/"  +mainData.content_id}  key={item.content_id}>
                                                    <div className="item-content position-realtive h-100">
                                                        <div className="image w-100 h-100">
                                                            <img src={mainData.base_url + "/" + mainData.content_manager_folder + "/" +mainData.content_id +"/" + mainData.thumbnail_folder + "/" + mainData.media_folder + "/" + mainData.thumbnail_4_3 + "/" +item.platform_images.thumbnails_4_3
                                                                } className="slide-img h-100 w-100 hover-card" alt="" />
                                                        </div>
                                                        <img src={playButton} className="play-video-button-img" alt=""/>
                                                        <div className="play-button-content" style={{ outline: 'none' }}>
                                                        </div>
                                                        <div className="series-number">
                                                            {(item.display_order).toString().length === 1 ? "0" + item.display_order : item.display_order}
                                                        </div>
                                                    </div>
                                                    <div className="d-inline-block  serires-name mt-2" style={{ width: '100%' }} >
                                                        <div className="gemplex-bold text-truncate primary2-text">
                                                            {item.title}
                                                        </div>
                                                        <div className="thumb-details-div mb-2">
                                                            <span className="mr-1">26 min</span>
                                                            <span className="mx-1">2019</span>
                                                            <span className="mx-1">18+</span>
                                                        </div>
                                                        <div className="short-disc">
                                                            {item.short_desciption ? item.short_desciption : null}
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        )
                                    })
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

}