import React, { Component } from "react";
import { Helmet } from "react-helmet";

export default class MetaData extends Component {
    render() {
        return (
            <>
                <Helmet>
                    <meta name="title" content={this.props.title}></meta>
                    <meta name="description" content={this.props.description}></meta>
                    <meta name="keywords" content={this.props.keywords}></meta>
                    <title>{this.props.mainTitle}</title>
                </Helmet>
            </>
        );
    }
}