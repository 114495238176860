import React, { Component } from 'react';


import "../../stylesheet/fonts/fonts.css"
var headerImage="https://preprod-gemplex-assets.gemplex.tv/web/alldevices_svg+new.svg";


export default class StepOne extends Component {
    constructor() {
        super();
        this.state = {
        }
    }
 

    render() {
        const style = {
            fontSize: '18px',
            backgroundImage: 'linear-gradient(to right, #0070CC , #26BDEB)',
        }
        const imageStyle = {
            width: '100%'
        }
        return (
            <div>
                <div class="container my-5 gemplex-regular">
                    <div class="row justify-content-md-center py-5">
                        <div class="col-md-4 text-center">
                            <div><img src={headerImage} style={imageStyle} alt="" /></div>
                            <div className="mt-5">Step 2 of 3</div>
                            <div className="h3 gemplex-medium">Create your account.</div>
                            <div className="mt-5">Gemplex is personalised for you. Use your email and create a password to watch Gemplex on any device at any time.</div>
                            {/* <button className="btn mt-5 w-100 py-3 gemplex-bold text-white" style={style}>Continue</button> */}
                        </div>
                    </div>
                    <div class="row justify-content-md-center">
                        <div class="col-md-5 text-center">
                            <button className="btn w-100 py-3 gemplex-bold text-white" style={style}>Continue</button>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

}