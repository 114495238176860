 
import React, { Component } from "react";
import ApiList from "../../../config/api.js";
import CommonApi from "../../../util/common_api_handler.js";
import Footer from "../../partials/footer";
import HeaderJson from '../../../util/headers';
import Navbar from "../../partials/NavigationBar.jsx";
import page_journey from "../../../util/page_journey.js";
import RegenrateToken from "../../../util/regenrateToken.js";

import Gif from "../../../images/static/Gemplex Loader.gif";

import "../../../stylesheet/user/my_order/index.css";
import "../../../stylesheet/user/my_movies/index.css";

export default class MyMovies extends Component {
    constructor(props) {
        super();
        this.state = {
            contentData: [],
            expireData:[],
            renderingStatus: false
        }
    }


    componentDidMount = () => {
        var self = this;
        page_journey.setDeviceInfo().then((data) => {
            self.fetchSuccessData();
            this.userProfile()
        })

    }
    userProfile = () => {
        const self = this;

        const apiUrl = ApiList.current.url + ApiList.endpoints.user.userProfile;

        const apiHeaders = HeaderJson.header();

        CommonApi.getCall(apiUrl, apiHeaders)
            .then((data) => {
                if (data.statusCode === 200) {
                    self.setState({ 
                        expireData: data.data.expiresInDays, 
                    });
                }
            })
    }
    fetchSuccessData = () => {
        const self = this;

        const apiUrl = ApiList.current.url + ApiList.endpoints.user.myMovies;

        const apiHeaders = HeaderJson.header();

        CommonApi.getCall(apiUrl, apiHeaders)
            .then((data) => {
                if (data.statusCode === 200) {
                    let jsonData = data.data; 
                    self.setState({
                        contentData: jsonData,
                        renderingStatus: true
                    })
                }
            })
            .catch((err) => {
                if (err.responseJSON.status === 403) {
                    RegenrateToken.userRegenrateToken()
                        .then((data) => {
                            self.setState({
                                renderingStatus: true
                            });
                        })
                }
            })
}
render() {
    const { renderingStatus, contentData,expireData } = this.state
    return (
        <div>
            {
                renderingStatus === true ?
                    (<div>
                        <Navbar />
                        {
                            contentData.length > 0 ?
                                (<div className="container-fluid cart-align cart-top-border">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 ">
                                            <table className="table">
                                                <thead className="">
                                                    <tr className="movies-text01">
                                                        <th scope="col">Thumbnails</th>
                                                        <th scope="col">Content Name</th>
                                                        <th scope="col">Price</th>
                                                        <th scope="col">Validity</th>
                                                        <th scope="col">Expirey</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {contentData.map(function (item) {
                                                        return (
                                                            <tr key={item._id} className="movies-text01">
                                                                <td><img src={item.base_url + "/" + item.userTVODContentData[0].content_manager_folder + "/" + item.userTVODContentData[0].content_id + "/" + item.userTVODContentData[0].thumbnail_folder + "/" + item.userTVODContentData[0].banner_folder + "/" + item.userTVODContentData[0].thumbnail_16_9 + "/" + item.userTVODContentData[0].platform_images.thumbnails_16_9} width="200" height="100"  alt={item.userTVODContentData[0].content_name}/></td>
                                                                <td>{item.userTVODContentData[0].content_name}</td>
                                                                <td ><p id="product_val" >{item.userTVODContentData[0].monetization.tvod.price_inr} / <small>Rs</small></p></td>
                                                                <td>{item.userTVODContentData[0].monetization.tvod.validity}</td>
                                                                <td style={{color:'red'}}>{expireData === 0 ? 'Expire Today' : `${expireData} Days left` } </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                )
                                :
                                (<h3 className="empty-cart-align movies-text01">Your Movie Is Empty</h3>)

                        }
                        <Footer />
                    </div>)
                    :
                    <img src={Gif} alt="" style={{ top: "50%", left: "50%", position: "absolute", transform: "translate(-50%,-50%)" }} />
            }

        </div>
    )
}
}