import React, { Component } from "react";

import ApiList from "../../config/api.js";
import CommonApi from "../../util/common_api_handler.js";
import Banner from "../home_page/Banner.jsx";
import Footer from "../partials/footer";
import Filter from "../partials/filter";
import Grid from '../home_page/GridDashbord.jsx' 
import HeaderJson from '../../util/headers.js';
import MetaData from "../partials/metaData.jsx";
import Navigation from "../partials/NavigationBar.jsx";
import PageJourny from "../../util/page_journey.js"; 
import RComp from './VideoSectionComponent.jsx';

import "../../stylesheet/videos_page/index.css"

class VideosPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headers: HeaderJson.header(),
      videos: [],
      error: false,
      listDataFromChild: [],
      sortByData: '',
      listLanguage: [],
    };
    this.dataGet = this.dataGet.bind(this)
  }

  componentDidMount=()=>{
    const self = this;
    //scrollTo Function use because every time page start form top.
    window.scrollTo(0, 0);
    PageJourny.setDeviceInfo()
      .then((data) => {
        self.fetchMainData()
      });
  }
  fetchMainData = () => {

    const self = this;

    const apiUrl = ApiList.current.url + ApiList.endpoints.video.catalogue;

    const apiHeaders = HeaderJson.header();

    CommonApi.getCall(apiUrl, apiHeaders)
      .then((data) => {
        if (data.statusCode === 200 && data.data) {
          const mainData = data.data
          let component_arry_var = self.state.videos.slice();
          if (mainData.length > 0) {
            for (var i = 0; i < mainData.length; i++) {
              component_arry_var.push(<RComp bread="false" key={i} pageType="VIDEOS" sortData={this.state.sortByData} genreData={this.state.listDataFromChild} languageData={this.state.listLanguage} catalogueName={mainData[i]._id !== null ? mainData[i]._id.catalogue_name : ''} catalogId="null" api_data={mainData[i]} />);
            }
            self.setState({
              videos: component_arry_var
            });
          }
        }
      })
  }

  dataGet = (dataFromChild) => {
    const self = this
    self.setState({
      listDataFromChild: dataFromChild,
      videos: []
    }, () => { this.fetchMainData() });
  }
  
  getSorting = (childSorting) => {
    const self = this;
    self.setState({
      sortByData: childSorting,
      videos: []
    }, () => { this.fetchMainData() })
  }

  getLanguageFilter = (data) => {
    const self = this;
    self.setState({
      listLanguage: data,
      videos: []
    }, () => { this.fetchMainData() })
  }

  render() {
    const { videos } = this.state
    const self = this;
    const title_data = "Gemplex - Stream The New!";
    const description_data = "Gemplex. Watch original TV Shows and Movies on Gemplex. Online Streaming Now.";
    const keywords_data = "gemplex, online, streaming, movies, tv shows, latest, streaming new";
    const title_tag = "Gemplex - Stream The New!";
    return (
      <>
              {/* <Header /> */}
              <MetaData  title={title_data} description={description_data} keywords={keywords_data} mainTitle={title_tag} />

              <Navigation active={'video'} />
              <Banner catalogueId={'VIDEOS'} />
              <Grid pageType='VIDEOS' />
              <Filter Language_type="video" showName='Genre' type='video' getSorting={self.getSorting} getLanguage={this.getLanguageFilter} getGenre={this.dataGet} />
              {videos}
              <Footer />
      </>
    );
  }
}
export default VideosPage;