import $ from "jquery";
import React, { Component } from 'react';
import swal from 'sweetalert';
import ApiList from "../../config/api.js";
import CommonApi from "../../util/common_api_handler.js";
import HeaderJson from '../../util/headers.js';
import RegenrateToken from "../../util/regenrateToken.js";

import '../../stylesheet/partials/filter.css';

export default class Filter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            panelWidth: '-250',
            genreData: [],
            languageData: [],
            sortByListing: [],
        }
    }

    async componentDidMount() {
        const self = this;
        const Language = self.fetchLanguage();
        const Genre = self.fetchGenre();
        const sortBy = self.sortBy()
        await Promise.all([Language, Genre, sortBy]);
        $(document).on('change', '.filter-check', function () {
            var check = []
            $('.filter-check').map(function () {
                if ($(this).is(":checked")) {
                    check.push($(this).val())
                }
                return true
            })
        })
        $("#_select_check_all").click(function () {
            $(".checkBoxClass").prop('checked', $(this).prop('checked'));
        });

        $("#_select_check_all_language").click(function () {
            $(".checkBoxLanguage").prop('checked', $(this).prop('checked'));
        });
    }
    fetchLanguage() {
        const self = this;
        const apiUrl = ApiList.current.url + ApiList.endpoints.language.language;
        const bodyData = {
            "type": self.props.Language_type,
            "limit": 1000,
            "offset": 0
        }
        const apiHeaders = HeaderJson.header();

        CommonApi.postCall(apiUrl, apiHeaders, bodyData)
            .then((data) => {
                if (data.statusCode === 200 && data.data) {
                    const jsonData = data.data;
                    self.setState({
                        languageData: jsonData
                    });

                }
            })
            .catch((err) => {
                if (err.responseJSON.status_code === 403) {
                    RegenrateToken.userRegenrateToken()
                        .then((data) => {
                            this.fetchLanguage()
                        })
                }
            })
    }

    fetchGenre = () => {
        const self = this;

        const apiUrl = ApiList.current.url + ApiList.endpoints.genre.genre;

        const apiHeaders = HeaderJson.header();

        const bodyData = {
            "type": [this.props.type]
        }
        CommonApi.postCall(apiUrl, apiHeaders, bodyData)
            .then((data) => {
                if (data.statusCode === 200 && data.data) {
                    const jsonData = data.data;
                    self.setState({
                        genreData: jsonData
                    });
                }
            })
            .catch((err) => {
                if (err.responseJSON.status_code === 403) {
                    RegenrateToken.userRegenrateToken()
                        .then((data) => {
                            this.fetchGenre()
                        })
                }
            })

    }

    sortBy = () => {
        const self = this;

        const apiUrl = ApiList.current.url + ApiList.endpoints.sortBy.sortByList;

        const apiHeaders = HeaderJson.header();
        CommonApi.getCall(apiUrl, apiHeaders)
            .then((data) => {
                if (data.statusCode === 200 && data.data) {
                    self.setState({
                        sortByListing: data.data
                    });
                }
            })
            .catch((err) => {
                if (err.responseJSON.status_code === 403) {
                    RegenrateToken.userRegenrateToken()
                        .then((data) => {
                            this.sortBy()
                        })
                }
            })
    }

    filterApply = () => {
        const self = this
        var radios = document.getElementsByName('filterRadios');
        let sorting = ''
        for (var i = 0, length = radios.length; i < length; i++) {
            if (radios[i].checked) {
                sorting = radios[i].value
                break;
            }
        }
        const language_selected = [];
        $(".language:checked").each(function (i) {
            language_selected[i] = $(this).val();
        });
        const genres_selected = [];
        $(".genres:checked").each(function (i) {
            genres_selected[i] = $(this).val();
        });
        if (language_selected.length !== 0) {
            self.props.getLanguage(language_selected)
        } else if (genres_selected.length !== 0) {
            self.props.getGenre(genres_selected)
        } else if (sorting !== '') {
            self.props.getSorting(sorting)
        } else {
            swal({ text: "Please select filter option", timer: 1500 })
        }
    }
    openFilterNav = () => {
        this.setState({
            panelWidth: '0'
        })
    }
    closeFilterNav = () => {
        this.setState({
            panelWidth: '-250'
        })
    }
    uncheckedAll = () => {
        var inputs = document.querySelectorAll('.unchekedAll');
        for (var i = 0; i < inputs.length; i++) {
            if (inputs[i].checked) {
                inputs[i].checked = false;
            }
        }

    }
    filterClear = () => {
        this.uncheckedAll();

        const language_selected = [];
        const genres_selected = [];
        const sorting = [];
        this.props.getGenre(language_selected)
        this.props.getLanguage(genres_selected)
        this.props.getSorting(sorting)



    }
    render() {
        const { genreData, languageData, sortByListing } = this.state
        const { showName } = this.props
        return (
            <div className="container-fluid ">
                <div className="row justify-content-end accordion" id="accordionExample" >
                    <div className="col-xl-12 primary-container-padding" id="headingOne" >
                        <button className="filter-button cursor " onClick={this.openFilterNav} >
                            Filter <i className="fa fa-filter"></i>
                        </button>
                        <div className="filter-panel" style={{ marginRight: this.state.panelWidth + "px", }}>
                            <div className="row">
                                <div className="col-md-12 col-xs-1" >
                                    <div className="filter-heading">Filter & Sorting  <button className="text-white border-0 ml-3 cursor" style={{ background: 'transparent', outline: 'none', fontSize: '13px' }}  ><i className="fas fa-undo filterClear" onClick={this.filterClear}></i></button>  <button className="float-right border-0 text-white cursor" style={{ background: 'transparent', outline: 'none' }} onClick={this.closeFilterNav} >
                                        <i className="fas fa-times show-on-hover my-auto mr-1" style={{ "fontSize": "20px" }}></i>
                                    </button></div>
                                    <div className="panel-group filter-panel-accordion" id="accordion">
                                        <div className="panel panel-default panel-border">
                                            <div className="panel-heading">
                                                <div className="panel-title accordion-toggle collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    <span className="filter-outline cursor">
                                                        {showName || 'Genre'}
                                                    </span>
                                                </div>
                                            </div>
                                            <div id="collapseOne" className="collapse pannel-collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                                                <div className="panel-body">
                                                    <ul className="list-unstyled gemplex-regular">
                                                        <li className="filter-inline">Select All <input type="checkbox" id="_select_check_all" className='checkbox-filter filter-check unchekedAll' />  </li>
                                                        {genreData.map((itemg) => {
                                                            return (
                                                                <div key={itemg._id}>
                                                                    <li className="filter-inline">
                                                                        {itemg.name}
                                                                        <input type="checkbox" name="checkItem" id="dummyId" className='genres checkbox-filter checkBoxClass filter-check unchekedAll' value={itemg.code} />

                                                                    </li>
                                                                </div>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            this.props.type !== "hype" ? 
                                            <div className="panel panel-default panel-border">
                                            <div className="panel-heading">
                                                <div className="panel-title">
                                                    <div className="accordion-toggle collapsed filter-outline" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                        <span className="filter-outline cursor">
                                                            Languages
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="collapseTwo" className="collapse panel-collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                                <div className="panel-body">
                                                    <ul className="list-unstyled gemplex-regular">
                                                        <li className="filter-inline">Select All <input type="checkbox" id="_select_check_all_language" className='checkbox-filter filter-check unchekedAll' />  </li>
                                                        {
                                                            languageData.map((iteml) => {
                                                                return (
                                                                    <li className="filter-inline" key={iteml.language_code}>{iteml.language_name} <input type="checkbox" className='checkbox-filter language checkBoxLanguage filter-check unchekedAll' value={iteml.language_code} />  </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>:""
                                        }
                                        <div className="panel panel-default panel-border">
                                            <div className="panel-heading">
                                                <div className="panel-title">
                                                    <div className="accordion-toggle collapsed filter-outline" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree" >
                                                        <span className="filter-outline cursor">
                                                            Sort By
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div id="collapseThree" className="collapse panel-collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                                <div className="panel-body">
                                                    <ul className="list-unstyled gemplex-regular">
                                                        {
                                                            sortByListing.map((iteml) => {
                                                                return (
                                                                    <li className="filter-inline" key={iteml}>
                                                                        <div className="form-check" style={{ padding: '0' }}>
                                                                            <label className="form-check-label" htmlFor="filterRadios1">
                                                                                {iteml}
                                                                            </label>
                                                                            <input className="form-check-input checkbox-filter unchekedAll" type="radio" name="filterRadios" id="filterRadios" value={iteml} style={{ right: '0' }} />
                                                                        </div>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button className="show-on-hover save-btn align-middle primary2-bg border-0 text-white gemplex-regular rounded  mr-3 btn cursor" style={{ fontSize: '13px', float: 'right', marginTop: '5%', outline: 'none' }} onClick={this.filterApply}>Apply</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}