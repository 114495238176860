import React, { Component } from "react";
import ApiList from "../../config/api.js";

import Banner from "./HypeBanner.jsx";
import CommonApi from "../../util/common_api_handler.js";
import Filter from '../partials/filter';
import Footer from "../partials/footer";
import Header from "../partials/header";
import HeaderJson from '../../util/headers.js';
import Navigation from "../partials/NavigationBar.jsx";
import PageJourny from "../../util/page_journey.js";
import Slider from "./HypeComponent.jsx";

import "../../stylesheet/home_page/index.css";
import "../../stylesheet/partials/hover-slider.css";
import "../../stylesheet/partials/slider-nav.css";
//  var component_arry_var;
class Hype extends Component {

  state = {
    catalogue: [],
    navigation: '',
    error: false,
    renderingStatus: false,
    apiData: [],
    listDataFromChild: [],
    sortByData: '',
    genreCode : this.props.match.params.genres ? this.props.match.params.genres : [],
    listLanguage: [],
    headers: HeaderJson.header(),
  };

  componentDidMount = () => {
    PageJourny.setDeviceInfo()
    this.fetchMainData()
  }

  fetchMainData = () => {
    const self = this;

    const pageType = "HYPE"

    const apiUrl = ApiList.current.url + ApiList.endpoints.home.homeCatalogue;

    const apiHeaders = HeaderJson.header();

    const bodyData = {
      'genreFilter': this.state.genreCode ? [this.state.genreCode] : self.state.listDataFromChild ,
      'subCatalogue': [],
      'languageFilter': [],
      'sortBy': self.state.sortByData ? self.state.sortByData : '',
      "parental_control": false,
      "page_type": pageType,
      "offset": 0,
      "limit": 15,
      "catalogue_offset": 0,
      "catalogue_limit": 100
    }
    CommonApi.postCall(apiUrl, apiHeaders, bodyData)
      .then((data) => {
        if (data.statusCode === 200) {
          let component_arry_var = self.state.apiData.slice();
          component_arry_var.push(<Slider key={1111} api_data={data.data ? data.data : []} />);
          self.setState({
            apiData: component_arry_var
          })
        }
      })
  }

  dataGet = (dataFromChild) => {
    const self = this
    self.setState({
      listDataFromChild: dataFromChild,
      apiData: []
    }, () => {
      this.fetchMainData()
    });
  }
  getSorting = (childSorting) => {
    const self = this;
    self.setState({
      sortByData: childSorting,
      apiData: []
    }, () => {
      this.fetchMainData()
    })
  }
  getLanguageFilter = (data) => {
    const self = this;
    self.setState({
      listLanguage: data,
      apiData: []
    }, () => {

      // this.fetchMainData()
    })
  }

  render() {
    const self = this
    return (
      <>
        <Header />
        <Navigation navigation_data={this.state.navigation} active={'hype'} />
        <Banner catalogueId={'HYPE'} />
        <Filter showName='Topic' type='hype' getGenre={self.dataGet} getLanguage={self.getLanguageFilter} getSorting={self.getSorting} />
        {this.state.apiData}
        <Footer />
      </>
    );
  }
}
export default Hype;
