/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import $ from "jquery";
import Cookies from 'universal-cookie';

import ApiList from "../../config/api.js";
import CommonApi from "../../util/common_api_handler.js";
import HeaderJson from '../../util/headers.js';
import Slider from "react-slick";

import "../../stylesheet/home_page/banner.css";
import "../../stylesheet/partials/slider-dots.css";
import "../../stylesheet/partials/slide-style.css";
import "../../stylesheet/partials/hover-slider.css";

const prev = "https://preprod-gemplex-assets.gemplex.tv/web/scroll-arrow-to-left.svg";
const next = "https://preprod-gemplex-assets.gemplex.tv/web/scroll-arrow-to-right.svg";
const cookies = new Cookies();

var userType
class SliderCom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slides: [],
      mediaManagerId: [],
      heading: "",
      content_id: '',
      userPlanCode: '',
      ratio: '',
      cell_size: '',
      favouritesFlag: true,
      video_details: [],
      headingCode: "",
      slider: []
    };
    this.click = this.click.bind(this);
    this.state = { show: false };
    this.detailsSection = this.detailsSection.bind(this);
  }

  componentWillMount() {
    if (cookies.get('plan-order') === '1') {
      localStorage.setItem('queue', JSON.stringify([]))
    }
  }
  componentDidMount() {
    userType = cookies.get('userType')
    const self = this;
    this.Set_data(this.props.slider_data);

    if (this.props && this.props.slider_data) {
      if (this.props.slider_data.ratio === '4:5') {
        self.setState({
          ratio: '45'
        })
      } else if (this.props.slider_data.ratio === '4:3') {
        self.setState({
          ratio: '43'
        })
      } else if (this.props.slider_data.ratio === '16:9') {
        self.setState({
          ratio: '169'
        })
      }
    }
    cookies.get('userType') === "REGULAR" && this.fetchUserData()
  }
  fetchUserData = () => {
    const apiUrl = ApiList.current.url + ApiList.endpoints.user.userProfile;

    const apiHeaders = HeaderJson.header();
    CommonApi.getCall(apiUrl, apiHeaders)
      .then((data) => {
        if (data.statusCode === 200) {
          this.setState({
            userPlanCode: data.data.subscription_plan_order,
          });
        }
      })
  }


  Set_data = data => {
    var self = this;
    if (data != null) {
      let arr = data.data
      self.setState({
        slides: arr,
        heading: data.genre_name ? data.genre_name : data.sub_catalogue_name,
        headingCode: data.genre_name ? data.sub_catalogue_code : data.sub_catalogue_code,
      })
    }
  };

  click() {
    const { slides } = this.state;
    this.setState({
      slides:
        slides.length === 6 ? [1, 2, 3, 4, 5, 6, 7, 8, 9] : [1, 2, 3, 4, 5, 6]
    });
  }

  detailsSection = () => {
    const { show } = this.state;
    this.setState({ show: !show });
  };

  detailRedirect = (id, cId, ) => {
    if (userType === "GUEST") {
      $(".openSignIn").click()
    } else if (userType === "REGULAR") {
      if (this.state.userPlanCode > 1) {
        this.props.callbackFromParent(id);
        var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?content_id=' + id;
        // alert(id);
        // alert(cId);
        window.history.pushState({ path: newurl }, '', newurl);
        
      } else {
        $(".open-subscription-modal").click()
      }
    }

  }
  allRedirect = (callback, ratio) => {
    this.props.callback(callback, ratio)
  }
  render = () => {
    const self = this;
    const { heading, ratio, slider } = this.state
    let _class = '';
    let _cell_size = 'thumb-3';
    let toScroll = '2'
    if (this.props && this.props.slider_data) {
      if (this.props.slider_data.ratio === '4:5') {
        _class = 'ratio-4-5'
      } else if (this.props.slider_data.ratio === '4:3') {
        _class = 'ratio-4-3'
      } else if (this.props.slider_data.ratio === '16:9') {
        _class = 'ratio-16-9'
      }
      if (this.props.slider_data.cell_size === '3') {
        _cell_size = 'thumb-3'
        toScroll = '3'
      } else if (this.props.slider_data.cell_size === '4') {
        _cell_size = 'thumb-4'
        toScroll = '4'
      } else if (this.props.slider_data.cell_size === '6') {
        _cell_size = 'thumb-6'
        toScroll = '6'
      } else if (this.props.slider_data.cell_size === '8') {
        _cell_size = 'thumb-8'
        toScroll = '8'
      }
    }
    const settings = {
      className: [_class, _cell_size],
      centerMode: false,
      dots: true,
      infinite: false,
      speed: 500,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      swipeToSlide: true,
      responsive: [
        {
          breakpoint: 4000,
          settings: {
            slidesToScroll: parseInt(toScroll) - 1,
            variableWidth: true,
            adaptiveHeight: false,
          }
        },
        {
          breakpoint: 2000,
          settings: {
            slidesToScroll: parseInt(toScroll) - 1,
            variableWidth: true,
            adaptiveHeight: false,
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToScroll: parseInt(toScroll) - 1,
            variableWidth: true,
            adaptiveHeight: true,
          }
        },
        {
          breakpoint: 600,
          settings: {
            // slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: true,
            adaptiveHeight: true,
            dots: false,
          }
        },
        {
          breakpoint: 480,
          settings: {
            // slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: true,
            adaptiveHeight: true,
            dots: false,
          }
        }
      ]
    };

    return (
      <div>

        <div className="has-animation animate-on-hover container-fluid  p-0 ">
          <div className="col-xl-12  mb-2 p-0" >
            <div className="word_space ">
              <div className="d-inline-block primary1-text gemplex-medium sub-heading" id="view">
                {heading
                  ? heading
                  : 'heading'}
              </div>
              <div className="behind-heading d-inline-block">
                <a onClick={() => self.allRedirect(this.state.headingCode, ratio)} className="">
                  All
                  <div className="d-inline-block behind-heading-arrow" ><i className="fa fa-angle-right"></i></div>
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-12 p-0" >
            {this.state.slides ? (
              <Slider {...settings}>
                {this.state.slides.map(function (slide) {
                  return (
                    <div className="item-music cursor" key={slide._id} onClick={() => self.detailRedirect(slide.content_id, slide.media_manager_id)}>
                      <div className="item-content position-realtive h-100">
                        <div className="image w-100 h-100">
                          <img src={ratio === '43' ? slide.base_url + '/' + slide.content_manager_folder + "/" + slide.content_id + '/' + slide.thumbnail_folder + '/' + slide.banner_folder + '/' + slide.thumbnail_4_3 + '/' + slide.platform_images.thumbnails_4_3 : ratio === '45' ? slide.base_url + '/' + slide.content_manager_folder + "/" + slide.content_id + '/' + slide.thumbnail_folder + '/' + slide.banner_folder + '/' + slide.thumbnail_4_5 + '/' + slide.platform_images.thumbnails_4_5 : ratio === '169' ? slide.base_url + '/' + slide.content_manager_folder + "/" + slide.content_id + '/' + slide.thumbnail_folder + '/' + slide.banner_folder + '/' + slide.thumbnail_16_9 + '/' + slide.platform_images.thumbnails_16_9 : ''}
                            className="slide-img h-100 w-100 hover-card" alt={slide.series_name} />
                        </div>
                        <div className="play-button-content" style={{ outline: 'none' }}>

                        </div>
                        <a className="video-play-button" style={{ outline: 'none' }} key={slide._id}>
                          <span></span>
                        </a>
                        {
                          slide.monetization_flag === true ? <div className="home-primary premium-icon position-absolute"> </div> : ''
                        }
                      </div>
                      <div className="d-inline-block text-truncate serires-name" style={{ width: '100%' }} >
                        <div className="gemplex-bold  primary2-text">  {slide.content_name ? slide.content_name : null}</div> <div className="duration-span">
                          <span>{slide.released_year ? slide.released_year : null}<span className="seprator"> | </span><span> {slide.duration ? (slide.duration > 60 ? Math.floor(slide.duration / 60) + 'hr ' + (slide.duration % 60) + 'min' : slide.duration + 'min') : null} </span></span></div>
                      </div>
                      {/* </a> */}
                    </div>
                  );
                })}
              </Slider>
            ) : <div className="row">
                <div className="col-md-12 p-0 slick-slider " >
                  {
                    slider ?
                      slider.map(function (item, i) {
                        console.log("item", item.base_url + "/" + item.platform_images.thumbnails_4_3)
                        return (
                          <div className={"item d-inline-block m-3"} key={item._id}>
                            <a className={"item"} href={'/music/' + item.content_id + '/'} key={item._id}>
                              <div className="item-content position-realtive ">
                                <div className="item" onClick={() => this.detailRedirect(item.content_id, item.media_manager_id)}>
                                  <div className="item-content position-realtive h-100">
                                    <div className="image w-100 h-100">
                                      <img
                                        src={item.base_url + "/" + item.platform_images.thumbnails_4_3}
                                        className="slide-img h-100 w-100 hover-card"
                                        alt={item.series_name}
                                      />
                                    </div>
                                    <div className="play-button-content" style={{ outline: 'none' }}>
                                    </div>
                                    <a className="video-play-button" style={{ outline: 'none' }} key={item._id}>
                                      <span></span>
                                    </a>
                                  </div>

                                </div>
                              </div>
                              <div className="d-inline-block text-truncate serires-name" style={{ width: '100%' }} >
                                <div className="gemplex-bold  primary2-text">  {item.content_name}</div>
                                <div className="duration-span">
                                  <span>{item.released_year ? item.released_year : null}<span className="seprator"> | </span><span> {item.duration ? (item.duration > 60 ? Math.floor(item.duration / 60) + 'hr ' + (item.duration % 60) + 'min' : item.duration + 'min') : null} </span></span>
                                </div>
                              </div>
                            </a>
                          </div>
                        )
                      }) : null
                  }
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none" }}
      onClick={onClick}
    >
      <img src={next} alt={next} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none" }}
      onClick={onClick}
    >
      <img src={prev} alt={next} />
    </div>
  );
}

export default SliderCom;
