import React, { Component } from "react";
import Slider from "react-slick";

import seeAll45 from "../../images/assets/4-5-see-all.jpg";
import seeAll43 from "../../images/assets/4-3-see-all.jpg";
import seeAll169 from "../../images/assets/16-9-see-all.jpg";

import "../../stylesheet/partials/slider-dots.css";
import "../../stylesheet/partials/slide-style.css";
import "../../stylesheet/partials/slider-dots.css";

class SliderCom extends Component {
  state={
    redirect : false,
    contentId:'',
    seeAllRedirect : false
  }
  redirectFunction = (data) => {
    this.setState({
      contentId:data,
      redirect : true
    })
    window.location.href = '/detail/'+data.slug +"/" + data.content_id
  }

  sellAllRedirectFunction = () =>{
    const self = this;
    self.setState({
      seeAllRedirect : true
    })
  }

  render() {
    const self = this
    if (this.props.slider_data !== null) {
      var slides = this.props.slider_data.data;
      var heading = this.props.slider_data.genre_name ? this.props.slider_data.genre_name : this.props.slider_data.sub_catalogue_name;
      var headerCode = this.props.slider_data.genre_name ? this.props.slider_data.genre_name : this.props.slider_data.sub_catalogue_code;
      var ratio = this.props.slider_data.ratio === '4:5' ? '45' : this.props.slider_data.ratio === '4:3' ? '43' : this.props.slider_data.ratio === '16:9' ? '169' : '169';
    }
    let _class = '';
    let _cell_size = 'thumb-3';
    let toScroll = '2'
    if (this.props && this.props.slider_data) {
      if (this.props.slider_data.ratio == '4:5') {
        _class = 'ratio-4-5';
      } else if (this.props.slider_data.ratio == '4:3') {
        _class = 'ratio-4-3'
      } else if (this.props.slider_data.ratio == '16:9') {
        _class = 'ratio-16-9'
      }
      if (this.props.slider_data.cell_size == "2") { 
        _cell_size = 'thumb-3'
        toScroll = '2'
      }else if (this.props.slider_data.cell_size == '3') { 
        _cell_size = 'thumb-3'
        toScroll = '3'
      } else if (this.props.slider_data.cell_size == '4') {
        _cell_size = 'thumb-4'
        toScroll = '4'
      } else if (this.props.slider_data.cell_size == '6') {
        _cell_size = 'thumb-6'
        toScroll = '6'
      } else if (this.props.slider_data.cell_size == '8') {
        _cell_size = 'thumb-8'
        toScroll = '8'
      }
    } 
    const settings = {
      className: [_class, _cell_size],
      centerMode: false,
      dots: true,
      infinite: false,
      speed: 500,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      swipeToSlide: true,
      responsive: [
        {
          breakpoint: 4000,
          settings: {
            // slidesToShow: parseInt(toScroll) - 1,
            slidesToScroll: parseInt(toScroll) - 1,
            variableWidth: true,
            adaptiveHeight: true
          }
        },
        {
          breakpoint: 2000,
          settings: {
            // slidesToShow: parseInt(toScroll) - 1,
            slidesToScroll: parseInt(toScroll) - 1,
            variableWidth: true,
            adaptiveHeight: true
          }
        },
        {
          breakpoint: 1024,
          settings: {
            // slidesToShow: parseInt(toScroll) - 1,
            slidesToScroll: parseInt(toScroll) - 1,
            variableWidth: true,
            adaptiveHeight: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: true,
            adaptiveHeight: true,
            dots: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: true,
            adaptiveHeight: true,
            dots: false
          }
        }
      ]
    };

    return (
      <div id={"catalogueName"} ref="myscroll" className="has-animation animate-on-hover cursor container-fluid  pt-3 pr-0 pl-0">
        <div className="col-xl-12  mb-2 p-0" >
          <div className="word_space ">
            <div className="d-inline-block primary1-text gemplex-medium sub-heading" id="view">
              {heading ? heading : null}
            </div>
            <div className="behind-heading d-inline-block">
              <a className="d-inline-block behind-heading-arrow" href={this.props.catName ? "/content/video/" + this.props.pageType + "/" + this.props.catName + "/" + headerCode + "/cell=" + _class + "/ratio=" + _cell_size : "/content/video/" + this.props.pageType + "/" + headerCode + "/cell=" + _class + "/ratio=" + _cell_size} >All<i className="fa fa-angle-right"></i></a>
            </div>
          </div>
        </div>
        {slides.length !== 0 ?
          <Slider {...settings}>
            {slides.map((slide) => {
              return (
                <span onClick={()=>self.redirectFunction(slide)} key={slide._id} className="item">
                  <div className="item-content position-realtive h-100">
                    <div className="image w-100 h-100">
                      <img src={ratio === '43' ? slide.base_url + '/' + slide.content_manager_folder + "/" + slide.content_id + '/' + slide.thumbnail_folder + '/' + slide.banner_folder + '/' + slide.thumbnail_4_3 + '/' + slide.platform_images.thumbnails_4_3 : (ratio === '45' ? slide.base_url + '/' + slide.content_manager_folder + "/" + slide.content_id + '/' + slide.thumbnail_folder + '/' + slide.banner_folder + '/' + slide.thumbnail_4_5 + '/' + slide.platform_images.thumbnails_4_5 : slide.base_url + '/' + slide.content_manager_folder + "/" + slide.content_id + '/' + slide.thumbnail_folder + '/' + slide.banner_folder + '/' + slide.thumbnail_16_9 + '/' + slide.platform_images.thumbnails_16_9)} className="slide-img h-100 w-100 hover-card" alt={slide.content_name ? slide.content_name : "GEMPLEX ONLINE STREAMING MOVIES"} />
                    </div>
                    <div className="play-button-content" style={{ outline: 'none' }}>
                    </div>
                    <div className="video-play-button" style={{ outline: 'none' }} key={slide._id} >
                      <span></span>
                    </div>
                    {slide.monetization_flag ? <div className="home-primary premium-icon position-absolute"></div> : null}
                  </div>
                  <div className="d-inline-block text-truncate serires-name" style={{ width: '100%' }}>
                    <div className="gemplex-bold  primary2-text">  {slide.content_name ? slide.content_name : null}</div>
                    <div className="duration-span">
                      <span>
                        {slide.released_year ? slide.released_year : null}
                        <span className="seprator"> | </span>
                        <span>
                          {slide.duration ? (slide.duration > 60 ? Math.floor(slide.duration / 60) + 'hr ' + (slide.duration % 60) + 'min' : slide.duration + 'min' ) : null }
                        </span>
                      </span>
                    </div>
                  </div>
                </span>
              );
            })}
            <a className="item" href={this.props.catName ? "/content/video/" + this.props.pageType + "/" + this.props.catName + "/" + headerCode + "/cell=" + _class + "/ratio=" + _cell_size : "/content/video/" + this.props.pageType + "/" + headerCode + "/cell=" + _class + "/ratio=" + _cell_size}>
              <div className="item-content position-realtive h-100">
                <div className="image w-100 h-100">
                  <img src={ratio === '43' ? seeAll43 : (ratio === '45' ? seeAll45 : seeAll169)} className="slide-img h-100 w-100 hover-card" alt="GEMPLEX ONLINE STREAMING MOVIES" />
                </div>
              </div>
            </a>
          </Slider>
          :
          ''
        }
      </div>
    );
  }
}

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style, display: "none" }} onClick={onClick} >
      <img src="https://preprod-gemplex-assets.gemplex.tv/web/scroll-arrow-to-right.svg" alt={"GEMPLEX ONLINE STREAMING MOVIES"} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style, display: "none" }} onClick={onClick} >
      <img src="https://preprod-gemplex-assets.gemplex.tv/web/scroll-arrow-to-left.svg" alt={"GEMPLEX ONLINE STREAMING MOVIES"} />
    </div>
  );
}

export default SliderCom;