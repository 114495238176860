import React, { Component } from 'react';
import Form from "react-validation/build/form";
import $ from 'jquery';
import swal from 'sweetalert';
import ApiList from "../../config/api.js"
import headerJson from '../../util/headers.js';
import CommonApi from "../../util/common_api_handler.js";
import ErrorMess from "../../util/error.js";
 export default class About extends Component {
    constructor() {
        super();
        this.state = {
            header: headerJson.header(),
        }
    }
    phoneNumberLength = (object) => {
        if (object.target.value.length < object.target.minLength) {             
            object.target.value = object.target.value.slice(
                0,
                object.target.minLength,
            );
        }
        else if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(
                0,
                object.target.maxLength
            );
        }
    }
    // handleValidation = () => {
    //     let emailId = document.getElementById('email_id').value
    //     const phoneNumber = document.getElementById('mobile_no').value;
    //     let errors = {};
    //     let formIsValid = true;
    //     if (!emailId) {
    //         swal({ text: "Please enter Email Address", timer: 1500 })
    //         formIsValid = false;
    //     }
    //     if (typeof emailId !== "undefined") {
    //         var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    //         if (!pattern.test(emailId)) {
    //             swal({ text: "Please Enter Valid Email Address", timer: 1500 })
    //             formIsValid = false;
    //         }
    //     }
    //     if(!phoneNumber){
    //         swal({ text: "Please Enter Valid Mobile Number", timer: 1500 })
    //         formIsValid = false;
    //     }
    //     if(typeof phoneNumber !== 'undefined'){
    //         if(typeof phoneNumber.length > 10 ){
                
    //         }else{
    //         swal({ text: "Please Enter Valid Mobile Number", timer: 1500 })
    //         formIsValid = false;
    //         }
    //     }
    //     this.setState({ errors: errors });
    //     return formIsValid;
    // }
    postData = () => {
        const self = this;
        const description = document.getElementById('description').value;
        const emailId = document.getElementById('email_id').value;
        const phoneNumber = document.getElementById('mobile_no').value;
        let dataString = {
            "description": description ? description : "",
            "email_id": emailId ? emailId : "",
            "mobile_no": phoneNumber ? phoneNumber : "",
        }
        if (emailId === "") {
            document.getElementById("EmailError").innerHTML = "Please fill the valid email";
            
        }else if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailId)){
            document.getElementById("EmailError").innerHTML = "";
        }else{
            document.getElementById("EmailError").innerHTML = "Please fill the valid email";
        }
        if (phoneNumber === "") {
            document.getElementById("ContectError").innerHTML = ErrorMess.alertMessages.login.MNNE;
        }
        else {
            document.getElementById("ContectError").innerHTML = "";
          
        }
        if(description ===""){
            document.getElementById("comment01").innerHTML = "Please Fill the description box";
        }else{
            document.getElementById("comment01").innerHTML = "";
        }

        if(phoneNumber !="" && emailId !="" && description !="" && (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailId))){
            let apiUrl=ApiList.current.url + ApiList.endpoints.footer.career;
            let apiHeaders=self.state.header;
            let bodyData=dataString;
    
            CommonApi.postCall(apiUrl, apiHeaders, bodyData)
            .then(data=>{
                   
                if (data.statusCode === 200) {
                    swal({ text: "Sent Successfully", timer: 1500 })
                  }
               })
               .catch(err=>{
               })
            // axios.post(ApiList.current.url + ApiList.endpoints.footer.contactUs, dataString, {
            //     headers: self.state.header
            // })
            //     .then(res => res.data)
            //     .then(data => {
            //         if (data.statusCode === 200) {
            //             swal({ text: "Sent Successfully", timer: 1500 })
            //         }
            //     })
            //     .catch(err => {
            //     })
        // }
        }
        
         
    }
    AllowOnlyChar=(event)=>{
        var inputval = document.getElementById('name');
        inputval.value = inputval.value.replace(/[^a-zA-Z]+/, '').trimLeft();
       }
       AllowOnlyNum=(event)=>{
        
            var inputval = document.getElementById('mobile_no');
            inputval.value = inputval.value.replace(/[^0-9]+/, '').trimLeft();
       }
     
    render() {
        $('.div-data').html(this.props.getData)
        return (
            <div>
                <div className="container">
                    <div className="div-data ">

                    </div>
                    <Form ref={c => { this.form = c; }} onSubmit={this.handleSubmit}>
                        <div className="row">
                            <div className="col-md-12 p-4 mb-3">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group text-left">
                                            <label className="modallogin-header">
                                                Email Id
                                        </label>
                                            <input type="text" id="email_id" className="form-control" />
                                        </div>
                                        <div className="pb-2 " id="EmailError" style={{marginTop:"-15px"}}></div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group text-left">
                                            <label className="modallogin-header">
                                                Mobile Number
                                        </label>
                                            <input type="number" id="mobile_no" className="form-control" minLength={10} maxLength={10} onInput={this.phoneNumberLength} onKeyUp={this.AllowOnlyNum} />
                                        </div>
                                        <div className="pb-2 " id="ContectError" style={{marginTop:"-15px"}}></div>
                                    </div>
                                </div>
                                <div className="form-group text-left">
                                    <label className="modallogin-header">
                                        Description
                                </label>
                                    <input type="text" id="description" className="form-control" />
                                </div>
                                <div className="pb-2 " id="comment01" style={{marginTop:"-15px"}}></div>

                                <div className="btn gradient1-bg gemplex-regular rounded mt-3 mb-3 text-white col-md-2 cursor" style={{ outline: 'none' }} onClick={this.postData}>Submit </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        )
    }
}