import $ from 'jquery';
import { Link } from 'react-router-dom';
import React, { Component } from 'react';
import Slider from "react-slick";
import swal from 'sweetalert';
import Cookies from 'universal-cookie';

import ApiList from "../../config/api.js";
import CommonApi from "../../util/common_api_handler.js"
import HeaderJson from '../../util/headers.js';

import '../../stylesheet/partials/slider-dots.css';
import '../../stylesheet/payment/rcomp.css';


var prev = "https://preprod-gemplex-assets.gemplex.tv/web/scroll-arrow-to-left.svg";
var next = "https://preprod-gemplex-assets.gemplex.tv/web/scroll-arrow-to-right.svg";

var couponValue
const cookies = new Cookies();

class Rcomp extends Component {
    state = {
        id: '',
        mainData: '',
        setIntial: 0,
        couponApply: false,
        codeValue: [],
        newValue: [],
        oldValue: [],
        planOrder: cookies.get("plan-order"),
        flagStatus: false,
    }

    componentDidMount = () => {
        this.fetchData()
    }

    fetchData = () => {
        const self = this
        let plan_id = self.props.id
        const apiUrl = ApiList.current.url + ApiList.endpoints.subscriptionPlan.getData + "/" + plan_id;
        const apiHeaders = HeaderJson.header();
        CommonApi.getCall(apiUrl, apiHeaders)
            .then((data) => {
                if (data.statusCode === 200) {
                    data.data.map((item, i) => {
                        if (item._id === self.props.preSelect) {
                            self.setState({ setIntial: i })
                        }
                        return true
                    })
                    self.setState({ mainData: data.data }, () => {
                        let planId = []
                        self.state.mainData.map((key) => {
                            return planId.push(key)
                        })
                        this.setState({ id: planId })
                    })
                }
            })
    };

    availCoupionCode = (id, plan_code, actual_amount, code) => {
        const self = this;
        var CODE = code !== null ? window.location.search !== "" ? window.location.search.split("&")[2] ? window.location.search.split("&")[2].split("=")[1] : " " : "" : "";
        document.getElementById(id + "-avail-code-section").innerHTML = `<div class="input-group mb-3 w-75 m-auto  " id="` + id + `field"  ><input type="text" id="` + id + `-input" class="form-control" placeholder="Avail Coupon Code" aria-label="Avail Coupon Code" aria-describedby="basic-addon2" value="` + CODE + `" /><div class="input-group-append"><button class="btn btn primary2-bg text-white applyBtn" id="` + id + `" type="button" >Apply</button></div></div>`;
        self.apply(id, plan_code, actual_amount);
    }

    apply = (id, plan_code, actual_amount) => {

        document.getElementById(id).onclick = () => {
            couponValue = document.getElementById(id + '-input').value;
            var couponFieldValue = document.getElementById(id + "-input").value;

            const apiUrl = ApiList.current.url + ApiList.endpoints.coupon.validate;

            const apiHeaders = HeaderJson.header();

            const bodyData = {
                "content_type": "SVOD",
                "coupon_code": couponValue,
                "actual_amount": actual_amount,
                "sub_platform_code": plan_code.toUpperCase()
            }

            CommonApi.postCall(apiUrl, apiHeaders, bodyData)
                .then((data) => {
                    if (data.statusCode === 200) {
                        if (couponFieldValue === "" || couponFieldValue === "") {
                            swal({ text: "Please choose the coupon", timer: 1500 })
                        } else {
                            document.getElementById(id + "-avail-code-section").style.display = "none";
                            document.getElementById(id + "edittext01").style.display = "block";
                        }
                        $("#" + id + "edittext").click(function () {
                            var couponFieldValue = document.getElementById(id + "-input").value;
                            if (couponFieldValue === " " || couponFieldValue === "") {
                                swal({ text: "Please choose the coupon", timer: 1500 })
                            } else {
                                document.getElementById(id + "-avail-code-section").style.display = "block";
                                document.getElementById(id + "edittext01").style.display = "none";
                            }
                        })
                        this.setState({
                            newValue: data.data.final_amount,
                            oldValue: data.data.actual_amount,
                            couponApply: true
                        })
                    } else if (data.statusCode === 401) {
                        

                    } else if (data.statusCode === 404) {

                        swal({ text: data.message, timer: 1500 })
                    }
                })
        }


    }

    buyPlan = (plan_id, plan_code, actual_amount, final_amount) => {
        var terms = document.getElementById('defaultCheck' + plan_code);
        if (cookies.get('userType') === "REGULAR") {
            if (!terms.checked) {
                swal({ text: "Please check this box if you want to proceed.", timer: 1500 })
                return false;
            } else {
                const subPlatformCode = plan_code.toUpperCase();

                const apiUrl = ApiList.current.url + ApiList.endpoints.user.createOrderIdSvod;

                const apiHeaders = HeaderJson.header();

                const bodyData = {
                    "sub_platform_code": subPlatformCode,
                    "voucher": couponValue === undefined ? " " : couponValue,
                    "actual_amount": actual_amount,
                    "final_amount": this.state.couponApply !== true ? final_amount : this.state.newValue,
                }
                CommonApi.postCall(apiUrl, apiHeaders, bodyData)
                    .then((data) => {
                        if (data.statusCode === 200) {
                            window.location.href = ApiList.current.paymentGateway + `/subscription-plan/${data.data.order_id}/${HeaderJson.header().user_id}/abcd/${cookies.get('country')}`
                        }
                    })
            }
        } else {
            swal({ text: "You Need To Login To Buy Plan.", timer: 1500 })
        }
    }

    setIntialSlide = value => {
        this.setState({ setIntial: value });
    }

    fetchCouponCode = (code) => {
        this.setState({ codeValue: code }, () => {
            $('.avail-code-btn').click()
        });
    }

    render = () => {
        const self = this;
        const { mainData, couponApply, oldValue, newValue, codeValue } = this.state;
        const settings = {
            className: "plans-slider",
            centerMode: false,
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            swipeToSlide: true,
            variableWidth: false,
            adaptiveHeight: true,
            initialSlide: this.state.setIntial
        };
        return (
            <>
                {mainData.length > 0 ?
                    <>
                        {mainData.length === 1 ?
                            <div className="container ">
                                <div className="row justify-content-md-center ">
                                    {mainData[0].region.length > 0 ?
                                        <div className="col-xl-6 col-lg-6 col-md- px-4">
                                            <div className="col-md-12 sub-panel  text-center py-4">
                                                <h4 className="text-center current-plan-name pt-2">{mainData[0].sub_platform_code}</h4>
                                                <h6 className="text-center current-plan-name  " style={{ fontSize: '15px', color: '#6e6e6e' }}> Default Plan</h6>
                                                {mainData[0].region[0].is_coupon_applied ?
                                                    <div id={mainData[0].platform_name.plan_id + "-avail-code-section"} className="mt-4">
                                                        <button onClick={() => self.availCoupionCode(mainData[0].platform_name.plan_id)} type="button" className="primary2-bg border-0 text-white gemplex-regular px-4 py-2 mr-3" style={{ cursor: "pointer" }}> Avail Coupon Code</button>
                                                    </div>
                                                    : null}
                                                <div className="mt-2">
                                                    <span className="plan-content mx-2">{mainData[0].video_quality} </span>
                                                    <span className="dot-class">
                                                        <i className="fa fa-circle" aria-hidden="true" style={{ fontSize: '5px' }}></i>
                                                    </span>
                                                    <span className="plan-content mx-2">{mainData[0].region[0].user_access} </span>
                                                    <span className="dot-class">
                                                        <i className="fa fa-circle" aria-hidden="true" style={{ fontSize: '5px' }}></i>
                                                    </span>
                                                    <span className="plan-content mx-2">{mainData[0].ads_display === 0 ? 'No Ads' : (mainData[0].ads_display === 1 ? 'less ads' : 'Ads')} </span>
                                                </div>
                                                <div className="mt-2">
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        ''
                                    }
                                </div>
                            </div>
                            :
                            <div className="container multiple-plans">
                                <div className="row justify-content-center ">
                                    <div className="col-xl-6 col-lg-6 col-md-10">
                                        <Slider {...settings}>
                                            {mainData.map(function (itemContent) {
                                                return (
                                                    <div className="px-3 text-center" key={itemContent.id}>
                                                        {itemContent.region.length > 0 ?
                                                            <div className=" sub-panel py-4">
                                                                {couponApply === false ?
                                                                    <>
                                                                        {cookies.get('country') === 'IN' ?
                                                                                <>
                                                                                    <span className="discount">&#8377; {itemContent.region[0].price}/ {itemContent.duration}Month</span>
                                                                                    <div className="price-to-pay">
                                                                                        <span className="amount"><sup>&#8377;</sup>{itemContent.region[0].discount}/<span>{itemContent.duration}Month</span> </span>
                                                                                    </div>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <span className="discount">&#x24; {itemContent.region[0].price_usd}/ {itemContent.duration}Month</span>
                                                                                    <div className="price-to-pay">
                                                                                        <span className="amount"><sup>&#x24;</sup>{itemContent.region[0].discount_usd}/<span>{itemContent.duration}Month</span> </span>
                                                                                    </div>
                                                                                </>
                                                                        }
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {itemContent.region[0].discount !== oldValue ?
                                                                                <>
                                                                                    {cookies.get('country') === 'IN' ?
                                                                                            <>
                                                                                                <span className="discount">&#8377; {itemContent.region[0].price}/ {itemContent.duration}Month</span>
                                                                                                <div className="price-to-pay">
                                                                                                    <span className="amount"><sup>&#8377;</sup>{itemContent.region[0].discount}/<span>{itemContent.duration}Month</span> </span>
                                                                                                </div>
                                                                                            </>
                                                                                            :
                                                                                            <>
                                                                                                <span className="discount">&#x24; {itemContent.region[0].price_usd}/ {itemContent.duration}Month</span>
                                                                                                <div className="price-to-pay">
                                                                                                    <span className="amount"><sup>&#x24;</sup>{itemContent.region[0].discount_usd}/<span>{itemContent.duration}Month</span> </span>
                                                                                                </div>
                                                                                            </>
                                                                                    }
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    {cookies.get('country') === 'IN' ?
                                                                                            <>
                                                                                                <span className="discount">&#8377; {oldValue}/{itemContent.duration} Month</span>
                                                                                                <div className="price-to-pay">
                                                                                                    <span className="amount"><sup>&#8377;</sup>{newValue}/<span>{itemContent.duration} Month</span> </span>
                                                                                                </div>
                                                                                            </>
                                                                                            :
                                                                                            <>
                                                                                                <span className="discount">&#x24; {oldValue}/{itemContent.duration} Month</span>
                                                                                                <div className="price-to-pay">
                                                                                                    <span className="amount"><sup>&#x24;</sup>{newValue}/<span>{itemContent.duration} Month</span> </span>
                                                                                                </div>
                                                                                            </>
                                                                                    }
                                                                                </>
                                                                        }
                                                                    </>
                                                                }
                                                                <h4 className="text-center current-plan-name pt-2">{itemContent.sub_platform}</h4>
                                                                {itemContent ?
                                                                    <>
                                                                        <div id={itemContent.sub_platform + "-avail-code-section"} className="mt-4">
                                                                            {cookies.get("plan-order") >= itemContent.order ?
                                                                                <button onClick={() => self.availCoupionCode(itemContent.sub_platform, itemContent.sub_platform, itemContent.region[0].country_list[0] ? itemContent.region[0].country_list[0].price : '', codeValue)} type="button" disabled className={itemContent._id + " avial-code avail-code-btn gemplex-regular px-4 py-2 mr-0"} style={{ cursor: "pointer", backgroundColor: 'gray', color: "white", opacity: '0.6' }}> Avail Coupon Code</button>
                                                                                :
                                                                                <button onClick={() => self.availCoupionCode(itemContent.sub_platform, itemContent.sub_platform, itemContent.region[0].country_list[0] ? itemContent.region[0].country_list[0].price : '', codeValue)} type="button" className={itemContent._id + " avial-code avail-code-btn gemplex-regular px-4 py-2 mr-0"} style={{ cursor: "pointer" }}> Avail Coupon Code</button>
                                                                            }
                                                                        </div>
                                                                        <div id={itemContent.sub_platform + "edittext01"} className=" m-2 text-success" style={{ display: 'none' }}>Coupon Applied successfully <span className="text-danger cursor" id={itemContent.sub_platform + "edittext"}><i className="fas fa-pencil-alt"></i></span></div>
                                                                    </>
                                                                    :
                                                                    null
                                                                }
                                                                {cookies.get("plan-order") >= itemContent.order ?
                                                                    <p className="disabled" style={{ opacity: "0.5" }}>Click here to check all offers</p>
                                                                    :
                                                                    <Link to={{
                                                                        pathname: "/coupon",
                                                                        state: {
                                                                            sub_platform_code: itemContent.sub_platform,
                                                                            planOuterID: itemContent._id,
                                                                            planInnerId: itemContent.platform_name.plan_id
                                                                        }
                                                                    }} >
                                                                        <span style={{ cursor: 'pointer' }} className="offer-code" data-toggle="modal">
                                                                            <p> Click here to check all offers</p>
                                                                        </span>
                                                                    </Link>
                                                                }
                                                                <div className="mt-2">
                                                                    <span className="plan-content mx-2">{itemContent.video_quality} </span>
                                                                    <span className="dot-class">
                                                                        <i className="fa fa-circle" aria-hidden="true" style={{ fontSize: '5px' }}></i>
                                                                    </span>
                                                                    <span className="plan-content mx-2">{itemContent.region[0].user_access} </span>
                                                                    <span className="dot-class">
                                                                        <i className="fa fa-circle" aria-hidden="true" style={{ fontSize: '5px' }}></i>
                                                                    </span>
                                                                    <span className="plan-content mx-2">{itemContent.ads_display === undefined || itemContent.ads_display === false ? 'Ad Free' : (itemContent.ads_display === true ? 'less ads' : 'Ads')} </span>
                                                                </div>
                                                                <div className="mt-2">
                                                                    <span className="text-capitalize plan-content">downloads upto {((itemContent.region[0].downloads))} GB per month </span>
                                                                </div>
                                                                <div className="form-check py-2 mobile-responsive">
                                                                    {cookies.get("plan-order") >= itemContent.order ?
                                                                            <input className="form-check-input mobile-responsive-int " type="checkbox" disabled id={"defaultCheck" + itemContent.sub_platform} style={{ top: '10px', }} checked />
                                                                            : <input className="form-check-input mobile-responsive-int cursor" type="checkbox" id={"defaultCheck" + itemContent.sub_platform} style={{ top: '10px', }} />

                                                                    }
                                                                    <label className="form-check-label mobile-responsive " htmlFor="defaultCheck1">
                                                                        <small style={{ fontSize: '10px' }}>I agree to above <a href="/terms-and-conditions" target="_blanck" style={{ fontSize: '10px' }} >Terms & Conditions</a> and <a href="/privacy-policy" target="_blanck" style={{ fontSize: '10px' }} >Privacy Policy</a>.</small>
                                                                    </label>
                                                                </div>
                                                                {itemContent.purchase_rights ?
                                                                    <div className="my-4">
                                                                        {cookies.get("plan-order") >= itemContent.order ?
                                                                                <button className="btn gradient1-bg select-button text-white gemplex-bold br18" disabled style={{ background: 'Gray' }}>Activated</button>
                                                                                :
                                                                                <button className="btn gradient1-bg select-button text-white gemplex-bold br18" style={{ width: '50%' }} onClick={() => self.buyPlan(itemContent._id, itemContent.sub_platform, cookies.get('country') === 'IN' ? itemContent.region[0].price : itemContent.region[0].price_usd ? itemContent.region[0].price_usd : oldValue, cookies.get('country') === 'IN' ? itemContent.region[0].discount : itemContent.region[0].discount_usd ? itemContent.region[0].discount_usd : newValue)}>Select</button>
                                                                        }
                                                                    </div>
                                                                    :
                                                                    <div className="my-4">
                                                                        <button className="btn gradient1-bg select-button text-white gemplex-bold br18" disabled>Select</button>
                                                                    </div>
                                                                }
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                )
                                            })}
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                        }
                    </>
                    :
                    <h2 className="text-center  my-5">
                        {"Data not available."}
                    </h2>
                }
            </>
        )
    }
}

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div className={className} style={{ ...style }} onClick={onClick} >
            <img src={next} alt={next} />
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div className={className} style={{ ...style }} onClick={onClick} >
            <img src={prev} alt={next} />
        </div>
    );
}

export default Rcomp;