import React, { Component } from "react";
import ApiList from "../../config/api.js";
import Skeleton from 'react-loading-skeleton';

import Banner from "./HypeBanner.jsx";
import CommonApi from "../../util/common_api_handler.js";
import Filter from '../partials/filter';
import Footer from "../partials/footer";
import HeaderJson from '../../util/headers.js';
import MetaData from "../partials/metaData.jsx";
import Navigation from "../partials/NavigationBar.jsx";
import PageJourny from "../../util/page_journey.js";
import RegenrateToken from "../../util/regenrateToken.js";
import Slider from "./HypeComponent.jsx";

import "../../stylesheet/home_page/index.css";
import "../../stylesheet/partials/hover-slider.css";
import "../../stylesheet/partials/slider-nav.css";
//  var component_arry_var;
class Hype extends Component {

  state = {
    catalogue: [],
    navigation: '',
    error: false,
    renderingStatus: false,
    apiData: [],
    listDataFromChild: [],
    sortByData: '',
    listLanguage: [],
    headers: HeaderJson.header(),
  };


  async componentDidMount() {
    await PageJourny.setDeviceInfo();
    await this.fetchMainData()
  }



  fetchMainData = () => {
    const self = this;

    const pageType = "HYPE"

    const apiUrl = ApiList.current.url + ApiList.endpoints.home.homeCatalogue;

    const apiHeaders = HeaderJson.header();

    const bodyData = {
      'genreFilter': self.state.listDataFromChild ? self.state.listDataFromChild : [],
      'subCatalogue': [],
      'languageFilter': [],
      'sortBy': self.state.sortByData ? self.state.sortByData : '',
      "parental_control": false,
      "page_type": pageType,
      "offset": 0,
      "limit": 15,
      "catalogue_offset": 0,
      "catalogue_limit": 100
    }
    CommonApi.postCall(apiUrl, apiHeaders, bodyData)
      .then((data) => {
        if (data.statusCode === 200 && data.data) {
          let component_arry_var = self.state.apiData.slice();
          component_arry_var.push(<Slider key={1111} api_data={data.data ? data.data : []} />);
          self.setState({
            renderingStatus: false,
            apiData: component_arry_var
          })
        } else {
          self.setState({
            renderingStatus: true
          })
        }
      })
      .catch((err) => {
        if (err.responseJSON.status === 403) {
          RegenrateToken.userRegenrateToken()
            .then((data) => {
              self.setState({ renderingStatus: true });
            })
        }
      })
  }

  dataGet = (dataFromChild) => {
    const self = this
    self.setState({
      listDataFromChild: dataFromChild,
      apiData: []
    }, () => {
      this.fetchMainData()
    });
  }
  getSorting = (childSorting) => {
    const self = this;
    self.setState({
      sortByData: childSorting,
      apiData: []
    }, () => {
      this.fetchMainData()
    })
  }
  getLanguageFilter = (data) => {
    const self = this;
    self.setState({
      listLanguage: data,
      apiData: []
    }, () => {

      // this.fetchMainData()
    })
  }

  renderSkeletonTiles = () => {
    var aa = [];
    for (let i = 0; i < 5; i++) {
      aa.push(
        <div key={i}>
          <div className="mr-2" style={{ "height": "150px", "width": "170px" }}>
            <Skeleton width={"100%"} height={"100%"} />
          </div>
          <div className="mt-1" style={{ "width": "120px" }}>
            <label className={"m-0"} style={{ "width": "80px" }}>
              <Skeleton width={"100%"} height={"100%"} />
            </label>
            <label style={{ "width": "120px" }}>
              <Skeleton width={"100%"} height={"100%"} />
            </label>
          </div>
        </div>
      )
    }
    return aa;
  }

  render() {
    const self = this;
    const title_data = "Gemplex - Stream The New!";
    const description_data = "Gemplex. Watch original TV Shows and Movies on Gemplex. Online Streaming Now.";
    const keywords_data = "gemplex, online, streaming, movies, tv shows, latest, streaming new";
    const title_tag = "Gemplex - Stream The New!";
    return (
      <>
        {/* <Header /> */}
        <MetaData title={title_data} description={description_data} keywords={keywords_data} mainTitle={title_tag} />
        <Navigation navigation_data={this.state.navigation} active={'hype'} />
        <Banner catalogueId={'HYPE'} />
        <Filter showName='Topic' type='hype' getGenre={self.dataGet} getLanguage={self.getLanguageFilter} getSorting={self.getSorting} />
        {
          this.state.renderingStatus ?
            <div className="fit-content-class">
              <img src="https://preprod-gemplex-assets.s3.ap-south-1.amazonaws.com/web/gemplex/empty-screens/Videos.svg" className="img-fluid" alt="empty-img" />
              <div className="text-center">No Data Found</div>
            </div>
            :
            this.state.apiData
        }
        {/* <div className="container-fluid mt-2" style={{ "minHeight": "213px" }}>
          <div className="container-fluid" style={{ "minHeight": "213px" }}>
            <div className="row p-0 m-0">
              {self.renderSkeletonTiles()}
            </div>
          </div>
        </div> */}
        <Footer />
      </>
    );
  }
}
export default Hype;
