import React, { Component } from 'react';

import "../../stylesheet/fonts/fonts.css"
var padlockImage ="https://preprod-gemplex-assets.gemplex.tv/web/lock_svg.svg";


export default class StepThree extends Component {
    constructor() {
        super();
        this.state = {
        }
    }
 

    render() {

        const imageStyle = {
            width: '5rem'
        }
        const styleheader = {
            fontSize: '16px',
            color: '#0070CC',
            background: 'transparent',
            border: '2px solid',
            borderRadius: '.25rem',
            'border-image-source': 'linear-gradient(to right, #0070CC , #26BDEB)',
            'border-image-slice': '1',
        }

        return (
            <div>
                <div class="container my-5 gemplex-regular">
                    <div class="row justify-content-md-center py-5">

                        <div class="col-md-5 text-center">
                            <div>
                                <img src={padlockImage} alt="" className="my-3" style={imageStyle} />
                            </div>
                            <div className="mt-2">Step 2 of 3</div>
                            <div className="h3 gemplex-medium">Sign up to start your free account.</div>
                            <div className="mt-4 ">Cancel before <span className="gemplex-medium">26/4/19 if </span></div>
                            <div><span className="gemplex-medium">you don't want to be charged.</span></div>
                            <div className="mt-4 ">As a reminder, <span className="gemplex-medium">we'll email </span></div>
                            <div><span className="gemplex-medium">you 3 days before.</span></div>
                            <div className="mt-4 gemplex-medium">No commitments.</div>
                            <div className="gemplex-medium">Cancel online any time.</div>
                            <div> <button className="btn my-5 w-100 py-3 gemplex-thin" style={styleheader}>Credit or Debit Card</button></div>
                        </div>

                    </div>
                </div>
            </div>

        )
    }

}