import $ from 'jquery';
import queryString from 'query-string';
import React, { Component } from 'react';

import ApiList from "../../config/api.js";
import CommonApi from "../../util/common_api_handler.js"
import HeaderJson from '../../util/headers.js';
import RegenrateToken from "../../util/regenrateToken.js";
import Rcomp from './rcomp';

import '../../stylesheet/payment/sub_plans.css';

import gif from "../../images/static/Gemplex Loader.gif";


export default class Name extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mainData: [],
            renderingStatus: false,
            queryParams: queryString.parse(window.location.search)
        }
    }

    componentDidMount = () => {
        var self = this;
        this.fetchData();
        setTimeout(() => {
            self.tabAnimation()
        }, 1000);
        setTimeout(() => {
            if (self.state.queryParams.outer) {
                $('.tabs-animation').find('.nav-item').removeClass('active')
                $('.tab-content').find('.tab-pane').removeClass('active')
                $("#" + self.state.queryParams.outer).addClass('active').click()
            } else {
                $('.tabs-animation').find('.active').click()
            }
        }, 1000);
    }

    fetchData = () => {
        const self = this;
        return new Promise((resolve, reject) => {
            const apiUrl = ApiList.current.url + ApiList.endpoints.subscriptionPlan.getData + "/" + 0;

            const apiHeader = HeaderJson.header();
            CommonApi.getCall(apiUrl, apiHeader)
                .then((data) => {
                    if (data.statusCode === 200) {
                        self.setState({
                            mainData: data.data,
                            renderingStatus: true
                        })
                    }
                })
                .catch((err) => {
                    RegenrateToken.userRegenrateToken()
                        .then((data) => {
                            self.setState({ renderingStatus: true });
                        })
                })
        })
    };
    tabAnimation = () => {
        var tabs = $('.tabs-animation');
        var activeItem = tabs.find('.active');
        var activeWidth = activeItem.innerWidth();
        $(".selector").css({
            "left": activeItem.position.left + "px",
            "width": activeWidth + "px"
        });
        $(".tabs-animation").on("click", "a", function (e) {
            var activeWidth = $(this).innerWidth();
            var itemPos = $(this).position();
            $(".selector").css({
                "left": itemPos.left + "px",
                "width": activeWidth + "px"
            });
        });
    }

    render() {
        var self = this
        const { mainData, renderingStatus } = this.state;
        const dataLength = 12 / mainData.length;
        const userId = this.props.user_id ? this.props.user_id : null;
        return (
            <>
                {renderingStatus ?
                    <div className="container-fluid">
                        <div className="row justify-content-center text-center sub-plans">
                            <div className="col-md-8">
                                <nav>
                                    <div className="nav nav-tabs tabs-animation" id="nav_tab" role="tablist">
                                        <div className="selector"></div>
                                        {mainData.map(function (item, i) {
                                            return (
                                                <a key={item._id} id={item._id} className={item._id && i === 0 ? 'nav-item  col-md-' + dataLength + ' col-' + dataLength + ' nav-link  active ' + item._id + '' : 'nav-item  col-md-' + dataLength + ' col-' + dataLength + ' nav-link  ' + item._id + ''} data-toggle="pill" href={'#v-pills-' + item._id} role="tab" aria-controls={'#v-pills-' + item._id} aria-selected="true">{item.plan_name}</a>
                                            );
                                        })}
                                    </div>
                                </nav>
                                <div className="tab-content" id="nav-tabContent">
                                    {mainData.map(function (item, i) {
                                        return (
                                            <div key={item._id} className={item._id && i === 0 ? 'tab-pane fade show active ' + item._id + '' : 'tab-pane fade show ' + item._id + ''} id={'v-pills-' + item._id} role="tabpanel" aria-labelledby={'#v-pills-' + item._id + '-tab'}>
                                                <Rcomp userId={userId} id={item._id} preSelect={self.state.queryParams.inner ? self.state.queryParams.inner : 0} />
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <img src={gif} alt="" style={{ top: "50%", left: "50%", position: "absolute", transform: "translate(-50%,-50%)" }} />
                }
            </>
        )
    }
}