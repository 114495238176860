/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import cookie from "react-cookies";
import Cookies from 'universal-cookie';
import $ from "jquery";
import {Redirect} from 'react-router-dom'
import ApiList from "../../config/api.js";
import CommonApi from "../../util/common_api_handler.js";
import HeaderJson from '../../util/headers.js';
import LoginComponent from "../user/login_page/login"
import LoginButtons from "../user/login_page/register";
import LogoutButton from "../user/logout_page/Logout.jsx";

import Search from "./Search.jsx";
import "../../stylesheet/partials/navigation_header.css";
import "../../stylesheet/fonts/fonts.css";
import "../../stylesheet/partials/common.css";
import "../../stylesheet/partials/media_query.css";
import "../../stylesheet/user/login.css";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";

const cookies = new Cookies();
export default class navigationheader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      themeFlag: cookie.load("theme") ? cookie.load("theme") === "false" ? false : true : true,
      userLogIn: false,
      cartArrayCount: 0,
      cartDataProps: this.props.navData !== null ? JSON.stringify(this.props.navData) : '',
      redirectToOriginal : false,
      redirectToMovie : false,
      redirectToVideo : false,
      redirectToHome:false,
      cartRedirect:false,
      redirectToMusic : false,
      redirectToHype : false,
    };
  }
  componentWillMount = () => {
    cookies.get('userType') === "REGULAR" && this.fetchUserData()
  }
  componentWillReceiveProps = (prevProps) => {
    if (this.props !== prevProps) {
      if ("navBarData" in prevProps) {
        this.setState({
          cartArrayCount: this.state.cartArrayCount - 1
        })
      } else if ("navData" in prevProps) {
        if (prevProps.navData === null || prevProps.navData === "" || prevProps.navData === undefined) {
        } else (
          this.setState({
            cartArrayCount: this.state.cartArrayCount + 1
          })
        )
      }
    }
  }
  componentDidMount() {
    (this.state.cartDataProps !== null && cookies.get('userType') === "REGULAR") && this.fetchUserData()
    if (!this.state.themeFlag) {
      $("body").addClass("dark");
      $("body").removeClass("light");
    } else {
      $("body").addClass("light");
      $("body").removeClass("dark");
    }

    if (this.props.active === "originals") {
      document.getElementById("originals").classList.add("active");
      document.getElementById("original_web").classList.add("active");
    } else if (this.props.active === "movies") {
      document.getElementById("movies").classList.add("active");
      document.getElementById("movie_web").classList.add("active")
    } else if (this.props.active === "video") {
      document.getElementById("video").classList.add("active");
      document.getElementById("video_web").classList.add("active");
    } else if (this.props.active === "hype") {
      document.getElementById("hype").classList.add("active");
      document.getElementById("hype_web").classList.add("active");
    } else if (this.props.active === "music") {
      document.getElementById("music").classList.add("active");
      document.getElementById("music_web").classList.add("active");
    } else if (this.props.active === "live") {
      document.getElementById("live_guide").classList.add("active");
    }
    const userType = cookies.get('userType')
    var getData
    if (userType === 'REGULAR') {
      getData = cookies.get('userType')
    }
    this.setState({ isLoggedIn: getData });

    var stickyToggle = function (sticky, stickyWrapper, scrollElement) {
      var stickyHeight = sticky.outerHeight();
      var stickyTop = stickyWrapper.offset().top;
      if (scrollElement.scrollTop() >= stickyTop) {
        stickyWrapper.height(stickyHeight);
        sticky.addClass("is-sticky");
      } else {
        sticky.removeClass("is-sticky");
        stickyWrapper.height("auto");
      }
    };
    // Find all data-toggle="sticky-onscroll" elements
    $('[data-toggle="sticky-onscroll"]').each(function () {
      var sticky = $(this);
      var stickyWrapper = $("<div>").addClass("sticky-wrapper"); // insert hidden element to maintain actual top offset on page
      sticky.before(stickyWrapper);
      sticky.addClass("sticky");

      // Scroll & resize events
      $(window).on("scroll.sticky-onscroll resize.sticky-onscroll", function () {
        stickyToggle(sticky, stickyWrapper, $(this));
      });

      // On page load
      stickyToggle(sticky, stickyWrapper, $(window));
    });
  }

  themeSwitch = () => {
    const flagVal = this.state.themeFlag;
    this.setState({
      themeFlag: !flagVal
    });
    if (flagVal) {
      $("body").addClass("dark");
      $("body").removeClass("light");
      cookie.save("theme", !flagVal, { path: "/", maxAge: 365 * 86400 });
    } else {
      $("body").addClass("light");
      $("body").removeClass("dark");
      cookie.save("theme", !flagVal, { path: "/", maxAge: 365 * 86400 });
    }
  };

  openNav = () => {
    document.getElementById("mySidenav").style.width = "250px";
    document.getElementById("root-back").classList.add("overlay")
  }
  closeNav = () => {
    document.getElementById("mySidenav").style.width = "0";
    document.getElementById("root-back").classList.remove("overlay")

  }

  fetchUserData = () => {

    const apiUrl = ApiList.current.url + ApiList.endpoints.user.userProfile;

    const apiHeaders = HeaderJson.header();

    CommonApi.getCall(apiUrl, apiHeaders)
      .then((data) => {
        if (data.statusCode === 200) {
          cookies.set("plan-order", data.data.subscription_plan_order, { path: "/" });
          const cartListing = data.data.cartData ? data.data.cartData : []
          var arrLength
          for (var i = 0; i < cartListing.length; i++) {
            arrLength = (cartListing.length)
          }
          this.setState({
            cartArrayCount: arrLength !== undefined ? +arrLength : 0
          })
        }
      })
  }

  redirectCart = ()=>{
    if(this.state.cartRedirect === false){
      this.setState({
        cartRedirect:true
      })
    }
  }

  redirectToOriginals=()=>{
    if(window.location.pathname !== "/originals"){
      if(this.state.redirectToOriginal === false ){ 
        this.setState({
          redirectToOriginal:true
        })
      }else{
        this.setState({
          redirectToOriginal:false
        })
      }
    }
  }
  redirectToMovies=()=>{
    if(window.location.pathname !== "/movies")
    if(this.state.redirectToMovie === false){
      this.setState({
        redirectToMovie:true
      })
    }
  }
  redirectToVideos=()=>{
    if(window.location.pathname !== "/videos")
    if(this.state.redirectToVideo === false){
      this.setState({
        redirectToVideo:true
      })
    }
  }
  redirectToMusics=()=>{
    if(window.location.pathname !== "/music")
    if(this.state.redirectToMusic === false){
      this.setState({
        redirectToMusic:true
      })
    }
  }
  redirectToHypes=()=>{
    if(window.location.pathname !== "/hype")
    if(this.state.redirectToHype === false){
      this.setState({
        redirectToHype:true
      })
    }
  }
  redirectToHomes=()=>{
    if(window.location.pathname !== "/")
    if(this.state.redirectToHome === false){
      this.setState({
        redirectToHome:true
      })
    }
  }
  
  render = () => {

    const self = this
    const { isLoggedIn, themeFlag } = this.state;
    let button;
    if (isLoggedIn != null) {
      button = <LogoutButton />;
    } else {
      button = <LoginButtons />;
    }

    $("input").focus(function () {
      $(this)
        .parents(".form-group")
        .addClass("focused");
    });

    $("input").blur(function () {
      var inputValue = $(this).val();
      if (inputValue === "") {
        $(this).removeClass("filled");
        $(this)
          .parents(".form-group")
          .removeClass("focused");
      } else {
        $(this).addClass("filled");
      }
    });
    return (

      <div>
        <div id="root-back" >
          <nav className="navbar navbar-expand-lg navbar-light  navbar-color gemplex-regular" data-toggle="sticky-onscroll" >
            <a className="navbar-brand logo-gem logo-img justify-content-center click ml-0 mr-auto outline" onClick={this.redirectToHomes}>
              {this.state.redirectToHome === true && <Redirect push to={{ pathname: '/'}} />}
            </a>
            <div className="m-login">
              <div className="toggle-theme fs13 d-inline-block">
                <div id="chartToggle" className={themeFlag ? "on" : "off"} onClick={this.themeSwitch}>
                  <div id="toggleSwitch" className={themeFlag ? "moveOff" : "moveOn "} />
                </div>
              </div>
              {button}
              {/* <div className="pl-2 pr-3 d-none cursor d-sm-block d-md-block d-lg-block d-xl-block fs13 live-tab"> */}
              {/* <img src={Cart} alt="" style={{width:'27px'}}/><sup><span class="badge badge-secondary">0</span></sup> */}
              {
                (cookies.get('userType') === 'REGULAR' && cookies.get('plan-order') === '1') &&
              <i data-count={this.state.cartArrayCount} className="fa fa-shopping-cart cart-log fa-2x icon-grey" style={{ fontSize: '21px' }} onClick={this.redirectCart}>{this.state.cartRedirect === true && <Redirect to="/cart" />}</i>
              }
              {/* </div> */}
            </div>
            <div className="d-login px-2 search-tab">
              <Search />
            </div>
            <div className="toggle-theme fs13 d-none d-sm-none d-md-flex d-xl-flex mode-tab" style={{ display: "flex" }} >
              <div className="px-2"> Light</div>
              <div id="chartToggle" className={themeFlag ? "on" : "off"} onClick={this.themeSwitch} >
                <div id="toggleSwitch" className={themeFlag ? "moveOff" : "moveOn "} />
              </div>
              <div className="px-2">Dark </div>
            </div>

            <button className="navbar-toggler border-0" type="button" style={{ background: "transparent" }} onClick={self.openNav} >
              <i className="fa fa-bars" aria-hidden="true" />
            </button>

            <div className="d-login tab-mode">
              {button}
            </div>

            <div id="mySidenav" className="sidenav">
              <a className="closebtn" onClick={self.closeNav} > &times;  </a>
              <ul className="navbar-nav mr-auto ml-2">
                <li className="nav-item mx-1">
                  <div className="m-login ">
                    <Search />
                  </div>
                </li>
                <li className="nav-item mx-1">
                  <a className="nav-link Originals outline" id="original_web" href="/originals"> Originals </a>
                </li>
                <li className="nav-item dropdown mx-1">
                  <a className="nav-link Movies outline" id="movie_web" href="/movies"> Movies </a>
                </li>
                <li className="nav-item dropdown mx-1">
                  <a className="nav-link Video outline" id="video_web" href="/videos"> Videos</a>
                </li>
                <li className="nav-item dropdown mx-1">
                  <a className="nav-link Music outline" id="music_web" href="/music"> Music </a>
                </li>
                <li className="nav-item dropdown mx-1">
                  <a className="nav-link Hype outline" id="hype_web" href="/hype"> Hype</a>
                </li>
                <li className="nav-item dropdown mx-1">
                  <a href="/live_guide" id="live-guide" className="nav-link  m-login">LIVE Guide</a>
                </li>
              </ul>
            </div>
            <div className="collapse navbar-collapse ">
              <ul className="navbar-nav mr-auto ml-2">
                <li className="nav-item mx-1">
              {this.state.redirectToOriginal === true && <Redirect push to={{ pathname: '/originals'}} ></Redirect>}  <a className="nav-link Originals outline" id="originals" onClick={this.redirectToOriginals}>Originals</a>
                </li>
                <li className="nav-item dropdown mx-1">
                  <a className="nav-link Movies outline" id="movies" onClick={this.redirectToMovies}>{this.state.redirectToMovie === true && <Redirect push to={{ pathname: '/movies'}}/>} Movies </a>
                </li>
                <li className="nav-item dropdown mx-1">
                  <a className="nav-link Video outline" id="video" onClick={this.redirectToVideos}>{this.state.redirectToVideo === true && <Redirect push to={{ pathname: '/videos'}}/>} Videos </a>
                </li>
                <li className="nav-item dropdown mx-1">
                  <a className="nav-link Music outline" id="music" onClick={this.redirectToMusics}>{this.state.redirectToMusic === true && <Redirect push to={{ pathname: '/music'}}/>} Music</a>
                </li>
                <li className="nav-item dropdown mx-1">
                  <a className="nav-link Hype outline" id="hype" onClick={this.redirectToHypes}>{this.state.redirectToHype === true && <Redirect push to={{ pathname: '/hype'}} />} Hype </a>
                </li>
                <li className="nav-item dropdown mx-1">
                    <a href="/live_guide" id="live-guide" className="nav-link">LIVE Guide</a>
                </li>
              </ul>
              <div className="d-login px-2 search-web">
                <Search />
              </div>
              <div className="toggle-theme fs13 d-none d-sm-none d-md-flex d-xl-flex mode-web" style={{ display: "flex" }}>
                <div className="px-2"> Light</div>
                <div id="chartToggle" className={themeFlag ? "on" : "off"} onClick={this.themeSwitch}>
                  <div id="toggleSwitch" className={themeFlag ? "moveOff" : "moveOn "} />
                </div>
                <div className="px-2">Dark </div>
              </div>
              <div className="pl-2 pr-3 d-none cursor d-sm-block d-md-block d-lg-block d-xl-block fs13 live-tab">
                {
                  cookies.get('userType') !== 'GUEST' ? cookies.get('plan-order') === '1' ? <i data-count={this.state.cartArrayCount} className="fa fa-shopping-cart cart-log fa-2x icon-grey" onClick={this.redirectCart}> {this.state.cartRedirect === true && <Redirect to="/cart" />} </i> : '' : ''
                }
              </div>
            </div>
            <div className="d-login web-mode">
              {button}
            </div>
          </nav>
          <LoginComponent />
        </div>
      </div>
    );
  }
}
