import React, { Component } from "react";
import $ from 'jquery';
import Cookies from 'universal-cookie';

import ApiList from "../../config/api.js";
import CommonApi from "../../util/common_api_handler.js";
import HeaderJson from '../../util/headers.js';
import PageJourny from "../../util/page_journey.js";

import "../../../node_modules/video.js/dist/video-js.min.css";
import "../../stylesheet/partials/header.css";


const cookies = new Cookies(); 

export default class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showContainer: true,
    };
  }
 
  componentDidMount() { 
    if (cookies.get('_page_ads') === 'false' ){
      this.setState({showContainer: false})
    }else{
      PageJourny.setDeviceInfo()
      .then((data)=>{
        this.fetchTitle()
      })
    }
  }

  fetchTitle = () => {
    const apiUrl = ApiList.current.url + ApiList.endpoints.adHeader.ads;
    
    const apiHeaders = HeaderJson.header();
 
    const bodyData = {
      "page_type": "HOME"
    }

    CommonApi.postCall(apiUrl, apiHeaders, bodyData)
      .then((data) => {
        if (data.statusCode === 200) { 
          cookies.set('_page_ads', 'true', { path: '/', expires: new Date(Date.now() + 2592000 / 15) })
          $('.title-header').html(atob(data.data.ad_title))
        }
      })
  }

  hideContainer = () => {
    cookies.set('_page_ads', 'false', { path: '/', expires: new Date(Date.now()+ 2592000 / 15)})
    this.setState({
      showContainer: false
    })
  }

  render() {
    return (
      <header>
        {
          this.state.showContainer ?
            <div className="container-fluid" id="header_ad">
              <div className="row  justify-content-center top-ad-section">
                <div className="col-md-12 text-center align-self-center title-header"></div>
                <img src={"https://preprod-gemplex-assets.s3.ap-south-1.amazonaws.com/web/cancel.svg"} onClick={this.hideContainer} className="tophat__button align-self-center" alt="Gemplex Cross" />
              </div>
            </div>
            :
            ''
        }
      </header>
    );
  }
}
