import React, { Component } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Slider from "react-slick";

import ApiList from "../../config/api.js";
import CommonApi from "../../util/common_api_handler.js";
import HeaderJson from "../../util/headers.js";

import '../../stylesheet/home_page/banner.css';
import '../../stylesheet/banner/banner_media.css'

var prev = "https://preprod-gemplex-assets.gemplex.tv/web/arrow-left-blue.svg";
var next = "https://preprod-gemplex-assets.gemplex.tv/web/arrow-right-blue.svg";
var playButton = "https://preprod-gemplex-assets.gemplex.tv/web/play-button.svg";

class Banner extends Component {
  constructor(props){
    super(props)
    this.state = {
      slides: [],
      baseUrl: '',
      redirect:false,
      mobileBaseUrl: '',
      hideNav: '',
      contentId:''
    }
  }

  componentDidMount = () => {
    this.fetchBannerDetails(this.props.catalogueId);
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
   
  }
  

  resize = () => {
    this.setState({ hideNav: window.innerWidth <= 760 });
  }

  fetchBannerDetails = (catalogueId) => {
    const apiUrl = ApiList.current.url + ApiList.endpoints.banner.bannerAll + "/" + catalogueId;
     
    const apiHeaders = HeaderJson.header();

    CommonApi.getCall(apiUrl, apiHeaders)
      .then((data) => {
        if (data.statusCode === 200 && data.data) {
          const dataslide = []
          for (var i = 0; i < data.data.length; i++) {
            dataslide.push(data.data[i])
          }
          this.setState({
            slides: dataslide,
            baseUrl: data.base_url_16_5,
            mobileBaseUrl: data.base_url_16_9
          });
        }
      })
  };

  redirectFunction = (data) => {
    this.setState({
      redirect : true,
      contentId : data
    })
     window.location.href = '/detail/'+data.contentData[0].slug +"/" + data.content_id  

  }

  render() {
    const settings = {
      className: "Banner",
      centerMode: false,
      dots: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      swipeToSlide: true,
      adaptiveHeight: true,
      centerPadding: "50px",
      autoplay: true,
      speed: 1000,
      autoplaySpeed: 5000,
      cssEase: 'linear'
    };
    const { baseUrl, mobileBaseUrl, slides, hideNav} = this.state;
    const { catalogueId } = this.props;
    const self = this
    return (
      <>
        <div className="container-fluid" style={{ "padding": "0.01rem" }} >
          {slides.length !== 0 ?

            <Slider {...settings}>
              {slides.map(function (slide, i) {

                return (
                  <span className="position-relative cursor" onClick={()=>self.redirectFunction(slide)}  key={slide._id}>
                    {
                      hideNav !== true ?
                        <img src={baseUrl + "/" + slide.banner_16_5} className="w-100 img-fluid web-view banner-img" alt={slide.alternate_text} />
                        :
                        <img src={mobileBaseUrl + "/" + slide.banner_16_9} className="w-100 img-fluid mobile-view banner-img" alt={slide.alternate_text} />

                    }
                    <div className="background-cover-gradient"></div>
                    <div className="row justify-content-center">
                      <div className="col-lg-10 col-8 banner-details-section">
                        <div className="slider-title position-relative">
                          {catalogueId === 'HYPE' ? '' : <img src={playButton} className="video-play-button-banner-img" alt='' />
                          }
                          {slide.content_title}
                          <span>
                            {slide.content_released_year ?
                              `(${slide.content_released_year})`
                              :
                              null
                            }
                          </span>
                        </div>
                        {slide.content_genre ? <ul className="entry-tag list-unstyled list-inline text-sub-color ">
                          {
                            slide.content_genre.map((genre, itemg) => {
                              return (
                                <span key={itemg}>
                                  {itemg === '0' ?
                                    <li className="list-inline-item"><span className="mx-1">{genre.name}</span></li>
                                    :
                                    <li className="list-inline-item"><span>|</span><span className="mx-1">{genre.name}</span></li>
                                  }
                                </span>
                              )
                            })
                          }
                        </ul> : null}
                      </div>
                    </div>
                  </span>
                );
              })}
            </Slider> :
            <>
              <div className="w-100 banner-response" style={{marginTop:'-4px'}}>
                <Skeleton width={"100%"} height={"100%"} />
              </div>
              <div className="w-100 inner-banner-response">
                <Skeleton width={"100%"} height={"100%"} />
                <div className="ml-5" style={{ "position": "absolute", "left": "0", "width": "200px", "height": "90px", "display": "inline-flex" }}>
                  <img src={playButton} className="" alt='' style={{ "height": "40px", "width": "40px", "position": "absolute", "left": "0", "marginTop": "2px" }} />
                  <label className="m-0 ml-5" style={{ "height": "30px", "width": "150px", "position": "absolute", "left": "0" }}>
                    <SkeletonTheme height={"30px"} highlightColor={"#fafafa"} color={"#d5d5d5"}>
                      <Skeleton width={"100%"} height={"100%"} />
                    </SkeletonTheme>
                  </label>
                  <label className="m-0 ml-5 mt-5" style={{ "height": "30px", "width": "80px", "position": "absolute", "left": "0" }}>
                    <SkeletonTheme height={"30px"} highlightColor={"#fafafa"} color={"#d5d5d5"}>
                      <Skeleton width={"100%"} height={"100%"} />
                    </SkeletonTheme>
                  </label>
                </div>
              </div>
            </>
          }
        </div>
      </>
    );
  }
}
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style, display: "block", background: 'transparent' }} onClick={onClick} >
      <img src={next} alt={next} />
    </div>
  );
}


function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className={className} style={{ ...style, display: "block", background: "transparent" }} onClick={onClick}>
      <img src={prev} alt={next} />
    </div>
  );
}

export default Banner;