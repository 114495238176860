import React, { Component } from "react";
import $ from "jquery"
import ApiList from "../../../config/api.js";
import HeaderJson from '../../../util/inner_header.js';
import "../../../stylesheet/hype_page/hype_blog.css";
import "../../../stylesheet/hype_page/hypetab_section.css";

export default class LeftSide extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headers: HeaderJson.header(),
      trendingTopics: [],
      popularStories: [],
      contentId: ''
    }
    this.fetchContent = this.fetchContent.bind(this)
  }
  componentWillMount = () => {
    this.fetchPopularData(); 
  }
  componentDidMount = () => {
    this.fetchTrendingData();
  }
  fetchTrendingData = () => {
    $.ajax({
      url: ApiList.current.url + ApiList.endpoints.hype.hypeTrendingTopics,
      type: 'GET',
      headers: this.state.headers,
      success: (data) => {
        const trending_topics = data != null ? data.data : null;
        this.setState({
          trendingTopics: trending_topics
        })
      }
    })

  }
  fetchPopularData = () => {
    $.ajax({
      url: ApiList.current.url + ApiList.endpoints.hype.hypePopularStories,
      type: 'GET',
      headers: this.state.headers,
      success: (data) => {
        const popular_stories = data != null ? data.data : null;
        let conten_data = []
        popular_stories.map((key) => {
            return conten_data.push(key.hypeData ? key.hypeData.content_id : '');
        })
        this.setState({
          popularStories: popular_stories,
          contentId: conten_data
        })
      }
    })
  }
  fetchContent = list => { 
    window.location.href = "/"+list.type+"/"+ list.slug +"/" +list.content_id  
  }
  
  sendData = topics => {
    const codes = topics.code
    window.location.href = "/hype?content=" + codes
  }

  render() {
    const self = this
    const { trendingTopics, popularStories, contentId } = this.state;
    return (
      <div className="  col-xl-2 col-12 order-xl-1 order-2 order-md-2">
        <div className="container pb-2">
          <div className="row">
            <div className="col-md-12 mt-3 p-0">
              <h2 className="d-inline-block primary3-text font-hype gemplex-medium hype-second-heading heading-hype mt-2 pl-2">
                Trending Topics
              </h2>
              <hr className="hype-hr-trend" />
              {trendingTopics ?
                <ul className={"header-tranding popular-text  py-2 mt-1"}>
                  {trendingTopics.map(function (topics, item) {

                    return (
                      <li key={topics._id} className={'trending-topics cursor footer-color text-concat'} onClick={() => self.sendData(topics)} >
                        {topics.name}
                      </li>
                    )
                  })}
                </ul>
                : null}
            </div>
          </div>
        </div>
        <div className="container pb-2">
          <div className="row">
            <div className="col-md-12 mt-3 p-0">
              <h2 className="d-inline-block font-hype primary3-text gemplex-medium heading-hype hype-second-heading pl-2 mt-2">
                Popular Stories
              </h2>
              <hr className={"hype-hr-trend"} />
              {popularStories ?
                <ul className={"popular-text two-line-text ellipsis py-2"}>
                  {popularStories.map(function (list, itemg) { 
                    
                    return (
                      (list.hypeData[0] && list.hypeData[0].title) ?
                      <span key={list._id}>
                              <li className={"footer-color cursor text-concat"} onClick={() => self.fetchContent(list)} >
                                { list.hypeData[0] ? list.hypeData[0].title : ''}
                              </li>
                              <hr className="hype-hr-trend"></hr>
                      </span> : ''
                    )
                  })}
                </ul>
                : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
