import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import ApiList from "../../config/api.js";
import CommonApi from "../../util/common_api_handler.js";
import Footer from "../partials/footer";
import HeaderJson from '../../util/headers.js';
import Header from "../partials/header";
import Navbar from "../partials/NavigationBar.jsx";

import '../../stylesheet/coupon/index.css';

export default class Coupon extends Component {
    state = {
        couponList: [],
        couponMessage: '',
        price: [],
        content_id_list: [],
        code: []
    };

    componentDidMount = () => {
        this.allCouponList('all')
    }

    allCouponList = (data) => {

        const apiUrl = ApiList.current.url + ApiList.endpoints.subscriptionPlan.cuponCode;

        const apiHeaders = HeaderJson.header();

        const bodyData = {
            content_type: "TVOD",
            content_id: [data]
        }

        CommonApi.postCall(apiUrl, apiHeaders, bodyData)
            .then((data) => {
                if (data.statusCode === 200) {
                    this.setState({
                        couponList: data.data
                    });
                } else if (data.statusCode === 404) {
                    this.setState({
                        couponMessage: data.message
                    })
                }
            })
    }

    specificCouponList = (data) => {

        const apiUrl = ApiList.current.url + ApiList.endpoints.subscriptionPlan.cuponCode;

        const apiHeaders = HeaderJson.header();

        const bodyData = {
            content_type: "TVOD",
            content_id: data,
        }

        CommonApi.postCall(apiUrl, apiHeaders, bodyData)
            .then((data) => {
                if (data.statusCode === 200) {
                    this.setState({
                        couponList: data.data
                    });
                }
                else if (data.statusCode === 401) {
                    this.setState({
                        couponMessage: data.message
                    })
                }
                else if (data.statusCode === 404) {
                    this.setState({
                        couponMessage: data.message
                    })
                }
            })
    }

    renderCoupons = () => {
        var couponList = this.state.couponList.map((coupon) => {
            return (
                <div className="col-md-2 col-sm-4 col-6 mb-2 " key={coupon._id}>
                    <div className="text-center coupon-item background-color p-1">
                        <h6 className="font-weight-bold coupon-primary"> {coupon.content_type} </h6>
                        <p>{coupon.discount_type}</p><br></br>
                        <Link to={{
                            pathname: "/cart",
                            state: {
                                code: coupon._id
                            }
                        }}
                        >
                            <button className="button btn-primary coupon-btn rounded mb-1 btn-sm text-white" >{coupon._id}</button>
                        </Link>
                    </div>
                </div >
            )
        })
        return couponList;
    }

    renderSpecificCoupons = () => {
        var couponList = this.state.couponList.map((coupon) => {
            return (
                <div className="col-md-2 col-sm-4 col-6 mb-2 " key={coupon._id}>
                    <div className="text-center coupon-item background-color p-1">
                        <h6 className="font-weight-bold coupon-primary"> {coupon.content_type} </h6>
                        <p>{coupon.discount_type}</p><br></br>
                        <Link to={{
                            pathname: "/cart",
                            state: {
                                content_id_list: coupon.content_id,
                                price: coupon.coupon_amount,
                                code: coupon._id
                            }
                        }}
                        >
                            <button className="button btn-primary coupon-btn rounded mb-1 btn-sm text-white">{coupon._id}</button>
                        </Link>
                    </div>
                </div >
            )
        })
        return couponList;
    }

    redirectBackToPayment = () => {
        window.location.href = `/cart`
    }
    
    render = () => {
        const { couponMessage } = this.state
        return (
            <>
                <Header />
                <Navbar />
                <div className="container">
                    <div className="text-primary font-weight-bold">
                        <div className="d-flex  mt-4 mb-4 justify-content-between">
                            <div id="backButton" className="cursor" onClick={this.redirectBackToPayment}>Back</div>
                            <div>{"Coupon & Offers"}</div>
                            <div><i className="fas fa-times coupon-exit cursor" onClick={this.redirectBackToPayment}></i></div>
                        </div>
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true" onClick={() => this.allCouponList("all")}>All</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false" onClick={() => this.specificCouponList(this.props.location.state.content_id_list)}>Specific Item</a>
                            </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                {
                                    couponMessage === "" ?
                                        <div className="container">
                                            <div className="row mt-4 mb-4">
                                                {this.renderCoupons()}
                                            </div>
                                        </div>
                                        :
                                        <div className="row mb-4">
                                            <div className="col align-self-center">
                                                <h3 style={{ color: '#26bdbe', padding: '10%', textAlign: 'center' }}>Coupon codes not found.</h3>
                                            </div>
                                        </div>
                                }
                            </div>
                            <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                {
                                    couponMessage === "" ?
                                        <div className="container">
                                            <div className="row mt-4 mb-4">
                                                {this.renderSpecificCoupons()}
                                            </div>
                                        </div>
                                        :
                                        <div className="row mb-4">
                                            <div className="col align-self-center">
                                                <h3 style={{ color: '#26bdbe', padding: '10%', textAlign: 'center' }}>{couponMessage}</h3>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}
