import React, { Component } from "react";
import Cookies from 'universal-cookie';
import $ from "jquery";
import swal from 'sweetalert';
import OtpInput from "react-otp-input";
import RegenrateToken from "../../../util/regenrateToken.js";
import PageJourny from "../../../util/page_journey.js"
import Form from "react-validation/build/form";
import ApiList from "../../../config/api.js";
import SocialLogin from "./SocialLogin.jsx"

import "../../../stylesheet/partials/common.css"
import "../../../stylesheet/partials/navigation_header.css"
import ErrorMess from "../../../util/error.js";
import CommonApi from "../../../util/common_api_handler.js";
import DatePick from "../user_detail/DatePick"
import HeaderJson from '../../../util/headers.js';


const current = new Date();
const nextYear = new Date();
var resent_otp
var timerBody
var Emailotp="";
nextYear.setFullYear(current.getFullYear() + 1);

const cookies = new Cookies();

var Error = "https://preprod-gemplex-assets.gemplex.tv/web/exclamation-mark.svg";

export default class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userSentId: "",
      callCode: cookies.get('location') ? cookies.get('location') : '',
      mobileInput: "",
      focused: "",
      otp: "",
      email: "",
      nameFlag: false,
      emailFlag: false,
      passwordFlag: true,
      confirmPasswodFlag: false,
      phoneNumber: props.mobile_no,
      isLoggedIn: "",
      forgetClick: false,
      userMobileNumber: "",
      socailLoginVisibility: true,
      fields: {},
      errors: {},
      hideShow: false,
      userEnterPassword: "",
      userhideShow: false,
      genderValue: "",
      dob:"",
      userData:[]
     
    };
    this.otpSent = this.otpSent.bind(this)
    
    
  }
  fetchData = () => {

    const apiUrl = ApiList.current.url + ApiList.endpoints.user.userProfile;

    const apiHeaders = HeaderJson.header();

    CommonApi.getCall(apiUrl, apiHeaders)
      .then((data) => {
        if (data.statusCode === 200) {
          this.setState({
            userData: data.data,
            pageRendering: true
          });
        }
      })
      .catch((err) => {
      
      })
  };


   // USER DATA CHANGE API CALL
  //  sendData = () => {
    


  // };
  componentDidMount = () => {

 
  };

Emailotp=()=>{
  
  // if(Emailotp==""){
    const apiUrl = ApiList.current.url + ApiList.endpoints.otp.email_token;

    const apiHeaders = {
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      "device_type": 'WEB',
      "user_type": cookies.get('userType') ? cookies.get('userType') : 'GUEST',
      "x_access_token": cookies.get('xAccessToken') ? cookies.get('xAccessToken') : '',
      "country_code": cookies.get('country') ? cookies.get('country') : '',
      "ip": cookies.get('ip') !== null ? cookies.get('ip') : cookies.get('ip'),
      "device_id": cookies.get('userBrowserVersion') ? cookies.get('userBrowserVersion') : '',
      "browser_version": cookies.get('userBrowserVersion') ? cookies.get('userBrowserVersion') : '',
      "device_name": cookies.get('userBrowsers') ? cookies.get('userBrowsers') : '',
      "device_model": cookies.get('userBrowsers') ? cookies.get('userBrowsers') : '',
      "os_version": cookies.get('userOsVersion') ? cookies.get('userOsVersion') : '',
      "os": cookies.get('userOss') ? cookies.get('userOss') : '',
    };

    CommonApi.getCall(apiUrl, apiHeaders).then((data)=>{
      // alert(JSON.stringify(data.data.token))
      if(data.statusCode==200){
        
        Emailotp=data.data.token;
        
      }
      
    }) 
    
  
}
  otpSent = () => {
  
    this.Emailotp()
    var inputval = document.getElementById('input_phone');

    if (inputval.value.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) && inputval.value.length >= 8) {

      document.getElementById("emailmobErr").innerHTML = ErrorMess.alertMessages.login.EIV;
      this.otpSentBody();
    } 
    if (inputval.value.match(/^[0-9]+$/) && inputval.value.length === 10) {

      // document.getElementById("emailmobErr").innerHTML = ErrorMess.alertMessages.login.MNV;   #############################  Commented Code Because of this position of all button and icon change.
      this.otpSentBody();
    } else {

      document.getElementById("emailmobErr").innerHTML = ErrorMess.alertMessages.login.MENV;
      document.getElementById("emailmobErr").style.display = "block";
    }

  };

  otpSentBody = () => {

    document.getElementById('login_spinner_btn').style.display = "inherit";

    const element = document.getElementById("first_div_section");
    const elementSecond = document.getElementById("second_div_section");
    const error_txt = document.getElementById("error_txt");
    elementSecond.style.display = "none";
    document.getElementById("edit_mobile").style.display = "none";
    var regularUserId

    if (element.style.display !== "none") {
      const mobile_number = document.getElementById("input_phone").value;
      this.setState({
        userMobileNumber: mobile_number
      });
      const phoneno = /^(\d{10})$/g;
      if (mobile_number) {

        const apiUrl = ApiList.current.url + ApiList.endpoints.otp.send;
        
        const apiHeaders = {
          "Cache-Control": "no-cache",
          "Content-Type": "application/json",
          "device_type": 'WEB',
          "user_type": cookies.get('userType') ? cookies.get('userType') : 'GUEST',
          "x_access_token": cookies.get('xAccessToken') ? cookies.get('xAccessToken') : '',
          "country_code": cookies.get('country') ? cookies.get('country') : '',
          "ip": cookies.get('ip') !== null ? cookies.get('ip') : cookies.get('ip'),
          "device_id": cookies.get('userBrowserVersion') ? cookies.get('userBrowserVersion') : '',
          "browser_version": cookies.get('userBrowserVersion') ? cookies.get('userBrowserVersion') : '',
          "device_name": cookies.get('userBrowsers') ? cookies.get('userBrowsers') : '',
          "device_model": cookies.get('userBrowsers') ? cookies.get('userBrowsers') : '',
          "os_version": cookies.get('userOsVersion') ? cookies.get('userOsVersion') : '',
          "os": cookies.get('userOss') ? cookies.get('userOss') : '',
        };

        const bodyStringMob = {
          "mobile_no": mobile_number.match(phoneno) ? mobile_number : '',
          "is_forgot_password": false,
          "email_token":Emailotp?Emailotp:""
        }
        const bodyStringEmail = {
          "email": mobile_number.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) ? mobile_number : '',
          "is_forgot_password": false,
          "email_token":Emailotp?Emailotp:""
        }

        CommonApi.postCall(apiUrl, apiHeaders, mobile_number.match(phoneno) ? bodyStringMob : bodyStringEmail)
          .then((data) => {
            if (data.statusCode === 200) {
              if (data.data.is_otp === false) {
                document.getElementById('login_spinner_btn').style.display = "none"
                document.getElementById("otp_input").style.display = "none";
                document.getElementById("password_input").style.display = "block";
                document.getElementById("password_input_submit").style = "block"
                regularUserId = data.data.user_id;
                sessionStorage.setItem("regularUserId", regularUserId)  //User ID Save
                this.setState({
                  userSentId: data.data.user_id,
                  socailLoginVisibility: false
                });

                element.style.display = "none";
                elementSecond.style.display = "block";
              } else if (data.data.is_otp === true) {
                document.getElementById('login_spinner_btn').style.display = "none"
                document.getElementById("password_input").style.display = "none";
                document.getElementById("otp_input").style.display = "block";
                document.getElementById("password_input_submit").style = "block"
                regularUserId = data.data.user_id;
                sessionStorage.setItem("regularUserId", regularUserId)  //User ID Save
                this.setState({
                  userSentId: data.data.user_id,
                  socailLoginVisibility: false
                });
                // $(document).ready(function () {
                resent_otp = data.data.resend_otp_time_interval
                var sec = resent_otp;
                timerBody = setInterval(function () {
                  $(".resendcounter span").text(sec--);
                }, 1000);
                setTimeout(function () {
                  clearInterval(timerBody);
                  document.getElementById("resendCode01").style.display = "inline";
                  document.getElementById("hideCounter").style.display = "none";
                }, 120000);

                // });
                element.style.display = "none";
                elementSecond.style.display = "block";
              }
            } else if (data.statusCode === 404) {
              document.getElementById('login_spinner_btn').style.display = "none"
              swal({ text: data.message, timer: 1500 });
            }
          })
      } else {
        document.getElementById('login_spinner_btn').style.display = "none"
        error_txt.style.visibility = "inherit";
        return false;
      }
    }
    else {
      elementSecond.style.display = "none";
    }
  }

  resentOtpSent = () => {

    document.getElementById("resendCode01").style.display = "none";
    document.getElementById("hideCounter").style.display = "inline";

    const mobile_number = document.getElementById("input_phone_forget").value;

    const apiHeaders = {
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      "user_id": this.state.userSentId,
      "device_type": 'WEB',
      "user_type": cookies.get('userType') ? cookies.get('userType') : 'GUEST',
      "x_access_token": cookies.get('xAccessToken') ? cookies.get('xAccessToken') : '',
      "country_code": cookies.get('country') ? cookies.get('country') : '',
      "ip": cookies.get('ip') !== null ? cookies.get('ip') : cookies.get('ip'),
      "device_id": cookies.get('userBrowserVersion') ? cookies.get('userBrowserVersion') : '',
      "browser_version": cookies.get('userBrowserVersion') ? cookies.get('userBrowserVersion') : '',
      "device_name": cookies.get('userBrowsers') ? cookies.get('userBrowsers') : '',
      "device_model": cookies.get('userBrowsers') ? cookies.get('userBrowsers') : '',
      "os_version": cookies.get('userOsVersion') ? cookies.get('userOsVersion') : '',
      "os": cookies.get('userOss') ? cookies.get('userOss') : '',
    }
    var regularUserId;
    this.setState({
      userMobileNumber: mobile_number
    });
    const phoneno = /^(\d{10})$/g;
    if (mobile_number) {

      const apiUrl = ApiList.current.url + ApiList.endpoints.otp.send;

      const bodyStringMob = {
        "mobile_no": mobile_number.match(phoneno) ? mobile_number : '',
        "is_forgot_password": true
      }
      const bodyStringEmail = {
        "email": mobile_number.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) ? mobile_number : '',
        "is_forgot_password": true
      }

      CommonApi.postCall(apiUrl, apiHeaders, mobile_number.match(phoneno) ? bodyStringMob : bodyStringEmail)
        .then((data) => {
          if (data.statusCode === 200) {
            // if (data.data.data.is_otp === false) {
            document.getElementById("password_input").style.display = "none";
            document.getElementById("otp_input").style.display = "block";
            document.getElementById("password_input_submit").style = "block";
            document.getElementById("edit_mobile").style.display = "none";
            regularUserId = data.data.user_id;
            sessionStorage.setItem("regularUserId", regularUserId)  //User ID Save
            this.setState({
              // userSentId: data.data._,
              socailLoginVisibility: false
            });
            $(document).ready(function () {
              resent_otp = data.data.resend_otp_time_interval
              var sec = resent_otp
              timerBody = setInterval(function () {
                $(".resendcounter span").text(sec--);

              }, 1000);
              setTimeout(function () {
                clearInterval(timerBody);
                document.getElementById("resendCode01").style.display = "inline";
                document.getElementById("hideCounter").style.display = "none";
              }, 120000);
            });
          }
          else if (data.statusCode === 404) {
            swal({ text: data.message, timer: 1500 });
          }
        })
    } else {
      return false;
    }
  };

  //FUNCTION CALL FOR GOING BACK TO CHANGE MOBILE SCREEN
  mobileScreen = () => {
    clearInterval(timerBody);
    const element = document.getElementById("first_div_section");
    const elementSecond = document.getElementById("second_div_section");
    element.style.display = "block";
    elementSecond.style.display = "none";
    this.setState({
      socailLoginVisibility: true
    })
  };
  //OTP VERIFY API CALL
  verifyOtp = () => {
    const elements = document.getElementById("first_div_section");
    const element = document.getElementById("second_div_section");
    const elementSecond = document.getElementById("third_div_section");
    const socail_icon = document.getElementById("socail_icon");
    const second_section = document.getElementById('otp_input_edit')
    if (element.style.visibility !== "hidden") {
      const mobile_number = document.getElementById("input_phone").value;

      const phoneno = /^(\d{10})$/g;
      var mobEmailotp = this.state.otp;

      const apiUrl = ApiList.current.url + ApiList.endpoints.user.verify;

      const apiHeaders = {
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
        "device_type": 'WEB',
        "user_id": this.state.userSentId,
        "user_type": cookies.get('userType') ? cookies.get('userType') : 'GUEST',
        "x_access_token": cookies.get('xAccessToken') ? cookies.get('xAccessToken') : '',
        "country_code": cookies.get('country') ? cookies.get('country') : '',
        "ip": cookies.get('ip') !== null ? cookies.get('ip') : cookies.get('ip'),
        "device_id": cookies.get('userBrowserVersion') ? cookies.get('userBrowserVersion') : '',
        "browser_version": cookies.get('userBrowserVersion') ? cookies.get('userBrowserVersion') : '',
        "device_name": cookies.get('userBrowsers') ? cookies.get('userBrowsers') : '',
        "device_model": cookies.get('userBrowsers') ? cookies.get('userBrowsers') : '',
        "os_version": cookies.get('userOsVersion') ? cookies.get('userOsVersion') : '',
        "os": cookies.get('userOss') ? cookies.get('userOss') : '',
      };

      const bodyStringMobVotp = {
        "mobile_no": mobile_number.match(phoneno) ? mobile_number : '',
        "otp": mobEmailotp.toString()
      }

      const bodyStringEmailVotp = {
        "otp": mobEmailotp.toString(),
        "email": mobile_number.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/) ? mobile_number : ''
      }
      document.getElementById('login_re_otp_spinner_btn').style.display = "inherit";
      document.getElementById('login_otp_spinner_btn').style.display = "inherit";
      CommonApi.postCall(apiUrl, apiHeaders, mobile_number.match(phoneno) ? bodyStringMobVotp : bodyStringEmailVotp)
        .then((data) => {
          if (data.statusCode === 200) {
            if (data.data.is_password_set === false) {
              document.getElementById('login_re_otp_spinner_btn').style.display = "none";
              document.getElementById('login_otp_spinner_btn').style.display = "none";
              document.getElementById('otp_input_edit').style.display = "none"
              element.style.display = "none"
              elementSecond.style.display = "block";
              document.getElementById("third_div_section").style.display = " block"
              document.getElementById("change_pass_id").style.display = "none";
              document.getElementById("user_fields").style.display = "block";
              element.style.display = "none";
              elements.style.display = "none";
            } else if ((data.data.is_password_set === true)) {
              document.getElementById('login_re_otp_spinner_btn').style.display = "none";
              document.getElementById('login_otp_spinner_btn').style.display = "none";
              document.getElementById('otp_input_edit').style.display = "none"
              element.style.display = "none"
              elementSecond.style.display = "block";
              document.getElementById("third_div_section").style.display = " block"
              document.getElementById("change_pass_id").style.display = "block";
              document.getElementById("user_fields").style.display = "none";
            }
          }
          else if (data.statusCode === 404) {
            if (second_section.style.display === "block") {
              document.getElementById('login_re_otp_spinner_btn').style.display = "none";
              document.getElementById('login_otp_spinner_btn').style.display = "none";
              document.getElementById('otp_error').style.display = 'block'
              element.style.display = "none";
            } else {
              document.getElementById('login_re_otp_spinner_btn').style.display = "none";
              document.getElementById('login_otp_spinner_btn').style.display = "none";
              document.getElementById('otp_error').style.display = 'block'
              element.style.display = "block";
            }

          }
        })
    } else {
      elementSecond.style.display = "none";
    }

  };
  //USER FILL DATA API CALL
  handleValidation() {
    const mobileNumber = document.getElementById("input_phone").value;
    // const email = document.getElementById("email").value ? document.getElementById("email").value : '';
    const password = document.getElementById("input_passwords").value ? document.getElementById("input_passwords").value : document.getElementById("new_password").value
    const userName = document.getElementById("user_name").value ? document.getElementById("user_name").value : '';
    const confirmPassword = document.getElementById("confirm_pass").value !== '' ? document.getElementById("confirm_pass").value : document.getElementById("new_confirm_pass").value;

    let errors = {};
    let formIsValid = true;

    if (!userName) {
      formIsValid = false;
      document.getElementById('user_detail_name').style.visibility = 'visible'
    } else {
      document.getElementById('user_detail_name').style.visibility = 'hidden'
      this.setState({
        nameFlag: true,
      })
    }

    if (typeof userName !== "undefined") {
      if (!userName.match(/^[a-zA-Z ]*$/)) {
        document.getElementById('user_detail_name').style.visibility = 'visible';
        formIsValid = false;
      }
      else {
        document.getElementById('user_detail_name').style.visibility = 'hidden';
        this.setState({
          nameFlag: true,
        })
      }
    }

    // if (!email) {
    //   document.getElementById('user_detail_email').style.visibility = 'visible';
    //   formIsValid = false;
    // } else {
    //   document.getElementById('user_detail_email').style.visibility = 'hidden';
    //   this.setState({
    //     emailFlag: true,
    //   })
    // }

    // if (typeof email !== "undefined") {
    //   //regular expression for email validation
    //   var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    //   if (!pattern.test(email)) {
    //     document.getElementById('user_detail_email').style.visibility = 'visible';
    //     formIsValid = false;
    //   }
    //   else {
    //     document.getElementById('user_detail_email').style.visibility = 'hidden';
    //     this.setState({
    //       emailFlag: true
    //     })
    //   }
    // }

    if (!password) {
      document.getElementById('user_detail_enter_password').style.visibility = 'visible';
      formIsValid = false;
    } else {
      document.getElementById('user_detail_enter_password').style.visibility = 'hidden';
      this.setState({
        passwordFlag: true,
      })
    }

    if (typeof password !== "undefined") {

      if (!password.match(/.{8,14}/)) {
        // alert('8')
        document.getElementById('user_detail_enter_password').style.visibility = 'visible';
        formIsValid = false;
      } else {
        document.getElementById('user_detail_enter_password').style.visibility = 'hidden';
        this.setState({
          passwordFlag: true,
        })
      }
    }
    if (!confirmPassword) {
      document.getElementById('user_detail_cnfrm_password').style.visibility = 'visible';
      formIsValid = false;
    } else {
      document.getElementById('user_detail_cnfrm_password').style.visibility = 'hidden';
      this.setState({
        confirmPasswodFlag: true,
      })
    }

    if (typeof confirmPassword !== "undefined") {
      if (!confirmPassword.match(/.{8,14}/)) {
        document.getElementById('user_detail_cnfrm_password').style.visibility = 'visible';
        formIsValid = false;
      } else {
        document.getElementById('user_detail_cnfrm_password').style.visibility = 'hidden';
        this.setState({
          confirmPasswodFlag: true,
        })
      }
    }

    this.setState({ errors: errors });
    return formIsValid;
  }
  userDetailUpdate = () => {

    const mobileNumber = document.getElementById("input_phone").value;
    const dob = document.getElementById("lgn_dob").value;
    const gender = document.getElementById("gnd").value;
    // const email = document.getElementById("email").value ? document.getElementById("email").value : '';
    const password = document.getElementById("input_passwords").value ? document.getElementById("input_passwords").value : document.getElementById("new_password").value
    const userName = document.getElementById("user_name").value ? document.getElementById("user_name").value : '';
    const confirmPassword = document.getElementById("confirm_pass").value !== '' ? document.getElementById("confirm_pass").value : document.getElementById("new_confirm_pass").value;

    if (this.handleValidation()) {
      if (password === "") swal({ text: "Please enter password", timer: 1500 });
      else if (confirmPassword === "") swal({ text: "Please enter confirm password", timer: 1500 });
      else if (password !== confirmPassword) {
        swal({ text: "Password did not match: Please try again...", timer: 1500 })
        return false;
      }
      // If same return True.
      else {
        cookies.remove('xAccessToken');
        cookies.remove('userId');
        cookies.remove('userType');


        const apiUrl = ApiList.current.url + ApiList.endpoints.user.userDetails;

        const apiHeaders = {
          "Cache-Control": "no-cache",
          "Content-Type": "application/json",
          "device_type": 'WEB',
          "user_id": this.state.userSentId,
          "user_type": cookies.get('userType') ? cookies.get('userType') : 'GUEST',
          "x_access_token": cookies.get('xAccessToken') ? cookies.get('xAccessToken') : '',
          "country_code": cookies.get('country') ? cookies.get('country') : '',
          "ip": cookies.get('ip') !== null ? cookies.get('ip') : cookies.get('ip'),
          "device_id": cookies.get('userBrowserVersion') ? cookies.get('userBrowserVersion') : '',
          "browser_version": cookies.get('userBrowserVersion') ? cookies.get('userBrowserVersion') : '',
          "device_name": cookies.get('userBrowsers') ? cookies.get('userBrowsers') : '',
          "device_model": cookies.get('userBrowsers') ? cookies.get('userBrowsers') : '',
          "os_version": cookies.get('userOsVersion') ? cookies.get('userOsVersion') : '',
          "os": cookies.get('userOss') ? cookies.get('userOss') : '',
        };

        const bodyData = {
          "password": password,
          "fullname": userName,
          // "email": mobileNumber,
          "is_email_verified": 'false',
          // "mobile_no": mobileNumber,
        }

        document.getElementById('login_succe_spinner_btn').style.display = 'inherit';
        CommonApi.multiPostCall(apiUrl, apiHeaders, bodyData)
          .then((data) => {
            if (data.statusCode === 200) {
              var userId, userName, userEmail, userMobile, xAccessToken, userPassword, userType
              userId = data.data._id;
              userName = data.data.name;
              userEmail = data.data.email;
              userMobile = data.data.mobile_no;
              userPassword = data.data.password;
              userType = data.data.user_type
              xAccessToken = data.data.x_access_token;

              cookies.set('userName', userName, { path: '/' });
              cookies.set('userEmail', userEmail, { path: '/' });
              cookies.set('userMobile', userMobile, { path: '/' });
              cookies.set('userPassword', userPassword, { path: '/' });
              cookies.set('userType', userType, { path: '/' });
              cookies.set('userId', userId, { path: '/' });
              cookies.set('xAccessToken', xAccessToken, { path: '/', expires: nextYear });
              document.getElementById('login_succe_spinner_btn').style.display = 'none';
              PageJourny.setDeviceInfo()
                .then(() => {
                  window.location.href = window.location;
                })
            }
          })
      }
    } else {
      document.getElementById('login_succe_spinner_btn').style.display = 'none';
      // swal({ text: "Form has errors.", timer: 1500 })
    }
  //   const dob = document.getElementById("user_dob").value;
  //  const gender = document.getElementById("inputGroupSelect02").value;


    let additionHeader = {
     
      dob: dob ? dob : '',
      gender: gender ? gender : '',
 
    }
    console.log("additionHeaderlogin",additionHeader);
    $.ajax({
      url: ApiList.current.url + ApiList.endpoints.user.userDetails,
      type: "POST",
      headers: HeaderJson.header(),
      enctype: 'multipart/form-data',
      cache: false,
      contentType: false,
      processData: false,
      data: JSON.stringify(additionHeader),
      success: data => {
        if (data.statusCode === 200) {
          this.setState({
            profile: data.data
          });
          swal({ text: ErrorMess.alertMessages.myProfile.MPDU, timer: 1500 })
        }
      },
      error: err => {
        if (err.statusCode === 403) {
        }
      }
    });
  };
  forgetPassword = () => {

    var allClear = "";
    const mobileNumber = document.getElementById("input_phone").value;
    // const email = document.getElementById("email").value ? document.getElementById("email").value : '';
    const password = document.getElementById("input_passwords").value ? document.getElementById("input_passwords").value : document.getElementById("new_password").value
    const confirmPassword = document.getElementById("confirm_pass").value !== '' ? document.getElementById("confirm_pass").value : document.getElementById("new_confirm_pass").value;
    // document.getElementById("edit_mobile").style.display="none";
    if (!password) {
      document.getElementById('forget_password').style.visibility = 'visible'
    } else {
      document.getElementById('forget_password').style.visibility = 'hidden';
      allClear = true
    }

    if (typeof password !== "undefined") {
      if (!password.match(/.{8,14}/)) {
        document.getElementById('forget_password').style.visibility = 'visible'
      } else {
        document.getElementById('forget_password').style.visibility = 'hidden';
        allClear = true
      }
    }

    if (allClear = true) {
      if (password === "") swal({ text: 'Please enter Password', timer: 2000 });
      else if (confirmPassword === "") swal({ text: "Please enter confirm password", timer: 2000 });
      else if (password !== confirmPassword) {
        return false;
      }
      // If same return True.
      else {
        cookies.remove('xAccessToken');
        cookies.remove('userId');

        cookies.remove('userType');

        const apiUrl = ApiList.current.url + ApiList.endpoints.user.userDetails;

        const apiHeaders = {
          "Cache-Control": "no-cache",
          "Content-Type": "application/json",
          "device_type": 'WEB',
          "user_id": this.state.userSentId,
          "user_type": cookies.get('userType') ? cookies.get('userType') : 'GUEST',
          "x_access_token": cookies.get('xAccessToken') ? cookies.get('xAccessToken') : '',
          "country_code": cookies.get('country') ? cookies.get('country') : '',
          "ip": cookies.get('ip') !== null ? cookies.get('ip') : cookies.get('ip'),
          "device_id": cookies.get('userBrowserVersion') ? cookies.get('userBrowserVersion') : '',
          "browser_version": cookies.get('userBrowserVersion') ? cookies.get('userBrowserVersion') : '',
          "device_name": cookies.get('userBrowsers') ? cookies.get('userBrowsers') : '',
          "device_model": cookies.get('userBrowsers') ? cookies.get('userBrowsers') : '',
          "os_version": cookies.get('userOsVersion') ? cookies.get('userOsVersion') : '',
          "os": cookies.get('userOss') ? cookies.get('userOss') : '',
        }

        const bodyData = {
          "password": password,
          mobile_no: mobileNumber,
        }

        CommonApi.multiPostCall(apiUrl, apiHeaders, bodyData)
          .then((data) => {
            if (data.statusCode === 200) {
              var userId, userName, userEmail, userMobile, xAccessToken, userPassword, userType
              userId = data.data._id;
              userName = data.data.name;
              userEmail = data.data.email;
              userMobile = data.data.mobile_no;
              userPassword = data.data.password;
              userType = data.data.user_type
              xAccessToken = data.data.x_access_token;

              cookies.set('userName', userName, { path: '/' });
              cookies.set('userEmail', userEmail, { path: '/' });
              cookies.set('userMobile', userMobile, { path: '/' });
              cookies.set('userPassword', userPassword, { path: '/' });
              cookies.set('userType', userType, { path: '/' });
              cookies.set('userId', userId, { path: '/' });
              cookies.set('xAccessToken', xAccessToken, { path: '/', expires: nextYear });

              PageJourny.setDeviceInfo()
                .then(() => {
                  window.location.href = window.location;
                })
            }
          })
        // $.ajax({
        //   url: ApiList.current.url + ApiList.endpoints.user.userDetails,
        //   type: "POST",
        //   headers: additionHeader,
        //   enctype: 'multipart/form-data',
        //   cache: false,
        //   contentType: false,
        //   processData: false,
        //   data: JSON.stringify(dataString),
        //   success: data => {

        //   },
        //   error: err => {

        //   }
        // });
      }
    }
  }
  // VERIFY PASSWORD
  SignIn = () => {
    document.getElementById('continue_spinner_btn').style.display = "inherit"
    const element = document.getElementById("second_div_section");
    const userEnterPassword = document.getElementById("input_password").value;
    if (element.style.visibility !== "hidden" && userEnterPassword) {

      this.setState({
        userEnterPassword: userEnterPassword
      })

      const apiUrl = ApiList.current.url + ApiList.endpoints.user.verifyPassword;

      const apiHeaders = {
        "Cache-Control": "no-cache",
        "Content-Type": "application/json",
        "user_id": this.state.userSentId,
        "device_type": 'WEB',
        "user_type": cookies.get('userType') ? cookies.get('userType') : 'GUEST',
        "x_access_token": cookies.get('xAccessToken') ? cookies.get('xAccessToken') : '',
        "country_code": cookies.get('country') ? cookies.get('country') : '',
        "ip": cookies.get('ip') !== null ? cookies.get('ip') : cookies.get('ip'),
        "device_id": cookies.get('userBrowserVersion') ? cookies.get('userBrowserVersion') : '',
        "browser_version": cookies.get('userBrowserVersion') ? cookies.get('userBrowserVersion') : '',
        "device_name": cookies.get('userBrowsers') ? cookies.get('userBrowsers') : '',
        "device_model": cookies.get('userBrowsers') ? cookies.get('userBrowsers') : '',
        "os_version": cookies.get('userOsVersion') ? cookies.get('userOsVersion') : '',
        "os": cookies.get('userOss') ? cookies.get('userOss') : '',
      }

      const bodyData = {
        "password": userEnterPassword,
      };

      CommonApi.postCall(apiUrl, apiHeaders, bodyData)
        .then((data) => {
          if (data.statusCode === 200) {
            var userId, userName, userEmail, userMobile, xAccessToken, userPassword, userType
            userId = data.data._id;
            userName = data.data.name;
            userEmail = data.data.email;
            userMobile = data.data.mobile_no;
            userPassword = data.data.password;
            userType = data.data.user_type
            xAccessToken = data.data.x_access_token;
            cookies.set('userName', userName, { path: '/' });
            cookies.set('userEmail', userEmail, { path: '/' });
            cookies.set('userMobile', userMobile, { path: '/' });
            cookies.set('userPassword', userPassword, { path: '/' });
            cookies.set('userType', userType, { path: '/' });
            cookies.set('userId', userId, { path: '/' });
            cookies.set('xAccessToken', xAccessToken, { path: '/', expires: nextYear });

            PageJourny.setDeviceInfo()
              .then((data) => {
                document.getElementById('continue_spinner_btn').style.display = "none";
                window.location.href = window.location
              })
          } else if (data.statusCode === 404) {
            // $("#password_err_login").text(data.data.message);
            document.getElementById("password_erro_txt_empty").style.display = "none";
            document.getElementById('continue_spinner_btn').style.display = "none";
            document.getElementById("password_erro_txt").style.display = "block"

          }
          else {
            document.getElementById('continue_spinner_btn').style.display = "none";
            document.getElementById("error_txt").style.visibility = "visible"
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.statusCode === 401) {
              document.getElementById('password_erro_txt').style.display = 'block'
            }
          }
        })
    } else {
      document.getElementById("password_erro_txt").style.display = "none"
      document.getElementById('continue_spinner_btn').style.display = "none";
      document.getElementById("password_erro_txt_empty").style.display = "block";
    }
  };
  forgetChange = () => {
    document.getElementById("back_option").style.display = "none";
    document.getElementById("password_input").style.display = "none";
    document.getElementById("edit_mobile").style.display = "block";
    this.setState({
      forgetClick: !this.state.forgetClick
    });
  }

  enable = () => {
    document.getElementById("input_phone_edit").disabled = false;
  };

  newUserShow = () => {
    const self = this;
    const showConfrmPass = document.getElementById('confirm_pass');
    const showConfOldPassword = document.getElementById('new_confirm_pass')
    if (showConfrmPass !== null) {
      showConfrmPass.type = "text";
      self.setState({
        userhideShow: true
      })
    }
    else {
      self.setState({
        userhideShow: false
      })
    }

    if (showConfOldPassword !== null) {
      showConfOldPassword.type = "text";
      self.setState({
        userhideShow: true
      })
    }
    else {
      self.setState({
        userhideShow: false
      })
    }

  }

  newUserHide = () => {
    const self = this;
    const showConfrmPass = document.getElementById('confirm_pass');
    const showConfOldPassword = document.getElementById('new_confirm_pass')
    if (showConfrmPass !== null) {
      showConfrmPass.type = "password";
      self.setState({
        userhideShow: false
      })
    }
    else {
      self.setState({
        userhideShow: true
      })
    }

    if (showConfOldPassword !== null) {
      showConfOldPassword.type = "password";
      self.setState({
        userhideShow: false
      })
    }
    else {
      self.setState({
        userhideShow: true
      })
    }
  }

  showPassword = () => {
    const self = this;
    const showObje = document.getElementById('new_password');
    const showPassword = document.getElementById('input_password');
    const showUserPassword = document.getElementById('input_passwords');


    if (showPassword !== null) {
      showPassword.type = "text"
      self.setState({
        hideShow: true
      })
    } else {
      self.setState({
        hideShow: false
      })
    }


    if (showObje !== null) {
      showObje.type = "text";
      self.setState({
        hideShow: true
      })
    } else {
      self.setState({
        hideShow: false
      })
    }

    if (showUserPassword !== null) {
      showUserPassword.type = "text";
      self.setState({
        hideShow: true
      })
    } else {
      self.setState({
        hideShow: false
      })
    }
  }

  hidePassword = () => {
    const self = this;
    const hideObje = document.getElementById('new_password');
    const hidePassword = document.getElementById('input_password');
    const hideUserPassword = document.getElementById('input_passwords');
    const hideConfrmPass = document.getElementById('confirm_pass');

    if (hidePassword !== null) {
      hidePassword.type = "password"
      self.setState({
        hideShow: false
      })
    } else {
      self.setState({
        hideShow: true
      })
    }

    if (hideObje !== null) {
      hideObje.type = "password";
      self.setState({
        hideShow: false
      })
    } else {
      self.setState({
        hideShow: true
      })
    }

    if (hideUserPassword !== null) {
      hideUserPassword.type = "password";
      self.setState({
        hideShow: false
      })
    } else {
      self.setState({
        hideShow: true
      })
    }

    if (hideConfrmPass !== null) {
      hideConfrmPass.type = "password";
      self.setState({
        hideShow: false
      })
    } else {
      self.setState({
        hideShow: true
      })
    }
  }

  showConfPassword = () => {
    var showpass = document.getElementById("new_confirm_pass");
    showpass.type = "text";
    this.setState({
      hideShowConf: true
    })
  }

  hideConfPassword = () => {
    var showpass = document.getElementById("new_confirm_pass");
    showpass.type = "password";
    this.setState({
      hideShowConf: false
    })
  }

  mobileValidation = (event) => {
    var inputval = document.getElementById('input_phone');
    if (inputval.value.length >= 3) {
      if (inputval.value.match(/^[0-9]+$/)) {
        document.getElementById("basic_addon1").style.display = "inline";
      }
      else if (inputval.value.match(/^[0-9a-zA-Z]+$/)) {
        document.getElementById("basic_addon1").style.display = "none";
      }
    }
    else {
      document.getElementById("basic_addon1").style.display = "none";
    }
    if (cookies.get('country')=='IN' ? inputval.value.length >= 11 : inputval.value.length > 12) {
      if (inputval.value.match(/^[0-9]+$/)) {
      
          document.getElementById("emailmobErr").innerHTML =cookies.get('country')=='IN' ? ErrorMess.alertMessages.login.LMNE :"Mobile No should be less than 12 digit";
    
      }
    }else {
      document.getElementById("emailmobErr").innerHTML = "";
      document.getElementById("emailmobErr").style.display = "block";
    }
  }


  passwordScreeen = () => {
    this.setState({
      forgetClick: false
    })
    document.getElementById('edit_mobile').style.display = 'none';
    document.getElementsByClassName('modal-otp').classList.add("display-none")
    document.getElementById('password_input').style.display = 'block'
  }

  onInputClick = () => {
    this.setState({
      callCode: cookies.get('location')
    });
  }


  handleChangeGender(event) {
    this.setState({ genderValue: event.target.value });
  }

  //RENDER FUNCTION CALL
  render = () => {
    const self = this;
    const { userData } = this.state;
    const {
      userMobileNumber,
      hideShow,
      userhideShow,
      socailLoginVisibility
    } = this.state;

  


    // console.log("lgn_dob",lgn_dob);
    // console.log("lgn_dob",lgn_dob);
    return (
      <div>
        <div className="modal fade sign-in-box " id="sign_up_box">
          <div className="modal-dialog modal-dialog-centered modal-size">
            <div className="modal-content modal-overflow"  style={{height:"600px"}}  >
              <div className="modal-body text-dark container-fluid position-relative">
                <button className="close-modal login-close-modal cursor" style={{ outline: 'none' }} type="button" data-dismiss="modal"> &times; </button>
                <div className="row text-center justify-content-center position-relative">
                  <div className="container-fluid p-0">
                    <div className="row pb-0 pt-4 pr-4 pl-4 login-logo-sections">
                      <div className="col-md-12 mb-4 position-relative">
                        <img className="login-logo-modal" src="https://preprod-gemplex-assets.gemplex.tv/general/gemeplex_logo_black.png" alt="Gemplex_Logo" />
                      </div>
                    </div>

                    <div className="row mt-2 px-4 pt-4 pb-2 text-center justify-content-center">
                      <div className="col-md-12 mt-2">
                        <Form ref={c => { this.form = c; }}>
                          <span id="first_div_section">
                            <div className="form-wrapper">
                              <div className="form-group">
                                <div className="input-group mb-1">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text" id="basic_addon1" style={{ display: "none" }}>
                                      {this.state.callCode}
                                    </span>
                                  </div>
                                  <label className="form-label enter-mobile-text" htmlFor="input_phone"> Mobile Number / Email </label>
                                  <input className="form-control form-input" onClick={this.onInputClick} autoComplete="no-fill" type="text" id="input_phone" aria-describedby="basic_addon1" onInput={this.maxLengthCheck} onKeyUp={this.mobileValidation} onBlur={this.EmailValidation01} />

                                </div>
                                <small className="text-left" id="emailmobErr" style={{ display: "none", opacity: "0.8" }}></small>
                                <small className="error-txt-mobile hidden-prop " id="error_txt">
                                  {ErrorMess.alertMessages.login.MNNE}
                                  <img className="error-icon-triangle" src={Error} style={{ width: "12px" }} alt="" />
                                </small>
                              </div>
                            </div>

                            <div className="btn gradient1-bg gemplex-regular rounded cursor mb-3 text-white col-md-12 " style={{ outline: "none" }} onClick={this.otpSent}> <span className="spinner-border spinner-border-sm" style={{ display: 'none' }} id="login_spinner_btn" role="status" aria-hidden="true"></span> Login</div>
                          </span>
                          <span className="display-none" id="second_div_section">

                            <div style={{ display: 'block' }}>
                              <div className="modal-otp pb-0" id="otp_input">
                                <h6 className="otp-screen-head">Enter the 4-digit code</h6>
                                <OtpInput onChange={otp => self.setState({ otp })} numInputs={4} isInputNum={true} shouldAutoFocus={true} inputStyle={"otp-input"} separator={<span className="space-otp"> </span>} />
                                <div>
                                  <small className="error-txt-otp display-none" id="otp_error">
                                    {ErrorMess.alertMessages.login.WOTP}
                                    {/* <img className="error-icon-triangle ml-1" src={Error} style={{ width: "12px" }} alt="" /> */}
                                  </small>
                                </div>
                                <div className="button cursor resend-top-cls" id="resendCode01" rel="nofollow" target="_blank" onClick={this.resentOtpSent}>Resend Code</div>
                                <div id="resend_counter02">
                                  <b></b>
                                  <div>
                                    <div className="resendcounter" style={{ fontFamily: 'Gemplex-Light', fontSize: '12px', paddingBottom: '-8%', float: 'left', width: 'auto', paddingTop: '10px' }}><p id="hideCounter">Resend Code <span>{resent_otp}</span> seconds</p></div></div>
                                </div>
                                <div className="btn gradient1-bg gemplex-regular cursor rounded mt-3 mb-2 text-white col-md-12" style={{ outline: "none" }} onClick={this.verifyOtp} id="otp_input">
                                  <span className="spinner-border spinner-border-sm" style={{ display: 'none' }} id="login_otp_spinner_btn" role="status" aria-hidden="true"></span>
                                  Continue
                                  </div>
                              </div>

                              <div className="form-wrapper forgetPass " id="password_input">
                                <div className={"form-group mb-0"}>
                                  <label className="form-label enter-mobile-text" htmlFor="input_password" > Enter Password </label>
                                  <div className="input-group mb-1">
                                    <input className="form-control form-input new-mobile-input" type="password" id="input_password" minLength={8} maxLength={14} aria-describedby="basic-addon1" />
                                    {
                                      hideShow === true ?
                                        <i className="far fa-eye see-pass" id="fa_eye_show" onClick={self.hidePassword}></i>
                                        :
                                        <i className="far fa-eye-slash see-pass" id="fa_eye_hide" onClick={self.showPassword}></i>

                                    }

                                  </div>
                                  <p id="password_err_login"></p>
                                  <div className="error-txt-password display-none" id="password_erro_txt" > The password that you have entered is incorrect
                                      <img className="error-icon-triangle ml-1" src={Error} style={{ width: "12px" }} alt="" />
                                  </div>
                                  <div className="error-txt-password display-none" id="password_erro_txt_empty" > Please Enter Password
                                      <img className="error-icon-triangle ml-1" src={Error} style={{ width: "12px" }} alt="" />
                                  </div>
                                </div>

                                <span className="cursor forget-text" onClick={this.forgetChange}><small> Forget Password?</small></span>
                                <div className="btn gradient1-bg gemplex-regular cursor rounded mt-1  mb-2 text-white col-md-12 outline" id="password_input_submit" onClick={this.SignIn}> <span className="spinner-border spinner-border-sm" style={{ display: 'none' }} id="continue_spinner_btn" role="status" aria-hidden="true"></span> Continue </div>
                              </div>

                              <div className="form-wrapper" id="edit_mobile" style={{ display: "none" }}>
                                <div className="form-group focused">
                                  <label className="form-label enter-mobile-text" htmlFor="input_phone_forget"> Mobile Number / Email </label>
                                  <div className="input-group mb-1">
                                    <div className="input-group-prepend">
                                      <span className="input-group-text" id="basic_addon1" style={{ display: "none" }}>
                                        {this.state.callCode}
                                      </span>
                                    </div>
                                    <input className="form-control form-input" onClick={this.onInputClick} autoComplete="no-fill" type="text" id="input_phone_forget" aria-describedby="basic_addon1" defaultValue={userMobileNumber && userMobileNumber} onInput={this.maxLengthCheck} onKeyUp={this.mobileValidation} onBlur={this.EmailValidation01} />
                                  </div>
                                  <small className="text-left" id="emailmobErr" style={{ display: "none", opacity: "0.8" }}></small>
                                  <small className="error-txt-mobile hidden-prop " id="error_txt">
                                    {ErrorMess.alertMessages.login.MNNE}
                                    <img className="error-icon-triangle" src={Error} style={{ width: "12px" }} alt="" />
                                  </small>
                                </div>

                                <div className="btn gradient1-bg gemplex-cursor regular rounded ml-auto mr-auto  mb-3 text-white col-md-12 outline cursor" id="resend_otp" onClick={this.resentOtpSent}> Send OTP </div>
                                {/* <div className="gemplex-regular mb-2 p-3" style={{ outline: "none", fontSize: "11px" }}>
                                  <span className="cursor" onClick={this.passwordScreeen} id="back_option" >
                                    <i className="fas fa-arrow-left back-arrow mx-1"></i>
                                    Back
                              </span>
                                </div> */}
                              </div>

                              <div className="gemplex-regular mb-2 p-3" style={{ outline: "none", fontSize: "11px" }}>
                                <span className="cursor" onClick={this.mobileScreen} id="" >
                                  <i className="fas fa-arrow-left back-arrow mx-1"></i>
                                  Back
                              </span>
                              </div>

                            </div>
                            {/* : */}
                            {/* send otp block is started.. */}
                            {/* } */}
                            {/* back button of enter your mobile number */}
                            <div className="gemplex-regular mb-2 p-3" style={{ outline: "none", fontSize: "11px", display: "none" }}>
                              <span className="cursor" onClick={this.mobileScreen} id="back_option" >
                                <i className="fas fa-arrow-left back-arrow mx-1"></i>
                                Back
                              </span>
                            </div>
                          </span>
                          <div className="modal-otp pb-0" id="otp_input_edit" style={{ display: "none" }}>
                            <h6 className="otp-screen-head">Enter the 4-digit code</h6>
                            <OtpInput onChange={otp => self.setState({ otp })} numInputs={4} isInputNum={true} shouldAutoFocus={true} inputStyle={"otp-input"} separator={<span className="space-otp"> </span>} />
                            <small className="error-txt-otp display-none" id="otp_error">
                              {ErrorMess.alertMessages.login.WOTP}
                              <img className="error-icon-triangle ml-1" src={Error} style={{ width: "12px" }} alt="" />
                            </small>
                            <div className="button cursor resend-top-close" id="resend_code" rel="nofollow" target="_blank" onClick={this.resentOtpSent}>Resend Code</div>
                            <div id="resend_counter">
                              <b></b>
                              <div>
                                <div className="resendcounter" style={{ fontFamily: 'Gemplex-Light', fontSize: '12px', paddingBottom: '-8%', float: 'left', width: 'auto', paddingTop: '10px' }}>Resend Code <span>{resent_otp}</span> seconds</div></div>
                            </div>

                            <div className="btn gradient1-bg gemplex-regular cursor rounded mt-3 mb-2 text-white col-md-12" style={{ outline: "none" }} onClick={this.verifyOtp}>
                              <span className="spinner-border spinner-border-sm" style={{ display: 'none' }} id="login_re_otp_spinner_btn" role="status" aria-hidden="true"></span>
                              verify Otp</div>
                          </div>
                          <div id="third_div_section" style={{ display: "none" }}>
                            <div className="form-wrapper" id="user_fields" style={{ marginTop: "-5%" }}>
                              <div className="form-group mt-0 mb-0 focused">
                                <label className="form-label" htmlFor="user_name" >
                                  Enter Name
                                </label>
                                <div className="input-group mb-1">
                                  <input type="text" className="form-control form-input" id="user_name" aria-describedby="basic-addon1" style={inputStyle} />
                                </div>
                                <small className="error-txt-name" id="user_detail_name" style={{ visibility: "hidden" }}>
                                  Please enter valid Name
                                <img className="error-icon-triangle" src={Error} style={{ width: "12px" }} alt="" />
                                </small>
                              </div>
          
    <div class="form-group"  style={{marginTop:"0px"}} >
    <label for="gnd" style={{float:"left",marginBottom: "0px",fontSize:"12px"}}>Gender <span style={{color:"red"}}>*</span></label>
    
    <select class="custom-select input-lg field-color" id="gnd" defaultValue={userData && userData.gender ? userData.gender : ''} onChange={(e) => this.handleChangeGender(e)}>
      <option value="Male">Male</option>
      <option value="Female">Female</option>
      <option value="Other">Other</option></select>
      <i class="fa fa-chevron-down"></i>
   
  </div>


  <div class="form-group"  style={{marginTop:"0px"}} >
  <label class="" for="user_name"  style={{float:"left",marginBottom: "0px",fontSize:"12px"}}>Date Of Birth <span style={{color:"red"}}>*</span></label>
      <div class="input-group  mb-1">
    <DatePick autocomplete="off" id="lgn_dob" onKeyDown={(e) => e.preventDefault(e)} className="form-control field-color user_dob_date"  />

    </div>
  </div>

{/* 
    <div class="form-group mt-0 mb-0">
     
    </div>
 */}



{/* <div class="form-group mt-2 mb-4">
<label className="form-label" htmlFor="">Date Of Birth</label>
<DatePick  id="user_dob" onKeyDown={(e) => e.preventDefault()} className="form-control field-color user_dob_date"  />
</div> */}
                              <div className="form-group mt-4 mb-4 focused">
                                <label className="form-label" htmlFor="input_passwords" style={{zIndex:"0"}}>
                                  Enter Password
                                </label>
                                <div className="input-group mb-1">
                                  <input type="password" className="form-control form-input" id="input_passwords" aria-describedby="basic-addon1" style={inputStyle} />
                                  {
                                    hideShow === true ?
                                      <i className="far fa-eye" id="fa_eye_show" style={{ paddingTop: "10px" }} onClick={this.hidePassword}></i> :
                                      <i className="far fa-eye-slash" id="fa_eye_hide" style={{ paddingTop: "10px" }} onClick={this.showPassword}></i>
                                  }
                                </div>
                                <small className="error-txt-password" id="user_detail_enter_password" style={{ visibility: "hidden" }}>
                                  Enter minimum 8 to 10 digit character
                                    {/* <img className="error-icon-triangle" src={Error} style={{ width: "12px" }} alt="" /> */}
                                </small>
                              </div>
                              <div className="form-group mt-5 mb-4 focused">
                                <label className="form-label" htmlFor="confirm_pass" style={{ top: "1px",zIndex:"0"}}>
                                  Confirm Password
                                </label>
                                <div className="input-group mb-1">
                                  <input type="password" className="form-control form-input user-conf-pass" id="confirm_pass" aria-describedby="basic-addon1" style={inputStyle} />
                                  {userhideShow === true ?
                                    <i className="far fa-eye" id="fa_eye_show" style={{ paddingTop: "10px" }} onClick={this.newUserHide}></i> :
                                    <i className="far fa-eye-slash" id="fa_eye_hide" style={{ paddingTop: "10px" }} onClick={this.newUserShow}></i>
                                  }
                                </div>
                                <small className="error-txt-email" id="user_detail_cnfrm_password" style={{ visibility: "hidden" }}>
                                  Enter minimum 8 to 10 digit character
                                  {/* <img className="error-icon-triangle" src={Error} style={{ width: "12px" }} alt="" /> */}
                                </small>
                              </div>

                            
                           
                              {/* <div className="form-group primary-container-padding">
                                  <label className="input-size" htmlFor="">
                                    Gender
                                </label>
                                  <select className="custom-select input-lg field-color" id="inputGroupSelect02"  >
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    <option value="Other">Other</option>
                      
                                  </select>
                                  <i className="fa fa-chevron-down" />
                                </div>
   */}

                              <div className="btn gradient1-bg gemplex-regular cursor rounded  mb-3 text-white col-md-12" style={{ outline: "none", textAlign: "center" }} onClick={this.userDetailUpdate}>
                                <span className="spinner-border spinner-border-sm" style={{ display: 'none' }} id="login_succe_spinner_btn" role="status" aria-hidden="true"></span>
                                Submit
                              </div>
                            </div>
                            <div className="form-wrapper" id="change_pass_id" style={{ marginTop: "-5%", display: "none" }}>
                              <div className="form-group mt-0 mb-0 ">
                                <label className="form-label" htmlFor="new_password">
                                  Enter Password
                                </label>
                                <div className="input-group mb-1">
                                  <input type="password" className="form-control form-input" id="new_password" aria-describedby="basic-addon1" style={inputStyle} />
                                  {
                                    hideShow === true ?
                                      <i className="far fa-eye" id="fa_eye_show" style={{ paddingTop: "10px" }} onClick={this.hidePassword}></i> :
                                      <i className="far fa-eye-slash" id="fa_eye_hide" style={{ paddingTop: "10px" }} onClick={this.showPassword}></i>
                                  }
                                </div>
                                <small className="error-txt-password error-txt-password-password" id="forget_password" style={{ visibility: "hidden" }}   >
                                  Please enter secure and strong password
                                  <img className="error-icon-triangle ml-1" src={Error} style={{ width: "12px" }} alt="" />
                                </small>
                              </div>
                              <div className="form-group ">
                                <label className="form-label" htmlFor="new_confirm_pass" >
                                  Confirm Password
                                </label>
                                <div className="input-group mb-1">
                                  <input type="password" className="form-control form-input" id="new_confirm_pass" aria-describedby="basic-addon1" style={inputStyle} />
                                  {
                                    userhideShow === true ?
                                      <i className="far fa-eye" id="fa_eye_show_conf" style={{ paddingTop: "10px" }} onClick={this.newUserHide}></i> :
                                      <i className="far fa-eye-slash" id="fa_eye_hide_conf" style={{ paddingTop: "10px" }} onClick={this.newUserShow}></i>
                                  }
                                </div>
                                <small className="error-txt-password error-txt-password-password" id="forget_cnfrm_password" style={{ visibility: "hidden" }}>
                                  Please enter secure and strong password
                                  <img className="error-icon-triangle ml-1" src={Error} style={{ width: "12px" }} alt="" />
                                </small>
                              </div>
                              <div className="btn gradient1-bg gemplex-regular rounded cursor mb-3 text-white col-md-12" style={{ outline: "none", textAlign: "center" }} onClick={this.forgetPassword}  >
                                Log In
                              </div>
                            </div>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                  {
                    socailLoginVisibility === true ?
                      <div className="row px-4 mt-3 pb-1 text-center justify-content-center" id="socail_icon" >
                        <div className="col-md-8">
                          <div className="modallogin-policy py-3">
                            By Proceeding you agree to the
                            <a className="modal-login px-1" href="/terms-and-conditions" target="_blanck"> Terms of Use </a>
                            &
                            <a className="modal-login px-1" href="/privacy-policy" target="_blanck">
                              Privacy Policy
                            </a>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <hr className="hr-text" data-content="Social Login" />
                        </div>
                        <SocialLogin />
                      </div>
                      :
                      ""
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


const inputStyle = {
  paddingTop: "10px",
  borderRadius: "0px"
}