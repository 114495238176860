import React, { Component } from 'react';

export default class LyricsModal extends Component {
    constructor() {
        super();
        this.state = {
        }
    }
    componentDidMount() {
    }
    render() {
        const {currentPlayData} = this.props
        return (
            <div>
                <div className="modal fade music-lyrics-modal" id="music-lyrics-modal">
                    <div className="modal-dialog modal-sm modal-dialog-centered">
                        <div className="modal-content p-0">
                            <div className="modal-header">
                                <h4 className="modal-title lyrics-header" >Lyrics</h4>
                                <button type="button" className="close cursor" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body p-0 add-to-playlist-body">
                                {/* <button type="button" className="close position-absolute" style={{ right: 20, top: 10, zIndex: 2 }} data-dismiss="modal">&times;</button> */}
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-12 styling-lyrics" >
                                            <p className="font-fam">
                                               {currentPlayData && currentPlayData.lyrics?currentPlayData.lyrics:null}
                                            </p>
                                           
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

}