import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";

import ApiList from "../../config/api.js";
import CommonApi from "../../util/common_api_handler.js";
import HeaderJson from '../../util/headers.js';
import Filter from '../partials/filter';
import SliderComp from "./HomeSlider.jsx";

const offSet = 0;
const limitRange = 13;
const catalogueOffset = 0;
const catalogueLimit = 999;

class HomeSectionComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      slider: [],
      catalogNames: this.props.catalogueName,
      pageType: this.props.pageType,
      mdata: [],
      listDataFromChild: '',
      sortByData: '',
      listLanguage: []
    };
  }

  componentDidMount = () => {
    //scrollTo Function use because every time page start form top.
    window.scrollTo(0, 0);
    const self = this;
    self.fetchSliderData(self.state.catalogNames, self.state.pageType, offSet, limitRange, catalogueOffset, catalogueLimit);
  }

  fetch_data = (data) => {
    const self = this;
    if (data != null) {
      let component_arry_var = self.state.slider.slice();
      for (let i = 0; i < data.length; i++) {
        component_arry_var.push(<SliderComp slider_data={data[i]} />);
      }
      self.setState({
        slider: component_arry_var
      })
    }
  }

  fetchSliderData = (catName, pageType, offSet, limitRange, catalogueOffset, catalogueLimit) => {
    const self = this;

    const apiUrl = ApiList.current.url + ApiList.endpoints.home.homeCatalogue;

    const apiHeaders = HeaderJson.header();

    const bodyData = {
      "genreFilter": self.state.listDataFromChild ? self.state.listDataFromChild : [],
      "subCatalogue": [],
      "languageFilter": self.state.listLanguage ? self.state.listLanguage : [],
      "sortBy": self.state.sortByData ? self.state.sortByData : '',
      "parental_control": false,
      "page_type": pageType,
      "catalogue_name": catName,
      "offset": offSet,
      "limit": limitRange,
      "catalogue_offset": catalogueOffset,
      "catalogue_limit": catalogueLimit,
    }

    CommonApi.postCall(apiUrl, apiHeaders, bodyData)
      .then((data) => {
        if (data.statusCode === 200 && data.data) {
          let mdata = data.data
          let component_arry_var = self.state.slider.slice();
          for (let i = 0; i < mdata.length; i++) {
            component_arry_var.push(<SliderComp slider_data={mdata[i]} pageType={pageType} catName={catName} key={i} />);
          }
          self.setState({
            slider: component_arry_var,
            mdata: data.data
          })
        }
      })
  }

  fetchGenreData = (dataFromChild) => {
    const self = this
    self.setState({
      listDataFromChild: dataFromChild,
      slider: []
    }, () => {
      self.fetchSliderData(self.state.catalogNames, self.state.pageType, offSet, limitRange, catalogueOffset, catalogueLimit);
    });
  }

  fetchSortData = (childSorting) => {
    const self = this;
    self.setState({
      sortByData: childSorting,
      slider: []
    }, () => {
      self.fetchSliderData(self.state.catalogNames, self.state.pageType, offSet, limitRange, catalogueOffset, catalogueLimit);
    })
  }

  fetchLanguageData = (data) => {
    const self = this;
    self.setState({
      listLanguage: data,
      slider: []
    }, () => {
      self.fetchSliderData(self.state.catalogNames, self.state.pageType, offSet, limitRange, catalogueOffset, catalogueLimit);
    })
  }

  renderSkeletonTiles = () => {
    var aa = [];
    for (let i = 0; i < 4; i++) {
      aa.push(
        <div key={i}>
          <div className="mr-2" style={{ "height": "250px", "width": "270px" }}>
            <Skeleton width={"100%"} height={"100%"} />
          </div>
          <div className="mt-1" style={{ "width": "120px" }}>
            <label className={"m-0"} style={{ "width": "80px" }}>
              <Skeleton width={"100%"} height={"100%"} />
            </label>
            <label style={{ "width": "120px" }}>
              <Skeleton width={"100%"} height={"100%"} />
            </label>
          </div>
        </div>
      )
    }
    return aa;
  }
  render() {
    const self = this
    const { catalogueName, bread } = this.props;
    
    return (
      <div>
        {bread !== 'false' ?
          <div> <Filter Language_type="video" showName='Genre' type={"video"} getGenre={this.fetchGenreData} getLanguage={this.fetchLanguageData} getSorting={self.fetchSortData} /> </div> : null}
        <div className="container-fluid mt-2">
          <div className="row primary-container-padding">
            {this.state.slider.length > 0 ?
              <div className="col-xl-12 p-0">
                {catalogueName !== '' ? <div className="col-xl-12 mt-4 p-0" >
                  <div className="catalogue_name primary3-text">
                    {catalogueName}
                  </div>
                </div> : null}
                {this.state.slider}
              </div>
              :
              <>
                <div className="row p-1 m-0">
                  {this.renderSkeletonTiles()}
                </div>
                <div className="row p-1 m-0">
                  {this.renderSkeletonTiles()}
                </div>
                <div className="row p-1 m-0">
                  {this.renderSkeletonTiles()}
                </div>
              </>
            }
          </div>
          <div className="container-fluid">
            <div className="row justify-content-center my-3">
              <div className="col-md-11 text-center">
                <img src='https://preprod-gemplex-web.s3.ap-south-1.amazonaws.com/gemplex-web_ad01-1280-300.jpg' alt="GEMPLEX ONLINE" className="h-100 w-100 img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HomeSectionComponent;
