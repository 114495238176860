import React, { Component } from 'react';
import swal from 'sweetalert';
import $ from 'jquery';

import ApiList from "../../config/api.js";
import HeaderJson from '../../util/headers.js';
import CommonApi from "../../util/common_api_handler.js";

export default class Playlist extends Component {
    constructor(props) {
        super(props);
        this.state = {
            playlistQueue: [],
            playListId: '',
            playlistData: []
        }
    }

    componentDidMount = () => {
        this.fetchPlaylistData()
    }

    // Playlist Create Api Call Here
    createPlaylist = () => {
        const self = this;
        const playlistName = document.getElementById('create-playlist-input').value

        const apiUrl = ApiList.current.url + ApiList.endpoints.music.createPlaylist;

        const apiHeaders = HeaderJson.header();

        const bodyData = {
            "user_id": this.props.userProfile ? this.props.userProfile.userId : this.state.userProfile,
            "playlist_name": playlistName
        }

       if(playlistName.trim().length >= 1){
        CommonApi.postCall(apiUrl, apiHeaders, bodyData)
        .then((data) => {
            if (data.statusCode === 200) {
                self.addToPlaylist(data.data._id);
                $('.playlist-name').val('')
            }
        })
       }else{
        swal({ text: "Please Enter Playlist Name", timer: 1500 })
       }
    }

    // Song Add to Playlist Here
    addToPlaylist = (playlist_name) => {
        const self = this;
        const media_id = this.props.mediaId ? this.props.mediaId : ''
        const content_id = this.props.song_content_id ? this.props.song_content_id : null

        const apiUrl = ApiList.current.url + ApiList.endpoints.music.addToPlaylist;
        const apiHeaders = HeaderJson.header();
        const bodyData = {
            "playlist_id": playlist_name,
            "media_manager_id": media_id,
            "content_id": content_id,
        }
        CommonApi.postCall(apiUrl, apiHeaders, bodyData)
            .then((data) => { 
                if (data.statusCode === 200) {
                    self.setState({
                        playListId: playlist_name
                    })
                    swal({ text: data.message, timer: 1500 })
                    self.fetchPlaylistData(playlist_name)
                }else if(data.statusCode === 404){
                    swal({ text: data.message , timer: 1500 })
                }
            })
    }

    // Fetch User Playlist Api Call Here 
    fetchPlaylistData = () => {
        const self = this;

        const apiUrl = ApiList.current.url + ApiList.endpoints.music.fetchMyPlaylist;

        const apiHeaders = HeaderJson.header();

        CommonApi.getCall(apiUrl, apiHeaders)
            .then((data) => {
                self.setState({
                    playlistQueue: data.data
                })
            })
    }

    // Delete Playlist Api Call Here
    deletePlaylist = (id) => {
        const self = this;
        let playlistId = id && id

        const apiUrl = ApiList.current.url + ApiList.endpoints.music.delete + "/" + playlistId;

        const apiHeaders = HeaderJson.header();

        CommonApi.deleteCall(apiUrl, apiHeaders)
            .then((data) => {
                if (data.statusCode === 200) {
                    self.fetchPlaylistData()
                }
            })
    }

    render() {
        const self = this
        const { playlistQueue } = this.state;
        return (
            <div>
                <div className="modal fade playlist-modal" id="playlist-modal">
                    <div className="modal-dialog modal-sm modal-dialog-centered">
                        <div className="modal-content p-0">
                            <div className="modal-header">
                                <h4 className="modal-title">Add to Playlist</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body p-0 add-to-playlist-body">
                                {/* <button type="button" className="close position-absolute" style={{ right: 20, top: 10, zIndex: 2 }} data-dismiss="modal">&times;</button> */}
                                <div className="container-fluid">
                                    <div className="row">
                                        {playlistQueue ? playlistQueue.map(function (pqitem, i) {
                                            return (
                                                <div className="col-md-12 list-of-playlist cursor" key={pqitem._id}>
                                                    <span className="mr-3">{pqitem.songs.length} </span>  <span onClick={() => self.addToPlaylist(pqitem._id)}>{pqitem.playlist_name} </span> <span className="float-right playlist-delete-fa" onClick={() => self.deletePlaylist(pqitem._id)}> <i className="far fa-trash-alt"></i> </span>
                                                </div>
                                            )
                                        }) : null}
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 create-playlist">
                                            <div className="input-group">
                                                <input type="text" id="create-playlist-input" className="form-control primary2-border playlist-name" placeholder="Create playlist" aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                                <div className="input-group-append " onClick={this.createPlaylist} >
                                                    <span className="input-group-text" id="basic-addon2" >  <i className="fas fa-plus primary2-text"></i></span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

}