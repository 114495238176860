import React, { Component } from 'react';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

import ApiList from "../../config/api.js";
import CommonApi from "../../util/common_api_handler.js";
import RegenrateToken from "../../util/regenrateToken.js";
import HeaderJson from '../../util/headers.js';
import Footer from "../partials/footer";
import PageJourny from "../../util/page_journey.js";
import Navbar from "../partials/NavigationBar.jsx";
import Recommended from '../common/recommended';

import '../../stylesheet/cast/index.css';

export default class PageName extends Component {
    constructor(props) {
        super(props);
        this.state = {
            castData: null,
            castList: [],
            baseUrl: [],
            renderingStatus: true,
        }
    }

    componentDidMount() {
        const self = this
        let castId = this.props.match.params.cast_id;
        PageJourny.setDeviceInfo()
            .then((data) => {
                self.fetchData(castId);
            });
        self.fetchCasting();
    }

    fetchData = (castId) => {
        const self = this;

        const apiUrl = ApiList.current.url + ApiList.endpoints.cast.castApi + '/' + castId;

        const apiHeaders = HeaderJson.header()

        CommonApi.getCall(apiUrl, apiHeaders)
            .then((data) => {
                if (data.statusCode === 200) {
                    self.setState({
                        baseUrl: data,
                        renderingStatus: true,
                        castData: data.data[0]
                    });
                }
            })
            .catch((err) => {
                if (err.responseJSON.status_code === 403 || err.responseJSON.status_code === 400) {
                    RegenrateToken.userRegenrateToken()
                        .then((data) => {
                            self.setState({
                                renderingStatus: true
                            });
                        })
                }
            })
    }

    fetchCasting = () => {
        const self = this;

        const bodyData = {
            "offset": 0,
            "limit": 10,
            "sortBy": "popular",
        }

        const apiUrl = ApiList.current.url + ApiList.endpoints.cast.castList;

        const apiHeaders = HeaderJson.header()

        CommonApi.postCall(apiUrl, apiHeaders, bodyData)
            .then((data) => {
                if (data.statusCode === 200 && data.data) {
                    self.setState({
                        castList: data.data
                    })
                }
            })
            .catch((err)=>{
                if (err.responseJSON.status_code === 403 || err.responseJSON.status_code === 400) {
                    RegenrateToken.userRegenrateToken()
                        .then((data) => {
                            self.setState({
                                renderingStatus: true
                            });
                        })
                }
            })
    }

    pageRedirect = () => {
        window.location.href = "/cast-producer-listings"
    }

    detailPage = (data) => {
        if (data) {
            window.location.href = `/cast/${data._id}`
        }
        
    }
    render() {
        const { castData, baseUrl, castList, renderingStatus} = this.state
        let styleBg = null
        if (castData != null) {
            styleBg = {
                backgroundImage: 'url(' + baseUrl.people_banner_base_url + "/" + castData.banner_file_name + ')'
            }
        }
        return (
            <div>
                <Navbar />
                {
                    renderingStatus ? 
                    <>
                    {castData ?
                    <>
                        <div className="container-fluid cast-section primary-container-padding position-relative on-mobileview" style={styleBg}>
                            <div className="bg-layer"></div>
                            <div className="row h-100">
                                <div className="col-xl-12 mt-auto mb-5 cast-onmobile" style={{ margin: "-12px" }}>
                                    <div className="row">
                                        <div className="col-md-auto profile-onmobile p-0">
                                            <img src={baseUrl.people_file_base_url + "/" + castData.file_name} className="cast-image" alt={castData.name} /></div>
                                        <div className="col-md-6 my-auto text-white profile-title">
                                            <h4>{castData.name}</h4>
                                            <h6>{castData.designations.length !== 0 ? castData.designations[0].name : ''}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container-fluid primary-container-padding profile-bio">
                            <div className="row mt-3">
                                <div className="col-md-8">
                                    <h3>Bio</h3>
                                    <p>{castData.short_description}</p>
                                    <h5 className="mt-4">Have also worked in</h5>
                                    {
                                        castData && castData._id ?
                                            <div>
                                                <Recommended title={'Movies'} content_type={'VIDEO'} catalogue={['MOVIES']} type={"people"} keywords={castData._id} />
                                                <Recommended title={'Originals'} content_type={'VIDEO'} catalogue={['ORIGINALS']} type={"people"} keywords={castData._id} />
                                                <Recommended title={'Videos'} content_type={'VIDEO'} catalogue={['VIDEOS']} type={"people"} keywords={castData._id} />
                                                <Recommended title={'Music'} content_type={'AUDIO'} catalogue={['MUSIC']} type={"people"} keywords={castData._id} />
                                                <Recommended title={'Hype'} content_type={'VIDEO'} catalogue={['HYPE']} type={"people"} keywords={castData._id} />
                                            </div>
                                            : null
                                    }
                                </div>
                                <div className="col-md-4 people-div">
                                    {
                                        castList !== null ?
                                            <div className="card">
                                                <div className="card-body" style={{ height: 'auto' }}>
                                                    <h6 className="d-inline-block primary1-text gemplex-medium sub-heading">People & More</h6>
                                                    <p className="d-inline-block primary1-text gemplex-regular sub-heading cursor position-right" onClick={this.pageRedirect}><small>See All</small></p>
                                                    <ul className="p-0">
                                                        {castList.map((item) => {
                                                            return (
                                                                <span key={item._id} className="cursor" onClick={() => this.detailPage(item)}>
                                                                    
                                                                    <div className="media py-2">
                                                                        <img src={baseUrl.people_file_base_url + "/" + item.file_name} className="mr-3" alt="" style={{ width: '100px' }} />
                                                                        <div className="media-body">
                                                                            <h6 className="mt-0">{item.name}</h6>
                                                                            <p className="mt-0">{item.designations && item.designations.length >= 1 ? item.designations[0].name : ""}</p>
                                                                        </div>
                                                                    </div>
                                                                </span>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>
                                            :
                                            null
                                    }
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className="w-100 banner-response" style={{ marginTop: '-4px' }}>
                            <Skeleton width={"100%"} height={"100%"} />
                        </div>
                        <div className="w-100 inner-banner-response">
                            <Skeleton width={"100%"} height={"100%"} />
                            <div className="ml-5" style={{ "position": "absolute", "left": "0", "width": "200px", "height": "90px", "display": "inline-flex" }}>
                                <label className="m-0 ml-5" style={{ "height": "30px", "width": "150px", "position": "absolute", "left": "0" }}>
                                    <SkeletonTheme height={"30px"} highlightColor={"#fafafa"} color={"#d5d5d5"}>
                                        <Skeleton width={"100%"} height={"100%"} />
                                    </SkeletonTheme>
                                </label>
                                <label className="m-0 ml-5 mt-5" style={{ "height": "30px", "width": "80px", "position": "absolute", "left": "0" }}>
                                    <SkeletonTheme height={"30px"} highlightColor={"#fafafa"} color={"#d5d5d5"}>
                                        <Skeleton width={"100%"} height={"100%"} />
                                    </SkeletonTheme>
                                </label>
                            </div>
                        </div>
                    </>
                }
                    </>:
                    <>
                    </>
                }
                <Footer />
            </div>
        )
    }

}