
import React, { Component } from "react";
import Cookies from "universal-cookie";
import Skeleton from "react-loading-skeleton";

import Ad from "../partials/ad";
import ApiList from "../../config/api.js";
import CommonApi from "../../util/common_api_handler.js";
import Cast from "./CastSliderPage.jsx";
import Footer from "../partials/footer";
import HeaderJson from '../../util/headers.js';
import MovieInfo from "./MovieInfo.jsx";
import Navbar from "../partials/NavigationBar.jsx";
import PageJourny from "../../util/page_journey.js";
import RegenrateToken from "../../util/regenrateToken.js";
import Recommended from "../common/recommended";

import "../../stylesheet/partials/hover-slider.css";
import MetaData from "../partials/metaData";

const cookies = new Cookies();
export default class DetailPage extends Component {
  state = {
    headers: HeaderJson.header(),
    mainData: [],
    favouriteFlag: [],
    profile: '',
    passNavigation: '',
    renderingStatus: false,
    deviceActive: []
  };

  componentDidMount = () => {
    //scrollTo Function use because every time page start form top.
    window.scrollTo(0, 0);
    // PageJourny Function Call because first check all needed header in cookies. 
    PageJourny.setDeviceInfo()
      .then((data) => {
        //Function Call Here.
        this.callFunction();
      });
  }

  callFunction = () => {
    const self = this;
    const contentId = self.props.match.params.content_id ? self.props.match.params.content_id : null;
    if (cookies.get('userType') !== undefined || cookies.get('xAccessToken') !== undefined) {
      cookies.get('userType') === "REGULAR" && self.fetchUserData()
      self.fetch_video(contentId);
    }
  }

  fetchUserData = () => {
    const self = this;
    const apiUrl = ApiList.current.url + ApiList.endpoints.user.userProfile;
    const apiHeaders = HeaderJson.header();
    CommonApi.getCall(apiUrl, apiHeaders)
      .then((data) => {
        if (data.statusCode === 200) {
          const deviceId = cookies.get('userBrowserVersion');
          const filterDevice = data.data.devices.filter(item => item.device_id === deviceId)
          var activeDevice = filterDevice.filter(item => item.status === 'active');
          self.setState({
            deviceActive: activeDevice,
            profile: data.data,
          });
        }
      })
  }

  fetch_video = (contentId) => {
    const self = this;
    const apiUrl = ApiList.current.url + ApiList.endpoints.detail.videoDetail + contentId;
    const apiHeaders = HeaderJson.header();
    CommonApi.getCall(apiUrl, apiHeaders)
      .then((data) => {
        if (data.statusCode === 200) {
          let mainData = data.data;
          self.setState({
            mainData: mainData,
            favouriteFlag: mainData,
            renderingStatus: true
          });
        }
      })
      .catch((err) => {
        if (err.responseJSON.status === 403 || err.responseJSON.status === 400) {
          RegenrateToken.userRegenrateToken()
            .then((data) => { self.setState({ renderingStatus: true }); })
        }
      })
  }


  dataGate = (data) => {
    this.setState({ passNavigation: data.data.nModified })
  }

  detailAgain = (data) => {
    this.fetch_video(data.content_id)
  }

  renderSkeletonTiles = () => {
    var aa = [];
    for (let i = 0; i < 5; i++) {
      aa.push(
        <div key={i}>
          <div className="mr-2" style={{ "height": "150px", "width": "170px" }}>
            <Skeleton width={"100%"} height={"100%"} />
          </div>
          <div className="mt-1" style={{ "width": "120px" }}>
            <label className={"m-0"} style={{ "width": "80px" }}>
              <Skeleton width={"100%"} height={"100%"} />
            </label>
            <label style={{ "width": "120px" }}>
              <Skeleton width={"100%"} height={"100%"} />
            </label>
          </div>
        </div>
      )
    }
    return aa;
  }
  render() {
    const { mainData, favouriteFlag, profile, deviceActive, passNavigation, renderingStatus } = this.state;
    const title_data = mainData.meta_data ? mainData.meta_data.meta_tags[0] : '';
    const keywords_data = mainData.meta_data ? mainData.meta_data.meta_keywords[0] : '' ;
    const description_data = mainData.meta_data ? mainData.meta_data.meta_description : '';
    const title_tag = mainData.meta_data ? mainData.meta_data.meta_tags[0] : '';
    return (
      <>
        <MetaData title={title_data} description={description_data} keywords={keywords_data}  mainTitle={title_tag} />
        <Navbar navData={passNavigation} />
        {renderingStatus === true ?
          <>
            {mainData != null ?  <MovieInfo description={mainData} callApi={this.detailAgain} userProfile={profile} deviceList={deviceActive} favourite_flag={favouriteFlag.is_favourite} reverseData={this.dataGate} /> : null }
            <Ad />
            {mainData.genre ?
              <div className="container-fluid">
                <div className="row primary-container-padding">
                  <div className="col-md-12 p-0">
                    <Recommended content_type={"VIDEO"} catalogue={['ORIGINALS', 'VIDEOS', 'MOVIES']} type={"genre"} keywords={mainData.genre} />
                  </div>
                </div>
              </div>
              :
              null
            }
            {mainData.cast ? <Cast name={'Cast'} baseUrl={mainData.people_file_base_url} slider_data={mainData.cast} /> : null }
            {mainData.crew ? <Cast name={'Crew'} baseUrl={mainData.people_file_base_url} slider_data={mainData.crew} /> : null }
          </>
          :
          <>
            <div className="w-100 banner-response" style={{ marginTop: '-4px' }}>
              <Skeleton width={"100%"} height={"100%"} />
            </div>
            <div className="w-100 inner-banner-response">
              <Skeleton width={"100%"} height={"100%"} />
              {/* <div className="ml-5" style={{ "position": "absolute", "left": "0", "width": "200px", "height": "90px", "display": "inline-flex" }}>
              <img src={playButton} className="" alt='' style={{ "height": "40px", "width": "40px", "position": "absolute", "left": "0", "marginTop": "2px" }} />
              <label className="m-0 ml-5" style={{ "height": "30px", "width": "150px", "position": "absolute", "left": "0" }}>
              <SkeletonTheme height={"30px"} highlightColor={"#fafafa"} color={"#d5d5d5"}>
              <Skeleton width={"100%"} height={"100%"} />
              </SkeletonTheme>
              </label>
              <label className="m-0 ml-5 mt-5" style={{ "height": "30px", "width": "80px", "position": "absolute", "left": "0" }}>
              <SkeletonTheme height={"30px"} highlightColor={"#fafafa"} color={"#d5d5d5"}>
              <Skeleton width={"100%"} height={"100%"} />
              </SkeletonTheme>
              </label>
              </div> */}
            </div>
            <div className="row p-5 m-0">
              {this.renderSkeletonTiles()}
            </div>
            <div className="row p-5 m-0">
              {this.renderSkeletonTiles()}
            </div>
            <div className="row p-5 m-0">
              {this.renderSkeletonTiles()}
            </div>
          </>
        }
        <Footer />
      </>
    );
  }
}
