import React, { Component } from "react";
import swal from 'sweetalert';
import $ from "jquery";
import Form from "react-validation/build/form";

import ApiList from "../../../config/api.js";
import CommonApi from "../../../util/common_api_handler.js";
import ErrorMess from "../../../util/error.js";
import HeaderJson from '../../../util/headers.js';

import "../../../stylesheet/user/user_detail/language.css";


export default class Language extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoLanguage: [],
      musicLanguage: [],
      videoCode: [],
      videoLanguageCheck: [],
      musicLanguageCheck: [],
      musicCode: [],
      videoLanguageSelect: [],
      musicLanguageSelect: [],
      videoValue: '',
      musicValue: '',
      value: false,
      renderFlag: false,
      profile: [],
    };
  }
  componentWillMount = () => {
    const self = this;
    self.fetchData();
  }
  componentDidMount = () => {

    const self = this;

    self.videoArray();
    self.musicArray();

  }
  fetchData = () => {

    const apiUrl = ApiList.current.url + ApiList.endpoints.user.userProfile;

    const apiHeaders = HeaderJson.header();

    CommonApi.getCall(apiUrl, apiHeaders)
      .then((data) => {
        if (data.statusCode === 200) {
          this.setState({
            profile: data.data,
            videoValue: data.data.preferred_language_video,
            musicValue: data.data.preferred_language_music,
            renderFlag: true
          });
        }
      })

  };

  videoArray = () => {

    const apiUrl = ApiList.current.url + ApiList.endpoints.language.language;

    const apiHeaders = HeaderJson.header();

    const bodyData = {
      type: "video",
      limit: 100,
      offset: 0
    }

    CommonApi.postCall(apiUrl, apiHeaders, bodyData)
      .then((data) => {
        if (data.statusCode === 200 && data.data) {
          var json_data = data.data;
          var language_code = this.state.videoCode.slice();
          let cat_video = [];
          json_data.map((language) => {
            cat_video.push(language);
            language_code.push(language);
            return true;
          });
          this.setState({
            videoLanguage: cat_video ? cat_video : null,
            videoCode: language_code ? language_code : null
          });
        }
      })
  }
  musicArray = () => {

    const apiUrl = ApiList.current.url + ApiList.endpoints.language.language;

    const apiHeaders = HeaderJson.header();

    const bodyData = {
      type: "music",
      limit: 100,
      offset: 0
    }

    CommonApi.postCall(apiUrl, apiHeaders, bodyData)
      .then((data) => {
        if (data.statusCode === 200 && data.data) {
          var json_data = data.data;
          var language_code = this.state.musicCode.slice();
          let cat_music = [];
          json_data.map((language) => {
            cat_music.push(language);
            language_code.push(language);
            return true;
          });
          this.setState({
            musicLanguage: cat_music ? cat_music : null,
            musicCode: language_code ? language_code : null
          });
        }
      })
  }

  userProfileApi = (type) => {
    var self = this;
    if (this.state.videoLanguageSelect.length <= 0) {

      swal({ text: type === 'video' ? ErrorMess.alertMessages.Language.Video : ErrorMess.alertMessages.Language.Music, timer: 1500 });

    } else {
      const apiUrl = ApiList.current.url + ApiList.endpoints.user.userProfile;

      const apiHeaders = HeaderJson.header();

      CommonApi.getCall(apiUrl, apiHeaders)
        .then((data) => {
          if (data.statusCode === 200 && data.data) {
            self.setState({
              profile: (data.data)
            })
            swal({ text: ErrorMess.alertMessages.Language.LUS, timer: 1500 });
          }
        })
    }
  }
  updatePrefered = async (data) => {
   
    await this.languageUpdateApi(data);
    await this.userProfileApi(data)

  }

  languageUpdateApi = (type) => {
    const self = this;

    const apiUrl = ApiList.current.url + ApiList.endpoints.user.updatePreferredArray;

    const apiHeaders = HeaderJson.header();

    const bodyData = {
      type: type,
      preferred_language_video: self.state.videoLanguageSelect != null ? self.state.videoLanguageSelect : null,
      preferred_language_music: self.state.musicLanguageSelect != null ? self.state.musicLanguageSelect : null
    }

    CommonApi.postCall(apiUrl, apiHeaders, bodyData)
      .then((data) => {
        if (data.statusCode === 200) {
          self.setState({
            profile: (data.data)
          })
        }
      })
  }
  changeVideoLanguage = () => {

    const self = this;

    var videoArray = [];

    $(`#video_value:checked`).each(function (i) {
      videoArray[i] = $(this).val();
    });

    self.setState({ videoLanguageSelect: videoArray });

  }
  changeMusicLanguage = (e) => {

    const self = this;

    let musicArray = [];

    $("#music_value:checked").each(function (i) {
      musicArray[i] = $(this).val();
    });

    self.setState({ musicLanguageSelect: musicArray });

  }
  handleChange = (event) => {
    this.setState({ value: event.target.value });
  }

  render() {
    const { videoLanguage, videoValue, musicLanguage, musicValue } = this.state;
    const self = this;
    return (
      <div>
        {this.state.renderFlag === true
          ? <div className="container-fluid">
            <Form onSubmit={this.handleSubmit}>
              <div className="row">
                <div className="col-md-12 primary-container-padding ">
                  {/* <h6 className="setting-tab-head"> Languages</h6> */}
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-12 primary-container-padding parental-control">
                  <div className="parental-para">
                    Choose the languages you'd like to listen to and personalize your content.
                </div>
                </div>
              </div>
              <div className="row  mt-4">
                <div className="col-md-12 primary-container-padding parental-control">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item">
                      <a className="nav-link active" id="video-tab" data-toggle="tab" href="#videolanguage" role="tab" aria-controls="video" aria-selected="true">Video</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" id="music-tab" data-toggle="tab" href="#musiclanguage" role="tab" aria-controls="music" aria-selected="false">Music</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 primary-container-padding parental-control">
                  <div className="tab-content" id="myTabContent" style={{ padding: '15px' }}>
                    <div className="tab-pane fade show active" id="videolanguage" role="tabpanel" aria-labelledby="video-tab">
                      <div className="row">
                        {videoLanguage.map(function (itemv) {
                          return (
                            <div className="col-4 col-sm-4 col-md-4 col-lg-2" key={itemv.language_name}>
                              <div className="custom-control custom-checkbox" style={{ width: '150%', display: 'flex' }}>
                                <input
                                  type="checkbox"
                                  id="video_value"
                                  name="video"
                                  className="dropdown-check styling-dropdown cursor"
                                  defaultValue={itemv.language_code}
                                  onClick={self.changeVideoLanguage}
                                  defaultChecked={(videoValue.indexOf(itemv.language_code) !== -1) ? 'checked' : ''}
                                  onChange={self.handleChange}
                                  style={{ width: "20px", height: "20px" }}
                                  autoComplete="off"
                                />
                                <label className="">{itemv.language_name}</label>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                      <button type="button" className="btn br18 gradient1-bg border-0 mr-0 text-white gemplex-regular primary-container-padding py-1 user-save-detail-button" onClick={()=>self.updatePrefered('video')}> Save </button>
                    </div>

                    <div className="tab-pane fade" id="musiclanguage" role="tabpanel" aria-labelledby="music-tab">
                      <div className="row">
                        {musicLanguage.map(function (item) {
                          return (
                            <div className="col-4 col-sm-4 col-md-4 col-lg-2" key={item.language_code}>
                              <div className="custom-control custom-checkbox" style={{ width: '150%', display: 'flex' }}>
                                <input
                                  type="checkbox"
                                  id="music_value"
                                  name="music"
                                  className="dropdown-check styling-dropdown cursor"
                                  defaultValue={item.language_code}
                                  onClick={self.changeMusicLanguage}
                                  defaultChecked={(musicValue.indexOf(item.language_code) !== -1) ? 'checked' : ''}
                                  onChange={self.handleChange}
                                  style={{ width: "20px", height: "20px" }}
                                  autoComplete="off"
                                />
                                <label className="">{item.language_name}</label>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                      <button type="button" className="btn br18 gradient1-bg border-0 mr-0 text-white gemplex-regular primary-container-padding py-1 user-save-detail-button" onClick={()=>self.updatePrefered('music')}> Save </button>
                    </div>

                  </div>
                </div>
              </div>
              {/* <div className="row"> */}
                {/* <div className="col-md-12 primary-container-padding parental-control">   
                </div> */}
              {/* </div> */}
            </Form>
          </div>
          : ''
        }
      </div>
    );
  }
}



