/* eslint-disable indent */
/* eslint-disable no-undef */
const development = {"url": "https://apidev.gemplex.tv/api","paymentGateway" : "http://localhost:3000"};
const staging = {"url": "https://api-staging.gemplex.tv/api", "paymentGateway": "https://payment-staging.gemplex.tv"};
const production = { "url": "https://api.gemplex.tv/api","paymentGateway":"https://payment.gemplex.tv",};

const current = production;
// const current = process.env.REACT_APP_API_URL === "development" ? development : process.env.REACT_APP_API_URL === "staging" ? staging : production ;

module.exports = {
    current,
    "endpoints": {
        "adHeader" : {
            "ads" : "/ads"
        },
        "banner": {
            "bannerAll": "/banner"
        },
        "cast": {
            "castApi": "/cast",
            "castList": "/cast"
        },
        "commonApi": {
            "common": "/geoip"
        },
        "coupon":{
            "validate":"/coupon/validate"
        },
        "home": {
            "homeCatalogue": "/video/content",
            "homeCatArr": ["ORIGINALS", "MOVIES"], //MOVIES
            "grid": "/home/trending"
        },
        "hype": {
            "listHype": "/hype",
            "fetchById": "/hype/content",
            "hypePopular": "/hype-popular",
            "hypeTrending": "/hype-trending",
            "hypeTrendingTopics": "/hype/content/trending-topics",
            "hypePopularStories": "/hype/content/popular-stories",
            "hypeTrendingTopicsOnClick": "/hype/content/trending-topics/details",
            "hypeRecentStories": "/hype/recent-stories"
        },
        "getIp": {
            "ipUrl": "https://ifconfig.co/ip"
        },
        "language": {
            "language": "/language"
        },
        "genre": {
            "genre": "/genre"
        },
        "subscriptionPlan": {
            "getData": "/subscription-plans",
            "buyPlan": "/subscription/buy-plan",
            "transactionUpdate": "/subscription/update-transaction",
            "cuponCode": "/coupon/coupon-codes",
            "tvodSuccess": "/subscription"
        },
        "search": {
            "searchApi": "/video/search"
        },
        "favourite": {
            "fetchList": "/user/favourites",
            "fetchListHype": "/hype/favourites"
        },
        "watchlist": {
            "watchlist": "/user/watch-list"
        },
        "content": {
            "fetchData": "/media/content"
        },
        "video": {
            "content": "/media/content",
            "catalogue": "/content/catalogue-structure",
            "lastWatch": "/logs/last-watched"
        },
        "detail": {
            "videoDetail": "/video/content/"
        },
        "music": {
            "listing": "/audio/content",
            "detail": "/audio/",
            "createPlaylist": "/user/playlist",
            "fetchPlaylist": "/user/playlist",
            "fetchMyPlaylist": "/user/playlist-names",
            "addToPlaylist": "/user/playlist/add",
            "delete": "/user/playlist/delete",
            "detailPlaylist": "/user/playlist",
            "singleShare": "/audio/fetch",
            "singleDelete":"user/playlist/delete-songs",
            "singleSongFav" : "/user/favourite-songs/add",
            "fetchSingleSong" : "/user/favourite-songs/fetch",
            "removeSingleSong":"/user/favourite-songs/remove"
        },
        "otp": {
            "send": "/otp/send",
            "otpVerify": "/otp/mobile-verification"
        },
        "social": {
            "socialNotification": "/user/connect-with-facebook"
        },
        "user": {
            "social": "/user/social-login",
            // "userProfile": "/user/profile", //OLD PROFILE URL
            "userProfile":"/v2/user/profile",
            "userDetails": "/user/update-details",
            "verify": "/otp/verify",
            "updatePreferredArray": "/user/language-preference",
            "verifyPassword": "/user/login",
            "changePassword": "/user/change-password",
            "logOutUser": "/user/delete-token",
            "userDevices": "/user/active-devices",
            "parentalResetPin": "/user/reset-pin",
            "userClearHistory": "/user/settings",
            "devicePin" : "/user/parental-lock",
            "userRegenrateToken": "/user/regenerate-token",
            "parentalAge": "user/settings/age-restrictions",
            "userCart": "/user/cart/add",
            "deleteCart": "/user/cart/delete",
            "createOrderId": "/subscription/buy-content",
            "createOrderIdSvod": "/subscription/buy-plan/",
            "myMovies": "/user/tvod/",
            "removeDevice" : "/user/deregister-device",
            "validateParentalPassword" : "/user/parental-lock/validate-password",
        },
        "recommended": {
            "getData": "/recommended"
        },
        "nextSubscription": {
            "nextSubscription": "/user/subscription/recommended"
        },
        "sortBy": {
            "sortByList": "/sort-by-options"
        },
        "footer": {
            "pageListing": "/page-manager/fetch-names",
            "support": "/page-manager/support",
            "contactUs": "/page-manager/contact-us",
            "feedback": "/page-manager/feedback",
            "career": "/page-manager/career",
            "pageDetails": "/page-manager"
        },
        "guest": {
            "guest": "/user/guest-user"
        },
        "emailVerification": {
            "emailVerify": "/user/email/send",
            "emailToken": "/user/email/verify"
        }
    }
};