import React, { Component } from 'react';
import ApiList from "../../../config/api.js";
import axios from 'axios';
// import LogoBlack from "../../../images/logo-black.png";

import helpers from '../../../util/headers.js';
import "../../../stylesheet/email_verification/index.css"

var emailToken
export default class Name extends Component {
    constructor() {
        super();
        this.state = {
            header : helpers.header(),
            renderTrue: true
        }
    }

    componentDidMount() {
         emailToken = window.location.search.split('id=')[1];
        this.fetchToken();
    }
    fetchToken=()=>{
        let bodyData ={
            "id" : emailToken
        }
        axios.post(ApiList.current.url + ApiList.endpoints.emailVerification.emailToken,bodyData,{
            headers : this.state.header
        })
        .then(response=>response.data)
        .then(data=>{
            if(data.status === true){
                this.setState({
                    renderTrue: true
                })
            }
        })
    }
    redirect=()=>{
        window.location.href="/"
    }
    render() {
        return (
            <div>
               {
                   this.state.renderTrue === true ? 
                   <div className="container main-templet">
                   <div className="row">
                       <div className="col align-self-center div-style">
                           <div className="div-styless">
                               <img src="https://preprod-gemplex-assets.gemplex.tv/web/logo-black.png" className="" style={{ width: '12rem' }} alt="" />
                           </div>
                           <h1>Successful</h1>
                           <h4>Your email address has been successfully verfied</h4>
                           <div className="wrapper-btn-succes">
                               <button type="button" class="btn btn-success btn-lg button-succes" onClick={this.redirect}>Continue</button>
                               </div>
                       </div>
                   </div>
               </div>
               :
               <div className="container">
               <div className="row">
                   <div className="col align-self-center div-style">
                       <div className="div-styless">
                           <img src="https://preprod-gemplex-assets.gemplex.tv/web/logo-black.png" className="" style={{ width: '12rem' }} alt="" />
                       </div>
                       <h1>UnSuccessful</h1>
                       <h4>Your email address verification failed</h4>
                       <div className="wrapper-btn-succes">
                           <button type="button" class="btn btn-success btn-lg button-succes" onClick={this.redirect}>Continue</button>
                           </div>
                   </div>
               </div>
           </div>

               }
            </div>

        )
    }

}
