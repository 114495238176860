import React, { Component } from "react";

import "../../../stylesheet/hype_page/hype_blog.css"
import "../../../stylesheet/hype_page/hypetab_section.css";

export default class Article extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {  
    }
    render() {
        return (
            <div className="ad-sidebar col-xl-3 col-md-12 col-12 order-xl-3 order-md-3 order-3 d-md-none d-lg-block mt-2 ">
            <div className="container pb-2">
                <div className="row">
                    <div className="col-md-12 mt-3 p-0 ">
                        <img src="https://preprod-gemplex-assets.gemplex.tv/web/static/hype-side-ad-250-300.jpg" alt="" className="img-fluid ad-image-hype  mx-auto d-block"  />
                    </div>
                </div>
            </div>
            <div className="container pb-2" >
                <div className="row" >
                        <div className="col-md-12 mt-3  p-0 slider-hype-height" >
                        {/* <h2 className="d-inline-block font-hype primary3-text gemplex-medium sub-heading mt-2 hype-second-heading heading-hype pl-2" >Recent Stories</h2> */}
                        {/* <hr className={"slider-hr-top"}  /> */}
                            {/* <Slider {...settings}>
                              
                                <div className="item mb-3">
                                    <div className="card main-card"  >
                                        <img src={"https://s3.ap-south-1.amazonaws.com/qa-vod-media/images/media-manager/hype/hype-articles/hype-articles-images/thumbnail_4_3/media/1557499496439.jpg"} alt="" className="img-fluid"  />
                                        <div className="hover-effct">   
                                            <img  src={img_podcast} className="hype-thumbnail-icon" alt="play" />
                                        </div>
                                            
                                        </div>
                                        <div className="card-bg card-body card-border-hype">
                                        <div style={{display:'flex'}}>
                                            <div className="hype-catalog footer-color mr-2">Author</div>
                                            <div className="duration-hype">11 May, 2019 </div>
                                        </div>
                                        <div className="two-line-recent ellips-recent">
                                            <div className="footer-color text-conca-recent mt-2"> Ishaan Khatter Reveals Who's His Favourite Amongst Brother Shahid Kapoor's Ex-Girlfriends</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item mb-3">
                                    <div className="card main-card" >
                                        <img src={"https://s3.ap-south-1.amazonaws.com/qa-vod-media/images/media-manager/hype/hype-articles/hype-articles-images/thumbnail_4_3/media/1557499496439.jpg"} alt="" className="img-fluid"  />
                                        <div className="hover-effct">   
                                            <img  src={img_play} className="hype-thumbnail-icon" alt="play" />
                                        </div>
                                        </div>
                                        <div className="card-bg card-body card-border-hype">
                                        <div style={{display:'flex'}}>
                                            <div className="hype-catalog footer-color mr-3">Author</div>
                                            <div className="duration-hype">11 May, 2019 </div>
                                        </div>
                                        <div className="two-line-recent ellips-recent">
                                            <div className="footer-color text-conca-recent mt-2"> Ishaan Khatter Reveals Who's His Favourite Amongst Brother Shahid Kapoor's Ex-Girlfriends</div>
                                        </div>
                                    </div>  
                                </div>
                           
                           </Slider>   */}
                           {/* <hr className="slider-hr" /> */}
                        </div> 
                    </div>
                </div>
        </div>
        
        )
    }
}