import React, { Component } from 'react';
import ApiList from "../../config/api.js"
import $ from 'jquery';
import axios from 'axios';
import helpers from '../../util/headers.js';
import "../../stylesheet/information_page/font_color.css"

export default class Name extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.getData,
            headers: helpers.header(),
        }
    }

    componentDidMount() {
        let dataString = {
            "slug":"/about-us" 
         }
         axios.post(ApiList.current.url + ApiList.endpoints.footer.pageDetails,dataString,{
             headers: this.state.headers
         })
         .then(res=>res.data)
         .then(data=>{
             if(data.statusCode === 200){
                 $('.about-us').html(atob(data.data.html_content))
             }
         })
    }

    render() {
        return (
            <div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mt-5">
                            <div className="about-us footer-page text-left">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

}