/* eslint-disable no-useless-escape */
import React, { Component } from 'react';
import $ from 'jquery';
import swal from 'sweetalert';
import Form from "react-validation/build/form";

import ApiList from "../../config/api.js";
import headerJson from '../../util/headers.js';
import CommonApi from "../../util/common_api_handler.js";
import ErrorMess from "../../util/error.js";
import "../../stylesheet/information_page/feedback.css";

export default class Support extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.getData,
            header: headerJson.header(),
        }
    }

    phoneNumberLength = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(
                0,
                object.target.maxLength
            );
        }
    }

    postData = () => {
        const self = this;
        const fullName = document.getElementById('name').value;
        const emailId = document.getElementById('email_id').value;
        const phoneNumber = document.getElementById('mobile_no').value;
        const userSubject = document.getElementById('subject').value;
        const userDepartment = document.getElementById('exampleFormControlSelect1').value;
        const userComment = document.getElementById('comment').value;
        let dataString = {
            "name": fullName ? fullName : "",
            "email_id": emailId ? emailId : "",
            "mobile_no": phoneNumber ? phoneNumber : "",
            "subject": userSubject ? userSubject : "",
            "department": userDepartment ? userDepartment : "",
            "comment": userComment ? userComment : ""
        }
        if (fullName === "") {
            document.getElementById("nameError01").innerHTML = ErrorMess.alertMessages.login.PFN;

        } else{
            document.getElementById("nameError01").innerHTML = "";
        }
        if (emailId === "") {
            document.getElementById("EmailError").innerHTML = "Please fill the valid email";
            
        }else if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailId)){
            document.getElementById("EmailError").innerHTML = "";
        }else{
            document.getElementById("EmailError").innerHTML = "Please fill the valid email";
        }
        if (phoneNumber === "") {
            document.getElementById("ContectError").innerHTML = ErrorMess.alertMessages.login.MNNE;
        }
        else {
            document.getElementById("ContectError").innerHTML = "";
          
        }
        if(userComment ===""){
            document.getElementById("comment01").innerHTML = "Please Fill the Comment box";
        }else{
            document.getElementById("comment01").innerHTML = "";
        }
        if(phoneNumber !="" && emailId !="" && fullName !="" &&  userComment !="" && (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailId))){
            let apiUrl = ApiList.current.url + ApiList.endpoints.footer.career;
            let apiHeaders = self.state.header;
            let bodyData = dataString;

            CommonApi.postCall(apiUrl, apiHeaders, bodyData)
                .then(data => {
                    if (data.statusCode === 200) {
                        swal({ text: "Sent form Successfully", timer: 1500 })
                    }
                })
        }
    }
    AllowOnlyChar = (event) => {
        var inputval = document.getElementById('name');
        inputval.value = inputval.value.replace(/[^a-zA-Z]+/, '').trimLeft();
    }
    AllowOnlyNum = (event) => {

        var inputval = document.getElementById('mobile_no');
        inputval.value = inputval.value.replace(/[^0-9]+/, '').trimLeft();
    }
    // ValidateEmail(mail) {
    //     var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    //     document.getElementById("email_id").value
    //     if (document.getElementById("email_id").value.match(mailformat)) {
    //         document.getElementById("EmailError").innerHTML = "";
    //         return true;
    //     } else {
    //         document.getElementById("EmailError").innerHTML = ErrorMess.alertMessages.login.UNEE;
    //         return false;
    //     }
    // }
    render() { 
        $('.div-data').html(this.props.getData)
        return (
            <div>
                <div className="container">
                    <div className=" div-data"></div>
                    <Form ref={c => { this.form = c; }} onSubmit={this.handleSubmit}>
                        <div className="row">
                            <div className="col-md-12 p-4 mb-3">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group text-left">
                                            <label className="modallogin-header">
                                                Name
                                        </label>
                                            <input type="text" id="name" className="form-control" onKeyUp={this.AllowOnlyChar} />
                                        </div>
                                        <div className="pb-2 " id="nameError01" style={{marginTop:"-15px"}}></div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group text-left">
                                            <label className="modallogin-header">
                                                Email Id
                                        </label>
                                            <input type="text" id="email_id" className="form-control" onBlur={this.ValidateEmail} />
                                        </div>
                                        <div className="pb-2 " id="EmailError" style={{marginTop:"-15px"}}></div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group text-left">
                                            <label className="modallogin-header">
                                                Mobile Number
                                        </label>
                                            <input type="number" id="mobile_no" className="form-control" minLength={10} maxLength={10} onInput={this.phoneNumberLength} onKeyUp={this.AllowOnlyNum} />
                                        </div>
                                        <div className="pb-2 " id="ContectError" style={{marginTop:"-15px"}}></div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group text-left">
                                            <label className="modallogin-header">
                                                Subject
                                        </label>
                                            <input type="text" id="subject" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div class="form-group">
                                            <label for="exampleFormControlSelect1" className="modallogin-header">
                                                Department
                                        </label>
                                            <select class="form-control" id="exampleFormControlSelect1">
                                                <option>Billing</option>
                                                <option>Technical</option>
                                                <option>Other</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group text-left">
                                    <label className="modallogin-header">
                                        Comment
                                </label>
                                    <textarea type="text" id="comment" className="form-control" />
                                </div>
                                <div className="pb-2 " id="comment01" style={{marginTop:"-15px"}}></div>
                                
                                <div className="btn gradient1-bg gemplex-regular rounded mt-3 mb-3 text-white col-md-2 cursor" style={{ outline: 'none' }} onClick={this.postData}>Submit </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        )
    }
}