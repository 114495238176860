import React, { Component } from "react"; 
import $ from "jquery";
import cookie from "react-cookies";

import "../../../stylesheet/user/register.css";
export default class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
        themeFlag: cookie.load("theme") ? cookie.load("theme") === "false"? false : true : true
    };
  }
  componentDidMount = () => { 
    if (!this.state.themeFlag) {
        $("body").addClass("dark");
        $("body").removeClass("light");
      } else {
        $("body").addClass("light");
        $("body").removeClass("dark");
      }
  };
  openSignUpModal = () => {
    setTimeout(function () {
      $('#openSignIn').click()
    }, 500); 
  }
  onChange = (key, value) => {
    this.setState({ [key]: value });
  };
  themeSwitch = () => {
    const flagVal = this.state.themeFlag;
    this.setState({
      themeFlag: !flagVal
    });
    if (flagVal) {
      $("body").addClass("dark");
      $("body").removeClass("light");
      cookie.save("theme", !flagVal, { path: "/", maxAge: 365 * 86400 });
    } else {
      $("body").addClass("light");
      $("body").removeClass("dark");
      cookie.save("theme", !flagVal, { path: "/", maxAge: 365 * 86400 });
    }
  };
  loginPop=()=>{
    $("#sign_up_box").modal("show");  
  }
  render() {
    return (
      <div style={{display:'inline-block'}}>
       <span className="m-login" id="login_logo">
              <span
                className="d-inline-block  openSignIn"
                style={{ cursor: "pointer", margin: "0 0 0 10px"}}
                data-toggle="modal"
                data-backdrop="static"
                data-target="#sign_up_box"
              >
                <span className="border-align ">Log In</span>
              </span>
        </span>
        <div className="d-login" id="login_logo">
              <span className="cursor" onClick={this.loginPop} style={{ margin: "0 0 0 10px", fontSize: 16 }}>
                <span className="border-align br-5" style={{ border: "1px solid", padding: "8px 15px", cursor:'pointer' }}>
                  LOGIN
                </span>
              </span>
            </div> 
      </div>
    );
  }
}