import React, { Component } from "react"; 
import PageJourny from "../../util/page_journey.js" 

import Navigation from "../partials/NavigationBar.jsx";
import Banner from "../home_page/Banner.jsx";
import Footer from "../partials/footer";
import HomeSectionComponent from "../home_page/HomeSectionComponent";
import Breadcr from '../partials/breadcrumb';
import MetaData from "../partials/metaData.jsx";

class OriginalPage extends Component {
  constructor(props) {
    super(props);
    this.child = React.createRef();
    this.state = {
      catalogue: [],
      error: false,
      pageType: 'ORIGINALS',
      breadData: [],
      mdata: [],
    };
  }
  componentDidMount() {
    PageJourny.setDeviceInfo()
  }
  onClick = () => {
    this.refs.child.fetch_main_data('MOVIES', 'MOVIES');
  };
  handleLanguage(langValue) {
    this.setState({
      mdata: langValue
    })
  }
  render() {
    const { mdata } = this.state;
    const title_data = "Gemplex - Stream The New!";
    const description_data = "Gemplex. Watch original TV Shows and Movies on Gemplex. Online Streaming Now.";
    const keywords_data = "gemplex, online, streaming, movies, tv shows, latest, streaming new";
    const title_tag = "Gemplex - Stream The New!";
    return (
      <div>
        {/* <Header /> */}
        <MetaData  title={title_data} description={description_data} keywords={keywords_data} mainTitle={title_tag} />
        <Navigation active={'originals'} />
        <Breadcr mdata={mdata} />
        <Banner catalogueId={'ORIGINALS'} />
        <HomeSectionComponent onSelectLanguage={this.handleLanguage} ref="child" key={12345} catalogueName={""} bread='true' pageType={this.state.pageType} />
        <Footer />
      </div>
    );
  }
}
export default OriginalPage;