import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import Slider from "react-slick";

import HeaderJson from '../../util/headers.js';
import CommonApi from "../../util/common_api_handler.js"
import ApiList from "../../config/api.js"; 

import Alternative from "../../images/gemplex-vector logo-black.svg" 


class Recommended extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: this.props.type,
            redirect : false,
            contentId: '',
            keywords: this.props.keywords,
            content_type: this.props.content_type,
            catalogue: this.props.catalogue,
            slides: [],
            ratio: '',
            cell_size: '',
            cName: this.props.title,
            headName: "",
        }
    }

    componentDidMount = () => {
        this.fetchData()
    }

    fetchData = () => {
        const self = this;
        let keyArr = [];
        if (self.state.type !== "people") {
            self.state.keywords.map(function (item) {
              return  keyArr.push(item.genre_code ? item.genre_code : item.code)
            })
        }
        else {
            keyArr = [self.state.keywords]
        }
        const apiUrl = ApiList.current.url + ApiList.endpoints.recommended.getData;
        const bodyData = {
            "type": self.state.type,
            "keywords": keyArr,
            "catalogue": this.state.catalogue,
            "offset": 0,
            "limit": 30,
            "content_type": this.state.content_type,
        }
        const apiHeaders = HeaderJson.header()
        CommonApi.postCall(apiUrl, apiHeaders, bodyData)
            .then((data)=>{
                if (data.statusCode === 200 && data.data) {
                    let mainData = data.data ? data.data[0] : null
                    self.setState({
                        slides: mainData.data,
                        headName: mainData.catalogue_name,
                        ratio: mainData.ratio,
                        cell_size: mainData.cell_size,
                        baseUrl: mainData.base_url_video,
                        thumbnailFolder: mainData.thumbnail_folder
                    })
                }
            })
    }
    contentRedirect = (content_id) => {
        if (content_id) {
            this.setState({
                redirect : true,
                contentId: content_id,
            })
        }

    }
    render = () => {
        const { ratio, cell_size, cName, headName } = this.state

        let _class = 'ratio-4-5';
        let _cell_size = 'thumb-3';
        let toScroll = '2'
        if (cell_size && ratio) {
            if (ratio == '4:5') {

                _class = 'ratio-4-5'

            } else if (ratio == '4:3') {
                _class = 'ratio-4-3'

            } else if (ratio == '16:9') {
                _class = 'ratio-16-9'

            }
            if (cell_size == '3') {
                _cell_size = 'thumb-3'
                toScroll = '3'

            } else if (cell_size == '4') {
                _cell_size = 'thumb-4'
                toScroll = '4'
            } else if (cell_size == '6') {
                _cell_size = 'thumb-6'
                toScroll = '6'
            } else if (cell_size == '8') {
                _cell_size = 'thumb-8'
                toScroll = '8'
            }
        } 
        const settings = {
            className: [_class, _cell_size],
            centerMode: false,
            dots: true,
            infinite: false,
            speed: 500,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            swipeToSlide: true,
            responsive: [
                {
                    breakpoint: 4000,
                    settings: {
                        // slidesToShow: parseInt(toScroll) - 1,
                        slidesToScroll: parseInt(toScroll) - 1,
                        variableWidth: true,
                        adaptiveHeight: true
                    }
                },
                {
                    breakpoint: 2000,
                    settings: {
                        // slidesToShow: parseInt(toScroll) - 1,
                        slidesToScroll: parseInt(toScroll) - 1,
                        variableWidth: true,
                        adaptiveHeight: true
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        // slidesToShow: parseInt(toScroll) - 1,
                        slidesToScroll: parseInt(toScroll) - 1,
                        variableWidth: true,
                        adaptiveHeight: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        variableWidth: true,
                        adaptiveHeight: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        variableWidth: true,
                        adaptiveHeight: true,
                        dots: false
                    }
                }
            ]
        }
        return (
            <div>
    {/* {this.state.redirect === true &&  <Redirect push to={{ pathname: `/detail/${slide.meta_data.seo_title}/${this.state.contentId}`}} /> } */}
                {this.state.slides.length > 0 ?
                    <div className="has-animation animate-on-hover container-fluid mt-4 p-0">
                        <div className="col-xl-12  mb-4 p-0" >
                            <div className="word_space ">
                                <div className="d-inline-block primary1-text gemplex-medium sub-heading" id="view">
                                    {cName ? cName : headName}
                                </div> 
                            </div>
                        </div>
                        <Slider {...settings}>

                            {this.state.slides.map(function (slide) {
                                return (
                                    <a href={'/detail/' + slide.slug +"/"  + slide.content_id} key={slide._id} className="item">
                                        <div className="item-content position-realtive h-100">
                                            <div className="image w-100 h-100">
                                                <img src={ratio === '4:3' ? slide.base_url_thumbnail_4_3 + "/" + slide.platform_images.thumbnails_4_3 : ratio === '4:5' ? slide.base_url_thumbnail_4_5 + "/" + slide.platform_images.thumbnails_4_5 : ratio === "16:9" ? slide.base_url_thumbnail_16_9 + "/" + slide.platform_images.thumbnails_16_9 : Alternative} className="slide-img h-100 w-100 hover-card" alt={'Recommended'} />
                                            </div>
                                            <div className="play-button-content" style={{ outline: 'none' }}> </div>
                                            {slide.content_id ? <div className="video-play-button" style={{ outline: 'none' }} onClick={() => this.contentRedirect(slide)} key={slide.content_id}> <span></span></div> : ""}
                                            {slide.monetization_flag ? <div className="home-primary premium-icon position-absolute"></div> : null}
                                        </div>
                                        <div className="d-inline-block text-truncate serires-name" style={{ width: '100%' }} >
                                            <div className="gemplex-bold  primary2-text">  {slide.content_name ? slide.content_name : null}</div> <div className="duration-span">
                                                <span>{slide.released_year ? slide.released_year : null}<span className="seprator"> | </span><span> {slide.duration ? (slide.duration > 60 ? Math.floor(slide.duration / 60) + 'hr ' + (slide.duration % 60) + 'min' : slide.duration + 'min') : null} </span></span></div>
                                        </div>
                                    </a>
                                    // {/* </div> */}
                                );
                            })}
                        </Slider>


                    </div>
                    : null}
            </div>
        )

    }


}
function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "none" }}
            onClick={onClick}
        >
            <img src="https://preprod-gemplex-assets.gemplex.tv/web/scroll-arrow-to-right.svg" alt="next" />
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "none" }}
            onClick={onClick}
        >
            <img src="https://preprod-gemplex-assets.gemplex.tv/web/scroll-arrow-to-left.svg" alt="next" />
        </div>
    );
}


export default Recommended