import React from 'react';
import videojs from 'video.js';
import $ from 'jquery';
import '../../stylesheet/videojs/videojs.css';
import "../../stylesheet/hype_page/hype_video_responsive.css";
let player;
let current_playtime;
export default class VideoPlayerHype extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mainData: this.props.video_params,
            videoUrl: this.props.video_url,
            poster: this.props.video_poster,
            // videoRes: [],
            videoRes: this.props.videoResolution,
            videoLabel: this.props.videoLabel
        };
        
    }

    componentDidMount = () => {
        this.playerFunction()
    }
    componentWillReceiveProps = (prevProps) => {
        this.setState({
            videoRes: this.props.videoResolution,
            videoLabel: this.props.videoLabel
        })
        
    }
    playerFunction = () => {
        require('silvermine-videojs-quality-selector')(videojs);
        var videoPlayTime = require('videojs-playtime');
        videojs.plugin('videoPlayTime', videoPlayTime);
        player = videojs('my_video_1', {
            controls: true,
            controlBar: { volumePanel: { inline: false } },
            responsive: true,
            // aspectRatio: '16:9',
            fluid: true,
            autoplay: true,
            playbackRates: false,
            techCanOverridePoster: true,
            textTrackSettings: false,
            seekButtons: require('videojs-seek-buttons'),
            plugins: {
                controls: true,
                seekButtons: {
                    forward: 30,
                    back: 30
                },
                videoPlayTime: null

            },
        })


         // Append Dynamic Url in Video Tag...



         const self = this
         player = videojs('my_video_1', {
             plugins: {
               videoJsResolutionSwitcher: {
                 default: 'low',
                 dynamicLabel: true
               }
             }
           });
           player.src(
             this.props.videoLabel.map(function(key, i){ 
                 console.log(self.props.video_url + self.props.videoResolution[i])
                return [
                     {
                       src: self.props.video_url + "/"+self.props.videoResolution[i],
                       type: 'application/x-mpegURL',
                       label: key,  
                     },
                   ]
             }))


        var tracks = player.textTracks();
        
        player.controlBar.addChild('QualitySelector');
        for (var i = 0; i < tracks.length; i++) {
            var track = tracks[i];
            // Find the English captions track and mark it as "showing".
            if (track.kind === 'captions' && track.language === 'en') {
                track.mode = 'showing';
            }
        }
        player.on('ended', function () {
            // this.props.nextVideo.click()
            $('#play-next').click()
        });
        // hide the video and this current page confirm..
        $(".hide-page-content").click(function () {
            $(".video-js-section").hide("slow");
            // $("").show("slow");
        })
        player.playTime();
        // player.currentTime(localStorage.getItem('lastplayed') ? localStorage.getItem('lastplayed'):this.state.playTime) 

        // var threshold = 4;
        var thresholdReached = false;
        // player = this;
        player.on('timeupdate', function () {
            if (player.currentTime() >= !thresholdReached) {
                thresholdReached = true;
                if (!player.paused()) {
                    current_playtime = player.currentTime()
                }
            }
        });
    }
    // destroy player on unmount
    componentWillUnmount = () => {
        player.dispose()
    }

    render = () => {
        const self = this;
        const { poster, videoUrl, videoRes,srtVideoUrl } = this.state
        const { video_url, videoLabel, videoResolution, mainData } = this.props
        return (
            <div className="row video-js-section hype-responsive-ht">
                
                {/* {mainData.label_name.map(function (item, i) {
                    return(<h1></h1>);
                    // return (
                    //     <h1>
                    //         {mainData.resolutions[i]}
                    //     </h1>
                    //     // <source src={mainData.resolutions[i] ? videoUrl + mainData.resolutions[i] : 'master.m3u8'} type="application/x-mpegURL" label={item ? (item ? (item) : "Auto") : " "} ></source>                                        // <source src={'https://dev-gemplex-movies.gemplex.tv/transcoder/assets/180000040/master.m3u8?Expires=1564296544&Key-Pair-Id=APKAIRSV4TUQATCEP5JQ&Signature=A6sLbiriXLJHbh5pP~9gWNga6Y5V1-1w1Tfw0tKwP5IlqogfQf7MJgZ7MtW-OsKEIEOjgPe2MrIL7KhPHRPp6~tuSW5HOzA029jtynXcjt4xl-oSmsUxSVdJTte9Fczfle8N~aP~uIHTEjsblLFDJkiqkGK5vs4vGvioyPkNvQacwDzm8TOewUFNJryr8ZfyRLu-eJHQ-vlOi8oWjuZ4B2jq-XLXQy0K3Xzy0NqhUEDyG9UaJnWfBosPVRK9dIYU7RnDcX4DDzYU74nDJP0Y3qobHa2WVd3MZN5mtgAD92HTpi0e0OT31cqg6oEr53X-Q3UuCMM2xqDe6UWk8IUGlQ__'} type="application/x-mpegURL"  key={item} ></source>
                    // )
                })} */}
                <div className="col-md-7 p-0 hype-videojs-responsive">
                    <video poster={poster ? poster : null} id="my_video_1" className="w-100 video-js vjs-fluid vjs-default-skin vjs-big-play-centered hover-card" width="640" height="264">
                    { mainData.video[0].srt && mainData.video[0].srt .map(function (item, i) {
                        
                        return (
                            <track kind='captions' src={mainData.srt_base_url + "/" + item.srt_url} srclang={item.language_name} label={item.language_name} />
                        )
                    })}
                    </video>
                    {/* {video_url ?
                        <video poster={poster ? poster : ""} id="my_video_1" width="300" height="264" className="w-100 video-js vjs-fluid vjs-default-skin vjs-big-play-centered hover-card vjs-16-9">

                            {videoResolution.map(function (item, i) {
                                return (
                                    <source src={`${video_url}/master.m3u8`} type="application/x-mpegURL" label={videoLabel[i] || "Auto"}></source>
                                )
                            })}
                        </video>
                        :
                        ""
                    } */}
                </div>
            </div>
        )
    }
}