import React, { Component } from 'react'; 
export default class ad extends Component {
    render() {
        return (
            <div>
                <div className="container-fluid my-1 p-5">
                    <div className="row justify-content-center my-3">
                        <div className="col-md-12 text-center">
                         <img src={"https://preprod-gemplex-web.s3.ap-south-1.amazonaws.com/gemplex-web_ad01-1280-300.jpg"} alt="Gemplex Ad Banner" className="h-100 w-100 img-fluid"/>
                        </div>
                    </div>
                </div>
            </div>

        )
    }

}