/* eslint-disable no-useless-escape */
import React, { Component } from 'react';
import $ from 'jquery';
import swal from 'sweetalert';

import ApiList from "../../config/api.js"
import CommonApi from "../../util/common_api_handler.js";
import HeaderJson from '../../util/headers.js';

import "../../stylesheet/information_page/feedback.css"

export default class FeedBack extends Component {
    constructor() {
        super();
        this.state = {
             header: HeaderJson.header(),
        }
    }

    postData = () =>{
        const self = this;
        const userCommnet = document.getElementById('comment').value;
        const emailId = document.getElementById('email_id').value;
        const phoneNumber = document.getElementById('mobile_no').value;
        const userfile = document.getElementById('inputGroupFile01').value;
         
        let dataString = {
            "name": userCommnet ? userCommnet : "",
            "email_id": emailId ? emailId :"",
            "mobile_no": phoneNumber ? phoneNumber: "",
            "screenshot": userfile? userfile: "",
        }

        if (userCommnet === "") {
            document.getElementById("nameError01").innerHTML = "please fill the comment";
        } else {
            document.getElementById("nameError01").innerHTML = "";
        }

        if (emailId === "") {
            document.getElementById("EmailError").innerHTML = "Please fill the valid email";
            
        }else if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailId)){
            document.getElementById("EmailError").innerHTML = "";
        }else{
            document.getElementById("EmailError").innerHTML = "Please fill the valid email";
        }
        
        if (phoneNumber === "") {
            document.getElementById("ContectError").innerHTML = "Please fill the phone no";
        }
        else{
            document.getElementById("ContectError").innerHTML = "";
        }
        if(phoneNumber !=="" && emailId !=="" && userCommnet !=="" && (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailId))){
            let apiUrl=ApiList.current.url + ApiList.endpoints.footer.career;
            let apiHeaders=self.state.header;
            let bodyData=dataString;
    
            CommonApi.postCall(apiUrl, apiHeaders, bodyData)
            .then(data=>{
                if (data.statusCode === 200) {
                    swal({ text: "Sent Successfully", timer: 1500 })
                  }
               })
        }
       
    }

    phoneNumberLength = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(
                0,
                object.target.maxLength
            );
        }
    }

    AllowOnlyNum = (event) => {

        var inputval = document.getElementById('mobile_no');
        inputval.value = inputval.value.replace(/[^0-9]+/, '').trimLeft();

    }
    render() { 
        $('.div-data').html(this.props.getData)
        return (
            <> 
                <div className="container">
                   <div className=" mt-3 catalogue_name primary3-text" >FeedBack</div>
                    <div className='row py-3' >
                        <div className="col-md-6">
                            <label className="  content-head" >Comment</label>
                            <div className="input-group mb-3">
                                <textarea className="form-control" name="comment" id="comment" placeholder="Start Typing..." rows="2" />
                            </div>
                            <div className="pb-2 " id="nameError01" style={{marginTop:"-15px"}}></div>
                            <label  className="content-head">Your Email</label>
                            <div className="input-group mb-3">
                                <input className="form-control" name="comment" id="email_id" placeholder="Enter Email" onBlur={this.ValidateEmail} />
                            </div>
                            <div className="pb-2 " id="EmailError" style={{marginTop:"-15px"}}></div>

                            <label  className="content-head">Contact</label>
                            <div className="input-group mb-3">
                                <input className="form-control" name="contactno" type="number" id="mobile_no" placeholder="Enter Mobile Number"minLength={10} maxLength={10} onInput={this.phoneNumberLength} onKeyPress={this.AllowOnlyNum} />
                            </div>
                            <div className="pb-2 " id="ContectError" style={{marginTop:"-15px"}}></div>

                            <label className="content-head">Attach a screenshot</label>
                            <div className="input-group mb-3">
                                <div className="custom-file">
                                    <input type="file" className="custom-file-input" id="inputGroupFile01" />
                                    <label className="custom-file-label" for="inputGroupFile01" style={{fontSize:"13px",fontFamily:"gemplex-light"}}>Choose file</label>
                                </div>
                            </div>
                            <div className="btn gradient1-bg gemplex-regular rounded mt-3 mb-3 text-white col-md-4 cursor" style={{ outline: 'none' }} onClick={this.postData}>Submit </div> 
                        </div>
                        <div className="col-md-6 div-data"> 
                        </div>
                    </div>
                </div> 
            </>

        )
    }

}