/* eslint-disable indent */
import React, { Component } from "react";
import Breadcr from "../partials/breadcrumb";
import Banner from "../home_page/Banner.jsx";
import Footer from "../partials/footer";
import HomeSectionComponent from "../home_page/HomeSectionComponent";
import Navigation from "../partials/NavigationBar.jsx";
import PageJourny from "../../util/page_journey.js";
import MetaData from "../partials/metaData.jsx";
 
import "../../stylesheet/home_page/index.css";
import "../../stylesheet/partials/hover-slider.css";
import "../../stylesheet/partials/slider-nav.css";


class OriginalPage extends Component {
  constructor() {
    super();
    this.state = {
      catalogue: [],
      error: false,
      mdata: []
    };
  }
  componentDidMount() {
    PageJourny.setDeviceInfo()
  }
  handleLanguage(langValue) { 
    this.setState({
      mdata: langValue
    })
  }

  render() {
    const { mdata } = this.state;
    const title_data = "Gemplex - Stream The New!";
    const description_data = "Gemplex. Watch original TV Shows and Movies on Gemplex. Online Streaming Now.";
    const keywords_data = "gemplex, online, streaming, movies, tv shows, latest, streaming new";
    const title_tag = "Gemplex - Stream The New!";
    return (
      <div>
        {/* <Header /> */}
        <MetaData  title={title_data} description={description_data} keywords={keywords_data} mainTitle={title_tag} />
        <Navigation active={'movies'} />
        <Breadcr mdata={mdata} />
        <Banner catalogueId={'MOVIES'}  />
        {/* <Filter Language_type="video" showName='Genre' type='video' /> */}
        <HomeSectionComponent onSelectLanguage={this.handleLanguage} key={12345} catalogueName={''} pageType={'MOVIES'} bread='true' />
        <Footer />
      </div>

    );
  }
}
export default OriginalPage;