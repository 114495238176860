import React, { Component } from "react";
import $ from 'jquery';
import Slider from "react-slick";

import ApiList from "../../config/api.js";
import CommonApi from "../../util/common_api_handler.js";
import HeaderJson from '../../util/headers.js';

import '../../stylesheet/home_page/language.css';

var prev = "https://preprod-gemplex-assets.gemplex.tv/web/scroll-arrow-to-left.svg";
var next = "https://preprod-gemplex-assets.gemplex.tv/web/scroll-arrow-to-right.svg";

class Language extends Component {
  state = {
    slides: [],
    baseUrl: '',
    redirect: false,
    content : '',
    header : ''
  };

  componentDidMount = () => {
    const self = this;
    let hasScrolled = false;
    $(window).scroll(() => {
      if (window.pageYOffset > 1000 && !hasScrolled) {
        self.fetchLanguage(100, 9);
        hasScrolled = true;
      }
    });
  }

  fetchLanguage = () => {
    const self = this;

    const apiUrl = ApiList.current.url + ApiList.endpoints.language.language;

    const apiHeaders = HeaderJson.header();

    const bodyData = {
      "limit": 99,
      "offset": 0,
      "type": self.props.Language_type
    }

    CommonApi.postCall(apiUrl, apiHeaders, bodyData)
      .then((data) => {
        if (data.statusCode === 200 && data.data) {
          const json_data = data.data;
          let component_arry_var = self.state.slides.slice();
          for (let i = 0; i < json_data.length; i++) {
            component_arry_var.push(json_data[i]);
          }
          self.setState({
            slides: component_arry_var,
            baseUrl: data.base_url
          })
        }
      })
  }

  click = () => {
    this.setState({
      slides: this.state.slides.length === 6 ? [1, 2, 3, 4, 5, 6, 7, 8, 9] : [1, 2, 3, 4, 5, 6]
    });
  }

  detailsSection = () => {
    const { show } = this.state;
    this.setState({ show: !show });
  };

  sentGenreData = (content , header) => {
    const self = this;
    self.setState({
      redirect: true,
      content : content,
      header : header
    })
    window.location.href = `/language/type=${this.props.type}/content=${content}/heading=${header}`
  }

  render() {
    const self = this;
    const settings = {
      className: 'language',
      centerMode: false,
      dots: true,
      infinite: false,
      speed: 500,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      swipeToSlide: true,
      responsive: [
        {
          breakpoint: 4000,
          settings: {
            slidesToScroll: 10,
            variableWidth: true,
            adaptiveHeight: false
          }
        },
        {
          breakpoint: 2000,
          settings: {
            slidesToScroll: 10,

            variableWidth: true,
            adaptiveHeight: false
          }
        },
        {
          breakpoint: 1024,
          settings: {

            slidesToScroll: 10,
            variableWidth: true,
            adaptiveHeight: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: true,
            adaptiveHeight: true,
            dots: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: true,
            adaptiveHeight: true,
            dots: false

          }
        }
      ]
    };
    const { slides, baseUrl } = this.state
    return (
      <div>
        <div className="container-fluid has-animation my-4 primary-container-padding">
          {slides.length !== 0 ?
            <div className="col-md-12 mt-5 mb-2 p-0" >
              <div className="word_space ">
                <div className="d-inline-block catalogue_name text-uppercase" id="view">
                  Language
              </div>
                <div className="behind-heading d-inline-block">
                </div>
              </div>
            </div>
            : null}
          <div className="container-fluid p-0">
            {this.state.slides ? (
              <Slider {...settings}>
                {this.state.slides.map(function (slides) {
                  return (
                    <div className="item text-center mt-3 px-2 d cursor" key={slides._id}>
                      <div className="language-container" onClick={()=>self.sentGenreData(slides ? slides.language_code : null , slides ? slides.language_name : null)}>
                        <img src={baseUrl + "/" + slides.thumbnail_4_4} className="language-img" alt='' />
                      </div>
                      <span>{slides.language_name ? slides.language_name : null}</span>
                    </div>
                  )
                })}
              </Slider>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none" }}
      onClick={onClick}
    >
      <img src={next} alt={next} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "none" }}
      onClick={onClick}
    >
      <img src={prev} alt={next} />
    </div>
  );
}

export default Language;
